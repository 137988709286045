import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdataByIdim_principal = (id) => {
  return httpClient.get(server.IM_PRINCIPAL + `/getbyid/${id}`);
};

export const getAllim_principal_Company = (query) => {
    return httpClient.get(server.IM_PRINCIPAL + `/getAllim_principal_Company`, query);
  };

  export const getfile_numberim_principal_Company = (query) => {
    return httpClient.get(server.IM_PRINCIPAL + `/getfile_numberim_principal_Company`, query);
  };

export const addim_principal = (data) => {
  return httpClient.post(server.IM_PRINCIPAL + `/`, data);
};

export const updateim_principal = (id,data) => {
  return httpClient.put(server.IM_PRINCIPAL + `/${id}`, data);
};

export const deleteim_principal = (id) => {
  return httpClient.delete(server.IM_PRINCIPAL + `/${id}`);
};
