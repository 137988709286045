import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getRequestAll = () => {
  return httpClient.get(server.ADVANCE_D_URL + `/get`);
};

export const getAllDById = (id) => {
  return httpClient.get(server.ADVANCE_D_URL + `/getAllDetailById/${id}`);
};

export const printInvAD = (id) => {
  return httpClient.get(server.ADVANCE_D_URL + `/print_inv_ad/${id}`);
};

export const getRequest = (employee_id) => {
  return httpClient.get(server.ADVANCE_D_URL + `/get/${employee_id}`);
};

export const getclearRequest = (employee_id) => {
  return httpClient.get(server.ADVANCE_D_URL + `/getclear/${employee_id}`);
};

export const addAdvanceD = (data) => {
  return httpClient.post(server.ADVANCE_D_URL, data);
};
export const getAdvanD = (data) => {
  return httpClient.post(server.ADVANCE_D_URL + `/getadvanD`, data);
};

export const updateAdvanceD = (id, data) => {
  return httpClient.put(server.ADVANCE_D_URL + `/${id}`, data);
};

export const deleteAdvanceD = (id) => {
  return httpClient.delete(server.ADVANCE_D_URL + `/${id}`);
};
