import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getInvTitle = () => {
    return httpClient.get(server.INV_MIS_LIST);
};

export const getInvMiscellaneousByCompany = (query) => {
    return httpClient.get(server.INV_MIS_LIST +`/getbycompany`,query);
};

export const getInvMiscellaneousByDep = (dep_id) => {
    return httpClient.get(server.INV_MIS_LIST +`/get-by-dep_id/${dep_id}`);
};

export const getInvMiscellaneousByID = (id) => {
    return httpClient.get(server.INV_MIS_LIST +`/get-by-id/${id}`);
};

export const addInvMiscellaneous = (data) => {
    return httpClient.post(server.INV_MIS_LIST, data);
};

export const UpdateInvMiscellaneous = (id, data) => {
    return httpClient.put(server.INV_MIS_LIST +`/${id}`,data);
};

export const DeleteInvMiscellaneous = (id) => {
    return httpClient.delete(server.INV_MIS_LIST +`/${id}`);
};

export const getInvMiscellaneousByCom_Dep = (query) => {
    return httpClient.get(server.INV_MIS_LIST +`/getbycompanyanddepartment`,query);
};