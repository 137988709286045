import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const getRequestAll = () => {
  return httpClient.get(server.ADVANCE_H_URL + `/get`);
};

export const getRequest = (employee_id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/get/${employee_id}`);
};

export const getclearRequest = (employee_id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getclear/${employee_id}`);
};

export const getapproveAdvance = (id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getapproveadvance/${id}`);
};

export const getapproveAdvanceByCompany = (id, query) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getapproveadvancebycompany/${id}`, query);
};