import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDocDropByDoctempID = (doctemplate_id) => {
    return httpClient.get(server.DOCDROPDOWN + `/bydoctemplateid/${doctemplate_id}`);
};

export const CreateDocDropDown = (data) => {
    return httpClient.post(server.DOCDROPDOWN, data);
};

export const updateDocDropDown = (id, data) => {
    return httpClient.put(server.DOCDROPDOWN + `/${id}`, data);
};


export const DeleteDocDropDown = (doctemplate_id) => {
    return httpClient.delete(server.DOCDROPDOWN +`/${doctemplate_id}`);
};
export const DeleteDocDropDownByID = (id) => {
    return httpClient.delete(server.DOCDROPDOWN +`/byid/${id}`);
};