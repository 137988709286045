import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getTRANNING_USER_D = () => {
  return httpClient.get(server.TRANNING_USER_D);
};

export const getTRANNING_USER_DById = id => {
    return httpClient.get(server.TRANNING_USER_D + `/${id}`);
};

  
export const addTRANNING_USER_D = data => {
    return httpClient.post(server.TRANNING_USER_D, data);
};
  
export const updateTRANNING_USER_D = (id,data) => {
    return httpClient.put(server.TRANNING_USER_D + `/${id}`, data);
};

export const deleteTRANNING_USER_D = id => {
    return httpClient.delete(server.TRANNING_USER_D + `/${id}`);
};

