//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import unity from "@/unity/unity";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";

export default {
  name: "NewAdvance",
  data() {
    return {
      menu: false,
      menu2: false,
      os_h: {
        saleorder_id: "",
        customerid: 0,
        remark: "",
        po_no: "",
        po_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        delivery_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        additional: "",
        status: 0,
        image: "",
      },
      add_sod: {
        sales_order_h_id: 0,
        seq: 0,
        item_name: "",
        item_qty: 0,
        item_price: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      add_leaveQuota: {},
      dialogDeleteimage: false,

      desserts: [],
      headers: [
        // {
        //   text: "ID",
        //   value: "leave_id",
        //   class: "bg-colorth",
        //   width: "10%",
        //   align: "start",
        //   divider: true,
        //   sortable: false,
        // },
        {
          text: "Leave Type",
          align: "center",
          value: "leave_type",
          class: "bg-colorth",
          width: "30%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Quota(Days)",
          value: "actionsD",
          class: "bg-colorth texttablehcenter ",
          width: "30%",
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "Quota(Hour)",
          value: "actionsH",
          class: "bg-colorth texttablehcenter ",
          width: "30%",
          divider: true,
          align: "center",
          sortable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        no: 0,
        description: "",
        amount: 0,
        unit: 0,
        PricePerUnit: 0,
      },
      advance_log: {
        module: "SO",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },

      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      customerNameList: [],
      customerList: [
        {
          cust_account: "",
        },
      ],
      employeelist: [
        {
          id: 0,
          department_name: "",
          position_name: "",
        },
      ],
      cus_id: 0,
      datePO: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateDelivery: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogDelete: false,
      no_cus: 0,
      sumtotal: 0.0,
      vatCal: 0,
      vatCalSum: 0.0,
      vat: "0.00",
      sumGrandTotal: 0.0,
      preview_list: [],
      image_list: [],
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      companyAll: [],
      approver: [],
      emp_id: 0,
      // datefrom: "",
      // dateto: "",
      datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      checklistNull: 0,
      company_id: 0,
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateFromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.dateto);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();
    this.$forceUpdate();

    this.loadCustomer();

    // // alert(result.data[0])
    // console.log("testresult")
    //console.log(this.customerNameList)

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    const result = await api.getAllCustomerListactive();
    // console.log("result.data : ", JSON.stringify(result.data));
    this.customer = result.data;

    // const resultlist = await api.getCustomers();
    // this.customerNameList = result.data[0];
    this.customerNameList = result.data;
  },
  methods: {
    async loadCustomer() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      // const result = await api.getAllCompany();
      // this.companyAll = result.data;

      var result;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   result = await api.getAllCompany();
      // } else {
      result = await api.getCompanyByID(localStorage.getItem(server.COMPANYID));
      // }
      this.companyAll = result.data;

      // const resultList = await api.getAccountslist();
      // this.approver = resultList.data;

      // const resultleaveype = await api.getAllleave_type();
      const resultleaveype = await api.getLeaveTypeAll(localStorage.getItem(server.COMPANYID));
      let resultleaveypeD = resultleaveype.data;
      resultleaveypeD.forEach((item, index) => {
        item.disabled = true;
        item.quotaD = 0;
        item.quotaH = 0;
      });

      this.desserts = resultleaveypeD;

      console.log(this.desserts);

      loader.hide();
    },
    async changeRoute(item) {
      //console.log(item.item)
      if (item.item.quotaD < 0 || item.item.quotaH < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Check Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (item.item.quotaH < 0 || item.item.quotaH >= 8) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Check Data Quota(Hour) " +
            item.item.leave_type +
            " in ( 0 - 7 ) Hour",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async ckeckperiod() {
      if (this.company_id == 0 && this.employeelist[0].id == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Period Select Company and employee",
          "text-h5 red--text text-center"
        );
        this.datefrom = "";
        this.dateto = "";

        return;
      }
      if (this.employeelist[0].id == 0 || this.employeelist[0].id == "0") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Select Employee Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.datefrom != "" && this.dateto != "") {
        if (this.datefrom > this.dateto) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Error",
            "Period From > Period To",
            "text-h5 red--text text-center"
          );
          return;
        }
        if (this.employeelist[0].id != "") {
          const checkperiod = await api.getcheckperiodquota(
            this.employeelist[0].id,
            this.datefrom,
            this.dateto
          );
          //console.log(checkperiod.data[0].status);
          if (checkperiod.data[0].status > 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Error",
              "ช่วงเวลาซ้ำ กรุณาตรวจสอบข้อมูล",
              "text-h5 red--text text-center"
            );
            return;
          }
        }
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async saveQuota() {
      // alert(this.datefrom);
      // alert(this.dateto);

      if (
        this.company_id == 0 ||
        this.company_id == "0" ||
        this.company_id == "" ||
        this.company_id == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Select Company",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.employeelist[0].id == 0 || this.employeelist[0].id == "0") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Select Employee Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.datefrom == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Enter Period From",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dateto == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Enter Period To",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.datefrom > this.dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Period From > Period To",
          "text-h5 red--text text-center"
        );
        return;
      }

      const checkperiod = await api.getcheckperiodquota(
        this.employeelist[0].id,
        this.datefrom,
        this.dateto
      );
      //console.log(checkperiod.data[0].status);
      if (checkperiod.data[0].status > 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "ช่วงเวลาซ้ำ กรุณาตรวจสอบข้อมูล",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.checklistNull == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Error",
          "Please Select Leave Quota ",
          "text-h5 red--text text-center"
        );
        return;
      }

      for (let i = 0; i < this.desserts.length; i++) {
        if (this.desserts[i].disabled) {
        } else {
          if (this.desserts[i].quotaD == "") {
            this.desserts[i].quotaD = 0;
          }
          if (this.desserts[i].quotaH == "") {
            this.desserts[i].quotaH = 0;
          }
          if (this.desserts[i].quotaH < 0 || this.desserts[i].quotaH >= 8) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Error",
              "Please Check Data Quota(Hour) " +
                this.desserts[i].leave_type +
                " in ( 0 - 7 ) Hour",
              "text-h5 red--text text-center"
            );
            return;
          }
          if (this.desserts[i].quotaH < 0 || this.desserts[i].quotaH >= 8) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Error",
              "Please Check Data Quota(Hour) " +
                this.desserts[i].leave_type +
                " in ( 0 - 7 ) Hour",
              "text-h5 red--text text-center"
            );
            return;
          }
          if (this.desserts[i].quotaD <= 0 && this.desserts[i].quotaH <= 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Error",
              "Please Enter Data Quota " + this.desserts[i].leave_type,
              "text-h5 red--text text-center"
            );
            return;
          }
        }
      }
      // console.log(this.desserts);

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      this.desserts.forEach(async (item) => {
        if (item.disabled) {
        } else {
          this.add_leaveQuota.employee_id = this.employeelist[0].id;
          this.add_leaveQuota.leave_period_from = this.datefrom;
          this.add_leaveQuota.leave_period_to = this.dateto;
          this.add_leaveQuota.leave_type_id = item.id;
          this.add_leaveQuota.quota =
            Number(item.quotaD) + Number(item.quotaH) / 8;
          this.add_leaveQuota.company_id = this.company_id;
          this.add_leaveQuota.remark = this.remark;
          this.add_leaveQuota.status = "A";
          await api.createLeaveQuota(this.add_leaveQuota);
        }
      });
      this.$store.state.global_push_dialog = true;
      this.setupAlertDialogPush(
        true,
        "Information Saved",
        "Information successfully saved",
        "text-h5 green--text text-center",
        "/leave-quota"
      );

      loader.hide();
    },
    async showEmp() {
      const result = await api.getAccountByid(this.emp_id);
      this.employeelist = result.data;
    },
    async testtestD(item) {
      this.desserts[item.index].quotaD = Number(item.quotaD);
      console.log(this.desserts)
    },
    async testtestH(item) {
      this.desserts[item.index].quotaH = Number(item.quotaH);
    },
    enterSelect(values) {
      if (values.length > 0) {
        this.checklistNull = 1;
      } else {
        this.checklistNull = 0;
      }
      this.desserts.forEach((item) => {
        item.disabled = true;
      });
      values.forEach((item, index) => {
        item.disabled = false;
      });
    },
    closeDelete() {
      this.sumtotal = 0;
      this.sumGrandTotal = 0;

      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        this.sumtotal += parseInt(item.amount, 10);
      });
      this.sumGrandTotal = (this.sumtotal * (1 + this.vatCal / 100)).toFixed(2);
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // deleteItem(item) {
    //   console.log(item.id)
    // },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      //console.log(this.image_list);
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
    async changeCompany(selectObj) {
      const resultList = await api.getAccountsByCompany(selectObj);
      this.approver = resultList.data;
    },
  },
  async beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
