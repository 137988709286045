//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import api from "@/services/api";
  import { server } from "@/services/constants";
  import StockCard from "@/components/cards/StockCard";
  import SuccessDialog from "@/components/cards/SuccessDialog";
  import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
  import unity from "@/unity/unity";
  import pdfvuer from "pdfvuer";
  import {
    tolocalestringnumber,
    stringcommatonumber,
    } from "../computing/tolocalestringnumber";
import {
  pettyCashFile,
  pettyCashImage,
} from "@/services/constants";
  export default {
    name: "NewAdvance",
    data() {
      return {
        //file
        file_ori: [],
        previewpopupfile2: [],
        filenameattach: [],
        filenameattach_old: [],
        previewpopupattachfile2: [],
        previewpopupattachfile: [],
        checkattachfileapreview: false,
        dialogpreviewattachfile: false,
        dialogpreviewattachimage: false,
        dialogpreviewattachimage_old: false,
        dialogconfirmdeleteattachfile: false,
        dialogconfirmdeleteattachfile_old: false,
        numPagespreviewattach: 0,
        previewattachimage: "",
        previewattachimage_old: "",
        indexdeleteattachfile: -1,
        getpreview_listattach: [],
        getpreview_listattach_old: [],
        preview_listattach: [],
        image_listattach: [],
        image_listattach_old: [],
                  //--
        disabled_inputtext: false,
        disabled_inputtext_reject: false,
        disabled_editsing_approver: true,
        buttuon_approve: true,
        buttuon_reject: true,
        disabled_editsing_account: true,
        disabled_editsing_request: true,
        pc_id: "0000",
        showbtnadd: true,
        showbtnapprove: false,
        advance_code: [],
        advance_code_list: [],
        advance_code_id: 0,
        dialogAdd: false,
        dialogSendMail: false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        customer: [],
        customer_list: [],
        pch_list: [],
        customer_id: 0,
        emp_draw: 0,
        emp_draw_jobtitle: 0,
        options: {
          penColor: "#000000",
          backgroundColor: "rgb(255,255,255)",
        },
        initial_data2: "",
        initial_data: "",
        pickbank: false,
        pickcheque: false,
        checkcheque: false,
        checkchequefilled: false,
        showsig1: false,
        showsig2: false,
        showsig3: false,
        showbuttonsavesig1: false,
        showbuttonsavesig2: false,
        showbuttonsavesig3: false,
        showeditsing3: false,
        defaultwordbuttonsig1: "Signature",
        defaultwordbuttonsig2: "Signature",
        defaultwordbuttonsig2: "Signature",
        totalleftoveramount: 0.0,
        sumwithdrawamount: 0.0,
        sunamountcleared: 0.0,
        showallamount: "",
        desserts: [
          {
            no: "1",
            detail: "",
            withdrawamount: 0,
          },
        ],
        selectbankname: "",
        bankname: [
          "ธนาคารกรุงเทพ",
          "ธนาคารกสิกรไทย",
          "ธนาคารกรุงไทย",
          "ธนาคารทหารไทยธนชาต",
          "ธนาคารไทยพาณิชย์",
          "ธนาคารกรุงศรีอยุธยา",
          "ธนาคารเกียรตินาคินภัทร",
          "ธนาคารซีไอเอ็มบีไทย",
          "ธนาคารทิสโก้",
          "ธนาคารยูโอบี",
          "ธนาคารไทยเครดิตเพื่อรายย่อย",
          "ธนาคารแลนด์ แอนด์ เฮ้าส์",
          "ธนาคารไอซีบีซี (ไทย)",
          "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
          "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
          "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
          "ธนาคารออมสิน",
          "ธนาคารอาคารสงเคราะห์",
          "ธนาคารอิสลามแห่งประเทศไทย",
        ],
        radioType: ["cash", "cheque"],
        radioGroup: "",
        setusername: localStorage.getItem(server.USERNAME),
        showrequester: "",
        role: localStorage.getItem(server.ROLE),
        department: "",
        department_show: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateCurrent: null,
        menu: false,
        modal: false,
        menu2: false,
        search: "",
        selectedProductId: "",
        confirmDeleteDlg: false,
        mDataArray: [],
        // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
        fullPage: true,
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: "Particular",
            value: "description",
            class: "my-header-style",
          },
          { text: "Amount", value: "amount", class: "my-header2-style " },
          {
            text: "Action",
            value: "actions",
            class: "my-header3-style  text-center",
          },
        ],
        desserts: [],
        jobtitle_list: [],
        editedIndex: -1,
        editedItem: {
          code: "",
          description: "",
          amount: 0,
        },
        defaultItem: {
          code: "",
          description: "",
          amount: 0,
        },
        advance_d: {
          advance_hid: "",
          advance_title: "",
          description: "",
          amount: 0,
        },
        advance_h: {
          advance_id: "",
          customer_id: 0,
          employee_id: 0,
          department_id: 0,
          company_id: localStorage.getItem(server.COMPANYID),
          advance_date: null,
          sum_amount: 0,
          clear_date: null,
          payment_type: null,
          bank_name: null,
          bank_number: null,
          cheque_number: null,
          status: null,
          remark: null,
          create_by: null,
          create_date: null,
        },
        lbl_date_request: false,
        dialogSignature: false,
        dialogSaveDraft: false,
        disabled_file_name: false,
        messageSaveDraft: "",
        paymentType: "",
        bankName: "",
        bankNumber: "",
        chequeNumber: "",
        remark: "",
        remark_reject: "",
        disabled_reject: false,
        emp_list: [],
        sendMail: {
          name: "",
          email: "",
          advance_id: "",
          link: "",
        },
        account: {
          signature: "",
        },
        advance_log: {
          module: "AD",
          module_id: 0,
          approve_id: 0,
          approve_status: 0,
          approve_date: null,
        },
        approver_status: 0,
        reject_status: 0,
        loadRouter: ""
      };
    },
    components: {
      StockCard,
      SuccessDialog,
      SuccessDialogPush,
    },
    computed: {
      version() {
        return this.initial_data;
      },
      formTitle() {
        return this.editedIndex === -1 ? "New Item" : "Edit Item";
      },
      computedExpectedClearDateFormatted() {
        return unity.formatDate(this.date);
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
      dialogDeletefile(val) {
        val || this.closeDeletefile();
      },
    },
  
    async mounted() {
      // this.$showLoader();
      this.$hideLoader();
  
      this.$forceUpdate();
  
      this.initialize();
      this.loadEmp();
      this.loadJobtitle();
      this.loadCustomer();
      this.loadPCH();
      this.loadPCD();
      this.loadDataFile();

  
      
      // this.$refs.firstsignaturePad.lockSignaturePad();
  
      // let yourUrlString = window.location;
      // alert(yourUrlString);
  
      // let parser = document.createElement("a");
      // parser.href = yourUrlString;
  
      // parser.protocol; // => "http:"
      // parser.hostname; // => "example.com"
      // parser.port; // => "3000"
      // parser.pathname; // => "/pathname/"
      // parser.search; // => "?search=test"
      // parser.hash; // => "#hash"
      // parser.host; // => "example.com:3000"
  
      // alert(parser.pathname);
  
      const userId = localStorage.getItem(server.USER_ID);
      if (userId && api.isLoggedIn()) {
        // this.$router.push("/");
      } else {
        this.$store.state.isLogged = false;
        this.$router.push("/login");
      }
      // console.log("Login mounted : " + api.isLoggedIn());
      // console.log("Login mounted : " + userId);
      // await this.loadProducts();
      
    },
    methods: {
      //set auto complte
  
        async loadEmp() {
          const result = await api.getAccountslist_by_ComID(
            localStorage.getItem(server.COMPANYID)
          );
  
          this.emp_list = result.data;
        },
        async loadJobtitle() {
          const result = await api.getAllJobtitleByCompany(
            localStorage.getItem(server.COMPANYID)
          );
  
          this.jobtitle_list = result.data;
        },
  
        async loadCustomer() {
          // console.log("loadCustomer");
          const result = await api.getAllCustomerListactiveCompany({
            params: {
              company_id: localStorage.getItem(server.COMPANYID),
            },
          });
          
          this.customer_list = result.data;
  
        },
        async loadPCH() {
            const result_pch = await api.getDataPCByIDSql(this.$route.params.id);
            this.pch_list = result_pch.data;
            console.log("result_pch.data")
            console.log(result_pch.data)
            this.showrequester = this.pch_list[0].petty_emp;
            this.department_show = this.pch_list[0].dp_name;
            this.emp_draw = this.pch_list[0].employee_id;
            this.emp_draw_jobtitle = this.pch_list[0].job_id;
            this.customer_id = this.pch_list[0].customer_id;
            this.radioGroup = this.pch_list[0].payment_type;

            if (this.radioGroup == "bank") {
                this.paymentType = "transfer";
                this.pickbank = true;
                this.selectbankname = this.pch_list[0].bank_name;
                this.bankNumber = this.pch_list[0].bank_number;
            } else {
                this.paymentType = "cash";
                this.pickbank = false;
                this.selectbankname = ""; 
                this.bankNumber = ""; 
            }
            this.remark = this.pch_list[0].remark;
            this.remark_reject = this.pch_list[0].remark_reject;
            if( this.pch_list[0].status != 0){
                this.pc_id = this.pch_list[0].pettycas_id;
            }else{
                this.pc_id = "0000";
            }
            
            this.loadDataDef();
        },
        async loadPCD() {
            const result_pcd = await api.getDataPCDByPchID(this.$route.params.id);

           for(const [index,item] of result_pcd.data.entries()){
                let data_des = {
                    amount: Number(item.amount),
                    code: "",
                    description: item.title,
                }
                this.desserts.push(data_des)
                this.initialize();
           }
        },
      async loadDataFile() {
        const res_file = await api.getDataFileA("PettyCash",Number(this.$route.params.id),localStorage.getItem(server.COMPANYID)); 
        if (res_file.data.length > 0) {
          this.file_ori = res_file.data;
          this.filenameattach_old = [];
          for(const [index,item] of res_file.data.entries()){
            this.filenameattach_old.push(item.file_name);
            if (item.file_name.split(/[\s.]+/).pop() == "pdf") {
              this.image_listattach_old.push(
                `${item.file_name}`
              );
              this.getpreview_listattach_old.push(
                `${item.file_name}`
              );
            } else {
              this.image_listattach_old.push(
                `${item.file_name}`
              );
              this.getpreview_listattach_old.push(
                `${item.file_name}`
              );
            }
          }
        }
        console.log("res_file.data")
        console.log(this.getpreview_listattach_old)
      },
      async loadDataDef(){
        const res_acc = await api.getAccountDetail(
          this.pch_list[0].requester
        );
    
    
        // alert(res_acc.data[0].department_i_d);
        const res_department = await api.getDepartmentByID(
          res_acc.data[0].department_i_d
        );
    
        this.department = res_department.data[0].name;
    
        console.log(
          "res_department : ",
          JSON.stringify(res_department.data[0].name)
        );
    
        this.department_view = res_department.data[0].name;
        // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));
    
        this.user_sign = res_acc.data[0].signature;
    
        this.showsig1 = true;
        this.dialogSignature = true;
        this.showdefaultsignature();
        this.savesig1();

        if(this.$route.params.mode == "Edit"){
          this.disabled_editsing_request = false;
          this.dateCurrent = unity.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          )
        }
        else if(this.$route.params.mode == "send"){
          this.disabled_file_name = true;
          this.disabled_inputtext = true;
          this.dateCurrent = this.pch_list[0].petty_date;
        }else if(this.$route.params.mode == "approve"){
          this.disabled_file_name = true;
          this.disabled_inputtext = true;
          this.dateCurrent = this.pch_list[0].petty_date;

          const res_acc2 = await api.getAccountDetail(
            Number(this.pch_list[0].approver_id)
          );

          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;

        }else if(this.$route.params.mode == "reject"){
          this.disabled_file_name = true;
          this.disabled_reject = true;
          this.disabled_inputtext_reject = true;
          this.disabled_inputtext = true;
          this.dateCurrent = this.pch_list[0].petty_date;

          const res_acc2 = await api.getAccountDetail(
            Number(this.pch_list[0].approver_id)
          );

          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;
        }else if(this.$route.params.mode == "cashier"){
          this.disabled_file_name = true;
          this.disabled_inputtext = true;
          this.dateCurrent = this.pch_list[0].petty_date;

          const res_acc2 = await api.getAccountDetail(
            Number(this.pch_list[0].approver_id)
          );

          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;

          const res_acc3 = await api.getAccountDetail(
            Number(this.pch_list[0].cashier_id)
          );
          this.user_sign3 = res_acc3.data[0].signature;
          this.showdefaultsignature();
          this.showsig3 = true;
        }else if(this.$route.params.mode == "reject_account"){
          this.disabled_file_name = true;
          this.disabled_reject = true;
          this.disabled_inputtext_reject = true;
          this.disabled_inputtext = true;
          this.dateCurrent = this.pch_list[0].petty_date;

          const res_acc2 = await api.getAccountDetail(
            Number(this.pch_list[0].approver_id)
          );

          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;

          const res_acc3 = await api.getAccountDetail(
            Number(this.pch_list[0].cashier_id)
          );
          this.user_sign3 = res_acc3.data[0].signature;
          this.showdefaultsignature();
          this.showsig3 = true;

        }else if(this.$route.params.mode == "approverRequest"){
          this.disabled_file_name = true;
          this.disabled_reject = true;
          this.disabled_inputtext = true;
          this.disabled_editsing_approver = false;
          this.buttuon_approve = false;
          this.buttuon_reject = false;
          this.dateCurrent = this.pch_list[0].petty_date;
          this.loadRouter = "/approve-petty-cash";
          const res_acc2 = await api.getAccountDetail(
            Number(localStorage.getItem(server.USER_ID))
          );
          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;
          this.approver_status = 2;
          this.reject_status = 4;
          
        }else if(this.$route.params.mode == "accountRequest"){
          this.disabled_file_name = true;
          this.disabled_reject = true;
          this.disabled_inputtext = true;
          this.disabled_editsing_account = false;
          this.dateCurrent = this.pch_list[0].petty_date;
          this.buttuon_approve = false;
          this.buttuon_reject = false;
          this.showeditsing3 = true;
          this.approver_status = 3;
          this.reject_status = 5;
          this.loadRouter = "/approve-account-petty-cash"
          const res_acc2 = await api.getAccountDetail(
            Number(this.pch_list[0].approver_id)
          );
          this.user_sign2 = res_acc2.data[0].signature;
          this.showdefaultsignature();
          this.showsig2 = true;

          const res_acc3 = await api.getAccountDetail(
            Number(localStorage.getItem(server.USER_ID))
          );
          this.user_sign3 = res_acc3.data[0].signature;
          this.showdefaultsignature();
          this.showsig3 = true;


        }
      },
      //end set auto complte
  
      //---------------function file------------//
      previewMultiImage: function(event) {
        this.filenameattach = [];
        var input = event.target;
        var count = input.files.length;
        var index = 0;
        if (input.files) {
          while (count--) {
            var reader = new FileReader();
            reader.onload = (e) => {
              // this.preview_list.push(e.target.result);
              this.getpreview_listattach.push(e.target.result);
              this.preview_listattach.push(e.target.result);
            };
            if (
              input.files[index].type === "application/pdf" ||
              input.files[index].type.split("/")[0] === "image"
            ) {
              this.image_listattach.push(input.files[index]);
              reader.readAsDataURL(input.files[index]);
            }
            index++;
          }
        }
        // console.log(this.image_list);
        this.image_listattach.forEach((x) => {
          this.filenameattach.push(x.name);
        });
        console.log("this.filenameattach")
        console.log(this.image_listattach)
        console.log(this.filenameattach)
      },
      reset: function() {
        this.image = null;
        this.preview = null;
        this.image_list = [];
        this.preview_list = [];
      },
      async previewattachfile(index) {
        if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
          await this.popuppreviewfile(index)
        } else {
          this.previewattachimage = this.getpreview_listattach[index];
          this.dialogpreviewattachimage = true;
        }
      },
      async previewattachfile_old(index) {
        if (this.filenameattach_old[index].split(/[\s.]+/).pop() == "pdf") {
          await this.popuppreviewfile_old(index)
        } else {
          this.previewattachimage_old = `${pettyCashImage}/${this.$route.params.id}/${this.getpreview_listattach_old[index]}`;
          this.dialogpreviewattachimage_old = true;
        }
      },
      async popuppreviewfile(fileindex) {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_listattach[fileindex] +
            "'></iframe></body></html>"
        );
      },
      async popuppreviewfile_old(fileindex) {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            `${pettyCashFile}/${this.$route.params.id}/${this.getpreview_listattach_old[fileindex]}` +
            "'></iframe></body></html>"
        );
      },
      async closepreviewattachimage() {
        this.dialogpreviewattachimage = false;
      },
      async closepreviewattachimage_old() {
        this.dialogpreviewattachimage_old = false;
      },
      async deletedialogconfirmdeleteattachfile() {
        this.filenameattach.splice(this.indexdeleteattachfile, 1);
        this.preview_listattach.splice(this.indexdeleteattachfile, 1);
        this.image_listattach.splice(this.indexdeleteattachfile, 1);
        this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
        this.dialogconfirmdeleteattachfile = false;
      },
      async deletedialogconfirmdeleteattachfile_old() {

      
        this.filenameattach_old.splice(this.indexdeleteattachfile, 1);
        // this.preview_listattach_old.splice(this.indexdeleteattachfile, 1);
        this.image_listattach_old.splice(this.indexdeleteattachfile, 1);
        this.getpreview_listattach_old.splice(this.indexdeleteattachfile, 1);
        this.dialogconfirmdeleteattachfile_old = false;
      },
      async confirmdeleteattachfile(index) {
        this.indexdeleteattachfile = index;
        this.dialogconfirmdeleteattachfile = true;
      },
      async confirmdeleteattachfile_old(index) {
        this.indexdeleteattachfile = index;
        this.dialogconfirmdeleteattachfile_old = true;
      },
      async closedialogconfirmdeleteattachfile() {
        this.indexdeleteattachfile = -1;
        this.dialogconfirmdeleteattachfile = false;
      },
      async closedialogconfirmdeleteattachfile_old() {
        this.indexdeleteattachfile = -1;
        this.dialogconfirmdeleteattachfile_old = false;
      },
            //--------------------end funcfile--------------------//
            
      closeDialogSendmail() {
        this.dialogSendMail = false;
        this.$router.push("/advance-list");
      },
      onSelectAdvanceCodeChange(acvance_code) {

        var tmp = acvance_code.split(" ");
        this.editedItem.code = tmp[0];
        this.editedItem.description = tmp[1];
      },
      setupAlertDialog(status, title, message, text_color) {
        this.title = title;
        this.message = message;
        this.dialogAdd = status;
        this.text_color = text_color;
      },
      setupAlertDialogPush(status, title, message, text_color, link) {
        this.title = title;
        this.message = message;
        this.dialogAdd = status;
        this.text_color = text_color;
        this.link = link;
      },
      async saveData(mode) {
        if (this.emp_draw == 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณาเลือกผู้รับเงิน!!!",
            "text-h5 red--text text-center"
          );
  
          return;
        }
  
        if (this.paymentType == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณาเลือกวิธีการชำระเงิน!!!",
            "text-h5 red--text text-center"
          );
  
          return;
        }else if(this.paymentType == "transfer"){
          if (this.selectbankname == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your information again",
              "กรุณาเลือกธนาคาร!!!",
              "text-h5 red--text text-center"
            );
  
            return;
          }
          if (this.bankNumber == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your information again",
              "กรุณากรอกเลขบัญชี!!!",
              "text-h5 red--text text-center"
            );
  
            return;
          }
        }
  
        let dataedit = {
            pettycas_id: "",
            requester: this.pch_list[0].requester,
            company_id: Number(localStorage.getItem(server.COMPANYID)),
            employee_id: this.emp_draw,
            job_id: this.emp_draw_jobtitle,
            customer_id: this.customer_id,
            document_date: unity.parseDate(this.dateCurrent),
            payment_type: this.radioGroup,
            bank_name: this.selectbankname,
            bank_number: this.bankNumber,
            status: 0,
            remark: this.remark,
            remark_reject: "",
            update_by: Number(localStorage.getItem(server.USER_ID)),
            approver_id: 0
        }
        if(mode == "Submit"){
            dataedit.status = 1;
        }

        this.$showLoader();
        const req_app = await api.getAccountid(this.emp_draw);
        dataedit.approver_id = req_app.data[0].approver_level1;
        console.log("res_data_h")

        const res_data_h = await api.updatePCH(this.$route.params.id,dataedit);

        if(Number(res_data_h.status) == 200){
            if(this.file_ori.length > 0){
              let check_file_delete = 0;
              for( const [index,item] of this.file_ori.entries()){
                check_file_delete = 0;
                for( const [index_file,item_file] of this.image_listattach_old.entries()){
                  if(item.file_name == item_file){
                    check_file_delete = 1;
                  }
                }
                if(check_file_delete == 0){
                  // console.log("file_delete: " + item.file_name)
                  let data_delete = {
                    mode:"",
                    module_name: "PettyCash",
                    file_name: item.file_name
                  }
                  if (item.file_name.split(/[\s.]+/).pop() == "pdf") {
                    data_delete.mode = "file";
                  }else{
                    data_delete.mode = "image";
                  }
                  await api.updateFA(this.$route.params.id,data_delete)
                }
              }
            }

            if(this.image_listattach.length > 0 ){
              for(const [index,item] of this.image_listattach.entries()){
                let formData = new FormData();
                formData.append("module_name",  "PettyCash");
                formData.append("module_id",  Number(this.$route.params.id));
                formData.append("company_id",  Number(localStorage.getItem(server.COMPANYID)));
                formData.append("create_by",  Number(localStorage.getItem(server.USER_ID)));
                formData.append(`file`, item);
    
                const res_file = await api.createFA(formData)
              }
            }
          
            await api.deletePCD(this.$route.params.id)
            if(this.desserts.length > 0){
                for(const [index,item] of this.desserts.entries()){
    
                    let dataD_add = {
                        pettycash_h_id: this.$route.params.id,
                        title: item.description,
                        amount: Number(item.amount),
                        create_by: Number(localStorage.getItem(server.USER_ID))
                    }
        
                    console.log(dataD_add)
        
                    await api.createPCD(dataD_add)
                    if(index == (this.desserts.length-1)){
                            this.$hideLoader();
                            this.$store.state.global_push_dialog = true;
                            this.setupAlertDialogPush(
                            true,
                            "Information Saved",
                            "Information successfully saved",
                            "text-h5 green--text text-center",
                            "/petty-cash-voucher"
                            );
                    }
                }
            }else{
                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                true,
                "Information Saved",
                "Information successfully saved",
                "text-h5 green--text text-center",
                "/petty-cash-voucher"
                );
            }
        }else if(Number(res_data_h.status) == 400){
          this.$hideLoader();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้!!!",
              "text-h5 red--text text-center"
          );
          return;
        }else{
          return;
        }
      },
  
      async saveApproveStatus(mode){
        var dataApp_edit = {
          pettycas_id: this.pch_list[0].pettycas_id,
          requester: this.pch_list[0].requester,
          status: 0,
          remark_reject: "",
          approver_id: this.pch_list[0].approver_id,
          cashier_id: this.pch_list[0].cashier_id,
        }

        if(mode == "approve"){
          // dataApp_edit.status = 3;
          dataApp_edit.status = this.approver_status;
          this.$showLoader();
          const res_approve = await api.updatePCH(this.$route.params.id,dataApp_edit);
          if(Number(res_approve.status) == 200){
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              `${this.loadRouter}`
            );
          }else{
            this.$hideLoader();
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Information Saved",
                "ไม่สามารถบันทึกได้!!!",
                "text-h5 red--text text-center"
            );
            return;
          }
        }else{
          if (this.remark_reject == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your information again",
              "กรุณากรอกเลขเหตุผลในการปฏิเสธ",
              "text-h5 red--text text-center"
            );
            this.$refs["txtremark_reject"].focus();
            return;
          }
          // dataApp_edit.status = 4;
          dataApp_edit.status = this.reject_status;
          dataApp_edit.remark_reject = this.remark_reject;

          this.$showLoader();
          const res_approve = await api.updatePCH(this.$route.params.id,dataApp_edit);
          if(Number(res_approve.status) == 200){
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              `${this.loadRouter}`
            );
          }else{
            this.$hideLoader();
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Information Saved",
                "ไม่สามารถบันทึกได้!!!",
                "text-h5 red--text text-center"
            );
            return;
          }
        }

      },
  
      changeFocusText(t) {
        if (t === 1) {
            this.$refs["txtDescription"].focus();
        }
        if (t === 2) {
          alert("กรุณากรอกรายละเอียด");
          this.$refs["txtDescription"].focus();
        } else if (t === 3) {
            alert("กรุณากรอกราคา");
            this.$refs["txtAmount"].focus();
        }
      },
      initialize() {
  
        this.sumwithdrawamount = 0;
  
        this.desserts.forEach((item) => {
          this.sumwithdrawamount += item.amount;
        });

        this.sumwithdrawamount = tolocalestringnumber(this.sumwithdrawamount);
      },
  
      editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  
      deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.closeDelete();
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeSaveDraft() {
        this.dialogSaveDraft = false;
      },
  
      closeDelete() {
        this.sumwithdrawamount = 0;
  
        this.desserts.forEach((item) => {
          this.sumwithdrawamount += parseInt(item.amount, 10);
        });
  
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      save() {
        if (this.editedItem.description == "") {
          alert("กรุณากรอกรายละเอียดรายการ");
          this.$refs["txtDescription"].focus();
          return;
        }
  
        if (this.editedItem.amount == "0" || this.editedItem.amount == 0) {
          alert("กรุณากรอกจำนวนเงิน");
          this.$refs["txtAmount"].focus();
          return;
        }
  
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
          this.desserts.push(this.editedItem);
        }
  
        this.sumwithdrawamount = 0;
  
        this.desserts.forEach((item) => {
          this.sumwithdrawamount += parseInt(item.amount, 10);
        });
        
        this.sumwithdrawamount = tolocalestringnumber((this.sumwithdrawamount))

        console.log("save : " + JSON.stringify(this.desserts));
        this.changeFocusText(1);
        this.close();
      },
      
      submit() {
        let loader = this.$loading.show({
          // Optional parameters
          color: "green",
          loader: "dots",
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          width: 120,
          height: 120,
          backgroundColor: "#F1F4F7",
          opacity: 0.5,
          zIndex: 999,
          onCancel: this.onCancel,
        });
        // simulate AJAX
        setTimeout(() => {
          loader.hide();
        }, 2000);
      },
      editsig1() {
        this.dialogSignature = true;
        this.lbl_date_request = false;
        this.showsig1 = true;
        this.showbuttonsavesig1 = true;
        this.$refs.firstsignaturePad.openSignaturePad();
      },
      editsig2() {
        this.showsig2 = true;
        this.showbuttonsavesig2 = true;
        this.$refs.secondsignaturePad.openSignaturePad();
      },
      editsig3() {
        this.showsig3 = true;
        this.showbuttonsavesig3 = true;
        this.$refs.thirdsignaturePad.openSignaturePad();
      },
      clearsig1() {
        this.lbl_date_request = false;
        this.$refs.firstsignaturePad.clearSignature();
      },
      clearsig2() {
        this.$refs.secondsignaturePad.clearSignature();
      },
      clearsig3() {
        this.$refs.thirdsignaturePad.clearSignature();
      },
      async savesig1() {
        this.lbl_date_request = true;
        this.defaultwordbuttonsig1 = "Edit";
        const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
        this.showbuttonsavesig1 = false;
        this.$refs.firstsignaturePad.lockSignaturePad();

        this.user_sign = data;
        this.account.signature = data;
        let loader = this.$loading.show({
          // Optional parameters
          color: "green",
          loader: "dots",
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          width: 120,
          height: 120,
          backgroundColor: "#ffffff",
          opacity: 0.5,
          zIndex: 999,
          onCancel: this.onCancel,
        });
        await api.updateAccount(
          this.pch_list[0].requester,
          this.account
        );
        loader.hide();
      },
      async savesig2() {
        this.defaultwordbuttonsig2 = "Edit";
        const { isEmpty, data } = this.$refs.secondsignaturePad.saveSignature();
        this.showbuttonsavesig2 = false;
        this.$refs.secondsignaturePad.lockSignaturePad();

        this.user_sign2 = data;
        this.account.signature = data;
        
        this.$showLoader()
        await api.updateAccount(
          localStorage.getItem(server.USER_ID),
          this.account
        );
        this.$hideLoader()

      },
      async savesig3() {
        this.defaultwordbuttonsig2 = "Edit";
        const { isEmpty, data } = this.$refs.thirdsignaturePad.saveSignature();
        this.showbuttonsavesig3 = false;
        this.$refs.thirdsignaturePad.lockSignaturePad();

        this.user_sign3 = data;
        this.account.signature = data;
        
        this.$showLoader()
        await api.updateAccount(
          localStorage.getItem(server.USER_ID),
          this.account
        );
        this.$hideLoader()

      },
      changeradio() {
        console.log(this.radioGroup);
        if (this.radioGroup === "bank") {
          this.paymentType = "transfer";
          this.pickbank = true;
        } else {
          this.paymentType = "cash";
          this.pickbank = false;
          this.selectbankname = ""; 
          this.bankNumber = ""; 
        }
      },
  
      showdefaultsignature() {
        this.$nextTick(() => {
          // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
          // this.$refs.signaturePaddefault.lockSignaturePad();
          this.$refs.firstsignaturePad.fromDataURL(this.user_sign);
          // this.$refs.firstsignaturePad.lockSignaturePad();
          this.$refs.secondsignaturePad.fromDataURL(this.user_sign2);
          this.$refs.thirdsignaturePad.fromDataURL(this.user_sign3);
          // this.$refs.secondsignaturePad.lockSignaturePad();
        });
      },
    },
    async beforeCreate() {
      console.log("beforeCreate");
      this.$store.state.navMenu = true;
      // this.showdefaultsignature();
    },
    async created() {
      // this.showdefaultsignature();
    },
  };
  