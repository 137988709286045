//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";
import unity from "@/unity/unity";

export default {
    name: "SO-list",
    data(vm) {
        return {
            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            id_del: 0,
            search: "",
            dialogDelete: false,
            authorize_view: false,
            authorize_add: false,
            authorize_edit: false,
            authorize_del: false,
            toggleEnable: false,
            selectstatus: "",
            mDataArray: [],
            authorize: [],
            headers: [
                {
                    text: "Title",
                    value: "title",
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                },
                {
                    text: "",
                    value: "actions",
                    align: "center",
                    class: "bg-colorth tabletextwhite",
                    divider: true,
                },
            ],
        };
        },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
    beforeUpdate() {},
    components: {
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
        itemsForSelected() {
            if (this.search.length) {
                return this.dataSource.filter((item) => this.search.includes(item));
            }
            return this.dataSource;
        },
    },
    async mounted() {
        await api.checkVersion();
        this.userId = localStorage.getItem(server.USER_ID);
        let yourUrlString = window.location;
        // alert(yourUrlString);
    
        let parser = document.createElement("a");
        parser.href = yourUrlString;
    
        this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    
        // alert("authorize_id:" + this.authorize_id);
        if (this.authorize_id == null || this.authorize_id == 0) {
            // this.$router.push("/login");
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
            "Authorize Failed!!!",
            "Please Logout And Login Again!!!",
            "text-h5 red--text text-center"
            );
            this.$router.back();
        }
    
        const router_path = parser.pathname.replace("/", "");
    
        const res_auth = await api.getAuthorize(this.userId, router_path);

    
        this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
        this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
        this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
        this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
    

    
        if (!this.authorize_view) {
            this.$router.back();
        }
    
        // ----------------- Check Authorize ---------------------------
    
        let comp_id = localStorage.getItem(server.COMPANYID);
    
        this.userId = localStorage.getItem(server.USER_ID);
        if (this.userId && api.isLoggedIn()) {
            // this.$router.push("/");
        } else {
            this.$store.state.isLogged = false;
            this.$router.push("/login");
        }

        //load data
        await this.loaddata();
        this.$hideLoader();
    },
    methods: {
        async loaddata() {
            const res_result = await api.getInvMiscellaneousByCom_Dep({
                params: {
      company_id: localStorage.getItem(server.COMPANYID),
      department_id: localStorage.getItem(server.DEPARTMENT_ID),
    },
  }
  );
            this.mDataArray = res_result.data;
            console.log(this.mDataArray)
        },
        async openeditItem(id){
            this.$router.push(`/miscellaneous-list-add/Edit/${id}`);
        },
        async deleteItem(id){
            this.id_del = id;
            this.dialogDelete = true;
        },
        async deleteItemConfirm(){
            this.dialogDelete = false;
            this.$showLoader();
            const res_del = await api.DeleteInvMiscellaneous(this.id_del);
            if(res_del.status == 200){
                    await this.loaddata();
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "ลบข้อมูล!!!",
                        "ลบข้อมูลสำเร็จ!!!",
                        "text-h5 green--text text-center",
                    );
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "ลบข้อมูล!!!",
                        "ไม่สามารถลบได้!!!",
                        "text-h5 red--text text-center"
                    );
                }
        },
        async closeDelete(){
            this.dialogDelete = false;
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },

    },
};
