//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "NewAdvance",
  data() {
    return {
      vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
      dataAddRc_H: {},
      dataAddRc_D: {},
      payment_by: "",
      payment_comment: "",
      customer_data: [],
      main_inv_id: [],
      res_amout: [],
      res_docRef: [],
      res_so_detail: [],
      res_ts_detail: [],
      res_ad_detail: [],
      res_ms_detail: [],
      dataAll: [],
      desserts: [],
      mDataArray: [],
      customer_code: "",
      customer_name: "",
      doc_ref: "",
      invoice_address: "",
      currency: "",
      tax_id: "",
      branch: "",
      wth_rate: 0,
      wth_ratetotal: 0,
      tax_ratetotal: 0,
      amount_total: 0,
      inv_subtotal: 0,
      payment_term: 0,
      exchange_rate: 0,
      vat_type:"",
      bank_name: "",
      bank_no: "",
      attention: "",
      vat: "",
      po_no: "",
      headers: [
        {
          text: "No.",
          value: "inv_seq",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service Details",
          align: "center",
          value: "inv_detail",
          class: "bg-colorth texttablehcenter",
          width: "35%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "inv_qty",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "Price Per Unit",
          value: "inv_price",
          class: "bg-colorth texttablehcenter",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Total Price  ",
          value: "inv_lineamount",
          class: "bg-colorth texttablehcenter",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          align: "center",
          width: "60px",
          divider: true,
          sortable: false,
        },
      ],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogEdit: false,
      dataedit_service: {
        inv_lineamount: 0,
      },
      editedIndex: -1,
      dialogDeleteServic: false,
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    this.$forceUpdate();
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    await this.loadCustomer();
    await this.loaddatainv_h();
    await this.loadInvByID();
    this.$hideLoader();
  },
  methods: {
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async SumDataT(){
     
      let sum_data = 0;
      this.mDataArray.forEach((item)=>{
        item.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
        sum_data = sum_data + item.inv_lineamount;
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);
      })
      if(this.vat_type == "E"){
      this.inv_subtotal = tolocalestringnumber(sum_data);
      this.wth_ratetotal = tolocalestringnumber(Number(this.wth_rate)/100 * sum_data);
      this.tax_ratetotal = tolocalestringnumber(Number(this.vat/100 * sum_data));
      this.amount_total = tolocalestringnumber(sum_data - (Number(this.wth_rate)/100 * sum_data) +(Number(this.vat/100 * sum_data)));
    }else if(this.vat_type == "I"){
      this.amount_total = tolocalestringnumber(sum_data /(1+Number(this.wth_rate)/100 ));
        this.wth_ratetotal = tolocalestringnumber(Number(sum_data - stringcommatonumber(this.amount_total)).toFixed(2));
        this.inv_subtotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) / (1 + this.vat / 100))).toFixed(2));
        this.tax_ratetotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) - stringcommatonumber(this.inv_subtotal))).toFixed(2));
      
    }else{

    }
      // console.log(this.mDataArray)
    },
    async loadInvByID() {
      let byid = []; 
      byid = this.$route.params.id.split(",");
      const res_inv = await api.getdataByIdInvoice_H(byid[0]);
      this.branch = res_inv.data[0].customer_branch;
      this.tax_id = res_inv.data[0].customer_taxid;
      this.payment_term = res_inv.data[0].customer_paymentterm;
      this.attention = res_inv.data[0].attention;
      this.po_no = res_inv.data[0].po_no;
      this.wth_rate = res_inv.data[0].wth_rate;
      this.vat = res_inv.data[0].tax_rate;
      this.vat_type = res_inv.data[0].vat_type;
      await this.SumDataT();
      // console.log(res_inv.data)
    },
    async loadCustomer() {
      if (this.$route.params.customer_id != 0) {
        this.main_customer_id = this.$route.params.customer_id;
        const res_customer = await api.getCustomersById(this.main_customer_id);
        this.customer_data = res_customer.data;

        //set data
        this.customer_code = this.customer_data[0].cust_account;
        this.customer_name = this.customer_data[0].name;
        this.invoice_address = this.customer_data[0].address_invoice;
        this.currency = this.customer_data[0].currency;
        if(this.currency == "THB"){
          this.exchange_rate = 1;
        }
        this.vat_type = this.customer_data[0].vat_type;
        this.vat = this.customer_data[0].vat;
      }
    },
    async loaddatainv_h() {
      this.main_inv_id = this.$route.params.id;

      //load inv doc_ref
      const res_docRef = await api.getReceiveDetail("INV_REF", {
        id: this.main_inv_id,
      });
      this.res_docRef = res_docRef.data;
      let inv_id_s = "";
      this.res_docRef.forEach((item, index) => {
        if (index < this.res_docRef.length - 1) {
          inv_id_s = inv_id_s + item.inv_id + ",";
        } else {
          inv_id_s = inv_id_s + item.inv_id;
        }
      });
      this.doc_ref = inv_id_s;
      // console.log(this.res_docRef)

      //load Sum amout detail
      const res_amout = await api.getReceiveDetail("Amount", {
        id: this.main_inv_id,
      });
      this.res_amout = res_amout.data;

      //load SO detail
      const res_so = await api.getReceiveDetail("SO", { id: this.main_inv_id });
      this.res_so_detail = res_so.data;

      //load ts detail
      const res_ts = await api.getReceiveDetail("TS", { id: this.main_inv_id });
      this.res_ts_detail = res_ts.data;

      //load Ad detail
      const res_ad = await api.getReceiveDetail("AD", { id: this.main_inv_id });
      this.res_ad_detail = res_ad.data;

      //load MS detail
      const res_ms = await api.getReceiveDetail("MS", { id: this.main_inv_id });
      this.res_ms_detail = res_ms.data;

      this.dataAll = this.res_so_detail.concat(this.res_ts_detail);
      this.dataAll = this.dataAll.concat(this.res_ad_detail);
      this.dataAll = this.dataAll.concat(this.res_ms_detail);
      let indexData = 0;
      this.dataAll.forEach((item) => {
        if (
          item.inv_lineamount != null &&
          item.inv_lineamount != "" &&
          Number(item.inv_lineamount) > 0
        ) {
          this.desserts[indexData] = item;
          indexData += 1;
          item.inv_seq = indexData;
        }
        item.inv_qty = tolocalestringnumber(item.inv_qty);
        item.inv_price = tolocalestringnumber(item.inv_price);
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);
      });
      this.mDataArray = this.desserts;
    },
    async openeditItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dataedit_service.inv_seq = item.inv_seq;
      this.dataedit_service.inv_detail = item.inv_detail;
      this.dataedit_service.inv_qty =  stringcommatonumber(item.inv_qty);
      this.dataedit_service.inv_price =   stringcommatonumber(item.inv_price);
      this.dataedit_service.inv_lineamount = item.inv_lineamount;
      this.dialogEdit = true;
    },
    async closeeditItem() {
      this.dialogEdit = false;
    },
    async calserviceamount() {
      this.dataedit_service.inv_lineamount = parseFloat(
       this.dataedit_service.inv_qty * this.dataedit_service.inv_price
      );
      this.dataedit_service.inv_lineamount = tolocalestringnumber(this.dataedit_service.inv_lineamount);
    },
    async saveeditItem() {
      this.desserts[this.editedIndex].inv_detail = this.dataedit_service.inv_detail;
      this.desserts[this.editedIndex].inv_qty = tolocalestringnumber(this.dataedit_service.inv_qty);
      this.desserts[this.editedIndex].inv_price = tolocalestringnumber(this.dataedit_service.inv_price);
      this.desserts[this.editedIndex].inv_lineamount = this.dataedit_service.inv_lineamount;
      this.dialogEdit = false;
      await this.SumDataT();
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dialogDeleteServic = true;
    },
    closeDeleteService() {
      this.dialogDeleteServic = false;
    },
    async deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.desserts.forEach((item, index) => {
        if (index >= this.editedIndex) {
          item.inv_seq = item.inv_seq - 1;
        }
      });
      this.dialogDeleteServic = false;
      await this.SumDataT();
    },
    async saveinvDraft() {
      if (this.exchange_rate == 0 || this.exchange_rate == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check information!!!",
          "Please Enter Exchange Rate Data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.dataAddRc_H.company_id = localStorage.getItem(server.COMPANYID);
      this.dataAddRc_H.trc_date = new Date();
      this.dataAddRc_H.customer_id = this.main_customer_id;
      this.dataAddRc_H.company_trc = this.$route.params.company_inv;
      this.dataAddRc_H.trc_amoumt = Number(stringcommatonumber(this.amount_total));
      this.dataAddRc_H.trc_sub_amoumt = Number(stringcommatonumber(this.inv_subtotal));
      this.dataAddRc_H.exchange_rate = this.exchange_rate;
      this.dataAddRc_H.vat_type = this.vat_type;
      this.dataAddRc_H.tax_rate = this.vat;
      this.dataAddRc_H.trc_tax = Number(stringcommatonumber(this.tax_ratetotal));
      this.dataAddRc_H.status = 1;
      this.dataAddRc_H.payment_by = this.payment_by;
      if(this.bank_name != ""){
        this.dataAddRc_H.bank_name = this.bank_name;
      }
      if(this.bank_no != ""){
        this.dataAddRc_H.bank_no = this.bank_no;
      }
      this.dataAddRc_H.payment_comment = this.payment_comment;
      this.dataAddRc_H.user_create = localStorage.getItem(server.USER_ID);
      this.dataAddRc_H.user_update = localStorage.getItem(server.USER_ID);
      // console.log(this.dataAddRc_H)
      this.$showLoader();
      const res_addData = await api.CreateDataReceiveH(
        "Draft",
        this.dataAddRc_H
      );
      if (res_addData.status == 200 || res_addData.status == 201) {
        this.desserts.forEach(async (item) => {
          this.dataAddRc_D.trc_hid = res_addData.data.id;
          this.dataAddRc_D.inv_seq = item.inv_seq;
          this.dataAddRc_D.inv_hid = item.id;
          this.dataAddRc_D.inv_detail = item.inv_detail;
          this.dataAddRc_D.inv_qty = Number(stringcommatonumber(item.inv_qty));
          this.dataAddRc_D.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
          this.dataAddRc_D.user_create = Number(localStorage.getItem(server.USER_ID));
          this.dataAddRc_D.user_update = Number(localStorage.getItem(server.USER_ID));
          await api.CreateDataReceiveD(this.dataAddRc_D);
        });
        await api.UpdataStatusInv(this.main_inv_id);
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          `Information successfully saved id : ${res_addData.data.id}`,
          "text-h5 green--text text-center",
          `/receive-edit/${res_addData.data.id}`
        );
      } else {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ผิดพลาด!!!",
          "บันทึกข้อมูลไม่สำเร็จ!!!",
          "text-h5 red--text text-center",
          "/receive"
        );
        this.$hideLoader();
      }
    },
    async saveinvSubmit() {
      if (this.exchange_rate == 0 || this.exchange_rate == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check information!!!",
          "Please Enter Exchange Rate Data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.dataAddRc_H.company_id = localStorage.getItem(server.COMPANYID);
      this.dataAddRc_H.trc_date = new Date();
      this.dataAddRc_H.customer_id = this.main_customer_id;
      this.dataAddRc_H.company_trc = this.$route.params.company_inv;
      this.dataAddRc_H.trc_amoumt = Number(stringcommatonumber(this.amount_total));
      this.dataAddRc_H.trc_sub_amoumt = Number(stringcommatonumber(this.inv_subtotal));
      this.dataAddRc_H.exchange_rate = this.exchange_rate;
      this.dataAddRc_H.vat_type = this.vat_type;
      this.dataAddRc_H.tax_rate = this.vat;
      this.dataAddRc_H.trc_tax = Number(stringcommatonumber(this.tax_ratetotal));
      this.dataAddRc_H.status = 2;
      this.dataAddRc_H.payment_by = this.payment_by;
      if(this.bank_name != ""){
        this.dataAddRc_H.bank_name = this.bank_name;
      }
      if(this.bank_no != ""){
        this.dataAddRc_H.bank_no = this.bank_no;
      }
      this.dataAddRc_H.payment_comment = this.payment_comment;
      this.dataAddRc_H.user_create = localStorage.getItem(server.USER_ID);
      this.dataAddRc_H.user_update = localStorage.getItem(server.USER_ID);
      // console.log(this.dataAddRc_H)
      this.$showLoader();
      const res_addData = await api.CreateDataReceiveH(
        "Summit",
        this.dataAddRc_H
      );
      if (res_addData.status == 200 || res_addData.status == 201) {
        this.desserts.forEach(async (item) => {
          this.dataAddRc_D.trc_hid = res_addData.data.id;
          this.dataAddRc_D.inv_seq = item.inv_seq;
          this.dataAddRc_D.inv_hid = item.id;
          this.dataAddRc_D.inv_detail = item.inv_detail;
          this.dataAddRc_D.inv_qty = Number(stringcommatonumber(item.inv_qty));
          this.dataAddRc_D.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
          this.dataAddRc_D.user_create = Number(
            localStorage.getItem(server.USER_ID)
          );
          this.dataAddRc_D.user_update = Number(
            localStorage.getItem(server.USER_ID)
          );
          await api.CreateDataReceiveD(this.dataAddRc_D);
        });
        await api.UpdataStatusInv(this.main_inv_id);
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          `Information successfully saved id : ${res_addData.data.id}`,
          "text-h5 green--text text-center",
          `/receive-edit/${res_addData.data.id}`
        );
      } else {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ผิดพลาด!!!",
          "บันทึกข้อมูลไม่สำเร็จ!!!",
          "text-h5 red--text text-center",
          "/receive"
        );
        this.$hideLoader();
      }
    },
    async onSelectvat_type() {
   
    },
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
