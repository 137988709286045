//
//
//
//

export default {
  name: "About",
  data() {
    return {
      message: "Welcome to Vue.js!",
    };
  },
  mounted() {
    this.$hideLoader();
  },
};
