//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
export default {
  name: "setting-group-menu",
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    dialogEdit: false,
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Name", value: "sgm_name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      sgm_name: "",
      company_id: localStorage.getItem(server.COMPANYID),
      user_create: 0,
      user_update: 0,
    },
    editedItem2: {
      sgm_name: "",
      company_id: localStorage.getItem(server.COMPANYID),
      user_update: 0,
    },
    edit_item_id: 0,
    defaultItem: {
      sgm_name: "",
      company_id: localStorage.getItem(server.COMPANYID),
      user_create: 0,
      user_update: 0,
    },
    setting_menu_detail: {
      setting_group_menu_id: 0,
      group_menu_id: 0,
      menu_detail_id: 0,
      company_id: localStorage.getItem(server.COMPANYID),
      user_create: 0,
    },
    fullPage: true,
    checkDataLoop: false,
    mArrayInsertMenuDetail: [],
    authorize_view: false,
    authorize_add: false,
    authorize_edit: false,
    authorize_del: false,
    authorize_id: 0,
  }),
  components: {
    SuccessDialog,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Authorize" : "Edit Authorize";
    },
  },
  async mounted() {
    await api.checkVersion();
    // this.$showLoader();
    this.$hideLoader();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      //   alert("initialize");
      // const res_get = await api.getSettingGroupMenu();
      // this.desserts = res_get.data;

      var res_get;

      // if (localStorage.getItem(server.ROLE) == "ADMIN") {
      //   res_get = await api.getSettingGroupMenu();
      //   this.desserts = res_get.data;
      // } else {
        res_get = await api.getSettingGroupMenu_By_CompanyID(
          localStorage.getItem(server.COMPANYID)
        );
        this.desserts = res_get.data;
      // }

      loader.hide();
      //   console.log("initialize desserts: " + JSON.stringify(res_get.data));
    },

    async editItem(item) {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.edit_item_id = item.id;
      //   this.editedIndex = this.desserts.indexOf(item);
      //   this.editedItem = Object.assign({}, item);
      //   this.dialog = true;
      // Go To Setting Menu Detail

      // const res_main_for_check = await api.getMenuDetail();

      const res_main_for_check = await api.getMenuDetail_By_CompanyId(
        localStorage.getItem(server.COMPANYID)
      );

      // console.log(
      //   "editItem res_main_for_check: " +
      //     JSON.stringify(res_main_for_check.data.count)
      // );

      const res_check = await api.getSMD_ById(item.id);
      // console.log(
      //   "editItem res_check: " + JSON.stringify(res_check.data.count)
      // );
      // console.log("editItem res_check: " + JSON.stringify(res_check.data.rows));

      if (res_check.data.count < res_main_for_check.data.count) {
        // alert("0");
        // console.log(
        //   "editItem res_main_for_check: " +
        //     JSON.stringify(res_main_for_check.data.rows)
        // );
        this.setting_menu_detail.user_create = localStorage.getItem(
          server.USER_ID
        );

        this.mArrayInsertMenuDetail = res_main_for_check.data.rows;

        // Start Loop Check Data
        for (let i = 0; i < res_main_for_check.data.count; i++) {
          this.checkDataLoop = false;
          console.log("Loop Data: " + this.mArrayInsertMenuDetail[i].id);
          this.setting_menu_detail.setting_group_menu_id = item.id;
          this.setting_menu_detail.group_menu_id = this.mArrayInsertMenuDetail[
            i
          ].group_menu_id;
          this.setting_menu_detail.menu_detail_id = this.mArrayInsertMenuDetail[
            i
          ].id;

          for (let i = 0; i < res_check.data.count; i++) {
            // console.log(
            //   "Sub Loop Data menu_detail_id: " +
            //     this.setting_menu_detail.menu_detail_id +
            //     " compare id :" +
            //     res_check.data.rows[i].menu_detail_id
            // );
            if (
              this.setting_menu_detail.menu_detail_id ==
              res_check.data.rows[i].menu_detail_id
            ) {
              // console.log("Sub Loop Data True");
              this.checkDataLoop = true;
            }
            if (this.checkDataLoop == true) {
              break;
            }
          }

          // console.log("Sub Loop Data End");
          // console.log("Sub Loop checkDataLoop :" + this.checkDataLoop);
          if (this.checkDataLoop == false) {
            const res_add_stdt = await api.createSettingMenuDetail(
              this.setting_menu_detail
            );
            // console.log("Loop Data " + i + ":" + res_add_stdt.status);
          }
        }
        // End Loop Check Data
        loader.hide();
        this.$router.push(`/setting-menu-detail/${item.id}`);
      } else {
        loader.hide();
        this.$router.push(`/setting-menu-detail/${item.id}`);
      }
    },

    deleteItem(item) {
      this.edit_item_id = item.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      const res_del = await api.deleteSettingGroupMenu(this.edit_item_id);
      if (res_del.status == 200) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success!!!",
          "Delete data success",
          "text-h5 green--text text-center"
        );
        this.initialize();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Delete data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Go To Setting Menu Detail
      } else {
        // alert("create");

        this.editedItem.company_id = localStorage.getItem(server.COMPANYID);
        this.editedItem.user_create = localStorage.getItem(server.USER_ID);
        const res_add = await api.createSettingGroupMenu(this.editedItem);
        if (res_add.status == 200) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success!!!",
            "Add data success",
            "text-h5 green--text text-center"
          );
          this.initialize();
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "Add data Failed",
            "text-h5 red--text text-center"
          );
        }
      }

      this.close();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
