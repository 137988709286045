//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { server } from "../../services/constants";
import api from "@/services/api";
export default {
  name: "Header",
  data() {
    return {
      user: {
        initials:"",
        fullName: "John Doe",
        email: "defaultmail@mail..com",
        companyname: localStorage.getItem(server.ROLE),
      },
      mycompany:[],
    };
  },
  async mounted() {
    await this.getMyCompany();
    this.user.initials = localStorage
            .getItem(server.FULLNAME)
            .split(" ")[1]
            .substring(0, 1) +
          localStorage
            .getItem(server.FULLNAME)
            .split(" ")[2]
            .substring(0, 1)
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    getENV() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
   async getMyCompany() {
      const result = await api.getCompanyByID(
          localStorage.getItem(server.COMPANYID)
        );
        this.mycompany = result.data;
    },
    onClickLogOff() {
      this.$store.state.navMenu = false;
      this.$store.dispatch("doLogout");
    },
    resetPassword() {
      // alert("Reset Password");
      this.$router.push("/my-profile");
    },
  },
};
