//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
  fileImmigration,
} from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import pdfvuer from "pdfvuer";
const moment = require('moment-timezone');
export default {
  name: "About",
  data(vm) {
    return {
      place_of_issue_list:[],
      work_place_of_issue_list:[],
      worker_list: [],
      customer_type_list: [
        { customer_type_id: "Company", customer_type_name: "Company" },
        { customer_type_id: "Agency", customer_type_name: "Agency" },
        { customer_type_id: "Personal", customer_type_name: "Personal" },
      ],
      customer_name_list: [],
      disableCalculateNewRenewalDate: false,
      country_list: [],
      date_of_public: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_opposition: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_filling_appeal: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_filling_lawsuit: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_renewal: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_renewal_filed: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      next_deadline: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      visa_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      visa_expire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      work_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      work_expire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      participant_visa_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      participant_workexpire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menudate_of_issuing_certificate: false,
      menudate_of_closed: false,
      dialogpreviewimage: false,
      checkfileapreview: false,
      previewpopupfile: [],
      previewpopupfile2: [],
      dialogpreviewfile: false,
      dialogDelete: false,
      dialogDeletefile: false,
      dialogDeleteimage: false,
      fileindex: -1,
      indexdelete: -1,
      preview_list2: [],
      getpreview_list: [],
      image: null,
      previewimage: null,
      previewimageedit: null,
      image_list: [],
      preview_list: [],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      tmstatus_list: [],
      contact_owner_list: [],
      contact_agent_list: [],
      contact_associate_list: [],
      filetype_list: [
        { id: "Global", name: "Global" },
        { id: "Local", name: "Local" },
        { id: "Personal", name: "Personal" },
      ],
      status_list: [
        { id: "Active", name: "Active" },
        { id: "Close", name: "Close" },
      ],
      participants_type_list: [
        { id: "Spouse", name: "Spouse" },
        { id: "Children", name: "Children" },
        { id: "Parent", name: "Parent" },
      ],
      filename: [],
      imagename: "",
      load_bg: false,
      checkfileadd: false,
      checkimageadd: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      principal: {
        file_type: "",
        file_number: "",
        company_name: "",
        client_name: "",
        nationality: "",
        customer_type: "",
        contact: "",
        customer_name: 0,
        worker: 0,
        visa_issue_date: "",
        place_of_issue: "",
        visa_expire_date: "",
        work_issue_date: "",
        work_place_of_issue: "",
        work_expire_date: "",
        file: "",
        status: "",
        remark: "",
        company_id: localStorage.getItem(server.COMPANYID),
        user_create: localStorage.getItem(server.USER_ID),
        user_update: localStorage.getItem(server.USER_ID),
      },
      menuaddappdate: false,
      menupublication: false,
      menuopposition: false,
      menufilling_appeal: false,
      menufilling_lawsuit: false,
      menurenewal: false,
      menurenewal_filed: false,
      menuaffidavits: false,
      menufiling_affidavits: false,
      menuvisaissuedate: false,
      menuworkissuedate: false,
      menuvisaexpiredate: false,
      menuworkexpiredate: false,
      participantsmenuvisaexpiredate: false,
      participantsmenuworkexpiredate: false,
      menuvisaexpireedittable: false,
      menuworkexpireedittable: false,
      menuadd: false,
      fullPage: true,
      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      mDataArray: [],
      desserts: [],
      headers: [
        {
          text: "* Type",
          value: "type",
          class: "bg-colorth texttablehcenter ",
          width: "17%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "* Name",
          value: "name",
          class: "bg-colorth texttablehcenter",
          width: "25%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "* Nationality",
          value: "nationality",
          class: "bg-colorth texttablehcenter ",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "* Visaexpire",
          value: "visa_expire",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Workexpire",
          value: "work_expire",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          width: "8%",
          divider: true,
          sortable: false,
        },
      ],
      defaultparticipants: {
        type: "",
        name: "",
        nationality: "",
        visa_expire: "",
        work_expire: "",
        user_create: localStorage.getItem(server.USER_ID),
        user_update: localStorage.getItem(server.USER_ID),
      },
      participants: {
        type: "",
        name: "",
        nationality: "",
        visa_expire: "",
        work_expire: "",
        user_create: localStorage.getItem(server.USER_ID),
        user_update: localStorage.getItem(server.USER_ID),
      },
      editedIndex: -1,
      dataeditItem: {
        type: "",
        name: "",
        nationality: "",
        visa_expire: "",
        work_expire: "",
        user_create: localStorage.getItem(server.USER_ID),
        user_update: localStorage.getItem(server.USER_ID),
      },
      dialogEdit:false,
      work_expire_editintable: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
        visa_expire_editintable: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
        
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    pdf: pdfvuer,
  },
  async mounted() {
    await this.loadCustomer();
    await this.loadplace_of_issue_list();
    await this.loadwork_place_of_issue_list();
    await this.loadWorker();
    await this.loaddataEdit();
    await this.loaddataEditintable();
    this.$hideLoader();
  },
  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  watch: {
    date_of_regis(val) {
      this.principal.date_of_regis = this.formatDate(this.date_of_regis);
    },
    date_of_application(val) {
      this.principal.date_of_application = this.formatDate(
        this.date_of_application
      );
    },
    date_of_public(val) {
      this.principal.date_of_public = this.formatDate(this.date_of_public);
    },
    date_of_opposition(val) {
      this.principal.date_of_opposition = this.formatDate(
        this.date_of_opposition
      );
    },
    date_of_filling_appeal(val) {
      this.principal.date_of_filling_appeal = this.formatDate(
        this.date_of_filling_appeal
      );
    },
    date_of_filling_lawsuit(val) {
      this.principal.date_of_filling_lawsuit = this.formatDate(
        this.date_of_filling_lawsuit
      );
    },
    date_of_renewal(val) {
      this.principal.date_of_renewal = this.formatDate(this.date_of_renewal);
    },
    date_of_renewal_filed(val) {
      this.principal.date_of_renewal_filed = this.formatDate(
        this.date_of_renewal_filed
      );
    },

    next_deadline(val) {
      this.principal.next_deadline = this.formatDate(this.next_deadline);
    },
    visa_issue_date(val) {
      this.principal.visa_issue_date = this.formatDate(this.visa_issue_date);
    },
    visa_expire_date(val) {
      this.principal.visa_expire_date = this.formatDate(this.visa_expire_date);
    },
    work_issue_date(val) {
      this.principal.work_issue_date = this.formatDate(this.work_issue_date);
    },
    work_expire_date(val) {
      this.principal.work_expire_date = this.formatDate(this.work_expire_date);
    },
    date_of_closed(val) {
      this.principal.date_of_closed = this.formatDate(this.date_of_closed);
    },
    date_of_issuing_certificate(val) {
      this.principal.date_of_issuing_certificate = this.formatDate(
        this.date_of_issuing_certificate
      );
    },
    participant_visa_issue_date(val) {
      this.participants.visa_expire = this.formatDate(
        this.participant_visa_issue_date
      );
    },
    participant_workexpire_date(val) {
      this.participants.work_expire = this.formatDate(
        this.participant_workexpire_date
      );
    },
    work_expire_editintable(val) {
      this.dataeditItem.work_expire = this.formatDate(this.work_expire_editintable);
    },
    visa_expire_editintable(val) {
      this.dataeditItem.visa_expire = this.formatDate(this.visa_expire_editintable);
    },


  },
  methods: {
    async openeditItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dataeditItem.type = item.type
      this.dataeditItem.name = item.name
      this.dataeditItem.nationality = item.nationality
      this.dataeditItem.visa_expire = item.visa_expire
      this.dataeditItem.work_expire = item.work_expire
      this.dialogEdit = true;
    },
    async closeeditItem() {
      this.editedIndex = -1;
      this.dialogEdit = false;
    },
    async saveeditItem() {
      this.desserts[this.editedIndex].type = this.dataeditItem.type;
      this.desserts[this.editedIndex].name = this.dataeditItem.name;
      this.desserts[this.editedIndex].nationality = this.dataeditItem.nationality;
      this.desserts[this.editedIndex].visa_expire = this.dataeditItem.visa_expire;
      this.desserts[this.editedIndex].work_expire = this.dataeditItem.work_expire;
    
      this.dialogEdit = false;
    },
    async loadplace_of_issue_list(){
      const result = await api.getImMasterPlaceofissueByCompany_Dep({
                    params: {
          company_id: localStorage.getItem(server.COMPANYID),
          department_id: localStorage.getItem(server.DEPARTMENT_ID),
        },
      });
      this.place_of_issue_list = result.data;
    },
    async loadwork_place_of_issue_list(){
      const result = await api.getImMasterWorkPlaceofissueByCompany_Dep({
                    params: {
          company_id: localStorage.getItem(server.COMPANYID),
          department_id: localStorage.getItem(server.DEPARTMENT_ID),
        },
      });
      this.work_place_of_issue_list = result.data;
    },
    async loaddataEdit() {
      const result = await api.getdataByIdim_principal(this.$route.params.id);
      console.log(result);
      this.principal = result.data[0];
      this.principal.user_update = localStorage.getItem(server.USER_ID);
      if(!this.principal.company_name){
        this.principal.company_name = "";
      }
      if (this.principal.im_file) {
        this.filename = this.principal.im_file.split(",");
        this.filename.forEach((x, index) => {
          this.image_list.push(
            `${fileImmigration}/${this.$route.params.id}/${x}`
          );
          this.getpreview_list.push(
            `${fileImmigration}/${this.$route.params.id}/${x}`
          );
        });
      }
    },
    async loaddataEditintable() {
      const result = await api.getdataByIdim_participant(this.$route.params.id);
      console.log(result);
      this.desserts = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.customer_name_list = result.data;
    },
    async loadWorker() {
      const result = await api.getAccountsByCompanyImmogration(
        localStorage.getItem(server.COMPANYID)
      );
      console.log(result);
      this.worker_list = result.data;
    },
    save() {
      if (this.participants.type == 0 || this.participants.type == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Type",
          "text-h5 red--text text-center"
        );
        this.$refs["participantstype"].focus();
        return;
      }
      if (this.participants.name == 0 || this.participants.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Name",
          "text-h5 red--text text-center"
        );
        this.$refs["participantsname"].focus();
        return;
      }
      if (
        this.participants.nationality == 0 ||
        this.participants.nationality == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Nationality",
          "text-h5 red--text text-center"
        );
        this.$refs["participantsnationality"].focus();
        return;
      }
      if (
        this.participants.visa_expire == 0 ||
        this.participants.visa_expire == "" ||
        this.participants.visa_expire == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Visa Expire",
          "text-h5 red--text text-center"
        );
        // this.$refs["participantsvisa_expire"].focus();
        return;
      }

      // if (this.editedIndex > -1) {
      //   Object.assign(this.desserts[this.editedIndex], this.participants);
      // } else {
      this.desserts.push(this.participants);
      // }

      console.log(this.desserts);

      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.participants = Object.assign({}, this.defaultparticipants);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirmfile() {
      this.filename.splice(this.indexdelete, 1);
      this.preview_list.splice(this.indexdelete, 1);
      this.image_list.splice(this.indexdelete, 1);
      this.getpreview_list.splice(this.indexdelete, 1);
      this.closeDeletefile();
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async saveaddItem(mode) {
      if (this.principal.file_type == 0 || this.principal.file_type == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File Type",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.client_name == 0 || this.principal.client_name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Client Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.nationality == 0 || this.principal.nationality == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Nationality",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.contact == 0 || this.principal.contact == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Contact",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.customer_name == 0 || this.principal.customer_name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Customer Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.worker == 0 || this.principal.worker == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Case Worker",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (
      //   this.principal.visa_issue_date == 0 ||
      //   this.principal.visa_issue_date == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Visa issue date",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (
      //   this.principal.place_of_issue == 0 ||
      //   this.principal.place_of_issue == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Place of issue",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (
      //   this.principal.visa_expire_date == 0 ||
      //   this.principal.visa_expire_date == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Visa expire date",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      // if (
      //   this.principal.work_issue_date == 0 ||
      //   this.principal.work_issue_date == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Work issue date",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (
      //   this.principal.work_place_of_issue == 0 ||
      //   this.principal.work_place_of_issue == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Work Place of issue",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (
      //   this.principal.work_expire_date == 0 ||
      //   this.principal.work_expire_date == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Work expire date",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      this.$showLoader();
      let formData = new FormData();
      formData.append(`file`, this.image);
      this.image_list.forEach((x, index) => {
        formData.append(`file`, x);
      });

      console.log(this.image_list);
      if (this.image_list.length > 0) {
        let getnamefilename = "";
        this.filename.forEach((x, index) => {
          if (index > 0) {
            getnamefilename += `,${x}`;
          } else {
            getnamefilename += `${x}`;
          }
        });
        formData.append(`im_file`, getnamefilename.replace(/\s/g, "_"));
      } else {
        formData.append(`im_file`, "");
      }

      formData.append("client_name", this.principal.client_name);
      formData.append("contact", this.principal.contact);
      formData.append("customer_name", this.principal.customer_name);
      formData.append("customer_type", this.principal.customer_type);
      formData.append("file_type", this.principal.file_type);
      formData.append("company_name", this.principal.company_name?this.principal.company_name:"");
      formData.append("nationality", this.principal.nationality);
      formData.append("place_of_issue", this.principal.place_of_issue);
      formData.append("remark", this.principal.remark);
      formData.append("status", this.principal.status);
      formData.append("user_create", this.principal.user_create);
      formData.append("user_update", this.principal.user_update);
      formData.append(
        "work_place_of_issue",
        this.principal.work_place_of_issue
      );
      formData.append("worker", this.principal.worker);
      formData.append("company_id", this.principal.company_id);
      if (
        this.principal.visa_expire_date !== "" &&
        this.principal.visa_expire_date !== null
      ) {
        formData.append(
          "visa_expire_date",
          this.changeformatDatetoinsert(this.principal.visa_expire_date)
        );
      }

      if (
        this.principal.visa_issue_date !== "" &&
        this.principal.visa_issue_date !== null
      ) {
        formData.append(
          "visa_issue_date",
          this.changeformatDatetoinsert(this.principal.visa_issue_date)
        );
      }

      if (
        this.principal.work_expire_date !== "" &&
        this.principal.work_expire_date !== null
      ) {
        formData.append(
          "work_expire_date",
          this.changeformatDatetoinsert(this.principal.work_expire_date)
        );
      }

      if (
        this.principal.work_issue_date !== "" &&
        this.principal.work_issue_date !== null
      ) {
        formData.append(
          "work_issue_date",
          this.changeformatDatetoinsert(this.principal.work_issue_date)
        );
      }

      const resultMasterTM = await api.updateim_principal(
        this.$route.params.id,
        formData
      );
      if(this.principal.status == "Close"){

      
       await api.updateByfile_noim_transaction(
        this.$route.params.id,
        {status:"Close"}
      );
    }
      console.log(resultMasterTM);
      
      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {
        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Update",
            table_name: "IM_Principal",
            table_id: this.$route.params.id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        console.log("dataAdd")
        console.log(dataAdd)

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
          last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        var res_participant_id_del = await api.getdataByIdim_participant(this.$route.params.id);

        const del_participant = await api.deleteim_participant(this.$route.params.id);
        console.log("del_participant.status")
        console.log(del_participant.status)

        if(del_participant.status == 200 || del_participant.status == 201){
          for (const [index, item] of res_participant_id_del.data.entries()){
            var dataAddParticipant = {
                user_id: localStorage.getItem(server.USER_ID),
                log_in_out_id: localStorage.getItem(server.LOGIN_ID),
                action: "Delete",
                table_name: "IM_Participant",
                table_id: item.id,
                date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.addTransectionLog(dataAddParticipant)

            var dataEditLog = {
              last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
          }
        }

        console.log(this.desserts[0]);
        if (this.desserts.length > 0) {
          this.desserts.forEach(async(item, index) => {
            item.im_principal_id = this.$route.params.id;
            item.visa_expire = this.changeformatDatetoinsert(item.visa_expire);
            item.work_expire = this.changeformatDatetoinsert(item.work_expire);
            var res_add_participant = await api.addim_participant(item);

            var dataAddParticipant = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Create",
              table_name: "IM_Participant",
              table_id: res_add_participant.data.id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.addTransectionLog(dataAddParticipant)

            var dataEditLog = {
              last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

            console.log(`${index} = ${this.desserts.length - 1}`);

            if (index === this.desserts.length - 1) {
              this.$hideLoader();
              this.$store.state.global_push_dialog = true;
              if (mode == "add") {
                if(this.principal.status == "Close"){
                  this.setupAlertDialogPush(
                  true,
                  "save data!!!",
                  "ระบบจะทำการ Close Transaction ทั้งหมดของ Master นี้",
                  "text-h5 green--text text-center",
                  "/immigration-master"
                );
                }else{
                  this.setupAlertDialogPush(
                  true,
                  "save data!!!",
                  "save data success!!!",
                  "text-h5 green--text text-center",
                  "/immigration-master"
                );
                }
               
              } else {
                if(this.principal.status == "Close"){
                  this.setupAlertDialogPush(
                  true,
                  "save data!!!",
                  "ระบบจะทำการ Close Transaction ทั้งหมดของ Master นี้",
                  "text-h5 green--text text-center",
                  `/immigration-transaction-add/${this.principal.file_number}`
                );
                }else{
                  this.setupAlertDialogPush(
                  true,
                  "save data!!!",
                  "save data success!!!",
                  "text-h5 green--text text-center",
                  `/immigration-transaction-add/${this.principal.file_number}`
                );
                }
                
              }
              return;
            }
          });
        } else {
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          if (mode == "add") {
            if(this.principal.status == "Close"){
              this.setupAlertDialogPush(
              true,
              "save data!!!",
              "ระบบจะทำการ Close Transaction ทั้งหมดของ Master นี้",
              "text-h5 green--text text-center",
              "/immigration-master"
            );
                }else{
                  this.setupAlertDialogPush(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              "/immigration-master"
            );
                }
            
          } else {
            if(this.principal.status == "Close"){
              this.setupAlertDialogPush(
              true,
              "save data!!!",
              "ระบบจะทำการ Close Transaction ทั้งหมดของ Master นี้",
              "text-h5 green--text text-center",
              `/immigration-transaction-add/${this.principal.file_number}`
            );
                }else{
                  this.setupAlertDialogPush(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              `/immigration-transaction-add/${this.principal.file_number}`
            );
                }
            
          }
          return;
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "save data!!!",
          "can't save data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async saveandcreateappointmentaddItem() {
      console.log(this.principal);
      if (this.principal.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Object Type Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.file_no == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No. Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.principal.trademark == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Trademark Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        !this.principal.date_of_application ||
        this.principal.date_of_application == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Application Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.principal.regis_no == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Registration No. Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      if (
        this.principal.closed == true &&
        this.principal.date_of_closed == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Closed File Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.principal.dip_link == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter DIP Link(URL) Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      this.$showLoader();
      let formData = new FormData();
      formData.append(`file`, this.image);
      this.image_list.forEach((x, index) => {
        formData.append(`file`, x);
      });

      formData.append("file_no", this.principal.file_no.trim());
      formData.append("regis_no", this.principal.regis_no.trim());
      formData.append("tm_type", this.principal.tm_type);
      formData.append("class_no", this.principal.class_no.trim());
      formData.append("country", this.principal.country.trim());
      // formData.append("date_of_filling", this.principal.date_of_filling);
      formData.append("name_of_client", this.principal.name_of_client.trim());
      if (
        this.principal.date_of_regis !== "" &&
        this.principal.date_of_regis !== null
      ) {
        formData.append(
          "date_of_regis",
          this.changeformatDatetoinsert(this.principal.date_of_regis)
        );
      }
      formData.append("cliant_ref", this.principal.cliant_ref.trim());
      formData.append("app_no", this.principal.app_no.trim());
      if (
        this.principal.date_of_application !== "" &&
        this.principal.date_of_application !== null
      ) {
        formData.append(
          "date_of_application",
          this.changeformatDatetoinsert(this.principal.date_of_application)
        );
      }
      formData.append("trademark", this.principal.trademark.trim());
      formData.append("owner_contact", this.principal.owner_contact);
      formData.append("agent_contact", this.principal.agent_contact);
      formData.append("associate_contact", this.principal.associate_contact);
      if (
        this.principal.date_of_public !== "" &&
        this.principal.date_of_public !== null
      ) {
        formData.append(
          "date_of_public",
          this.changeformatDatetoinsert(this.principal.date_of_public)
        );
      }
      if (
        this.principal.date_of_filling_appeal !== "" &&
        this.principal.date_of_filling_appeal !== null
      ) {
        formData.append(
          "date_of_filling_appeal",
          this.changeformatDatetoinsert(this.principal.date_of_filling_appeal)
        );
      }
      formData.append("good_service", this.principal.good_service.trim());
      formData.append("val_no", this.principal.val_no.trim());
      if (
        this.principal.date_of_filling_lawsuit !== "" &&
        this.principal.date_of_filling_lawsuit !== null
      ) {
        formData.append(
          "date_of_filling_lawsuit",
          this.changeformatDatetoinsert(this.principal.date_of_filling_lawsuit)
        );
      }
      formData.append("remark", this.principal.remark.trim());
      formData.append("page_no", this.principal.page_no.trim());
      if (
        this.principal.date_of_renewal !== "" &&
        this.principal.date_of_renewal !== null
      ) {
        formData.append(
          "date_of_renewal",
          this.changeformatDatetoinsert(this.principal.date_of_renewal)
        );
      }

      formData.append(
        "condition_of_regis",
        this.principal.condition_of_regis.trim()
      );
      if (
        this.principal.date_of_opposition !== "" &&
        this.principal.date_of_opposition !== null
      ) {
        formData.append(
          "date_of_opposition",
          this.changeformatDatetoinsert(this.principal.date_of_opposition)
        );
      }
      if (
        this.principal.date_of_renewal_filed !== "" &&
        this.principal.date_of_renewal_filed !== null
      ) {
        formData.append(
          "date_of_renewal_filed",
          this.changeformatDatetoinsert(this.principal.date_of_renewal_filed)
        );
      }
      if (
        this.principal.next_deadline !== "" &&
        this.principal.next_deadline !== null
      ) {
        formData.append(
          "next_deadline",
          this.changeformatDatetoinsert(this.principal.next_deadline)
        );
      }
      if (
        this.principal.visa_issue_date !== "" &&
        this.principal.visa_issue_date !== null
      ) {
        formData.append(
          "visa_issue_date",
          this.changeformatDatetoinsert(this.principal.visa_issue_date)
        );
      }
      formData.append("tmstatusasoff", this.principal.tmstatusasoff);
      formData.append("dip_link", this.principal.dip_link.trim());
      formData.append("user_create", this.principal.user_create);
      formData.append("user_update", this.principal.user_update);
      formData.append("closed", this.principal.closed);
      if (
        this.principal.date_of_closed !== "" &&
        this.principal.date_of_closed !== null
      ) {
        formData.append(
          "date_of_closed",
          this.changeformatDatetoinsert(this.principal.date_of_closed)
        );
      }
      if (
        this.principal.date_of_issuing_certificate !== "" &&
        this.principal.date_of_issuing_certificate !== null
      ) {
        formData.append(
          "date_of_issuing_certificate",
          this.changeformatDatetoinsert(
            this.principal.date_of_issuing_certificate
          )
        );
      }
      formData.append("disclaim", this.principal.disclaim);

      console.log(formData);
      const resultMasterTM = await api.createMasterTM(formData);
      console.log(resultMasterTM);
      this.$hideLoader();
      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {
        this.dialogAddmastertm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "save data!!!",
          "save data success!!!",
          "text-h5 green--text text-center",
          `/appointmenttm-add/${resultMasterTM.data.file_no}`
        );
        return;
      } else if (resultMasterTM.status == 204) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "data duplicate !!!",
          "please check File No. and Application No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "save data!!!",
          "can't save data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async onSelectCountryChange(name) {},
    async onSelectAgentChange(id) {
      this.customer_name_list.filter((item) => {
        if (item.id == id) {
          this.principal.contact = item.contact_name
          return;
        }
      });
    },
    async onSelectAssociateChange(id) {
      let setdata;
      setdata = this.contact_associate_list.filter((item) => {
        if (item.contact_id == id) {
          this.principal.associate_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectCustomerChange() {},

    async popupdeletefile(index) {
      this.indexdelete = index;
      this.dialogDeletefile = true;
    },
    async closeDelete() {
      this.editedIndex = -1;
      this.dialogDelete = false;
    },
    async closeDeletefile() {
      this.indexdelete = -1;
      this.dialogDeletefile = false;
    },
    async deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    async popupdeleteimage() {
      this.dialogDeleteimage = true;
    },
    async closeDeleteimage() {
      this.dialogDeleteimage = false;
    },
    async deleteItemConfirmimage() {
      this.checkimageadd = false;
      this.image = null;
      this.previewimage = "";
      this.imagename = "";
      this.dialogDeleteimage = false;
    },
    async popuppreviewfile(fileindex) {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_list[fileindex] +
          "'></iframe></body></html>"
      );

      return;
      this.previewpopupfile2 = [];
      await this.getPdfforpreview(this.getpreview_list[fileindex]);
      console.log(this.previewpopupfile);
      this.previewpopupfile2 = this.previewpopupfile;
      this.checkfileapreview = true;
      this.dialogpreviewfile = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfile = false;
    },
    async popuppreviewimage() {
      this.dialogpreviewimage = true;
    },
    async closepreviewimage() {
      this.dialogpreviewimage = false;
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewimage = e.target.result;
          this.checkimageadd = true;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }

      this.imagename = this.image.name;
    },
    async previewMultiImage(event) {
      //   this.preview_list = [];
      // this.image_list = [];
      this.filename = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_list.push(e.target.result);
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      await this.getPdfforAdd(this.getpreview_list);
      this.preview_list2 = this.preview_list;
      this.image_list.forEach((x) => {
        if (x.name) {
          this.filename.push(x.name);
        } else {
          this.filename.push(x.split(/[\s/]+/).pop());
        }
      });
      this.checkfileeadd = true;
    },
    reset: function() {
      this.image = null;
      this.previewimage = null;
      this.image_list = [];
      this.preview_list = [];
    },
    async getPdfforpreview(datafile) {
      this.previewpopupfile = [];
      let getfile = [];
      getfile = datafile;
      this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    async getPdfforAdd(item) {
      this.preview_list = [];
      let getfile = [];
      getfile = item;
      getfile.forEach((file, index) => {
        this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
      });
      this.preview_list.forEach((item, index) => {
        item.then((pdf) => {
          this.numPages = pdf.numPages;
          // window.onscroll = function() {
          //   changePage();
          //   stickyNav();
          // };
          // Get the offset position of the navbar
          // var sticky = $("#buttons")[0].offsetTop;
          // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
          // function stickyNav() {
          //   if (window.pageYOffset >= sticky) {
          //     $("#buttons")[0].classList.remove("hidden");
          //   } else {
          //     $("#buttons")[0].classList.add("hidden");
          //   }
          // }
          function changePage() {
            var i = 1,
              count = Number(pdf.numPages);
            do {
              if (
                window.pageYOffset >=
                  this.findPos(document.getElementById(i)) &&
                window.pageYOffset <=
                  this.findPos(document.getElementById(i + 1))
              ) {
                this.page = i;
              }
              i++;
            } while (i < count);
            if (
              window.pageYOffset >= this.findPos(document.getElementById(i))
            ) {
              this.page = i;
            }
          }
        });
      });
      this.checkfileadd = true;
    },
    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    async CalculateNewRenewalDate() {
      let addyear = 10;
      let addday = 1;
      console.log(this.principal.date_of_renewal);
      console.log(this.date_of_renewal);
      if (
        this.principal.date_of_renewal &&
        this.principal.date_of_renewal != ""
      ) {
        this.date_of_renewal = new Date(
          new Date(this.date_of_renewal).setFullYear(
            new Date(this.date_of_renewal).getFullYear() + addyear,
            new Date(this.date_of_renewal).getMonth(),
            new Date(this.date_of_renewal).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.principal.date_of_renewal = this.formatDate(this.date_of_renewal);
        this.disableCalculateNewRenewalDate = true;
      } else {
        if (
          !this.principal.date_of_application ||
          this.principal.date_of_application == ""
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "Please enter Application Date Data",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.date_of_renewal = new Date(
          new Date(this.date_of_application).setFullYear(
            new Date(this.date_of_application).getFullYear() + addyear,
            new Date(this.date_of_application).getMonth(),
            new Date(this.date_of_application).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.principal.date_of_renewal = this.formatDate(this.date_of_renewal);
        this.disableCalculateNewRenewalDate = true;
      }
    },
    async Selectchangedate_of_application() {
      // this.disableCalculateNewRenewalDate = false;
    },

    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${year}/${month}/${day}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("/");
    //   return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    // },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
