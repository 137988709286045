//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialogInvoice_Mis from "@/components/cards/SuccessDialogInvoice_Mis";
import api from "@/services/api";
import { server, apiKeyTiny } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import Editor from "@tinymce/tinymce-vue";

export default {
  props: [
    "so_id",
    "datainv_additional_top",
    "datainv_additional_bottom",
    "datainv_disable",
  ],
  mounted() {
    this.$showLoader();
    this.additional_top = this.datainv_additional_top;
    this.additional_bottom = this.datainv_additional_bottom;
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
    this.$store.state.global_dialog_invoice_mis = false;
  },
  components: {
    SuccessDialogInvoice_Mis,
    editor: Editor,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data() {
    return {
      additional_top: "",
      additional_bottom: "",
      apiKeyTiny: apiKeyTiny,
      amount_total: 0.0,
      dialog: false,
      dialogDelete: false,
      dialogAddMis: false,
      dialogSendMail: false,
      text_colorMis: "text-h5 green--text text-center",
      titleMis: "green",
      messageMis: "green",
      link: "",
      isShowPassword: false,
      fullPage: true,
      advance_code_list: [],
      desserts: [],
      headers: [
        {
          text: "Miscellaneous Disbursements",
          value: "miscellaneous_disbursements",
          class: "my-header4-style ",
        },
        // {
        //   text: "Description",
        //   value: "description",
        //   class: "my-header-style",
        // },
        {
          text: "Amount",
          value: "amount",
          class: "my-header2-style text-center",
          align: "end",
        },
        {
          text: "Action",
          value: "actions",
          class: "my-header3-style  text-center",
        },
      ],
      editedItem: {
        miscellaneous_disbursements: "",
        specify_type: "",
        // description: "",
        amount: 0,
      },
      miscellaneous_disbursementslist: [
        "Publication Fees",
        "Translation Fees",
        "Legalization Fees",
        "Courier Services",
        "Transportation and photocopies",
        "Government Fee for Work Permit",
        "Government Fee for Visa",
        "Certified Fee",
        "Others",
      ],
    };
  },
  methods: {
    async tohtml() {
      this.$emit("inv_additionaltab_data", {
        additional_top: this.additional_top,
        additional_bottom: this.additional_bottom,
      });
    },
    setupAlertDialog(status, titleMis, messageMis, text_colorMis) {
      this.titleMis = titleMis;
      this.messageMis = messageMis;
      this.dialogAddMis = status;
      this.text_colorMis = text_colorMis;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
