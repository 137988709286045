import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// add leave history web
export const addLeaveApproveDetail = (data) => {
  return httpClient.post(server.LEAVE_APPROVE_DETAIL + `/`, data);
};

//getListApproveDetailByRequestId
export const getListApproveDetailByRequestId = (id) => {
  return httpClient.get(server.LEAVE_APPROVE_DETAIL + `/${id}`);
};


export const getListApproveDetailNoZeroIdByRequestId = (id) => {
         return httpClient.get(server.LEAVE_APPROVE_DETAIL + `/NoZeroId/${id}`);
       };

export const delete_leaveAD = (id) => {
  return httpClient.delete(server.LEAVE_APPROVE_DETAIL + `/${id}`);
};