//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Solist.js";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "SalseOrder For Invoice",
  data(vm) {
    return {
      dialogNewInv: "invoice",
      dialogAddNewInv: false,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "Submit"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      selected: [],
      disableinstatus: false,
      soid: 0,

      headers: [
        {
          text: "Document No.",
          value: "saleorder_id",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
        {
          text: "Date",
          value: "po_date",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
        {
          text: "Customer",
          value: "name",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
        {
          text: "Total Amount",
          value: "sum_line_amt",
          class: "bg-colorth tabletextwhite",
          divider: true,
          align: "end",
        },
        {
          text: "Document Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },

        {
          text: "Editor",
          value: "user_create",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
        {
          text: "",
          value: "radiocheck",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
      ],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    this.userId = localStorage.getItem(server.USER_ID);
    await this.loaddataSO();
  },
  methods: {
    OpenDialog(id){
      this.dialogAddNewInv = true;
      this.soid = id;
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      //alert(this.datefrom)
      this.mDataArray = this.dataAll.filter((item) => {
          if(this.datefrom != null && this.dateto != null){
            if(item.po_date >= this.datefrom && item.po_date <= this.dateto){
              return item;
            }
          }
          if(this.datefrom == null && this.dateto == null){
            return item;
          }
      });
    },
    onClicktoadSolist(id) {
      this.$router.push(`/edit-so/${id}`);
    },
    async loaddataSO() {
      const result = await api.getSalesOrderBySubmit(localStorage.getItem(server.COMPANYID));
      this.dataAll = result.data;
      // console.log("dateAll:")
      //console.log(this.dataAll);
      //this.mDataArray = result.data;
      this.mDataArray = this.dataAll.filter((item) => {
        if (item.amount_total < item.sum_line_amt) {
          return item;
        }
      });

      this.mDataArray.forEach((item) => {
        item.sum_line_amt = tolocalestringnumber(item.sum_line_amt);
      });
      console.log(this.mDataArray);

      // this.$showLoader();
      this.$hideLoader();
    },

    async saveInvoice() {
      this.$router.push(
        `/new-invoice/${this.soid}/${this.dialogNewInv}/${this.$route.params.company_inv}`
      );
    },
    async saveFreePinvoice() {
      this.$router.push(
        `/new-free-invoice/0/performa/${this.$route.params.company_inv}`
      );
    },
    async saveFreeinvoice() {
      this.$router.push(
        `/new-free-invoice/0/invoice/${this.$route.params.company_inv}`
      );
    },
  },
};
