import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllleave_quota = () => {
  return httpClient.get(server.LEAVE_QUOTA + `/getAll`);
};

export const getAllleave_quota_By_Company = (id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getAll_By_Company/${id}`);
};

export const getAllleave_quotaByID = (id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getListUseByUserID/${id}`);
};

export const getAllCheckPeriod = (id, datefrom, dateto) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getAllCheckPeriod/${id}/${datefrom}/${dateto}`
  );
};

export const getAllCheckPeriodByLeaveType = (
  id,
  datefrom,
  dateto,
  leave_type_id
) => {
  return httpClient.get(
    server.LEAVE_QUOTA +
      `/getAllCheckPeriodByLeaveType/${id}/${datefrom}/${dateto}/${leave_type_id}`
  );
};

export const importxlsxLeave_quota = (data) => {
  return httpClient.post(server.LEAVE_QUOTA + `/importxlsx`, data);
};

export const getCheckType = (id, leave_type) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getleavetype/list/${id}/${leave_type}`
  );
};

export const getAllleave_type = () => {
  return httpClient.get(server.LEAVE_QUOTA + `/getAllLeavetype`);
};

export const getexportLeave_quota = () => {
  return httpClient.get(server.LEAVE_QUOTA + `/getexportactive`);
};

export const getcheckperiodquota = (id,datefrom,dateto) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getAllCheckPeriod/${id}/${datefrom}/${dateto}`);
};

export const getcheckeditperiodquota = (id,datefrom,dateto,datefromc,datetoc) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getAllCheckEditPeriod/${id}/${datefrom}/${dateto}/${datefromc}/${datetoc}`);
};

export const getDataEditLeaveQuota = (id,datefrom,dateto,company_id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getDataEditLeaveQuota/${id}/${datefrom}/${dateto}/${company_id}`);
};

export const updateCloseLeaveQuota = (datefrom,dateto) => {
  return httpClient.get(server.LEAVE_QUOTA + `/updateCloseLeaveQuota/${datefrom}/${dateto}`);
};

export const deleteLeave_quota = (data) => {
  return httpClient.post(server.LEAVE_QUOTA + `/delete`, data);
};

export const createLeaveQuota = (data) => {
  return httpClient.post(server.LEAVE_QUOTA + `/create`, data);
};
export const updateQuota = (id, data) => {
  return httpClient.put(server.LEAVE_QUOTA + `/update/quota/${id}`, data);
};
