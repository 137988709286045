//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import invoiceTimeSheetTap_Selet from "./invoiceTimeSheetTap_Selet";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  props: ["disableinstatus","ts_status_oper","tsTAB_IdforCus","ad_idTab","getdataByCusStatus","selected_showAv","oper_sel_Av"],
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
    invoiceTimeSheetTap_Selet
  },
  data(vm) {
    return {
      isShowPassword: false,
      fullPage: true,
      toggleEnable: false,
      mDataArray: [],
      selected: [],
      search: "",
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      menu: false,
      menu2: false,
      datefrom: null,
      dateto: null,
      dialogConfirm: false,
      headers: [
        {
          text: "Document No.",
         
          class: "bg-colorth texttablehcenter ",
          value: "no",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          class: "bg-colorth texttablehcenter",
         
          align: "start",
          divider: true,
          sortable: false,
          width: "105px"
        },

        {
          text: "Name",
          class: "bg-colorth texttablehcenter",
          value: "name",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Customer",
          class: "bg-colorth texttablehcenter",
          value: "customer",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Amount",
          class: "bg-colorth texttablehcenter",
          value: "clear_amount",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Status",
          class: "bg-colorth texttablehcenter",
          align: "center",
          value: "status",
          divider: true,
          sortable: false,
        },
      ],
      mDataArrayC: [],
      dataAllC: [],
      So_hid: [],
      hid_array: {
        h_id: [],
      },
      headersC: [
        {
          text: "Document No.",
                          
          class: "bg-colorth texttablehcenter ",
          value: "no",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          class: "bg-colorth texttablehcenter",               
          align: "start",
          divider: true,
          sortable: false,
        },

        {
          text: "Code",
          class: "bg-colorth texttablehcenter",
          value: "advance_title",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",             
          class: "bg-colorth texttablehcenter",
                      
          divider: true,
          align: "start",
          sortable: false,
        },              
        {
          text: "Clear Amount",
          value: "clear_amount_s",                
          class: "bg-colorth texttablehcenter",
                      
          divider: true,
          align: "end ",
          sortable: false,
        },                            
      ],
      sum_grandTotal_show: 0,
      ad_status_inv: [],
    }
  },
  async mounted() {
    await this.loaddataAdvance();
  },
  methods: {
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    async loaddataAdvance() {
      this.$showLoader();
      //get customer
      if(this.getdataByCusStatus == "INV"){
        const res_cusid = await api.getdataByIdInvoice_H(this.tsTAB_IdforCus);
        if(res_cusid.data[0]){
          this.cusid_ts = res_cusid.data[0].customer_id;
        }
      }else{
        const res_cusid = await api.getCusId(this.tsTAB_IdforCus);
        // console.log(res_cusid.data[0].customer_id)
        if(res_cusid.data[0]){
          this.cusid_ts = res_cusid.data[0].customer_id;
        }
      }

      // get data totable
      if(this.ts_status_oper == "Submit"){ 
        if(this.ad_idTab.length >0){
              let getSelected_showAv = {}
              getSelected_showAv.id = this.ad_idTab;
              const result_selected_showAv = await api.gatAdvanceHByManyId(getSelected_showAv)
              this.selected_showAv = result_selected_showAv.data;
              this.selected_showAv.forEach((x, index) => {
                x.clear_amount = tolocalestringnumber(x.clear_amount);
                x.status_id = x.status;
                if (x.status == 9) {
                  x.status = "Close";
                }else if (x.status == 10) {
                  x.status = "Invioce";
                }
                x.disabled = true;
              });
        }
        this.mDataArray = this.selected_showAv;
        this.selected =  this.selected_showAv;
      }else{
        const result = await api.getAllByCus(this.cusid_ts,localStorage.getItem(server.COMPANYID));
        this.dataAll = result.data;
        // console.log(`adId : `+this.ad_idTab)
        this.dataAll.forEach((x, index) => {
              x.clear_amount = tolocalestringnumber(x.clear_amount);
              x.status_id = x.status;
              if (x.status == 9) {
                x.status = "Close";
              }else if (x.status == 10) {
                x.status = "Invioce";
              }
        });
        if(this.oper_sel_Av == 1){
             if(this.ad_idTab.length >0){
              let getSelected_showAv = {}
              getSelected_showAv.id = this.ad_idTab;
              const result_selected_showAv = await api.gatAdvanceHByManyId(getSelected_showAv)
              this.selected_showAv = result_selected_showAv.data;
              this.selected_showAv.forEach((x, index) => {
                this.ad_status_inv[index] = x.id;
                x.clear_amount = tolocalestringnumber(x.clear_amount);
                x.status_id = x.status;
                if (x.status == 9) {
                  x.status = "Close";
                }else if (x.status == 10) {
                  x.status = "Invioce";
                }
              });
            }
            this.mDataArray = this.selected_showAv.concat(this.dataAll);
            this.mDataArray =  [ ...new Set(this.mDataArray)];
            //console.log(this.mDataArray)
            this.selected =  this.selected_showAv;
            this.oper_sel_Av = 0;
        }else{
          if(this.ad_idTab.length >0){
            let getSelected_showAv = {}
            getSelected_showAv.id = this.ad_idTab;
            const result_selected_showAv = await api.gatAdvanceHByManyId(getSelected_showAv)
            const selected_data = result_selected_showAv.data;
            selected_data.forEach((x, index) => {
              x.clear_amount = tolocalestringnumber(x.clear_amount);
              x.status_id = x.status;
              if (x.status == 9) {
                x.status = "Close";
              }else if (x.status == 10) {
                x.status = "Invioce";
              }
            });
            this.dataAll = selected_data.concat(this.dataAll);
            for(let i=0;i<this.selected_showAv.length;i++){
              this.dataAll.forEach((item, index) => {           
                if(this.selected_showAv[i].id == item.id){
                  this.dataAll.splice(index, 1);
                }
              });  
            }
            this.mDataArray = this.selected_showAv.concat(this.dataAll);
            this.selected = this.selected_showAv;
          }else{
            for(let i=0;i<this.selected_showAv.length;i++){
              this.dataAll.forEach((item, index) => {           
                if(this.selected_showAv[i].id == item.id){
                  this.dataAll.splice(index, 1);
                }
              });  
            }
            this.mDataArray = this.selected_showAv.concat(this.dataAll);
            this.selected = this.selected_showAv;
          }
        }
        //console.log(this.mDataArray)
      }
      this.$hideLoader();
      },
      enterSelect(values) {
        if(this.ts_status_oper == "Submit"){
          this.disableinstatus = true;
        }else{
          if (values.length > 0) {
            this.disableinstatus = false;
          } else {
            this.disableinstatus = true;
          }
        }
      },
      async closedialogConfirm(){
        this.$emit("getSelected_showAvTab", this.selected_showAv);
        this.$emit("getOper_sel_avTab", this.oper_sel_Av);
        this.$emit("getdatafromAdtotal",stringcommatonumber(this.sum_grandTotal_show));
        this.$emit("getAdIdTab", this.hid_array.h_id);
        this.$emit("getAdInvioceIdTab", this.ad_status_inv);
        this.sum_grandTotal_show = 0;
        this.disableinstatus = true;
        this.dialogConfirm = false;
      },

      async opendialogConfirm(){
        this.selected_showAv = this.selected;
        if (this.selected.length > 0) {
          // console.log(this.selected)
          this.So_hid = [];
          for (let i = 0; i < this.selected.length; i++){
            this.So_hid[i] = this.selected[i].id
           }
          this.hid_array.h_id = this.So_hid;
          // console.log(this.hid_array.h_id)

          this.$showLoader();
          const res_advanD = await api.getAdvanD(this.hid_array)
          this.dataAllC = res_advanD.data;
          this.dataAllC.forEach((item) => {    
            this.sum_grandTotal_show += Number(item.clear_amount);             
            item.clear_amount_s = tolocalestringnumber(Number(item.clear_amount));  
          });
          this.sum_grandTotal_show = tolocalestringnumber(this.sum_grandTotal_show);
          this.mDataArrayC = this.dataAllC;
          console.log(this.mDataArrayC)
          this.$hideLoader();

          this.dialogConfirm = true;
        }else{
          return;
        }
      },
      finddatafromdate() {
        this.mDataArray = this.dataAll.filter((item) => {
              if(this.datefrom != null && this.dateto != null){
                if(item.date >= this.datefrom && item.date <= this.dateto){
                  return item;
                }
              }
              if(this.datefrom == null && this.dateto == null){
                return item;
              }
        });
      },
      formatDate(date) {
          if (!date) return null;

          const [year, month, day] = date.split("-");
         return `${year}-${month - 1}-${day}`;
      },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
