//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import api from "@/services/api";
  import { server } from "@/services/constants";
  import SuccessDialog from "@/components/cards/SuccessDialog";
  import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
  import unity from "@/unity/unity";
  export default {
    name: "NewAdvance",
    data() {
      return {
        company_list: [],
        department_list: [],
        authorize_list: [],
        admin_list: [],
        position_list: [],
        tranning_name: "",
        dataAdd: {
            company_id: 0,
            department_id: 0,
            position_id: 0,
            authorize_id: 0,
            tranning_name: "",
            admin_id: 0,
            status: "INACTIVE",
            jobtitle_id: 0,
            date_start: "",
            date_end: "",
        },
        dialogAdd: false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        menu_date: false,
        date_training: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        start_hours:false,
        start_time: "",
        end_hours:false,
        end_time: "",
        date: "",
        time: ""
      };
    },
    components: {
      SuccessDialog,
      SuccessDialogPush,
    },
    computed: {
      computedDateFromFormatted() {
        return unity.formatDate(this.date_training);
      },
    },
    watch: {},
    async mounted() {
      const userId = localStorage.getItem(server.USER_ID);
      if (userId && api.isLoggedIn()) {
        // this.$router.push("/");
      } else {
        this.$store.state.isLogged = false;
        this.$router.push("/login");
      }
      await this.loadCompany();
      this.$hideLoader();
    },
    methods: {
        async loadCompany(){
            const res_com = await api.getAllCompany();
            this.company_list = res_com.data;
            console.log("this.company_list")
            console.log(this.company_list)
        },
        async showdepartName() {
          const res_dep = await api.getAllDepartment_By_CompanyID(this.dataAdd.company_id);
          this.department_list = res_dep.data;
        },
        async showAuthorize() {
          const res_aut = await api.getSettingGroupMenu_By_CompanyID(this.dataAdd.company_id);
          this.authorize_list = res_aut.data;
        },
        async showAdmin() {
          const res_adm = await api.getAccountsByCompany(this.dataAdd.company_id);
          console.log("res_adm.data")
          console.log(res_adm.data)
          this.admin_list = res_adm.data;
        },
        async showPositionName() {
          const res_po = await api.getAllPositionTranning(this.dataAdd.company_id,this.dataAdd.department_id);
          this.position_list = res_po.data;
        },
        closeMenuStart() {
          this.start_hours = false; // ปิดเมนู
        },
        closeMenuEnd() {
          this.end_hours = false; // ปิดเมนู
        },
        async checktime(){  
          console.log(this.start_time)
          console.log(this.end_time)
          if(this.start_time != "" && this.end_time != ""){
            if (this.start_time >= this.end_time) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Time(Start<END), Please New choose a DateTime",
                    "text-h5 red--text text-center"
                );
                return;
            }
          }

        },
        async saveItem() {
            console.log(this.dataAdd)
            if (this.dataAdd.company_id == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a Company",
                    "text-h5 red--text text-center"
                );
                this.$refs["company_id"].focus();
                return;
            }
            if (this.dataAdd.department_id == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a department",
                    "text-h5 red--text text-center"
                );
                this.$refs["department_id"].focus();
                return;
            }
            if (this.dataAdd.position_id == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a Position",
                    "text-h5 red--text text-center"
                );
                this.$refs["position_id"].focus();
                return;
            }
            if (this.dataAdd.authorize_id == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a Authorize",
                    "text-h5 red--text text-center"
                );
                this.$refs["authorize_id"].focus();
                return;
            }
            if (this.dataAdd.tranning_name == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please enter the Training Course Name",
                    "text-h5 red--text text-center"
                );
                this.$refs["tranning_name"].focus();
                return;
            }

            if (this.dataAdd.admin_id == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a Administrator",
                    "text-h5 red--text text-center"
                );
                this.$refs["admin_id"].focus();
                return;
            }
            if(this.start_time == "" || this.end_time == ""){
                this.$store.state.global_dialog = true;
                  this.setupAlertDialog(
                      true,
                      "Invalid Information",
                      "Please choose a DateTime",
                      "text-h5 red--text text-center"
                  );
                return;
            }
            if(this.start_time != "" && this.end_time != ""){
              if (this.start_time >= this.end_time) {
                  this.$store.state.global_dialog = true;
                  this.setupAlertDialog(
                      true,
                      "Invalid Information",
                      "Time(Start<END), Please New choose a DateTime",
                      "text-h5 red--text text-center"
                  );
                  return;
              }
            }
            console.log(this.dataAdd)
            const res_check = await api.getTRANNING_HByTranningName(this.dataAdd.tranning_name)
            console.log("res_check.data")
            console.log(res_check.data)
            if(res_check.data.length > 0){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Training Name Duplicate, Please Enter Document Type Data!!!",
                    "text-h5 red--text text-center"
                );
                this.$refs["tranning_name"].focus();
                return;
            }
            this.$showLoader();
            let data_add_job = {
              name: this.dataAdd.tranning_name,
              company_id: this.dataAdd.company_id,
              department_id: this.dataAdd.department_id,
              create_by: localStorage.getItem(server.USER_ID),
              status: "INACTIVE",
            }

            const res_addjob = await api.addJobtitle(data_add_job)

            this.dataAdd.jobtitle_id = res_addjob.data.id;

            let d_start = "";
            let e_start = "";
            d_start = `${this.date_training} ${this.start_time}:00`
            e_start = `${this.date_training} ${this.end_time}:00`

            this.dataAdd.date_start = d_start;
            this.dataAdd.date_end = e_start;

            const res_add = await api.addTRANNING_H(this.dataAdd);
            if (res_add.status == 200 || res_add.status == 201) {

                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                    true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    `/training-list`
                );
            } else {
                this.$hideLoader()
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Information Saved",
                    "ไม่สามารถบันทึกได้!!!",
                    "text-h5 red--text text-center"
                );
                return
            }
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
    },
    async beforeCreate() {
      console.log("beforeCreate");
      this.$store.state.navMenu = true;
    },
};
