//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialogPushpaymentIM from "@/components/cards/SuccessDialogPushpaymentIM";
import api from "@/services/api";
import {
  server,
  imageappointmentTMUrl,
  fileappointmentTMUrl,
} from "@/services/constants";
import pdfvuer from "pdfvuer";

export default {
  name: "About",
  data() {
    return {
      querydata:{},
      dataappiontment:{},
      filedfinaldeadlineninety_date: "",
      filedfinaldeadlinesixty_date: "",
      filed_date: "",
      issuingcer_date: "",
      receive_date: "",
      enddate: "",
      internaldate: "",
      sourcetypelist: ["Notice", "Mail", "Auto"],
      selectedtrademarkworktype: {},
      dialogDelete: false,
      menufiledfinaldeadlinesixty_date: false,
      menufiledfinaldeadlineninety_date: false,
      menufiled_date: false,
      menuissuingcer_date: false,
      menureceive_date: false,
      menustartdate: false,
      menuenddate: false,
      menuinternaldate: false,
      message: "Welcome to Vue.js!",
      file_no_list: [],
      objecttype_list: [],
      documenttype_list: [],
      notice_list: [],
      showapp_no: "",
      showaddItem: {
        objecttype: "",
        doctype: "",
        refno: "",
        trademark_name: "",
        receive_date: "",
        startdate: "",
        enddate: "",
        internaldate: "",
        issuingcer_date: "",
        filed_date: "",
        filedfinaldeadlinesixty_date: "",
        filedfinaldeadlineninety_date: "",
        categories: "",
        description: "",
        remark: "",
        nextdeadlineninety: false,
        nextdeadlinesixty: false,
        report: false,
        paymentrequest: false,
        cert: false,
        done: false,
        sourcetype: "",
        notice: "",
        action: "",
      },
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      getpreview_listnotice: [],
      preview_listnotice: [],
      image_listnotice: [],
      filenamenotice: [],
      preview_listnotice2: [],
      preview_listattach: [],
      image_listattach: [],
      filenameattach: [],
      getpreview_listattach: [],
      preview_listattach2: [],
      previewpopupfile: [],
      previewpopupfile2: [],
      checkfileapreview: false,
      dialogpreviewfilenotice: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      numPagespreviewattach: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      previewpopupattachfile2: [],
      previewpopupattachfile: [],
      checkattachfileapreview: false,
      dialogpreviewattachfile: false,
      previewattachimage: "",
      dialogpreviewattachimage: false,
      dialogconfirmdeletenoticefile: false,
      dialogconfirmdeleteattachfile: false,
      indexdeleteattachfile: -1,
      customer_id_forpatmentrequest:0,
      customer_name_list: [],
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    SuccessDialogPushpaymentIM,
    pdf: pdfvuer,
  },
  watch: {
    receive_date(val) {
      this.showaddItem.receive_date = this.formatDate(this.receive_date);
    },
    enddate(val) {
      this.showaddItem.enddate = this.formatDate(this.enddate);
    },
    internaldate(val) {
      this.showaddItem.internaldate = this.formatDate(this.internaldate);
    },
    issuingcer_date(val) {
      this.showaddItem.issuingcer_date = this.formatDate(this.issuingcer_date);
    },
    filed_date(val) {
      this.showaddItem.filed_date = this.formatDate(this.filed_date);
    },
    filedfinaldeadlinesixty_date(val) {
      this.showaddItem.filedfinaldeadlinesixty_date = this.formatDate(
        this.filedfinaldeadlinesixty_date
      );
    },
    filedfinaldeadlineninety_date(val) {
      this.showaddItem.filedfinaldeadlineninety_date = this.formatDate(
        this.filedfinaldeadlineninety_date
      );
    },
  },
  async mounted() {
    await this.loaddataobjecttypeBygroupId();
    await this.loaddatadocumenttype();
    await this.loaddatanotice();
    await this.loaddataappointment();
    await this.loadCustomer();
    this.$hideLoader();
  },
  methods: {
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.customer_name_list = result.data;
    },
    async loaddataappointment() {
      //   this.showaddItem.refno = this.$route.params.appointment_id;
      const resultappointment = await api.AppointmentgetById(
        this.$route.params.appointment_id
      );
      console.log(resultappointment.data);
      this.showaddItem.refno = resultappointment.data[0].refno;

      this.receive_date =
        resultappointment.data[0].receive_date == "0000-00-00" ||
        resultappointment.data[0].receive_date == null
          ? ""
          : // new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            //     .toISOString()
            //     .substr(0, 10)
            resultappointment.data[0].receive_date;

      this.showaddItem.receive_date =
        resultappointment.data[0].receive_date == "0000-00-00" ||
        resultappointment.data[0].receive_date == null
          ? ""
          : this.formatDate(resultappointment.data[0].receive_date);
      this.showaddItem.startdate = resultappointment.data[0].startdate;

      this.enddate =
        resultappointment.data[0].enddate == "0000-00-00" ||
        resultappointment.data[0].enddate == null
          ? ""
          : resultappointment.data[0].enddate;

      this.showaddItem.enddate =
        resultappointment.data[0].enddate == "0000-00-00" ||
        resultappointment.data[0].enddate == null
          ? ""
          : this.formatDate(resultappointment.data[0].enddate);

      this.internaldate =
        resultappointment.data[0].internaldate == "0000-00-00" ||
        resultappointment.data[0].internaldate == null
          ? ""
          : resultappointment.data[0].internaldate;

      this.showaddItem.internaldate =
        resultappointment.data[0].internaldate == "0000-00-00" ||
        resultappointment.data[0].internaldate == null
          ? ""
          : this.formatDate(resultappointment.data[0].internaldate);

      this.filed_date =
        resultappointment.data[0].filed_date == "0000-00-00" ||
        resultappointment.data[0].filed_date == null
          ? ""
          : resultappointment.data[0].filed_date;

      this.showaddItem.filed_date =
        resultappointment.data[0].filed_date == "0000-00-00" ||
        resultappointment.data[0].filed_date == null
          ? ""
          : this.formatDate(resultappointment.data[0].filed_date);

      this.issuingcer_date =
        resultappointment.data[0].issuingcer_date == "0000-00-00" ||
        resultappointment.data[0].issuingcer_date == null
          ? ""
          : resultappointment.data[0].issuingcer_date;

      this.showaddItem.issuingcer_date =
        resultappointment.data[0].issuingcer_date == "0000-00-00" ||
        resultappointment.data[0].issuingcer_date == null
          ? ""
          : this.formatDate(resultappointment.data[0].issuingcer_date);
      this.filedfinaldeadlinesixty_date =
        resultappointment.data[0].filedfinaldeadlinesixty_date ==
          "0000-00-00" ||
        resultappointment.data[0].filedfinaldeadlinesixty_date == null
          ? ""
          : resultappointment.data[0].filedfinaldeadlinesixty_date;

      this.showaddItem.filedfinaldeadlinesixty_date =
        resultappointment.data[0].filedfinaldeadlinesixty_date ==
          "0000-00-00" ||
        resultappointment.data[0].filedfinaldeadlinesixty_date == null
          ? ""
          : this.formatDate(
              resultappointment.data[0].filedfinaldeadlinesixty_date
            );

      this.filedfinaldeadlineninety_date =
        resultappointment.data[0].filedfinaldeadlineninety_date ==
          "0000-00-00" ||
        resultappointment.data[0].filedfinaldeadlineninety_date == null
          ? ""
          : resultappointment.data[0].filedfinaldeadlineninety_date;

      this.showaddItem.filedfinaldeadlineninety_date =
        resultappointment.data[0].filedfinaldeadlineninety_date ==
          "0000-00-00" ||
        resultappointment.data[0].filedfinaldeadlineninety_date == null
          ? ""
          : this.formatDate(
              resultappointment.data[0].filedfinaldeadlineninety_date
            );
      if (resultappointment.data[0].notice_file) {
        this.filenamenotice[0] = resultappointment.data[0].notice_file;
        this.getpreview_listnotice[0] = `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${this.filenamenotice}`;
        this.image_listnotice.push(this.getpreview_listnotice[0]);
      }

      if (resultappointment.data[0].attachment_files) {
        this.filenameattach = resultappointment.data[0].attachment_files.split(
          ","
        );
        this.filenameattach.forEach((x, index) => {
          if (x.split(/[\s.]+/).pop() == "pdf") {
            this.image_listattach.push({name:
              `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`}
            );
            this.getpreview_listattach.push(
              `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`
            );
          } else {
            this.image_listattach.push(
              {name:
              `${imageappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`}
            );
            this.getpreview_listattach.push(
              `${imageappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`
            );
          }
        });
        console.log(this.image_listattach)
      }
      this.showaddItem.objecttype = resultappointment.data[0].objecttype;
      this.showaddItem.notice = resultappointment.data[0].notice;
      this.onSelectnoticeChange(resultappointment.data[0].notice);
      this.showaddItem.action = resultappointment.data[0].action;
      this.showaddItem.doctype = resultappointment.data[0].doctype;
      this.onSelectCustomerChange(resultappointment.data[0].objecttype);
      this.showaddItem.categories = resultappointment.data[0].categories;
      this.showaddItem.description = resultappointment.data[0].description;
      this.showaddItem.remark = resultappointment.data[0].remark;
      this.showaddItem.nextdeadlineninety =
        resultappointment.data[0].nextdeadlineninety;
      this.showaddItem.nextdeadlinesixty =
        resultappointment.data[0].nextdeadlinesixty;
      this.showaddItem.done = resultappointment.data[0].done;
      this.showaddItem.report = resultappointment.data[0].report;
      this.showaddItem.paymentrequest =
        resultappointment.data[0].paymentrequest;
      this.showaddItem.cert = resultappointment.data[0].cert;
      this.showaddItem.sourcetype = resultappointment.data[0].sourcetype;

      if (this.showaddItem.refno != " ") {
        this.onSearchfile_no(this.showaddItem.refno);
      }
    },

    async saveaddItem(mode) {
      if (this.showaddItem.objecttype == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Object Type !!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.doctype == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Work Type !!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.notice == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Notice Type!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.refno == "" || this.showaddItem.refno == null) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put File No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.showaddItem.receive_date == "" ||
        this.showaddItem.receive_date == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Receive Date!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (
      //   this.showaddItem.issuingcer_date == "" ||
      //   this.showaddItem.issuingcer_date == null
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Check data !!!",
      //     "Put Issuing Cer. Date!!!",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      if (this.showaddItem.enddate == "" || this.showaddItem.enddate == null) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Final Deadline!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.showaddItem.internaldate == "" ||
        this.showaddItem.internaldate == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Internal Date!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (
      //   this.showaddItem.filed_date == "" ||
      //   this.showaddItem.filed_date == null
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Check data !!!",
      //     "Put Filed Date!!!",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      const checkfile_no = await api.getfile_noforappointment(
        this.showaddItem.refno
      );
      if (checkfile_no.data[0]) {
        console.log(this.showaddItem);
        this.showaddItem.receive_date = this.changeformatDatetoinsert(
          this.showaddItem.receive_date
        );
        this.showaddItem.enddate = this.changeformatDatetoinsert(
          this.showaddItem.enddate
        );
        this.showaddItem.internaldate = this.changeformatDatetoinsert(
          this.showaddItem.internaldate
        );
        this.showaddItem.filedfinaldeadlinesixty_date = this.changeformatDatetoinsert(
          this.showaddItem.filedfinaldeadlinesixty_date
        );
        this.showaddItem.filedfinaldeadlineninety_date = this.changeformatDatetoinsert(
          this.showaddItem.filedfinaldeadlineninety_date
        );
        this.showaddItem.issuingcer_date = this.changeformatDatetoinsert(
          this.showaddItem.issuingcer_date
        );
        this.showaddItem.filed_date = this.changeformatDatetoinsert(
          this.showaddItem.filed_date
        );

        let formData = new FormData();
        if (this.image_listnotice.length > 0) {
          formData.append(`file`, this.image_listnotice[0]);
        }
        if (this.image_listattach.length > 0) {
          this.image_listattach.forEach((x, index) => {
            formData.append(`file`, x);
          });
        }
        if (this.image_listnotice.length > 0) {
          formData.append(
            `notice_file`,
            this.filenamenotice[0].replace(/\s/g, "_")
          );
        } else {
          formData.append(`notice_file`, "");
        }
        if (this.image_listattach.length > 0) {
          let getnamefilenameattach = "";
          this.filenameattach.forEach((x, index) => {
            if (index > 0) {
              getnamefilenameattach += `,${x}`;
            } else {
              getnamefilenameattach += `${x}`;
            }
          });
          formData.append(
            `attachment_files`,
            getnamefilenameattach.replace(/\s/g, "_")
          );
        } else {
          formData.append(`attachment_files`, "");
        }

        formData.append("objecttype", this.showaddItem.objecttype);
        formData.append("doctype", this.showaddItem.doctype);
        formData.append("refno", this.showaddItem.refno);
        formData.append("trademark_name", this.showaddItem.trademark_name);
        formData.append("receive_date", this.showaddItem.receive_date);
        formData.append("enddate", this.showaddItem.enddate);
        formData.append("internaldate", this.showaddItem.internaldate);
        formData.append("issuingcer_date", this.showaddItem.issuingcer_date);
        formData.append("filed_date", this.showaddItem.filed_date);
        formData.append(
          "filedfinaldeadlinesixty_date",
          this.showaddItem.filedfinaldeadlinesixty_date
        );
        formData.append(
          "filedfinaldeadlineninety_date",
          this.showaddItem.filedfinaldeadlineninety_date
        );
        formData.append("categories", this.showaddItem.categories);
        formData.append("description", this.showaddItem.description);
        formData.append("remark", this.showaddItem.remark);
        formData.append(
          "nextdeadlineninety",
          this.showaddItem.nextdeadlineninety
        );
        formData.append(
          "nextdeadlinesixty",
          this.showaddItem.nextdeadlinesixty
        );
        formData.append("report", this.showaddItem.report);
        if (mode == "paymentrequest") {
          formData.append("paymentrequest", 1);
        }else{
          formData.append("paymentrequest", 0);
        }
        formData.append("sourcetype", this.showaddItem.sourcetype);
        formData.append("notice", this.showaddItem.notice);
        formData.append("action", this.showaddItem.action);

        this.showaddItem.receive_date = this.formatDate(
          this.showaddItem.receive_date
        );
        this.showaddItem.enddate = this.formatDate(this.showaddItem.enddate);
        this.showaddItem.internaldate = this.formatDate(
          this.showaddItem.internaldate
        );
        this.showaddItem.filedfinaldeadlinesixty_date = this.formatDate(
          this.showaddItem.filedfinaldeadlinesixty_date
        );
        this.showaddItem.filedfinaldeadlineninety_date = this.formatDate(
          this.showaddItem.filedfinaldeadlineninety_date
        );
        this.showaddItem.issuingcer_date = this.formatDate(
          this.showaddItem.issuingcer_date
        );
        this.showaddItem.filed_date = this.formatDate(
          this.showaddItem.filed_date
        );

        this.$showLoader();
        const resultAppointment = await api.AppointmentUpdate(
          this.$route.params.appointment_id,
          formData
        );

        if (
          resultAppointment.status == 200 ||
          resultAppointment.status == 201
        ) {
          var dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Update",
              table_name: "appointment",
              table_id: this.$route.params.appointment_id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

          if (mode == "paymentrequest") {
            let res_shearch_data = {
              objecttype_id: this.showaddItem.objecttype,
              work_type: this.showaddItem.doctype,
              notice_id: this.showaddItem.notice,
            }
            const res_data_table = await api.getIPPriceListS("TM",res_shearch_data)
            // console.log(res_data_table.data)
            this.dataappiontment = {};
            if(res_data_table.data.length > 0){
              this.dataappiontment = {
                description_inv: res_data_table.data[0].price_desc,
                price_inv: Number(res_data_table.data[0].thb)
              }
            }

            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPushpaymentIM(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              `/new-free-invoice/0/invoice/D/${this.customer_id_forpatmentrequest}/IP`,
              this.dataappiontment
            );
            return;
          }else{
            localStorage.setItem(server.MODELMENU, 6);
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;

            this.setupAlertDialogPush(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              "/appointment-trademark-list"
            );
            return;
          }
        } else {
          this.$hideLoader();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data!!!",
            "can't save data!!!",
            "text-h5 red--text text-center"
          );
          return;
        }
      } else {
        this.showaddItem.refno = "";
        this.showaddItem.trademark_name = "";
        this.showapp_no = "";
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Search File No. !!!",
          "File No. not found !!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async loaddataobjecttypeBygroupId() {
      const result = await api.getObjectTypeByGroupId(1);
      // const result = await api.getAllObjectType();
      console.log(result.data);
      this.objecttype_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatadocumenttype() {
      const result = await api.getdocumenttype_code();
      console.log(result.data);
      this.documenttype_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatanotice() {
      const result = await api.getnotice_code();
      console.log(result.data);
      this.notice_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async onSelectCustomerChange(objecttype_id) {
      const resultdocumenttype = await api.getdocumenttype_codeByobjecttype_id(
        objecttype_id
      );
      this.documenttype_list = resultdocumenttype.data;

      const resultnotice = await api.getnotice_codeByobjecttype_id(
        objecttype_id
      );
      this.notice_list = resultnotice.data;
      // this.selectedtrademarkworktype = doctype;
      // this.selectedtrademarkworktype = this.documenttype_list.filter((item) => {
      //   if (item.documenttype_id == doctype_id) {
      //     return item;
      //   }
      // });
      // console.log(this.selectedtrademarkworktype);
    },
    async onSelectnoticeChange(doctype_id) {
      this.selectedtrademarkworktype = this.notice_list.filter((item) => {
        if (item.notice_id == doctype_id) {
          return item;
        }
      });
      console.log(this.selectedtrademarkworktype);
    },
    async Selectchangereceivedate() {
      let setinternaldate = 7;
      if (this.showaddItem.doctype == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Trademark Work Type !!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      this.showaddItem.enddate = new Date(
        new Date(
          this.changeformatDatetoinsert(this.showaddItem.receive_date)
        ).setDate(
          new Date(
            this.changeformatDatetoinsert(this.showaddItem.receive_date)
          ).getDate() + this.selectedtrademarkworktype[0].numberofenddate
        ) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.showaddItem.enddate = this.formatDate(this.showaddItem.enddate);

      this.showaddItem.internaldate = new Date(
        new Date(
          this.changeformatDatetoinsert(this.showaddItem.enddate)
        ).setDate(
          new Date(
            this.changeformatDatetoinsert(this.showaddItem.enddate)
          ).getDate() - setinternaldate
        ) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.showaddItem.internaldate = this.formatDate(
        this.showaddItem.internaldate
      );
    },
    async onSearchfile_no(file_no) {
      if (!file_no || file_no.trim() == "") {
        this.showaddItem.trademark_name = "";
        this.showapp_no = "";
        return;
      }
      const result = await api.getfile_noforappointment(file_no);
      console.log(result.data[0]);
      if (result.data[0]) {
        this.showaddItem.trademark_name = result.data[0].trademark;
        this.showapp_no = result.data[0].app_no;
       
         this.customer_id_forpatmentrequest = result.data[0].agent_contact_custid != 0 ? result.data[0].agent_contact_custid : result.data[0].owner_contact_custid != 0 ? result.data[0].owner_contact_custid : 0;
        // this.customer_id_forpatmentrequest = result.data[0].agent_contact != 0 ? result.data[0].agent_contact : result.data[0].owner_contact != 0 ? result.data[0].owner_contact : 0;
      //  const resultcontact =  await api.getMasterContactCheckcontactid(getcontact_id);
      //  let getcontant_name  = "";
      //  let getdatasearch_contact = "";
      //  if(resultcontact.data[0]){
      //   getcontant_name = resultcontact.data[0].contact_name;
      //  }else{
      //   getcontant_name  = "";
      //  }

      //  if(getcontant_name != ""){
      //   getdatasearch_contact = this.customer_name_list.filter(customer =>{
      //     if(customer.name == getcontant_name){
           
      //       return customer.id;
      //     }
      //   });
      //   if(getdatasearch_contact != ""){
      //     this.customer_id_forpatmentrequest = getdatasearch_contact[0].id
      //   }else{
      //     this.customer_id_forpatmentrequest = 0;
      //   }
       
      //  }else{
      //   this.customer_id_forpatmentrequest = 0 
      //  }
      } else {
        this.showaddItem.refno = "";
        this.showaddItem.trademark_name = "";
        this.showapp_no = "";
        this.customer_id_forpatmentrequest = 0;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Search File No. !!!",
          "File No. not found !!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      this.dialogDelete = false;
      const resdel = await api.AppointmentDelete(
        this.$route.params.appointment_id
      );
      // let setflag = 0;
      // // 0 = delete
      // const resdel = await api.AppointmentUpdate(
      //   this.$route.params.appointment_id,
      //   {
      //     ocr_flag: setflag,
      //   }
      // );
      console.log(resdel);
      if (resdel.status == 200) {
          var dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Delete",
              table_name: "appointment",
              table_id: this.$route.params.appointment_id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
        // this.loaddatadetails();
        this.closeDelete();

        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "delete data!!!",
          "delete data success!!!",
          "text-h5 green--text text-center",
          "/appointment-trademark-list"
        );
        return;
      } else {
        this.$hideLoader();
        // this.loaddatadetails();
        this.closeDelete();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "delete data!!!",
          "can't delete data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async previewMultiFile(event) {
      this.getpreview_listnotice = [];
      this.preview_listnotice = [];
      this.image_listnotice = [];
      this.filenamenotice = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listnotice.push(e.target.result);
            this.preview_listnotice.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_listnotice.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listnotice);
      // await this.getPdf(this.getpreview_listnotice, "newfile");
      this.preview_listnotice2 = this.preview_listnotice;
      this.image_listnotice.forEach((x) => {
        this.filenamenotice.push(x.name);
      });
      this.checkfilenotice = true;
      console.log(this.image_listnotice);
    },
    async previewnoticefilepdf() {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_listnotice[0] +
          "'></iframe></body></html>"
      );

      return;
      this.previewpopupfile2 = [];
      await this.getPdfforpreview(this.getpreview_listnotice[0]);
      this.previewpopupfile2 = this.previewpopupfile;
      this.checkfileapreview = true;
      this.dialogpreviewfilenotice = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfilenotice = false;
    },
    async getPdfforpreview(datafile) {
      this.previewpopupfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },

    async confirmdeletenoticefile() {
      this.dialogconfirmdeletenoticefile = true;
    },
    async closedialogconfirmdeletenoticefile() {
      this.dialogconfirmdeletenoticefile = false;
    },
    async deletedialogconfirmdeletenoticefile() {
      this.getpreview_listnotice = [];
      this.preview_listnotice = [];
      this.image_listnotice = [];
      this.filenamenotice = [];
      this.dialogconfirmdeletenoticefile = false;
    },
    async previewMultiAttachFile() {
      // this.preview_listattach = [];
      // this.image_listattach = [];
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listattach.push(e.target.result);
            this.preview_listattach.push(e.target.result);
          };
          console.log(input.files[index].type);
          if (
            input.files[index].type === "application/pdf" ||
            input.files[index].type.split("/")[0] === "image"
          ) {
            this.image_listattach.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listattach);
      // await this.getPdf(this.getpreview_listattach, "newfile");
      this.preview_listattach2 = this.preview_listattach;
      this.image_listattach.forEach((x) => {
        if (x.name) {
          this.filenameattach.push(x.name);
        } else {
          this.filenameattach.push(x.split(/[\s/]+/).pop());
        }
      });
      this.checkfileattach = true;
      console.log(this.image_listattach);
    },
    async previewattachfile(index) {
      if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_listattach[index] +
            "'></iframe></body></html>"
        );

        return;
        this.previewpopupattachfile2 = [];
        await this.getPforpreviewattach(this.getpreview_listattach[index]);
        this.previewpopupattachfile2 = this.previewpopupattachfile;
        this.checkattachfileapreview = true;
        this.dialogpreviewattachfile = true;
      } else {
        this.previewattachimage = this.getpreview_listattach[index];
        this.dialogpreviewattachimage = true;
      }
    },
    async getPforpreviewattach(datafile) {
      this.previewpopupattachfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupattachfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupattachfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreviewattach = pdf.numPages;
        });
      });
      console.log(this.previewpopupattachfile);
    },
    async closepreviewattachfile() {
      this.dialogpreviewattachfile = false;
    },
    async closepreviewattachimage() {
      this.dialogpreviewattachimage = false;
    },
    async confirmdeleteattachfile(index) {
      this.indexdeleteattachfile = index;
      this.dialogconfirmdeleteattachfile = true;
    },
    async closedialogconfirmdeleteattachfile() {
      this.indexdeleteattachfile = -1;
      this.dialogconfirmdeleteattachfile = false;
    },
    async deletedialogconfirmdeleteattachfile() {
      this.filenameattach.splice(this.indexdeleteattachfile, 1);
      this.preview_listattach.splice(this.indexdeleteattachfile, 1);
      this.image_listattach.splice(this.indexdeleteattachfile, 1);
      this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
      this.dialogconfirmdeleteattachfile = false;
    },

    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async setupAlertDialogPushpaymentIM(status, title, message, text_color, link,querydata) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
      this.querydata = querydata;
    },
    async calfiledfinaldeadlinesixty_date() {
      if (!this.showaddItem.filed_date) {
        this.showaddItem.nextdeadlinesixty = false;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Filed Date !!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (!this.showaddItem.nextdeadlinesixty) {
        this.showaddItem.filedfinaldeadlinesixty_date = "";
        // this.formatDate(
        //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        //     .toISOString()
        //     .substr(0, 10)
        // );
        return;
      }
      this.showaddItem.filedfinaldeadlinesixty_date = new Date(
        new Date(this.filed_date).setDate(
          new Date(this.filed_date).getDate() + 60
        ) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.showaddItem.filedfinaldeadlinesixty_date = this.formatDate(
        this.showaddItem.filedfinaldeadlinesixty_date
      );
    },
    async calfiledfinaldeadlineninety_date() {
      if (!this.showaddItem.filed_date) {
        this.showaddItem.nextdeadlineninety = false;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data !!!",
          "Put Filed Date !!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (!this.showaddItem.nextdeadlineninety) {
        this.showaddItem.filedfinaldeadlineninety_date = "";
        // this.formatDate(
        //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        //     .toISOString()
        //     .substr(0, 10)
        // );
        return;
      }
      this.showaddItem.filedfinaldeadlineninety_date = new Date(
        new Date(this.filed_date).setDate(
          new Date(this.filed_date).getDate() + 90
        ) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.showaddItem.filedfinaldeadlineninety_date = this.formatDate(
        this.showaddItem.filedfinaldeadlineninety_date
      );
    },

    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },

  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
