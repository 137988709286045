//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    dialog: false,
    dialog1: false,
    dialogDelete: false,
    dialogDelete1: false,
    menu: [
      { text: "เมนู", value: "menu_i_d" },
      { text: "ประเภท", value: "menutype" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    menutype: ["Menu", "Sub-menu"],
    userlist: ["Jeep", "Plam", "Guvanch", "ruzee"],
    getmenu: ["home", "report", "config", "role"],
    gg: ["IT", "Accout", "adminIT", "View"],
    group: [
      { text: "Group Name", value: "group_i_d" },
      { text: "User", value: "user_i_d" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    menu_config: [],
    group_config: [],
    editedIndex: -1,
    editedIndex1: -1,
    editedItem: {
      menu_i_d: "",
      menutype: "",
      user_i_d: "",
    },
    defaultItem: {
      menu_i_d: "",
      menutype: "",
      user_i_d: "",
    },
    editedItem1: {
      menu_i_d: "",
      menutype: "",
      user_i_d: "",
    },
    defaultItem1: {
      menu_i_d: "",
      menutype: "",
      user_i_d: "",
    },
  }),

  computed: {
    formTitle_menu() {
      return this.editedIndex === -1 ? "เพิ่มเมนู" : "แก้ไขเมนู";
    },
    formTitle_group() {
      return this.editedIndex1 === -1 ? "เพิ่มกลุ่ม" : "แก้ไขกลุ่ม";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialog1(val) {
      val || this.close1();
    },
    dialogDelete1(val) {
      val || this.closeDelete1();
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    // this.$showLoader();
    this.$hideLoader();
  },

  methods: {
    initialize() {
      // this.below get from database
      this.menu_config = [
        {
          menu_i_d: "รายงาน ลา",
          menutype: "Sub-menu",
        },
        {
          menu_i_d: "Menusetting",
          menutype: "Menu",
        },
      ];
      this.group_config = [
        {
          group_i_d: "Staff Account",
          user: ["Jeep", "Plam", "Guvanch"],
        },
        {
          group_i_d: "Menusetting",
          user: ["Jeep", "Guvanch"],
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.menu_config.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItem1(item) {
      this.editedIndex1 = this.group_config.indexOf(item);
      this.editedItem1 = Object.assign({}, item);
      this.dialog1 = true;
    },

    deleteItem(item) {
      this.editedIndex = this.menu_config.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItem1(item) {
      this.editedIndex1 = this.group_config.indexOf(item);
      this.editedItem1 = Object.assign({}, item);
      this.dialogDelete1 = true;
    },

    deleteItemConfirm() {
      this.menu_config.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    deleteItemConfirm1() {
      this.group_config.splice(this.editedIndex1, 1);
      this.closeDelete1();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close1() {
      this.dialog1 = false;
      this.$nextTick(() => {
        this.editedItem1 = Object.assign({}, this.defaultItem1);
        this.editedIndex1 = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete1() {
      this.dialogDelete1 = false;
      this.$nextTick(() => {
        this.editedItem1 = Object.assign({}, this.defaultItem1);
        this.editedIndex1 = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.menu_config[this.editedIndex], this.editedItem);
      } else {
        this.menu_config.push(this.editedItem);
      }
      this.close();
    },
    save1() {
      if (this.editedIndex1 > -1) {
        Object.assign(this.group_config[this.editedIndex1], this.editedItem1);
      } else {
        this.group_config.push(this.editedItem1);
      }
      this.close1();
    },
  },
};
