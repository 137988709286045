import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDoctypeList = () => {
    return httpClient.get(server.DOCTYPE);
};

export const getDoctypeByID = (doctype_id) => {
    return httpClient.get(server.DOCTYPE + `/bydoctype_id/${doctype_id}`);
};
export const getDoctypeByDepID = (dep_id) => {
    return httpClient.get(server.DOCTYPE + `/bydoctype-depid/${dep_id}`);
};
export const getCheckDoctype = (doctype) => {
    return httpClient.get(server.DOCTYPE + `/bydoctype/${doctype}`);
};

export const createDoctype = (data) => {
    return httpClient.post(server.DOCTYPE,data);
};

export const UpdateFileDoctype = (id,data) => {
    return httpClient.post(server.DOCTYPE +`/updatefile/${id}`,data);
};
export const UpdateDoctype = (id, data) => {
    return httpClient.put(server.DOCTYPE +`/${id}`,data);
};

export const DeleteDoctype = (id) => {
    return httpClient.delete(server.DOCTYPE +`/${id}`);
};