import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdataByIdInv_SO = (id) => {
  return httpClient.get(server.INVOICE_SO + `/getbyid/${id}`);
};

export const printInvSO = (id) => {
  return httpClient.get(server.INVOICE_SO + `/print_inv_so/${id}`);
};

export const addInv_SO = (data) => {
  return httpClient.post(server.INVOICE_SO + `/`, data);
};

export const deleteInv_SO = (id) => {
  return httpClient.delete(server.INVOICE_SO + `/${id}`);
};
