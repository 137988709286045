//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";
import {
    tolocalestringnumber,
    stringcommatonumber,
    } from "../computing/tolocalestringnumber";
export default {
  name: "NewAdvance",
  data() {
    return {
      //file
      // indeximageDelete: 0,
      // preview_list2: [],
      // getpreview_list: [],
      // image: [],
      // previewimage: null,
      // previewimageedit: null,
      // image_list: [],
      // preview_list: [],
      // dialogpreviewimage: false,
      // checkfileapreview: false,
      // previewpopupfile: [],
      // previewpopupfile2: [],
      // dialogpreviewfile: false,
      // dialogDeletefile: false,
      // dialogDeleteimage: false,
      // filename: [],
      // imagename: [],
      // load_bg: false,
      // checkfileadd: false,
      // checkimageadd: false,
      previewpopupfile2: [],
      filenameattach: [],
      previewpopupattachfile2: [],
      previewpopupattachfile: [],
      checkattachfileapreview: false,
      dialogpreviewattachfile: false,
      dialogpreviewattachimage: false,
      dialogconfirmdeleteattachfile: false,
      numPagespreviewattach: 0,
      previewattachimage: "",
      indexdeleteattachfile: -1,
      getpreview_listattach: [],
      preview_listattach: [],
      image_listattach: [],
                //--
      showbtnadd: true,
      showbtnapprove: false,
      advance_code: [],
      advance_code_list: [],
      advance_code_id: 0,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      customer_list: [],
      customer_id: 0,
      emp_draw: 0,
      emp_draw_jobtitle: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      initial_data2: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: false,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",
      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      selectbankname: "",
      bankname: [
        "ธนาคารกรุงเทพ",
        "ธนาคารกสิกรไทย",
        "ธนาคารกรุงไทย",
        "ธนาคารทหารไทยธนชาต",
        "ธนาคารไทยพาณิชย์",
        "ธนาคารกรุงศรีอยุธยา",
        "ธนาคารเกียรตินาคินภัทร",
        "ธนาคารซีไอเอ็มบีไทย",
        "ธนาคารทิสโก้",
        "ธนาคารยูโอบี",
        "ธนาคารไทยเครดิตเพื่อรายย่อย",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "ธนาคารไอซีบีซี (ไทย)",
        "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
        "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
        "ธนาคารออมสิน",
        "ธนาคารอาคารสงเคราะห์",
        "ธนาคารอิสลามแห่งประเทศไทย",
      ],
      radioType: ["cash", "cheque"],
      radioGroup: "",
      setusername: localStorage.getItem(server.USERNAME),
      role: localStorage.getItem(server.ROLE),
      department: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: unity.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      previewimageshow: null,
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Particular",
          value: "description",
          class: "my-header-style",
        },
        { text: "Amount", value: "amount", class: "my-header2-style " },
        {
          text: "Action",
          value: "actions",
          class: "my-header3-style  text-center",
        },
      ],
      desserts: [],
      jobtitle_list: [],
      editedIndex: -1,
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },
      advance_d: {
        advance_hid: "",
        advance_title: "",
        description: "",
        amount: 0,
      },
      advance_h: {
        advance_id: "",
        customer_id: 0,
        employee_id: 0,
        department_id: 0,
        company_id: localStorage.getItem(server.COMPANYID),
        advance_date: null,
        sum_amount: 0,
        clear_date: null,
        payment_type: null,
        bank_name: null,
        bank_number: null,
        cheque_number: null,
        status: null,
        remark: null,
        create_by: null,
        create_date: null,
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      emp_list: [],
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      advance_log: {
        module: "AD",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedExpectedClearDateFormatted() {
      return unity.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeletefile(val) {
      val || this.closeDeletefile();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();

    this.$forceUpdate();

    this.initialize();
    this.loadEmp();
    this.loadJobtitle();
    this.loadCustomer();

    const res_acc = await api.getAccountDetail(
      localStorage.getItem(server.USER_ID)
    );

    this.setusername =
      res_acc.data[0].prename_e_n +
      " " +
      res_acc.data[0].firstname_e_n +
      " " +
      res_acc.data[0].lastname_e_n;

    // alert(res_acc.data[0].department_i_d);
    const res_department = await api.getDepartmentByID(
      res_acc.data[0].department_i_d
    );

    this.department = res_department.data[0].name;

    console.log(
      "res_department : ",
      JSON.stringify(res_department.data[0].name)
    );

    this.department_view = res_department.data[0].name;
    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));

    this.user_sign = res_acc.data[0].signature;

    this.showsig1 = true;
    this.dialogSignature = true;
    this.showdefaultsignature();
    this.savesig1();
    // this.$refs.firstsignaturePad.lockSignaturePad();

    // let yourUrlString = window.location;
    // alert(yourUrlString);

    // let parser = document.createElement("a");
    // parser.href = yourUrlString;

    // parser.protocol; // => "http:"
    // parser.hostname; // => "example.com"
    // parser.port; // => "3000"
    // parser.pathname; // => "/pathname/"
    // parser.search; // => "?search=test"
    // parser.hash; // => "#hash"
    // parser.host; // => "example.com:3000"

    // alert(parser.pathname);

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    // console.log("Login mounted : " + api.isLoggedIn());
    // console.log("Login mounted : " + userId);
    // await this.loadProducts();
  },
  methods: {
    //set auto complte

      async loadEmp() {
        const result = await api.getAccountslist_by_ComID(
          localStorage.getItem(server.COMPANYID)
        );

        this.emp_list = result.data;
        console.log("this.emp_list")
        console.log(this.emp_list)
      },
      async loadJobtitle() {
        const result = await api.getAllJobtitleByCompany(
          localStorage.getItem(server.COMPANYID)
        );

        this.jobtitle_list = result.data;
      },

      async loadCustomer() {
        // console.log("loadCustomer");
        const result = await api.getAllCustomerListactiveCompany({
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        });
        
        this.customer_list = result.data;

      },

    //end set auto complte

    //---------------function file------------//
    
    previewMultiImage: function(event) {
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            // this.preview_list.push(e.target.result);
            this.getpreview_listattach.push(e.target.result);
            this.preview_listattach.push(e.target.result);
          };
          if (
            input.files[index].type === "application/pdf" ||
            input.files[index].type.split("/")[0] === "image"
          ) {
            this.image_listattach.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      // console.log(this.image_list);
      this.image_listattach.forEach((x) => {
        this.filenameattach.push(x.name);
      });
      console.log("this.filenameattach")
      console.log(this.image_listattach)
      console.log(this.filenameattach)
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    async previewattachfile(index) {
      if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
        await this.popuppreviewfile(index)
        // this.previewpopupattachfile2 = [];
        // await this.getPforpreviewattach(this.getpreview_listattach[index]);
        // this.previewpopupattachfile2 = this.previewpopupattachfile;
        // // console.log(this.previewpopupattachfile2);
        // this.checkattachfileapreview = true;
        // this.dialogpreviewattachfile = true;
      } else {
        this.previewattachimage = this.getpreview_listattach[index];
        this.dialogpreviewattachimage = true;
      }
    },
    async popuppreviewfile(fileindex) {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_listattach[fileindex] +
          "'></iframe></body></html>"
      );
      },
    // async getPforpreviewattach(datafile) {
    //   this.previewpopupattachfile = [];
    //   let getfile = "";
    //   getfile = datafile;
    //   this.previewpopupattachfile.push(pdfvuer.createLoadingTask(`${getfile}`));
    //   this.previewpopupattachfile.forEach((item, index) => {
    //     item.then((pdf) => {
    //       this.numPagespreviewattach = pdf.numPages;
    //     });
    //   });
    //   // console.log(this.previewpopupattachfile);
    // },
    // async closepreviewattachfile() {
    //   this.dialogpreviewattachfile = false;
    // },
    async closepreviewattachimage() {
      this.dialogpreviewattachimage = false;
    },
    async deletedialogconfirmdeleteattachfile() {
      this.filenameattach.splice(this.indexdeleteattachfile, 1);
      this.preview_listattach.splice(this.indexdeleteattachfile, 1);
      this.image_listattach.splice(this.indexdeleteattachfile, 1);
      this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
      this.dialogconfirmdeleteattachfile = false;
    },
    async confirmdeleteattachfile(index) {
      this.indexdeleteattachfile = index;
      this.dialogconfirmdeleteattachfile = true;
    },
    async closedialogconfirmdeleteattachfile() {
      this.indexdeleteattachfile = -1;
      this.dialogconfirmdeleteattachfile = false;
    },
    //--------------------end funcfile--------------------//
          
    closeDialogSendmail() {
      this.dialogSendMail = false;
      this.$router.push("/advance-list");
    },
    onSelectAdvanceCodeChange(acvance_code) {
      // var tmp = null;
      // tmp = this.customer.filter((c) => c.name.includes(search_customer));

      // this.customer_id = tmp[0].id;
      // console.log("acvance_code : " + acvance_code);
      var tmp = acvance_code.split(" ");
      this.editedItem.code = tmp[0];
      this.editedItem.description = tmp[1];
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async saveData(mode) {
      if (this.emp_draw == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกผู้รับเงิน!!!",
          "text-h5 red--text text-center"
        );

        return;
      }

      if (this.paymentType == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกวิธีการชำระเงิน!!!",
          "text-h5 red--text text-center"
        );

        return;
      }else if(this.paymentType == "transfer"){
        if (this.selectbankname == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณาเลือกธนาคาร!!!",
            "text-h5 red--text text-center"
          );

          return;
        }
        if (this.bankNumber == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณากรอกเลขบัญชี!!!",
            "text-h5 red--text text-center"
          );

          return;
        }
      }

      let dataAdd = {
        pettycas_id: "",
        requester: Number(localStorage.getItem(server.USER_ID)),
        employee_id: this.emp_draw,
        company_id: Number(localStorage.getItem(server.COMPANYID)),
        department_id: Number(localStorage.getItem(server.DEPARTMENT_ID)),
        job_id: this.emp_draw_jobtitle,
        customer_id: this.customer_id,
        document_date: unity.parseDate(this.dateCurrent),
        payment_type: this.radioGroup,
        bank_name: this.selectbankname,
        bank_number: this.bankNumber,
        approver_id: Number(localStorage.getItem(server.APPROVE_LEVEL1)),
        cashier_id: 120,
        status: 0,
        remark: this.remark,
        remark_reject: "",
        create_by: Number(localStorage.getItem(server.USER_ID)),
      }
      if(mode == "Submit"){
        dataAdd.status = 1;
      }
      console.log(dataAdd)
      this.$showLoader();
      const req_app = await api.getAccountid(this.emp_draw);
      // console.log(req_app.data[0].approver_level1)
      dataAdd.approver_id = req_app.data[0].approver_level1;
      const res_data_h = await api.createPC(dataAdd);

      if(Number(res_data_h.status) == 201){

        if(this.image_listattach.length > 0){
          for(const [index,item] of this.image_listattach.entries()){
            let formData = new FormData();
            formData.append("module_name",  "PettyCash");
            formData.append("module_id",  res_data_h.data.id);
            formData.append("company_id",  Number(localStorage.getItem(server.COMPANYID)));
            formData.append("create_by",  Number(localStorage.getItem(server.USER_ID)));
            formData.append(`file`, item);

            const res_file = await api.createFA(formData)
          }
        }
        
        if(this.desserts.length > 0){
          for(const [index,item] of this.desserts.entries()){

            let dataD_add = {
              pettycash_h_id: res_data_h.data.id,
              title: item.description,
              amount: Number(item.amount),
              create_by: Number(localStorage.getItem(server.USER_ID))
            }

            // console.log(dataD_add)

            await api.createPCD(dataD_add)
            if(index == (this.desserts.length-1)){
                  this.$hideLoader();
                  this.$store.state.global_push_dialog = true;
                  this.setupAlertDialogPush(
                    true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    "/petty-cash-voucher"
                  );
                }
              }
        }else{
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/petty-cash-voucher"
          );
        }
      }else if(Number(res_data_h.status) == 404){
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้!!!",
            "text-h5 red--text text-center"
        );
        return;
      }else{
        return;
      }
    },



    changeFocusText(t) {
        if (t === 1) {
            this.$refs["txtDescription"].focus();
        }
        if (t === 2) {
          alert("กรุณากรอกรายละเอียด");
          this.$refs["txtDescription"].focus();
        } else if (t === 3) {
            alert("กรุณากรอกราคา");
            this.$refs["txtAmount"].focus();
        }
    },
    initialize() {

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += item.amount;
      });

      this.sumwithdrawamount = tolocalestringnumber(this.sumwithdrawamount);
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSaveDraft() {
      this.dialogSaveDraft = false;
    },

    closeDelete() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.description == "") {
        alert("กรุณากรอกรายละเอียดรายการ");
        this.$refs["txtDescription"].focus();
        return;
      }

      if (this.editedItem.amount == "0" || this.editedItem.amount == 0) {
        alert("กรุณากรอกจำนวนเงิน");
        this.$refs["txtAmount"].focus();
        return;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });
      this.sumwithdrawamount = tolocalestringnumber(this.sumwithdrawamount);

      console.log("save : " + JSON.stringify(this.desserts));
      this.changeFocusText(1);
      this.close();
    },
    
    submit() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#F1F4F7",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 2000);
    },
    editsig1() {
      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    editsig2() {
      this.showsig2 = true;
      this.showbuttonsavesig2 = true;
      this.$refs.secondsignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.lbl_date_request = false;
      this.$refs.firstsignaturePad.clearSignature();
    },
    clearsig2() {
      this.$refs.secondsignaturePad.clearSignature();
    },
    async savesig1() {
      this.lbl_date_request = true;
      this.defaultwordbuttonsig1 = "Edit";
      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
      // console.log("user_sign : " + data);
      // console.log("user_sign1 : " + this.user_sign);
      this.user_sign = data;
      this.account.signature = data;
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      await api.updateAccount(
        localStorage.getItem(server.USER_ID),
        this.account
      );
      loader.hide();
    },
    savesig2() {
      this.defaultwordbuttonsig2 = "Edit";
      const { isEmpty, data } = this.$refs.secondsignaturePad.saveSignature();
      this.showbuttonsavesig2 = false;
      this.$refs.secondsignaturePad.lockSignaturePad();
    },
    changeradio() {
      console.log(this.radioGroup);
      if (this.radioGroup === "bank") {
        this.paymentType = "transfer";
        this.pickbank = true;
      } else {
        this.paymentType = "cash";
        this.pickbank = false;
        this.selectbankname = ""; 
        this.bankNumber = ""; 
      }
    },

    showdefaultsignature() {
      this.$nextTick(() => {
        // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
        // this.$refs.signaturePaddefault.lockSignaturePad();
        this.$refs.firstsignaturePad.fromDataURL(this.user_sign);
        // this.$refs.firstsignaturePad.lockSignaturePad();
        this.$refs.secondsignaturePad.fromDataURL(this.initial_data2);
        // this.$refs.secondsignaturePad.lockSignaturePad();
      });
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
