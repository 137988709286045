//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "../services/constants";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";

export default {
  async mounted() {
    // this.$showLoader();
    await api.checkVersion();
    this.mycompany_id = localStorage.getItem(server.COMPANYID);
    this.loadEmp();
    this.loadCustomer();
    this.loadJobtitle();
    await this.loadDepartmentByCompanyId();
    await this.loadCompany();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
  },
  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.datasearch.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.datasearch.dateto);
    },
  },
  data() {
    return {
      showchargetype: "",
      checkdepartmentname: "",
      lengthAlldatasearch: 0,
      menudateto: false,
      menudatefrom: false,
      mycompany_id: 0,
      datasearch: {
        employeename: "",
        department: 0,
        // datefrom: "",
        // dateto: "",
        datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        // company_id: 13,
        company_id: localStorage.getItem(server.COMPANYID),
      },
      getnamesignature: [],
      prpoheader: [],
      memoref_list: [],
      no_list: [],
      mysignature: "",
      approvesignature: "",
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [
        // {
        //   DESCRIPTION: "Procurement - Tunnel",
        //   DIMENSION2_: "ENG-PJ-EP-21-007",
        //   ITEMID: "EN21007002",
        //   IVZ_BUDGETACCOUNT: "BEEQP-EN21007002",
        //   IVZ_BUDGETMODELID: "PJEP21-007",
        //   NAME:
        //     "Procurement - Tunnel\nค่าอากรขาเข้า \nค่าภาษีมูลค่าเพิ่ม \n\nRef No : 39280\nDate : 16/08/2565\nPP Business Coporation\n**ดำเนินการแล้วเสร็จ**",
        //   PURCHQTY: 1,
        //   PURCHREQID: "PR22-004569",
        //   PURCHUNIT: "ea",
        // },
      ],
      getheightcontentpr: 0,
      pageAll: 1,
      previewattachimage: "",
      dataformatpr: {
        title: "",
        subtitle: "Time Sheet Monthly Report",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
      },
      dataformatprdetail: {
        project: "โครงการ/Project",
        department: "แผนก/Department",
        requist_date: "วันที่ต้องการ/Requist date",
        type: "ประเภท/Type",
        date_period: "วันที่เริ่มต้น-สิ้นสุด/Date period",
        objective: "วัตถุประสงค์/PR Name",
        vender: "ชื่อผู้จำหน่าย/Vender",
      },
      department_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      isShowPassword: false,
      fullPage: true,
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      closeDelete: false,
      deleteItemConfirm: false,
      emp_list: [],
      jobtitle_list: [],
      customer_list: [],
    };
  },
  methods: {
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.emp_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListByCompany(
        localStorage.getItem(server.COMPANYID)
      );
      this.customer_list = result.data;
    },
    async loadCompany() {
    let copany_datat = await api.getCompanyByID(localStorage.getItem(server.COMPANYID));
    this.dataformatpr.title = copany_datat.data[0].name;
    },
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(this.mycompany_id);
      console.log(result);
      this.department_list = result.data;
    },
    findChaege(chargetype) {
      this.charge_list.filter((item, index) => {
        console.log(item);
        if (item.value == chargetype) {
          this.showchargetype = item.name;
          return;
        }
      });
    },
    async OnSearchDepartment() {
      console.log(this.datasearch.department);
    },
    async SearchAlldata() {
      this.datashowAll = [];
      // if (!this.datasearch.chargetype) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "ค้นหาข้อมูล!!!",
      //     "กรุณาเลือก ChargeType!!!",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      if (!this.datasearch.datefrom) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "กรุณาเลือก DateFrom!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.datasearch.dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "กรุณาเลือก DateTo!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.lengthAlldatasearch = 0;
      this.datashowAll = [];
      let resultcheckuserid = [];
      let haveemployee = false;
      if (this.datasearch.employeename == "") {
        haveemployee = false;
        resultcheckuserid = await api.getcheckattendantuserid({
          params: this.datasearch,
        });
        if (resultcheckuserid.data.length == 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "ค้นหาข้อมูล!!!",
            "ไม่มีข้อมูล!!!",
            "text-h5 red--text text-center"
          );
          return;
        }

        this.datasearch.employeename = "";
      } else {
        haveemployee = true;

        resultcheckuserid.data = [{ userId: this.datasearch.employeename }];
      }
      resultcheckuserid.data.forEach(async (x) => {
        this.datasearch.employeename = parseInt(x.userId);
        const result = await api.getActivityattendantreport({
          params: this.datasearch,
        });
        console.log(result.data);
        if (result.data[0].length > 0) {
          this.emp_list.map((emp) => {
            if (x.userId == emp.id) {
              result.data[0][0].showfullname_en = emp.fullname_en;
              result.data[0][0].showfullname_th = emp.fullname_th;
              result.data[0][0].showcustomer_id = emp.id;
            }
          });
        }

        if (haveemployee == false) {
          this.datasearch.employeename = "";
        }

        // this.dataforshowprpo = result.data;
        // console.log(result.data);

        this.lengthAlldatasearch = result.data.length;
        let getdata = result.data;
        let settimehour = 9;
        for (let i = 0; i < this.lengthAlldatasearch; i++) {
          getdata[i].forEach(async (x, index) => {
            if (
              x.id == null &&
              x.checkInDate2 == null &&
              x.timecheckOutDate == null
            ) {
              // console.log("if");
              if (x.checkdateweek == 5) {
                x.detail = `(วันเสาร์)`;
              } else if (x.checkdateweek == 6) {
                x.detail = `(วันอาทิตย์)`;
              } else {
                x.detail = "ไม่ได้สแกนเข้า";
              }
              if (x.leave_date) {
                x.detail += ` (ลางาน)`;
                if (parseFloat(x.leave_hour) > 0) {
                  x.detail += ` (${
                    x.leave_hour.split(".")[0] > 0
                      ? x.leave_hour.split(".")[0] + "ชั่วโมง "
                      : ""
                  }${
                    x.leave_hour.split(".")[1] > 0
                      ? parseFloat("0." + x.leave_hour.split(".")[1]) * 60 +
                        "นาที"
                      : ""
                  })`;
                }
              }
            } else if (
              x.id !== null &&
              x.checkInDate2 !== null &&
              x.timecheckOutDate == null
            ) {
              // console.log("else if");
              x.detail = "ไม่สแกนออก";
            } else {
              // console.log("else");
              //     if (
              //     parseFloat(x.days * 24) +
              //       parseFloat(x.hours) +
              //       parseFloat(x.minutes) +
              //       parseFloat(x.leave_hour) <
              //     settimehour
              //   )
              if (!x.timecheckInDate && x.leave_date && x.leave_hour) {
                x.detail = `ลางาน`;
                if (parseFloat(x.leave_hour) > 0) {
                  x.detail += ` (${
                    x.leave_hour.split(".")[0] > 0
                      ? x.leave_hour.split(".")[0] + "ชั่วโมง "
                      : ""
                  }${
                    x.leave_hour.split(".")[1] > 0
                      ? parseFloat("0." + x.leave_hour.split(".")[1]) * 60 +
                        "นาที"
                      : ""
                  })`;
                }
                return;
              }
              let timeall =
                parseFloat(x.days * 24) +
                parseFloat(x.hours) +
                parseFloat(x.minutes) +
                parseFloat(x.leave_hour);
              // console.log(timeall);

              // let timediff = settimehour - timeall;
              // let hourdiff = timediff.toString().split(".")[0];
              // hourdiff = parseInt(x.hours) + parseInt(hourdiff);
              // let minutediff = "0." + timediff.toString().split(".")[1];
              // minutediff = (parseFloat(x.minutes) + parseFloat(minutediff)) * 60;
              let gettimetonumber = parseFloat(
                x.timecheckInDate.replace(":", ".")
              );
              let datecheckout = parseFloat(
                gettimetonumber + settimehour
              ).toFixed(2);
              // let datecheckcurrent = parseFloat(
              //   x.timecheckOutDate.replace(":", ".")
              // ).toFixed(2);
              // console.log(datecheckout + " +" + datecheckcurrent);
              // let hourdiff = datecheckout - datecheckcurrent;
              // hourdiff = hourdiff.toString().split(".")[0];
              // let minutediff =
              //   parseFloat(datecheckout) - parseFloat(datecheckcurrent);
              // console.log("hourdiff" + hourdiff);
              // console.log("minutediff" + minutediff);
              // let settimecheckin = parseFloat(
              //   x.timecheckInDate.replace(":", ".") + settimehour
              // ).toFixed(2);
              let sethourmore = Math.floor(datecheckout / 24);
              let setminutemore = datecheckout - sethourmore * 24;
              // console.log(setminutemore);

              const [cutday, cutmonth, cutyear] = x.checkInDate.split("/");
              let setnewcheckindate = `${cutyear}-${cutmonth.padStart(
                2,
                "0"
              )}-${cutday.padStart(2, "0")}`;
              let setcreatenewdate = new Date(setnewcheckindate);

              setcreatenewdate.setDate(
                setcreatenewdate.getDate() + sethourmore
              );

              let getsetcheckInDate = new Date(
                setcreatenewdate - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10);
              const [toyear, tomonth, today] = getsetcheckInDate.split("-");
              let newdatecheckin = `${today.padStart(
                2,
                "0"
              )}/${tomonth.padStart(2, "0")}/${toyear}`;

              let setdatecheckin = `${this.formatDatetomonthdayyear(
                newdatecheckin
              )} ${setminutemore
                .toFixed(2)
                .toString()
                .replace(".", ":")
                .toString()}:00`;

              let setdatecheckout = `${this.formatDatetomonthdayyear(
                x.checkOutDate
              )} ${x.timecheckOutDate}:00`;

              var date1 = new Date(setdatecheckin);
              var date2 = new Date(setdatecheckout);
              console.log(date1);
              console.log(date2);
              var diff = date2.getTime() - date1.getTime();

              var msec = diff;
              var hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              var mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              var ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              var date11 = new Date(setdatecheckin);
              var date22 = new Date(setdatecheckout);
              var difff = date11.getTime() - date22.getTime();

              var msecc = difff;
              var hhh = Math.floor(msecc / 1000 / 60 / 60);
              msecc -= hhh * 1000 * 60 * 60;
              var mmm = Math.floor(msecc / 1000 / 60);
              msecc -= mmm * 1000 * 60;
              var sss = Math.floor(msecc / 1000);
              msecc -= ss * 1000;

              // console.log(parseInt(hh));
              // console.log(settimehour);
              // if (parseInt(hh) < 0) {
               if(x.leave_hour){
                hhh = hhh - parseFloat(x.leave_hour)
               }
              if (parseInt(hhh) > 0) {
                x.detail = `ขาดงาน`;
                // if (parseInt(hh) < 0) {
                if (parseInt(hhh) > 0) {
                  // x.detail += ` ${Math.abs(hh) - 1} ชั่วโมง`;
                  x.detail += ` ${Math.abs(hhh)} ชั่วโมง`;
                }
                if (mmm > 0) {
                  x.detail += ` ${mmm} นาที`;
                }
                if (parseFloat(x.leave_hour) > 0) {
                  x.detail += ` (ลางาน) (${
                    x.leave_hour.split(".")[0] > 0
                      ? x.leave_hour.split(".")[0] + "ชั่วโมง "
                      : ""
                  }${
                    x.leave_hour.split(".")[1] > 0
                      ? parseFloat("0." + x.leave_hour.split(".")[1]) * 60 +
                        "นาที"
                      : ""
                  })`;
                }
              } else if(parseInt(hhh) == 0 && mmm > 0){
                x.detail = `ขาดงาน`;
                x.detail += ` ${mmm} นาที`;
                if (parseFloat(x.leave_hour) > 0) {
                  x.detail += ` (ลางาน) (${
                    x.leave_hour.split(".")[0] > 0
                      ? x.leave_hour.split(".")[0] + "ชั่วโมง "
                      : ""
                  }${
                    x.leave_hour.split(".")[1] > 0
                      ? parseFloat("0." + x.leave_hour.split(".")[1]) * 60 +
                        "นาที"
                      : ""
                  })`;
                }
              }else{
                x.detail = "-";
                if(x.leave_date) {
                x.detail = "-";
                if (parseFloat(x.leave_hour) > 0) {
                  x.detail = `ลางาน (${
                    x.leave_hour.split(".")[0] > 0
                      ? x.leave_hour.split(".")[0] + "ชั่วโมง "
                      : ""
                  }${
                    x.leave_hour.split(".")[1] > 0
                      ? parseFloat("0." + x.leave_hour.split(".")[1]) * 60 +
                        "นาที"
                      : ""
                  })`;
                }
              }
              }
            }
          });
        }

        // console.log(this.dataforshowprpo);
        getdata.forEach(async (x, index) => {
          this.dataforshowprpo[0] = x;

          await this.checkcontent(this.dataforshowprpo[0]);
        });
      });
    },
    async checkcontent(data) {
      let totalclient = 0;
      // console.log("send data ====");
      // console.log(this.dataforshowprpo[0]);
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 34;
      let datainlineprpo = 45;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.dataforshowprpo[0].length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.dataforshowprpo[0][i].detail.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.dataforshowprpo[0][i].no = i + 1;
        totalclient += parseFloat(
          this.dataforshowprpo[0][i].duration
            ? this.dataforshowprpo[0][i].duration
            : 0
        );
        if (i == 0) {
          this.checkdepartmentname = this.dataforshowprpo[0][i].job_name;
          this.dataforshowprpo[0][i].changedepartment = true;
        } else {
          if (
            this.checkdepartmentname !== this.dataforshowprpo[0][i].job_name
          ) {
            this.dataforshowprpo[0][i].changedepartment = true;
            this.checkdepartmentname = this.dataforshowprpo[0][i].job_name;
            hllinedetails++;
          }
        }
        stringchecklength = this.dataforshowprpo[0][i].detail;

        // let myArrayname = this.dataforshowprpo[i].NAME.split("\n");
        // for (let a = 0; a < myArrayname.length; a++) {
        //   let lengthnewline = myArrayname[a].length;
        //   for (let b = 0; b < myArrayname[a].length; b++) {
        //     if (
        //       myArrayname[a][b] == maiek ||
        //       myArrayname[a][b] == maito ||
        //       myArrayname[a][b] == maitree ||
        //       myArrayname[a][b] == maijattawa ||
        //       myArrayname[a][b] == vowelei ||
        //       myArrayname[a][b] == vowelee ||
        //       myArrayname[a][b] == voweleue ||
        //       myArrayname[a][b] == voweleu ||
        //       myArrayname[a][b] == vowelu ||
        //       myArrayname[a][b] == voweluu ||
        //       myArrayname[a][b] == maihun ||
        //       myArrayname[a][b] == maiyamok
        //     ) {
        //       lengthnewline--;
        //     }

        //   }

        for (let j = 0; j < this.dataforshowprpo[0][i].detail.length; j++) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

        // newhllinedetails = Math.ceil(
        //   this.dataforshowprpo[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.dataforshowprpo[0][i]);
        // console.log(getdata.length);
        // console.log(hllinedetails + newhllinedetails + getdata.length);
        // console.log(hllinedetails);
        // console.log(newhllinedetails);
        // console.log(getdata.length);
        // console.log("=================================");
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(i + " ============== " + this.dataforshowprpo[0].length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.dataforshowprpo[0][i]);
          hllinedetails += newhllinedetails;
          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.dataforshowprpo[0][i]);

          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.pageAll += this.lengthAlldatasearch - 1;
      this.opensummarize = true;
      // console.log(this.pageAll);
      // console.log("showwwwwwwwwwwwwwwwwwwww");
      // console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        // console.log(item);
        // console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      // console.log(this.datashowAll);
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();
      let element = document.getElementById("mydivhtmltobase");

      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          // var filtered = pdf.internal.pages.filter(function(el) {
          //   return el != null;
          // });

          // pdf.internal.pages = filtered;
          // for (let i = 0; i < totalPages; i++) {
          //   if (i == totalPages - 1) {
          //     pdf.internal.pages.splice(i, 1);
          //   }
          // }
          // pdf.addPage();

          // console.log(pdf);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      // console.log(blob);
      // let formData = new FormData();
      // formData.append(`file`, blob, "test.pdf");
      // const resultadd = api.creatememoMaster(formData);
      // console.log(resultadd);

      // };
    },
    formatDatetomonthdayyear(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
