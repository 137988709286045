//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import html2pdf from "html2pdf.js";
var convert = require("decimal-number-to-words");
import unity from "@/unity/unity";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "invoice",
  data(vm) {
    return {
      showprint: "",
      textamount_total_en: "",
      dataformatpr: {
        headtitle: "Credit Note",
        title: "Dej-Udom & Associates LTD.",
        subtitle: "ATTORNEY AT LAW",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
        titleth: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
        address1: "CHARN ISSARA TOWER 9 FLOOR",
        address2: "942/69 RAMA IV ROAD,",
        address3: "KWAENG SURIYAWONG, KHET BANGRAK,",
        address4: "BANGKOK 10500, THAILAND",
        tel: "Tel : 0-2233-0055, 0-2267-5152",
        fax: "Fax : 0.2236-6681, 0-2233-0227",
        email: "E-MAIL:account@dejudom.com",
        taxid: 'TAX ID. No. 0992000028227 / "HEAD OFFICE"',
      },

      status: ["Draft", "P.Invoice", "Invoice", "Cancel"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        {
          text: "Credit Note No.",
          value: "credit_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Invoice No.",
          value: "invoice_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Customer",
          value: "customer_name_second",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "sub_total_show",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
      currency_show: "",
      inv_id: 0,
      dialogPrintOutData: "1",
      dialogPrintOut: false,
      print_header_list: [
        {
          id: "1",
          com_title: "Credit Note",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "Credit Note",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227`,
        },
      ],
      check_cus_name: false,
      print_header: {},
      print_table_detail: [],
      pageAll: 1,
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      dialogPrintOutData: "1",
      dialogPrintOut: false,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      opensummarizetimesheet: false,
      pageAlltimesheet: 1,
      setdatatoshowtimesheet: [],
      print_table_detailtimesheet: [],
      datashowAlltimesheet: [],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddataInvoice();
  },
  methods: {
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    editItem() {},

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      //alert(this.datefrom)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom != null && this.dateto != null) {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        }
        if (this.datefrom == null && this.dateto == null) {
          return item;
        }
      });
    },
    onClicktoeditCN(id) {
      // let name = "inv_h";
      this.$router.push(`/edit-credit-note/${id}`);
    },
    onClicktoPrint(id) {
      // let name = "inv_h";
      // this.$router.push(`/edit-credit-note/${id}`);
    },
    async loaddataInvoice() {
      const result = await api.getcreditnote_ListBycompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.dataAll = result.data;
      console.log(this.dataAll);
      //   console.log("loadLeave", JSON.stringify(this.dataAll));
      this.mDataArray = result.data;
      this.mDataArray.forEach((item) => {
        if (item.credit_no) {
          item.credit_no = item.credit_no;
        } else {
          item.credit_no = "####";
        }
        if (item.invoice_no) {
          item.invoice_no = item.invoice_no;
        } else {
          item.invoice_no = "####";
        }

        item.sub_total_show = tolocalestringnumber(item.sub_total)
      });

      // this.$showLoader();
      this.$hideLoader();
    },
    getPrintDialog(item_id) {
      this.inv_id = item_id;
      this.dialogPrintOut = true;
    },
    async getPrintOutData(item_id) {
      this.inv_id = item_id;
      // this.showprint = "";
      this.datashowAll = [];
      this.print_table_detail = [];
      this.dialogPrintOut = false;
      // alert(`Print Out Invoice ID: ${this.inv_id}, ${this.dialogPrintOutData}`);
      // if (this.dialogPrintOutData == "1") {
      //   this.print_header = this.print_header_list[0];
      // } else if (this.dialogPrintOutData == "2") {
      //   this.print_header = this.print_header_list[1];
      // }
      // console.log("print_header:", JSON.stringify(this.print_header));
      this.$showLoader();
      await this.loadInvSoData();
      await this.loadMainInvSoData();
      await this.loadRef_TypeCNById();
      // await this.loadInvADData();
      // await this.loadInvMISData();
      // await this.loadInvDocRefData();
      await this.checkcontent();
      await this.exportToPDF();
      // this.showprint = "display:none;";
      this.$hideLoader();
    },

    async loadRef_TypeCNById() {
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // console.log(res_docref_so.data[0]);
      const res_hresult = await api.getdataByIdInvoice_H(this.inv_id);
      console.log("loadRef_TypeCNById");
      console.log(res_hresult.data);

      if (res_hresult.data[0].company_inv == "D") {
        this.print_header = this.print_header_list[0];
      } else if (res_hresult.data[0].company_inv == "L") {
        this.print_header = this.print_header_list[1];
      }

      this.print_header.inv_id = res_hresult.data[0].inv_id
        ? res_hresult.data[0].inv_id
        : res_hresult.data[0].pinv_id;

      this.print_header.invoice_date = res_hresult.data[0].date_show;

      this.print_header.sub_total = parseFloat(res_hresult.data[0].sub_total);
      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = res_hresult.data[0].tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
      this.print_header.tax_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].tax_rate) / 100
      );

      this.print_header.amount_total = parseFloat(res_hresult.data[0].amount_total);

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }


      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total);
        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });

      }else{
        // console.log("test : flase")
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let  de_check = 0;

          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            let numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              let units = thaiUnits[numArrays.length - index - 1];
              let digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          let numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            let unit = thaiUnits[numArray.length - index - 1];
            let digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";

          console.log("decimalText: " + decimalText)
          console.log("integerText: " + integerText)

          let result = "";
          result = integerText + decimalText;

          console.log("result convert:" +  result);
          this.textamount_total_en = result;
      }

    },
    async loadInvSoData() {
      var tmp_inv_data;
      const res_inv_so = await api.printInvSO(this.inv_id);
      console.log("loadInvSoData");
      console.log(res_inv_so);

      tmp_inv_data = res_inv_so.data;

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };
        if (index == 0) {
          data.first_item = 1;
        }

        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });

      // console.log("loadInvSoData");
      // console.log(this.print_table_detail);
    },
    async loadMainInvSoData() {
      // var tmp_inv_data;
      const res_docref_so = await api.getRef_TypeCNById(this.inv_id);

      const res_inv_so = await api.getdataByIdInvoice_H(
        res_docref_so.data[0].ref_id
      );
      console.log(res_inv_so);
      if (res_inv_so.data[0].company_inv == "D") {
        this.print_header = this.print_header_list[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.print_header = this.print_header_list[1];
      }
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_header.addressall = res_inv_so.data[0].addressall;
      this.print_header.customer_address = res_inv_so.data[0].customer_address;

      this.print_header.province_invoice = res_inv_so.data[0].province_invoice;
      this.print_header.country_invoice = res_inv_so.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_inv_so.data[0].zipcode_invoice;

      this.print_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_inv_so.data[0].customer_taxid;

      // tmp_inv_data = res_inv_so.data;

      // tmp_inv_data.forEach((inv_so) => {
      //   var data = {
      //     report_type: "SO",
      //     report_desc: "",
      //     report_amount: 0,
      //   };

      //   data.report_desc = inv_so.report_desc;
      //   data.report_amount = inv_so.report_amount;
      //   this.print_table_detail.push(data);
      // });
    },
    async loadInvADData() {
      var tmp_inv_data;
      const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_docref_ad = await api.getRef_TypeADById(
        res_docref_so.data[0].ref_id
      );
      const res_inv_mis = await api.printInvAD(res_docref_ad.data[0].ref_id);
      console.log("loadInvMISData");
      console.log(res_inv_mis);
      tmp_inv_data = res_inv_mis.data;

      tmp_inv_data.forEach((inv_mis) => {
        var data = {
          report_type: "MIS",
          report_desc: "",
          report_amount: 0,
        };

        data.report_desc = inv_mis.report_desc;
        data.report_amount = inv_mis.report_amount;
        this.print_table_detail.push(data);
      });
      // console.log("loadInvMISData");
      // console.log(this.print_table_detail);
    },
    async loadInvMISData() {
      var tmp_inv_data;
      const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_inv_mis = await api.printInvMIS(res_docref_so.data[0].ref_id);
      console.log("loadInvMISData");
      console.log(res_inv_mis);
      tmp_inv_data = res_inv_mis.data;

      tmp_inv_data.forEach((inv_mis) => {
        var data = {
          report_type: "MIS",
          report_desc: "",
          report_amount: 0,
        };

        data.report_desc = inv_mis.report_desc;
        data.report_amount = inv_mis.report_amount;
        this.print_table_detail.push(data);
      });
      // console.log("loadInvMISData");
      // console.log(this.print_table_detail);
    },
    async loadInvDocRefData() {
      const res_inv_doc_ref = await api.printInvDocRef(this.inv_id);
      // console.log("print_inv_doc_ref:", JSON.stringify(res_inv_doc_ref.data));

      for await (const inv_doc_ref of res_inv_doc_ref.data) {
        if (inv_doc_ref.ref_type == "AD") {
          var tmp_inv_data;
          const res_inv_ad = await this.loadInvAdvanceDetailData(
            inv_doc_ref.ref_id
          );
          console.log("ADDDDD");
          console.log(res_inv_ad);
          tmp_inv_data = res_inv_ad.data;
          tmp_inv_data.forEach((inv_mis) => {
            var data = {
              report_type: "AD",
              report_desc: "",
              report_amount: 0,
            };

            data.report_desc = inv_mis.report_desc;
            data.report_amount = inv_mis.report_amount;
            this.print_table_detail.push(data);
          });
        }

        if (inv_doc_ref.ref_type == "TS") {
          await this.loadInvTimeSheetDetailData(inv_doc_ref.ref_id);
        }
      }
      // console.log("loadInvDocRefData");
      // console.log(this.print_table_detail);

      // this.dataforshowprpo = this.print_table_detail;

      // await this.checkcontent();
    },

    async checkcontent() {
      console.log("send data ====");
      console.log(this.print_table_detail);
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 23;
      let datainlineprpo = 80;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detail[i].report_desc
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detail[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        console.log("data lll");
        console.log(newhllinedetails);
        // if (this.print_table_detail[i].first_item == 1) {
        //   newhllinedetails++;
        // }

        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
        console.log(
          `${hllinedetails} +${newhllinedetails} +${linedetailprpo} +${i}`
        );
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(i + " ============== " + this.print_table_detail.length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
      console.log(this.pageAll);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      console.log(this.datashowAll);
    },

    async checkcontenttimesheet() {
      console.log("send data ====");
      console.log(this.print_table_detailtimesheet);
      this.datashowAlltimesheet = [];
      // alert(data.length);
      this.setdatatoshowtimesheet = [];
      let linedetailprpo = 46;
      let datainlineprpo = 60;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAlltimesheet = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detailtimesheet.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detailtimesheet[i]
          .report_desc.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detailtimesheet[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detailtimesheet[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        console.log("data lll");
        console.log(newhllinedetails);

        // newhllinedetails = Math.ceil(
        //   this.print_table_detailtimesheet[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detailtimesheet[i]);
        console.log(
          `${hllinedetails} +${newhllinedetails} +${linedetailprpo} +${i}`
        );
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(
            i + " ============== " + this.print_table_detailtimesheet.length
          );
          this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
          this.pageAlltimesheet++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detailtimesheet[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detailtimesheet[i]);

          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarizetimesheet = true;
      console.log(this.pageAlltimesheet);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshowtimesheet);

      this.setdatatoshowtimesheet.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshowtimesheet[0].length);
        if (iitem == this.setdatatoshowtimesheet.length - 1) {
          item[this.setdatatoshowtimesheet[iitem].length - 1].lastdata =
            "lastindex";
        }

        this.datashowAlltimesheet.push(item);
      });
      console.log(this.datashowAlltimesheet);
    },
  },
};
