import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getnotice_code = () => {
    return httpClient.get(server.NOTICE + `/getnotice_code`);
};

export const getnotice_codeByobjecttype_id = (id) => {
    return httpClient.get(server.NOTICE + `/getnotice_code_byobjecttype_id/${id}`);
};

export const getnoticeByID = (id) => {
    return httpClient.get(server.NOTICE + `/getnoticebyid/${id}`);
};