import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getByIdtimesheetH = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/get/${id}`);
};

export const getByempIdtimesheetH = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getemp/${id}`);
};

export const getByempIdtimesheetHByCompany = (id,company_id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getempByCompany/${id}/${company_id}`);
};

export const getByemptimesheetH = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/gettimeapor/${id}`);
};

export const getByemptimesheetHV2 = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/gettimeaporv2/${id}`);
};

export const getByemptimesheetHS = (data) => {
  return httpClient.post(server.TIMESHEET_H_URL + `/gettimeaporS`,data);
};

export const getupdatetimesheetH = (id, data) => {
  return httpClient.put(server.TIMESHEET_H_URL + `/${id}`, data);
};

export const getdeletetimesheetH = (id) => {
  return httpClient.delete(server.TIMESHEET_H_URL + `/${id}`);
};

export const timesheetsendMail = (data) => {
  return httpClient.post(server.TIMESHEET_H_URL + `/sendMail`, data);
};

//for approver
export const approvergettimesheetH = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getapprover/${id}`);
};

export const approvergettimesheetHbyCompany = (id,company_id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getapproverByCompany/${id}/${company_id}`);
};

export const approvedetailtimesheetH = (id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getdetailapprover/${id}`);
};

export const CreatetimesheetH = (data) => {
  return httpClient.post(server.TIMESHEET_H_URL + `/`, data);
};



export const getByIdtimesheetHBycompany = (company_id) => {
  return httpClient.get(server.TIMESHEET_H_URL + `/getbycompany/${company_id}`);
};