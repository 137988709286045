var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pl-6 pr-6 pt-6 pb-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.Mode)+" Keyword")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Work Type","disabled":"","background-color":"#F5F5F5"},model:{value:(_vm.work_type_show),callback:function ($$v) {_vm.work_type_show=$$v},expression:"work_type_show"}})],1),(_vm.disabledNotice)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Notice Type","disabled":"","background-color":"#F5F5F5"},model:{value:(_vm.notice_show),callback:function ($$v) {_vm.notice_show=$$v},expression:"notice_show"}})],1):_vm._e()],1),[[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories","footer-props":{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-arrow-collapse-left',
                          lastIcon: 'mdi-arrow-collapse-right',
                          prevIcon: 'mdi-minus',
                          nextIcon: 'mdi-plus',
                        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},(_vm.disableShow)?{key:"item.kw",fn:function(ref){
                        var item = ref.item;
return [_c('v-textarea',{staticClass:"pt-3",attrs:{"multi-line":"","dense":"","rows":"1","auto-grow":""},on:{"blur":function($event){return _vm.editItem(item)}},model:{value:(item.kw),callback:function ($$v) {_vm.$set(item, "kw", $$v)},expression:"item.kw"}})]}}:null,(_vm.disableShow)?{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn',{style:({
                              backgroundColor: '#9E3B1B',
                              color: 'white',
                              }),on:{"click":function($event){return _vm.deleteItem(item.ocrkw_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}:null],null,true)})],(_vm.disableShow)?_c('div',{staticClass:"pl-2 pr-2 pt-2 elevation-1 heightsize55formadvance",style:({
                        display: !_vm.sostatus ? 'show' : 'none',
                    })},[[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","xl":"1","lg":"1","md":"1","sm":"1"}}),_c('v-col',{attrs:{"cols":"12","xl":"9","lg":"9","md":"9","sm":"9"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Keyword"},model:{value:(_vm.keyword_add),callback:function ($$v) {_vm.keyword_add=$$v},expression:"keyword_add"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"2"}},[_c('v-btn',{staticClass:"ml-2",style:({
                                backgroundColor: '#254E58',
                                color: 'white',
                              }),on:{"click":function($event){return _vm.additem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" add ")])],1)],1)],1)]],2):_vm._e()],_c('br')],2),_c('v-card-actions',[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","width":"100"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Cance ")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }