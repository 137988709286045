//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import SuccessDialog from "@/components/cards/SuccessDialog";
  import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
  import api from "@/services/api";
  import { server } from "@/services/constants";
  import unity from "@/unity/unity";
  import tranningaddVue from './tranningadd.vue';

  export default {
      name: "SO-list",
      data(vm) {
          return {
              search: "",
              authorize_view: false,
              authorize_add: false,
              authorize_edit: false,
              authorize_del: false,
              toggleEnable: false,
              authorize: [],
              dialogAdd: false,
              text_color: "text-h5 green--text text-center",
              title: "green",
              message: "green",
              link: "",

              type_list: ["Header","Footer"],
              dataAdd: {
                  th_id: this.$route.params.id,
                  type: "Header",
                  sequence: 0,
                  descriptions: ""
              },
              defaultdata: {
                  th_id: this.$route.params.id,
                  type: "Header",
                  sequence: 0,
                  descriptions: ""
              },
              data_tranning_d: [],
              headers: [
                      {
                          text: "Type",
                          value: "type",
                          class: "bg-colorth tabletextwhite",
                          align: "start",
                          divider: true,
                          width: "20%",
                      },
                      {
                          text: "Sequence",
                          value: "sequence",
                          class: "bg-colorth tabletextwhite",
                          align: "start",
                          divider: true, 
                          width: "15%",
                      },
                      {
                          text: "Descriptions",
                          value: "descriptions",
                          class: "bg-colorth tabletextwhite",
                          align: "start",
                          divider: true,
                          width: "60%",
                      },
                      {
                          text: "",
                          value: "actions",
                          align: "center",
                          class: "bg-colorth tabletextwhite",
                          divider: true,
                      },
              ],
              dialogDelete: false,
              indexDelete: -1,

          };
      },
      async beforeCreate() {
          console.log("beforeCreate");
          this.$store.state.navMenu = true;
      },
      components: {
          SuccessDialog,
          SuccessDialogPush,
      },
      beforeUpdate() {},
      computed: {
          itemsForSelected() {
              if (this.search.length) {
              return this.dataSource.filter((item) => this.search.includes(item));
              }
              return this.dataSource;
          },
      },
      async mounted() {
          await api.checkVersion();
          this.userId = localStorage.getItem(server.USER_ID);
          let yourUrlString = window.location;
          // alert(yourUrlString);
      
          let parser = document.createElement("a");
          parser.href = yourUrlString;
      
          this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
      
          //   // alert("authorize_id:" + this.authorize_id);
          //   if (this.authorize_id == null || this.authorize_id == 0) {
          //     // this.$router.push("/login");
          //     this.$store.state.global_dialog = true;
          //     this.setupAlertDialog(
          //       true,
          //       "Authorize Failed!!!",
          //       "Please Logout And Login Again!!!",
          //       "text-h5 red--text text-center"
          //     );
          //     this.$router.back();
          //   }
      
          //   const router_path = parser.pathname.replace("/", "");
      
          //   const res_auth = await api.getAuthorize(this.userId, router_path);
      
          //   this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
          //   this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
          //   this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
          //   this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
      
          //   if (!this.authorize_view) {
          //     this.$router.back();
          //   }
      
          // ----------------- Check Authorize ---------------------------
      
          //   let comp_id = localStorage.getItem(server.COMPANYID);
      
          //   this.userId = localStorage.getItem(server.USER_ID);
          //   if (this.userId && api.isLoggedIn()) {
          //     // this.$router.push("/");
          //   } else {
          //     this.$store.state.isLogged = false;
          //     this.$router.push("/login");
          //   }
      
          //load data
          await this.loaddata();
          this.$hideLoader();
      },
  methods: {
      async loaddata() {
          const res_tranning_d = await api.getTRANNING_DById(this.$route.params.id)
          this.data_tranning_d = res_tranning_d.data;
          console.log("this.data_tranning_d")
          console.log(this.data_tranning_d)
      },

      async saveItem(){
          if (this.dataAdd.sequence == 0) {
              this
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "Invalid Information",
                  "Please Enter Sequence Data",
                  "text-h5 red--text text-center"
              );
              this.$refs["sequence"].focus();
              return;
          }

          this.$showLoader();

          const res_add = await api.addTRANNING_D(this.dataAdd);

          if (res_add.status == 200 || res_add.status == 201) {
              this.dataAdd = this.defaultdata;
              this.$hideLoader();
              await this.loaddata();

              this.$store.state.global_push_dialog = true;
              this.setupAlertDialogPush(
                  true,
                  "Information Saved",
                  "Information successfully saved",
                  "text-h5 green--text text-center",
                  `/training-edit/${this.$route.params.id}`
              );
          } else {
              this.$hideLoader()
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "Information Saved",
                  "ไม่สามารถบันทึกได้!!!",
                  "text-h5 red--text text-center"
              );
              return
          }
      },

      async UpdateItem(){
          console.log(this.data_tranning_d)
          this.$showLoader();

          for (const [index, item] of this.data_tranning_d.entries()){
              var dataUpdate = {
                  th_id: this.$route.params.id,
                  type: item.type,
                  sequence: item.sequence,
                  descriptions: item.descriptions
              }

              await api.updateTRANNING_D(item.id, dataUpdate);
              if(index == (this.data_tranning_d.length -1)){
                  this.$hideLoader();
                  await this.loaddata();

                  this.$store.state.global_push_dialog = true;
                  this.setupAlertDialogPush(
                      true,
                      "Information Saved",
                      "Information successfully saved",
                      "text-h5 green--text text-center",
                      `/training-edit/${this.$route.params.id}`
                  );
              }
          }

      },

      async opendelete(id){
          this.indexDelete = -1;
          this.indexDelete = id;
          this.dialogDelete = true
      },
      async deleteItemConfirm(){
          this.$showLoader();
          const res_del = await api.deleteTRANNING_D(this.indexDelete);
          if(res_del.status == 200){
              this.dialogDelete = false;
              await this.loaddata();
              this.$hideLoader();
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "ลบข้อมูล!!!",
                  "ลบข้อมูลสำเร็จ!!!",
                  "text-h5 green--text text-center",
                  );
          }else{
              this.dialogDelete = false;
              this.$hideLoader();
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "ลบข้อมูล!!!",
                  "ไม่สามารถลบได้!!!",
                  "text-h5 red--text text-center"
                  );
          }
      },
      async closeDelete() {
          this.dialogDelete = false;
      },
      setupAlertDialog(status, title, message, text_color) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
      },
      setupAlertDialogPush(status, title, message, text_color, link) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
          this.link = link;
      },  
  },
};
