import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDocTransList = () => {
    return httpClient.get(server.DOCTRANS);
};
export const getDocTransByDoctye = (doctype_id) => {
    return httpClient.get(server.DOCTRANS + `/bydoctype_id/${doctype_id}`);
};
export const getDocTransByDocTitle_H = (doctrans_hid) => {
    return httpClient.get(server.DOCTRANS + `/bydoctrans-hid/${doctrans_hid}`);
};
export const createDocTransListT = (data) => {
    return httpClient.post(server.DOCTRANS +`/doctrans-list`,data);
};
export const UpdateDoctrans = (id, data) => {
    return httpClient.put(server.DOCTRANS +`/${id}`,data);
};

export const createDocTransList = (data) => {
    return httpClient.post(server.DOCTRANS,data);
};