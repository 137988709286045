
//Importing Bar class from the vue-chartjs wrapper
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
//Exporting this so it can be used in other components
export default {
  extends: Bar,
  mixins:[reactiveProp],
  data() {
    return {
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
//         onClick: (e, legendItem, legend )=> {
//           var bar = this.getElementAtEvent(e)[0];
//           console.log(bar)
//           const index = legendItem;
//     // const chart = this.$refs;
//     // const activeElement = chart.getElementAtEvent(event)[0];
//     console.log(legendItem[0]['_model'])
//     console.log("aa")
// },
onClick: function(e,items) {
          
         var bar = this.getElementAtEvent(e)[0];
         var index = bar._index;
         var datasetIndex = bar._datasetIndex;
        //  let datasetLabel = e.chart.data.datasets[datasetIndex].label;
         console.log(bar)
         console.log(items[0]._chart.data.labels[items[0]._index])
         console.log(bar._chart.config.data.datasets[datasetIndex].label)
         console.log(bar._chart.config.data.datasets[datasetIndex].data[index])
       
         

         // check which bar is clicked
         if (index == 0 && datasetIndex == 0) console.log('First BAR Clicked!');
         else if (index == 0 && datasetIndex == 1) console.log('Second BAR Clicked!');
         else if (index == 1 && datasetIndex == 0) console.log('Third BAR Clicked!');
         else if (index == 1 && datasetIndex == 1) console.log('Fourth BAR Clicked!');
         else if (index == 2 && datasetIndex == 0) console.log('Fifth BAR Clicked!');
         else if (index == 2 && datasetIndex == 1) console.log('Sixth BAR Clicked!');
      }
      }
    };
  },
  mounted() {
    //renderChart function renders the chart with the datacollection and options object.
    setTimeout(() => {
      var myLine = this.renderChart(this.chartData, this.options);
    }, 100);
  }
};
