//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
    tolocalestringnumber,
    stringcommatonumber,
} from "./computing/tolocalestringnumber";

export default {
    name: "NewAdvance",
    data() {
        return {
            doc_title: "",
            doctype: "",
            type_list: ["Dropdown","Text","Date","Number"],
            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            documnet_type: "",
            doctemplate_id_delete: 0,
            dialogDelete: false,
            dialogDeleteAll: false,
            disabledDelete: false,
            showdropdown: false,
            desserts:[],
            authori_depart: [],
            depart_list:[],
            dropdownlist:[
                {
                    text: ""
                }
            ],
            dropdownlistTable:[
                {
                    text: ""
                }
            ],
            headers: [
                {
                    text: "Title",
                    value: "title",
                    class: "bg-colorth",
                    align: "start",
                    divider: true,
                    sortable: false,
                    width: "25%",
                },
                {
                    text: "Type",
                    align: "start",
                    value: "type",
                    class: "bg-colorth",
                    align: "start",
                    divider: true,
                    sortable: false,
                    width: "50%",
                },
                {
                    value: "actions",
                    align:"center",
                    class: "bg-colorth texttablehcenter",
                    width: "25%",
                    divider: true,
                    sortable: false,
                },
            ]
        };
    },
    components: {
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        const userId = localStorage.getItem(server.USER_ID);
        if (userId && api.isLoggedIn()) {
            // this.$router.push("/");
        } else {
            this.$store.state.isLogged = false;
            this.$router.push("/login");
        }
        await this.loadDepartment();
        await this.loadDoctrans();
        await this.loadDoctype();
        await this.loadDoctypeTemplate();
        this.$hideLoader();
    },
    methods: {    
        async loadDepartment(){
            const res_depart = await api.getAllDepartment_By_CompanyID( localStorage.getItem(server.COMPANYID));
            console.log(res_depart.data)
            this.depart_list = res_depart.data;
        },
        async loadDoctrans(){
            const res_doctrans = await api.getDocTransByDoctye(this.$route.params.doctype_id)
            if(res_doctrans.data.length > 0){
                this.disabledDelete = true;
            }
        },
        async loadDoctype(){
            const res_doctype = await api.getDoctypeByID(this.$route.params.doctype_id)
            this.doctype = res_doctype.data[0].doctype;
            if(res_doctype.data[0].department_authorization != ""){
                const dep_au =  res_doctype.data[0].department_authorization
                const temp_dep = dep_au.split(',');
                for(let i=0;i < temp_dep.length;i++){
                   temp_dep[i] = Number(temp_dep[i])
                }
                // console.log(temp_dep)
                this.authori_depart = temp_dep;

            }

        },
        async loadDoctypeTemplate(){
            const res_doctypetemplate = await api.getDoctypeTemplateByID(this.$route.params.doctype_id)
            this.desserts = res_doctypetemplate.data;
            this.desserts.forEach(async(item)=>{
                if(item.type == "Dropdown"){
                    const res_docdrop = await api.getDocDropByDoctempID(item.id)  
                    item.datadropdown = res_docdrop.data;
                }else{
                    item.datadropdown = [];
                }      
                item.textdrop = "";
            })
            console.log(this.desserts)
            
        },
        async saveItemAdd(){
            // console.log(this.doc_title)
            // console.log(this.documnet_type)
            if (this.doc_title == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Check information!!!",
                    "Please Enter Document Type Data!!!",
                    "text-h5 red--text text-center"
                );
                this.$refs["myDocTitle"].focus();
                return;
            }
            if (this.documnet_type == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Check information!!!",
                    "Please Enter Document Type Data!!!",
                    "text-h5 red--text text-center"
                );
                this.$refs["myType"].focus();
                return;
            }

            const additem = {
                    doctype_id: this.$route.params.doctype_id,
                    title: this.doc_title,
                    type: this.documnet_type
                }
            if(this.showdropdown){
                // console.log(this.dropdownlist)
                for(let i=0;i<this.dropdownlist.length;i++){
                    if(this.dropdownlist[i].text == ""){
                        this.$store.state.global_dialog = true;
                        this.setupAlertDialog(
                            true,
                            "Check information!!!",
                            "Please Enter Dropdown Item Data!!!",
                            "text-h5 red--text text-center"
                        );
                        return;
                    }
                }
                    const res_additem = await api.CreateDoctypeTemplate(additem);
                if(res_additem.status == 200){
                    this.$showLoader();
                    for(let i=0;i<this.dropdownlist.length;i++){
                        const addDocDropItem = {
                            doctemplate_id: res_additem.data.id,
                            text: this.dropdownlist[i].text,
                        }
                        const add_drop = await api.CreateDocDropDown(addDocDropItem)
                        if(add_drop.status != 200){
                            this.doc_title = "";
                            this.documnet_type = "";
                            this.showdropdown = false;
                            this.dropdownlist = [{
                                        text: ""
                                    }]
                            await api.DeleteDocDropDown(add_drop.data.id)
                            this.$hideLoader();
                            this.$store.state.global_dialog = true;
                            this.setupAlertDialog(
                                true,
                                "Check information!!!",
                                "Add Data fail!!!",
                                "text-h5 red--text text-center"
                            );
                            return;
                        }
                    }
                    this.doc_title = "";
                    this.documnet_type = "";
                    this.showdropdown = false;
                    this.dropdownlist = [{
                                        text: ""
                                    }]
                    await this.loadDoctypeTemplate();
                    this.$hideLoader();

                }else{
                    this.doc_title = "";
                    this.documnet_type = "";
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Please Enter Document Type Data!!!",
                        "text-h5 red--text text-center"
                    );
                    return;
                }
            }else{
                this.$showLoader();
                const res_additem = await api.CreateDoctypeTemplate(additem);
                if(res_additem.status == 200){
                    await this.loadDoctypeTemplate();
                    this.doc_title = "";
                    this.documnet_type = "";
                    this.$hideLoader();
                }else{
                    
                    this.doc_title = "";
                    this.documnet_type = "";
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Please Enter Document Type Data!!!",
                        "text-h5 red--text text-center"
                    );
                    return;
                }
                
            }
        },
        async editItemTitle(data){
            if(data.title == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Please Enter Title Data!!!!!!",
                        "text-h5 red--text text-center"
                );
                await this.loadDoctypeTemplate()
                return;
            }
            this.$showLoader();
            const res_update = await api.UpdateDoctypeTemplate(data.id, { title: data.title})
            if(res_update.status != 200){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Add Data fail!!!",
                        "text-h5 red--text text-center"
                );
                this.$hideLoader();
                return;
            }
            await this.loadDoctypeTemplate()
            this.$hideLoader();
        },
        async saveDocdrop(data){
            if(data.textdrop == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Please Dropdown Item Data!!!!!!",
                        "text-h5 red--text text-center"
                );
                await this.loadDoctypeTemplate()
                return;
            }
            const adddocdrop = {
                doctemplate_id: data.id,
                text: data.textdrop,
            }
            this.$showLoader();
            const res_addDropdoc = await api.CreateDocDropDown(adddocdrop)
            if(res_addDropdoc.status != 200){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Add Data fail!!!",
                        "text-h5 red--text text-center"
                );
                this.$hideLoader();
                return;

            }
            await this.loadDoctypeTemplate()
            this.$hideLoader();
        },
        async updateDocdrop(data){
            if(data.text == ""){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Please Dropdown Item Data!!!!!!",
                        "text-h5 red--text text-center"
                );
                await this.loadDoctypeTemplate()
                return;
            }
            this.$showLoader();
            const res_updateDocdrop = await api.updateDocDropDown(data.id, { text: data.text })
            if(res_updateDocdrop.status != 200){
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Update Data fail!!!",
                        "text-h5 red--text text-center"
                );                
                return;
            }
            await this.loadDoctypeTemplate()
            this.$hideLoader();
        },
        async saveDepartAu(){
            // if (this.authori_depart.length == 0) {
            //     this.$store.state.global_dialog = true;
            //     this.setupAlertDialog(
            //         true,
            //         "Check information!!!",
            //         "Please Enter Department Authorization  Data!!!",
            //         "text-h5 red--text text-center"
            //     );
            //     this.$refs["myDep"].focus();
            //     return;
            // }
            this.$showLoader();
            let dep_authri_id = "";
            this.authori_depart.forEach((item,index)=>{
                if(index == (this.authori_depart.length - 1)){
                    dep_authri_id = dep_authri_id + item;
                } else{
                    dep_authri_id = dep_authri_id + item + ",";
                }
                
                
            })
            const res_update_dep = await api.UpdateDoctype(
                this.$route.params.doctype_id,
                {
                    department_authorization: dep_authri_id
                }
            )
            if(res_update_dep.status != 200){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Add Data fail!!!",
                        "text-h5 red--text text-center"
                );
                this.$hideLoader();
                return;

            }
            this.authori_depart = [];
            this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    `/document-setup`
            );
            this.$hideLoader();
        },
        async deleteDocdrop(data){
            this.$showLoader();
            const res_deletedrop = await api.DeleteDocDropDownByID(data.id)
            if(res_deletedrop.status != 200){
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                        true,
                        "Check information!!!",
                        "Add Data fail!!!",
                        "text-h5 red--text text-center"
                );
                this.$hideLoader();
                return;

            }
            await this.loadDoctypeTemplate()
            this.$hideLoader();
        },
        async openDeleteAll(){
            this.dialogDeleteAll = true;
        },
        closeDeleteAll(){
            this.dialogDeleteAll = false;
        },
        async deleteItemTableAll(){
            this.$showLoader();
            this.desserts.forEach(async(item) => {
                await api.DeleteDocDropDown(item.id);
                await api.DeleteDoctypeTemplate(item.id);
            })
            await api.DeleteDoctype(this.$route.params.doctype_id);
            this.dialogDeleteAll = false;
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
                true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    `/document-setup`
            );

        },
        async openItemTable(doctemplate_id){
            this.dialogDelete = true;
            this.doctemplate_id_delete = doctemplate_id;
        },
        closeDelete(){
            this.dialogDelete = false;
            this.doctemplate_id_delete = 0;
        },
        async deleteItemTable(){
            this.$showLoader();
            await api.DeleteDocDropDown(this.doctemplate_id_delete)
            await api.DeleteDoctypeTemplate(this.doctemplate_id_delete)
            this.dialogDelete = false;
            await this.loadDoctypeTemplate();
            this.$hideLoader();
        },
        showTypeDropdown(){
            // alert(this.documnet_type)
            if(this.documnet_type == "Dropdown"){
                this.showdropdown = true;
            }else{
                this.showdropdown = false;
                this.dropdownlist = [{
                                        text: ""
                                    }]
            }
        },
        async inputapprover() {
            this.dropdownlist.push({
                text: ""
            });
        },
        async deletedropdown(index) {
            this.dropdownlist.splice(index, 1);
        },
        async inputapproverTable() {
            this.dropdownlistTable.push({
                text: ""
            });

        },
        async deletedropdownTable(index) {
            this.dropdownlistTable.splice(index, 1);
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
};
