import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getTransectionLog = () => {
  return httpClient.get(server.TRANSACTIONS_LOG);
};


export const getTransectionLogById = id => {
    return httpClient.get(server.TRANSACTIONS_LOG + `/${id}`);
};
  
export const addTransectionLog = data => {
    return httpClient.post(server.TRANSACTIONS_LOG, data);
};
  
export const updateTransectionLog = (id,data) => {
    return httpClient.put(server.TRANSACTIONS_LOG + `/${id}`, data);
};

export const deleteTransectionLog = id => {
    return httpClient.delete(server.TRANSACTIONS_LOG + `/${id}`);
};

export const getTransectionLogByTable = () => {
    return httpClient.get(server.TRANSACTIONS_LOG + `/serach/data-table`);
};
export const getTransectionLogBySearch = (com_id,query) => {
    return httpClient.get(server.TRANSACTIONS_LOG + `/serach/data-all/${com_id}`, query);
};