import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllJobtitle = () => {
  return httpClient.get(server.JOBTITLE + `/get/list`);
};

export const getAllJobtitle_By_CompanyID = (id) => {
  return httpClient.get(server.JOBTITLE + `/get/list_By_CompanyID/${id}`);
};

export const getAllJobtitleByCompany = (company_id) => {
    return httpClient.get(server.JOBTITLE + `/getByCompany/list/${company_id}`);
  };

export const addJobtitle = (data) => {
  return httpClient.post(server.JOBTITLE + `/add`, data);
};

export const updateJobtitle = (id, data) => {
  return httpClient.put(server.JOBTITLE + `/update/${id}`, data);
};
