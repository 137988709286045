//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import api from "@/services/api";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
} from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import pdfvuer from "pdfvuer";
export default {
  name: "About",
  components: {
    SuccessDialog,
    SuccessDialogPush,
    pdf: pdfvuer,
  },
  data(vm) {
    return {
      hideshowlistofrecord: false,
      disablebtnappointmentallrecord: false,
      mDataArrayappointment: [],
      headersappointment: [
        {
          text: "File No.",
          align: "left",
          // sortable: false,
          value: "refno",
          divider: true,
        },
        { text: "Application No.", value: "app_no", divider: true },
        { text: "Trademark Name", value: "trademark_name", divider: true },
        { text: "Agent", value: "agent_contact_name", divider: true },

        {
          text: "Work Type",
          align: "left",
          // sortable: true,
          value: "documenttype_code",
          width: "10%",
          divider: true,
        },
        { text: "Notice Type", value: "notice_name", divider: true },
        { text: "Final Deadline", value: "enddate", divider: true },
        { text: "Filed Date", value: "filed_date", divider: true },
        { text: "Status", value: "statusnow", divider: true },
        { text: "", value: "actions", divider: true },
      ],
      disableCalculateNewRenewalDate: false,
      country_list: [],
      date_of_public: "",
      date_of_opposition: "",
      date_of_filling_appeal: "",
      date_of_filling_lawsuit: "",
      date_of_renewal: "",
      date_of_renewal_filed: "",
      next_deadline: "",
      dateof_filling_affidavits: "",
      date_of_regis: "",
      date_of_application: "",
      dialogpreviewimage: false,
      getcurrentimage: "",
      getoldimage: "",
      getcurrentfilename: [],
      getoldfilename: [],
      oldpdffiles: [],
      checkfileapreview: false,
      previewpopupfile: [],
      previewpopupfile2: [],
      dialogpreviewfile: false,
      dialogDelete: false,
      dialogDeleteimage: false,
      fileindex: -1,
      preview_list2: [],
      getpreview_list: [],

      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_of_closed: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_issuing_certificate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      search: "",
      mDataArray: [],
      showeditItem: {
        mastertm_id: 0,
        tm_type: 0,
        class_no: "",
        country: "",
        cliant_ref: "",
        name_of_client: "",
        file_no: "",
        regis_no: "",
        trademark: "",
        owner_contact: 0,
        owner_address: "",
        agent_contact: 0,
        agent_address: "",
        associate_contact: 0,
        associate_address: "",
        app_no: "",
        good_service: "",
        condition_of_regis: "",
        val_no: "",
        page_no: "",
        tmstatusasoff: 0,
        dip_link: "",
        remark: "",
        date_of_public: "",
        date_of_opposition: "",
        date_of_filling_appeal: "",
        date_of_filling_lawsuit: "",
        date_of_regis: "",
        date_of_application: "",
        date_of_renewal: "",
        date_of_renewal_filed: "",
        date_of_filling: "",
        next_deadline: "",
        dateof_filling_affidavits: "",
        closed: false,
        disclaim: "",
        date_of_closed: "",
        date_of_issuing_certificate: "",
        user_update: localStorage.getItem(server.USER_ID),
      },
      menudate_of_issuing_certificate: false,
      menudate_of_closed: false,
      menuadd: false,
      menuaddappdate: false,
      menupublication: false,
      menuopposition: false,
      menufilling_appeal: false,
      menufilling_lawsuit: false,
      menurenewal: false,
      menurenewal_filed: false,
      menuaffidavits: false,
      menufiling_affidavits: false,
      image_listedit: [],
      getpreview_listedit: [],
      preview_listedit: [],
      preview_listedit2: [],
      previewimageedit: null,
      filename: [],
      filenameedit: [],
      imagenameedit: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      tmstatus_list: [],
      contact_owner_list: [],
      contact_agent_list: [],
      contact_associate_list: [],
      objecttype_list: [],
      load_bg: false,
      checkfileedit: false,
      checkimageedit: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      dialogEditmastertm: false,
      main_id: 0,
    };
  },
  async mounted() {
    await this.loaddataobjecttypeBygroupId();
    await this.loaddatacontactByContact_type_owner();
    await this.loaddatacontactByContact_type_agent();
    await this.loaddatacontactByContact_type_associate();
    await this.loaddatacountry();
    await this.loaddatatmupdatestatus();
    await this.loaddataEdit();
    this.$hideLoader();
  },

  watch: {
    date_of_regis(val) {
      this.showeditItem.date_of_regis = this.formatDate(this.date_of_regis);
    },
    date_of_application(val) {
      this.showeditItem.date_of_application = this.formatDate(
        this.date_of_application
      );
    },
    date_of_public(val) {
      this.showeditItem.date_of_public = this.formatDate(this.date_of_public);
    },
    date_of_opposition(val) {
      this.showeditItem.date_of_opposition = this.formatDate(
        this.date_of_opposition
      );
    },
    date_of_filling_appeal(val) {
      this.showeditItem.date_of_filling_appeal = this.formatDate(
        this.date_of_filling_appeal
      );
    },
    date_of_filling_lawsuit(val) {
      this.showeditItem.date_of_filling_lawsuit = this.formatDate(
        this.date_of_filling_lawsuit
      );
    },
    date_of_renewal(val) {
      this.showeditItem.date_of_renewal = this.formatDate(this.date_of_renewal);
    },
    date_of_renewal_filed(val) {
      this.showeditItem.date_of_renewal_filed = this.formatDate(
        this.date_of_renewal_filed
      );
    },

    next_deadline(val) {
      this.showeditItem.next_deadline = this.formatDate(this.next_deadline);
    },
    dateof_filling_affidavits(val) {
      this.showeditItem.dateof_filling_affidavits = this.formatDate(
        this.dateof_filling_affidavits
      );
    },
    date_of_closed(val) {
      this.showeditItem.date_of_closed = this.formatDate(this.date_of_closed);
    },
    date_of_issuing_certificate(val) {
      this.showeditItem.date_of_issuing_certificate = this.formatDate(
        this.date_of_issuing_certificate
      );
    },
  },
  methods: {
    async loaddataEdit() {
      this.main_id = this.$route.params.mastertm_id;
      const result = await api.getMasterTMById(this.main_id);
      console.log(result.data[0]);
      const item = result.data[0];

      this.imagenameedit = "";
      this.filenameedit = [];
      this.filename = [];
      if (item.master_files == "" || item.master_files == null) {
        this.checkfileedit = false;
      } else {
        let getdata = item.master_files.split(",");
        getdata.forEach((x) => {
          this.filename.push({ name: x, checktypefile: "oldfile" });
        });

        this.oldpdffiles = item.master_files.split(",");
        this.getcurrentfilename = this.oldpdffiles;
        console.log(this.oldpdffiles);
        await this.getPdf(this.oldpdffiles, "oldfile");
        this.preview_listedit2 = this.preview_listedit;
        this.checkfileedit = true;
      }
      this.getcurrentimage = item.master_images;
      this.previewimageedit = `${imagemasterTMUrl}/${item.mastertm_id}/${item.master_images}`;
      if (item.master_images == "" || item.master_images == null) {
        this.checkimageedit = false;
      } else {
        this.checkimageedit = true;
      }
      this.date_of_regis = item.date_of_regis;
      this.date_of_public = item.date_of_public;
      this.date_of_opposition = item.date_of_opposition;
      this.date_of_filling_appeal = item.date_of_filling_appeal;
      this.date_of_filling_lawsuit = item.date_of_filling_lawsuit;
      this.date_of_application = item.date_of_application;
      this.date_of_renewal = item.date_of_renewal;
      this.date_of_renewal_filed = item.date_of_renewal_filed;
      this.date_of_filling = item.date_of_filling;
      this.next_deadline = item.next_deadline;
      this.dateof_filling_affidavits = item.dateof_filling_affidavits;
      this.date_of_closed = item.date_of_closed;
      this.date_of_issuing_certificate = item.date_of_issuing_certificate;
      this.showeditItem = {
        mastertm_id: item.mastertm_id,
        tm_type: item.tm_type,
        class_no: item.class_no,
        app_no: item.app_no,
        country: item.country,
        cliant_ref: item.cliant_ref,
        name_of_client: item.name_of_client,
        file_no: item.file_no,
        regis_no: item.regis_no,
        trademark: item.trademark,
        owner_contact: item.owner_contact,
        agent_contact: item.agent_contact,
        associate_contact: item.associate_contact,
        good_service: item.good_service,
        condition_of_regis: item.condition_of_regis,
        val_no: item.val_no,
        page_no: item.page_no,
        tmstatusasoff: item.tmstatusasoff,
        dip_link: item.dip_link,
        remark: item.remark,
        date_of_public: this.formatDate(item.date_of_public),
        date_of_opposition: this.formatDate(item.date_of_opposition),
        date_of_filling_appeal: this.formatDate(item.date_of_filling_appeal),
        date_of_filling_lawsuit: this.formatDate(item.date_of_filling_lawsuit),
        date_of_regis: this.formatDate(item.date_of_regis),
        date_of_application: this.formatDate(item.date_of_application),
        date_of_renewal: this.formatDate(item.date_of_renewal),
        date_of_renewal_filed: this.formatDate(item.date_of_renewal_filed),
        date_of_filling: this.formatDate(item.date_of_filling),
        next_deadline: this.formatDate(item.next_deadline),
        dateof_filling_affidavits: this.formatDate(
          item.dateof_filling_affidavits
        ),
        disclaim: item.disclaim,
        date_of_issuing_certificate: item.date_of_issuing_certificate,
        closed: item.closed,
        date_of_closed: this.formatDate(item.date_of_closed),
        date_of_issuing_certificate: this.formatDate(
          item.date_of_issuing_certificate
        ),
        user_update: localStorage.getItem(server.USER_ID),
      };
      this.loadAppointmentByfile_no(item.file_no);
      console.log("===========================");
      console.log(this.showeditItem);
      this.onSelectEditOwnerChange(item.owner_contact);
      this.onSelectEditAgentChange(item.agent_contact);
      this.onSelectEditAssociateChange(item.associate_contact);
    },
    async loadAppointmentByfile_no(file_no) {
      this.mDataArrayappointment = [];
      const result = await api.getfile_no(file_no);
      console.log(result);
      if (result.data && result.data.length > 0) {
        this.mDataArrayappointment = result.data;
      } else {
        this.disablebtnappointmentallrecord = true;
      }
    },
    async loaddataobjecttypeBygroupId() {
      const result = await api.getObjectTypeByGroupId(1);
      console.log(result.data);
      this.objecttype_list = result.data;

      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_owner() {
      const result = await api.getContactByContactType(1);
      this.contact_owner_list = result.data;

      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_agent() {
      const result = await api.getContactByContactType(2);
      this.contact_agent_list = result.data;

      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_associate() {
      const result = await api.getContactByContactType(3);
      this.contact_associate_list = result.data;

      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacountry() {
      const result = await api.getAllCountry();
      this.country_list = result.data;
    },

    async loaddatatmupdatestatus() {
      const result = await api.getAlltmupdatestatus();
      this.tmstatus_list = result.data;
    },
    async saveeditItem() {
      console.log(this.showeditItem);
      // return;
      if (this.showeditItem.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Object Type Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditItem.file_no == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No. Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditItem.trademark == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Trademark Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showeditItem.regis_no == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Registration No. Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      // if (this.showeditItem.dip_link == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter DIP Link(URL) Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      this.$showLoader();

      let formData = new FormData();

      if (this.getoldimage) {
        formData.append(`getoldimage`, this.getoldimage);
      } else {
        formData.append(`getcurrentimage`, this.getcurrentimage);
      }
      if (this.imageedit) {
        formData.append(`file`, this.imageedit);
      }

      this.image_listedit.forEach((x, index) => {
        formData.append(`file`, x);
      });

      if (
        this.getoldfilename &&
        this.getoldfilename != null &&
        this.getoldfilename != ""
      ) {
        formData.append("getoldfilename", this.getoldfilename);
      }

      if (
        this.getcurrentfilename &&
        this.getcurrentfilename != null &&
        this.getcurrentfilename != ""
      ) {
        formData.append("getcurrentfilename", this.getcurrentfilename);
      }

      formData.append("file_no", this.showeditItem.file_no.trim());

      formData.append("regis_no", this.showeditItem.regis_no.trim());
      formData.append("tm_type", this.showeditItem.tm_type);

      formData.append("class_no", this.showeditItem.class_no);

      formData.append("country", this.showeditItem.country);

      //   formData.append("date_of_filling", this.showeditItem.date_of_filling);

      formData.append("name_of_client", this.showeditItem.name_of_client);

      formData.append(
        "date_of_regis",
        this.changeformatDatetoinsert(this.showeditItem.date_of_regis)
      );
      formData.append("cliant_ref", this.showeditItem.cliant_ref);
      formData.append("app_no", this.showeditItem.app_no);

      formData.append(
        "date_of_application",
        this.changeformatDatetoinsert(this.showeditItem.date_of_application)
      );

      formData.append("trademark", this.showeditItem.trademark.trim());

      // if (typeof this.showeditItem.owner_contact != "string") {
      formData.append("owner_contact", this.showeditItem.owner_contact);
      // }

      // if (typeof this.showeditItem.agent_contact != "string") {
      formData.append("agent_contact", this.showeditItem.agent_contact);
      // }

      // if (typeof this.showeditItem.associate_contact != "string") {
      formData.append("associate_contact", this.showeditItem.associate_contact);
      // }

      formData.append(
        "date_of_public",
        this.changeformatDatetoinsert(this.showeditItem.date_of_public)
      );

      formData.append(
        "date_of_filling_appeal",
        this.changeformatDatetoinsert(this.showeditItem.date_of_filling_appeal)
      );

      formData.append("good_service", this.showeditItem.good_service);

      formData.append("val_no", this.showeditItem.val_no);

      formData.append(
        "date_of_filling_lawsuit",
        this.changeformatDatetoinsert(this.showeditItem.date_of_filling_lawsuit)
      );

      formData.append("remark", this.showeditItem.remark);

      formData.append("page_no", this.showeditItem.page_no);

      formData.append(
        "date_of_renewal",
        this.changeformatDatetoinsert(this.showeditItem.date_of_renewal)
      );

      formData.append(
        "condition_of_regis",
        this.showeditItem.condition_of_regis
      );

      formData.append(
        "date_of_opposition",
        this.changeformatDatetoinsert(this.showeditItem.date_of_opposition)
      );

      formData.append(
        "date_of_renewal_filed",
        this.changeformatDatetoinsert(this.showeditItem.date_of_renewal_filed)
      );

      formData.append(
        "next_deadline",
        this.changeformatDatetoinsert(this.showeditItem.next_deadline)
      );

      formData.append(
        "dateof_filling_affidavits",
        this.changeformatDatetoinsert(
          this.showeditItem.dateof_filling_affidavits
        )
      );

      formData.append("tmstatusasoff", this.showeditItem.tmstatusasoff);

      formData.append("dip_link", this.showeditItem.dip_link);

      formData.append("user_update", this.showeditItem.user_update);
      formData.append("closed", this.showeditItem.closed);
      formData.append("disclaim", this.showeditItem.disclaim);
      formData.append(
        "date_of_closed",
        this.changeformatDatetoinsert(this.showeditItem.date_of_closed)
      );
      formData.append(
        "date_of_issuing_certificate",
        this.changeformatDatetoinsert(
          this.showeditItem.date_of_issuing_certificate
        )
      );
      const resultMasterTM = await api.updateMasterTM(
        this.showeditItem.mastertm_id,
        formData
      );
      console.log(resultMasterTM);
      
      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Update",
            table_name: "mastertm",
            table_id: this.showeditItem.mastertm_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

                
        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
 
        await api.AppointmentUpdateclientname(
          this.showeditItem.file_no.trim(),
          { trademark_name: this.showeditItem.trademark.trim() }
        );

        this.$hideLoader();
        this.dialogEditmastertm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "edit data!!!",
          "edit data success!!!",
          "text-h5 green--text text-center",
          "/master-trademark-list"
        );
        return;
      } else if (resultMasterTM.status == 204) {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "data duplicate !!!",
          "please check File No. and Application No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "edit data!!!",
          "can't edit data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async saveandcreateappointmentEditItem() {
      console.log(this.showeditItem);
      // return;
      if (this.showeditItem.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Object Type Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditItem.file_no == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No. Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditItem.trademark == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Trademark Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showeditItem.regis_no == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Registration No. Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      // if (this.showeditItem.dip_link == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter DIP Link(URL) Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      this.$showLoader();

      let formData = new FormData();

      if (this.getoldimage) {
        formData.append(`getoldimage`, this.getoldimage);
      } else {
        formData.append(`getcurrentimage`, this.getcurrentimage);
      }
      if (this.imageedit) {
        formData.append(`file`, this.imageedit);
      }

      this.image_listedit.forEach((x, index) => {
        formData.append(`file`, x);
      });

      if (
        this.getoldfilename &&
        this.getoldfilename != null &&
        this.getoldfilename != ""
      ) {
        formData.append("getoldfilename", this.getoldfilename);
      }

      if (
        this.getcurrentfilename &&
        this.getcurrentfilename != null &&
        this.getcurrentfilename != ""
      ) {
        formData.append("getcurrentfilename", this.getcurrentfilename);
      }

      formData.append("file_no", this.showeditItem.file_no.trim());

      formData.append("regis_no", this.showeditItem.regis_no.trim());
      formData.append("tm_type", this.showeditItem.tm_type);

      formData.append("class_no", this.showeditItem.class_no);

      formData.append("country", this.showeditItem.country);

      //   formData.append("date_of_filling", this.showeditItem.date_of_filling);

      formData.append("name_of_client", this.showeditItem.name_of_client);

      formData.append(
        "date_of_regis",
        this.changeformatDatetoinsert(this.showeditItem.date_of_regis)
      );
      formData.append("cliant_ref", this.showeditItem.cliant_ref);
      formData.append("app_no", this.showeditItem.app_no);

      formData.append(
        "date_of_application",
        this.changeformatDatetoinsert(this.showeditItem.date_of_application)
      );

      formData.append("trademark", this.showeditItem.trademark.trim());

      // if (typeof this.showeditItem.owner_contact != "string") {
      formData.append("owner_contact", this.showeditItem.owner_contact);
      // }

      // if (typeof this.showeditItem.agent_contact != "string") {
      formData.append("agent_contact", this.showeditItem.agent_contact);
      // }

      // if (typeof this.showeditItem.associate_contact != "string") {
      formData.append("associate_contact", this.showeditItem.associate_contact);
      // }

      formData.append(
        "date_of_public",
        this.changeformatDatetoinsert(this.showeditItem.date_of_public)
      );

      formData.append(
        "date_of_filling_appeal",
        this.changeformatDatetoinsert(this.showeditItem.date_of_filling_appeal)
      );

      formData.append("good_service", this.showeditItem.good_service);

      formData.append("val_no", this.showeditItem.val_no);

      formData.append(
        "date_of_filling_lawsuit",
        this.changeformatDatetoinsert(this.showeditItem.date_of_filling_lawsuit)
      );

      formData.append("remark", this.showeditItem.remark);

      formData.append("page_no", this.showeditItem.page_no);

      formData.append(
        "date_of_renewal",
        this.changeformatDatetoinsert(this.showeditItem.date_of_renewal)
      );

      formData.append(
        "condition_of_regis",
        this.showeditItem.condition_of_regis
      );

      formData.append(
        "date_of_opposition",
        this.changeformatDatetoinsert(this.showeditItem.date_of_opposition)
      );

      formData.append(
        "date_of_renewal_filed",
        this.changeformatDatetoinsert(this.showeditItem.date_of_renewal_filed)
      );

      formData.append(
        "next_deadline",
        this.changeformatDatetoinsert(this.showeditItem.next_deadline)
      );

      formData.append(
        "dateof_filling_affidavits",
        this.changeformatDatetoinsert(
          this.showeditItem.dateof_filling_affidavits
        )
      );

      formData.append("tmstatusasoff", this.showeditItem.tmstatusasoff);

      formData.append("dip_link", this.showeditItem.dip_link);

      formData.append("user_update", this.showeditItem.user_update);
      formData.append("closed", this.showeditItem.closed);
      formData.append("disclaim", this.showeditItem.disclaim);
      formData.append(
        "date_of_closed",
        this.changeformatDatetoinsert(this.showeditItem.date_of_closed)
      );
      formData.append(
        "date_of_issuing_certificate",
        this.changeformatDatetoinsert(
          this.showeditItem.date_of_issuing_certificate
        )
      );
      const resultMasterTM = await api.updateMasterTM(
        this.showeditItem.mastertm_id,
        formData
      );
      console.log(resultMasterTM);
      
      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Update",
            table_name: "mastertm",
            table_id: this.showeditItem.mastertm_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

                
        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        await api.AppointmentUpdateclientname(
          this.showeditItem.file_no.trim(),
          { trademark_name: this.showeditItem.trademark.trim() }
        );

        this.$hideLoader();
        this.dialogEditmastertm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "edit data!!!",
          "edit data success!!!",
          "text-h5 green--text text-center",
          `/appointmenttm-add/${this.showeditItem.file_no.trim()}/${
            this.date_of_renewal
          }`
        );
        return;
      } else if (resultMasterTM.status == 204) {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "data duplicate !!!",
          "please check File No. and Application No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "edit data!!!",
          "can't edit data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async popupdeletefile(index) {
      this.fileindex = index;
      this.dialogDelete = true;
    },
    async closeDelete() {
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      // this.preview_listedit = [];
      this.filename.splice(this.fileindex, 1);
      this.preview_listedit2 = [];
      let index = this.fileindex - this.oldpdffiles.length;
      if (this.fileindex >= this.oldpdffiles.length) {
        this.getpreview_listedit.splice(index, 1);
        this.image_listedit.splice(index, 1);
        this.filenameedit.splice(index, 1);
      } else {
        this.getoldfilename.push(this.oldpdffiles[this.fileindex]);
        this.oldpdffiles.splice(this.fileindex, 1);
        this.getcurrentfilename = this.oldpdffiles;

        // await this.getPdf(this.oldpdffiles[fileindex], "oldfile");
      }
      console.log("delete file");
      console.log(this.getoldfilename);
      await this.getPdf(this.getpreview_listedit, "newfile");

      this.preview_listedit2 = this.preview_listedit;
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async popupdeleteimage() {
      this.dialogDeleteimage = true;
    },
    async closeDeleteimage() {
      this.dialogDeleteimage = false;
    },
    async deleteItemConfirmimage() {
      this.checkimageedit = false;

      this.getoldimage = this.getcurrentimage;
      // this.getcurrentimage = "";
      this.previewimageedit = "";
      this.imageedit = null;
      this.imagenameedit = "";
      this.dialogDeleteimage = false;
    },
    async popuppreviewfile(fileindex) {
      this.previewpopupfile2 = [];
      console.log(fileindex);
      let index = fileindex - this.oldpdffiles.length;
      if (fileindex >= this.oldpdffiles.length) {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_listedit[index] +
            "'></iframe></body></html>"
        );

        return;
        await this.getPdfforpreview(this.getpreview_listedit[index], "newfile");
      } else {
        let pdf_newTab = window.open(
          `${filemasterTMUrl}/${this.$route.params.mastertm_id}/${this.oldpdffiles[fileindex]}`
        );

        return;
        await this.getPdfforpreview(this.oldpdffiles[fileindex], "oldfile");
      }

      this.previewpopupfile2 = this.previewpopupfile;
      console.log(this.previewpopupfile2);
      this.checkfileapreview = true;
      this.dialogpreviewfile = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfile = false;
    },
    async popuppreviewimage() {
      this.dialogpreviewimage = true;
    },
    async closepreviewimage() {
      this.dialogpreviewimage = false;
    },
    previewImageEdit: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewimageedit = e.target.result;
          this.checkimageedit = true;
        };
        this.imageedit = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      this.getoldimage = this.getcurrentimage;
      this.imagenameedit = this.imageedit.name;
    },
    async previewMultiImageEdit(event) {
      // this.preview_listedit = [];
      // this.image_listedit = [];
      this.filenameedit = [];
      // this.filename = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listedit.push(e.target.result);
            this.preview_listedit.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_listedit.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listedit);
      await this.getPdf(this.getpreview_listedit, "newfile");
      this.preview_listedit2 = this.preview_listedit;
      this.image_listedit.forEach((x) => {
        this.filename.push({ name: x.name, checktypefile: "newfile" });
        this.filenameedit.push(x.name);
      });
      this.checkfileedit = true;
      console.log(this.image_listedit);
    },
    async getPdfforpreview(datafile, method) {
      this.previewpopupfile = [];
      let getfile = [];
      getfile = datafile;
      if (method == "oldfile") {
        this.previewpopupfile.push(
          pdfvuer.createLoadingTask(
            `${filemasterTMUrl}/${this.$route.params.mastertm_id}/${getfile}`
          )
        );
      } else {
        this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      }

      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    async CalculateNewRenewalDate() {
      let addyear = 10;
      let addday = 1;
      console.log(this.showeditItem.date_of_renewal);
      console.log(this.date_of_renewal);
      if (
        this.showeditItem.date_of_renewal &&
        this.showeditItem.date_of_renewal != ""
      ) {
        addday = 0;
        this.date_of_renewal = new Date(
          new Date(this.date_of_renewal).setFullYear(
            new Date(this.date_of_renewal).getFullYear() + addyear,
            new Date(this.date_of_renewal).getMonth(),
            new Date(this.date_of_renewal).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showeditItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      } else {
        if (
          !this.showeditItem.date_of_application ||
          this.showeditItem.date_of_application == ""
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "Please enter Application Date Data",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.date_of_renewal = new Date(
          new Date(this.date_of_application).setFullYear(
            new Date(this.date_of_application).getFullYear() + addyear,
            new Date(this.date_of_application).getMonth(),
            new Date(this.date_of_application).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showeditItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      }
    },

    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    async getPdf(item, method) {
      this.preview_listedit = [];
      let getfile = [];

      if (method == "newfile") {
        getfile = item;

        this.oldpdffiles.forEach((file, index) => {
          this.preview_listedit.push(
            pdfvuer.createLoadingTask(
              `${filemasterTMUrl}/${this.$route.params.mastertm_id}/${file}`
            )
          );
        });

        getfile.forEach((file, index) => {
          this.preview_listedit.push(pdfvuer.createLoadingTask(`${file}`));
        });
        this.preview_listedit.forEach((item, index) => {
          item.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        });
        console.log("ertretdhbfghjf");
        console.log(this.preview_listedit);
      } else {
        if (item == "" || item == null) {
          this.checkfileedit = false;
        } else {
          getfile = item;
          getfile.forEach((file, index) => {
            this.preview_listedit.push(
              pdfvuer.createLoadingTask(
                `${filemasterTMUrl}/${this.$route.params.mastertm_id}/${file}`
              )
            );
          });

          this.preview_listedit.forEach((item, index) => {
            item.then((pdf) => {
              this.numPages = pdf.numPages;
              // window.onscroll = function() {
              //   changePage();
              //   stickyNav();
              // };

              // Get the offset position of the navbar
              // var sticky = $("#buttons")[0].offsetTop;

              // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
              // function stickyNav() {
              //   if (window.pageYOffset >= sticky) {
              //     $("#buttons")[0].classList.remove("hidden");
              //   } else {
              //     $("#buttons")[0].classList.add("hidden");
              //   }
              // }

              function changePage() {
                var i = 1,
                  count = Number(pdf.numPages);
                do {
                  if (
                    window.pageYOffset >=
                      this.findPos(document.getElementById(i)) &&
                    window.pageYOffset <=
                      this.findPos(document.getElementById(i + 1))
                  ) {
                    this.page = i;
                  }
                  i++;
                } while (i < count);
                if (
                  window.pageYOffset >= this.findPos(document.getElementById(i))
                ) {
                  this.page = i;
                }
              }
            });
          });
        }
        this.checkfileedit = true;
      }
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    async onSelectEditOwnerChange(id) {
      let setdata;
      setdata = this.contact_owner_list.filter((item) => {
        if (item.contact_id == id) {
          this.showeditItem.owner_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectEditAgentChange(id) {
      let setdata;
      setdata = this.contact_agent_list.filter((item) => {
        if (item.contact_id == id) {
          this.showeditItem.agent_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectEditAssociateChange(id) {
      let setdata;
      setdata = this.contact_associate_list.filter((item) => {
        if (item.contact_id == id) {
          this.showeditItem.associate_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectCustomerChange() {},
    async Selectchangedate_of_application() {
      // this.disableCalculateNewRenewalDate = false;
    },
    async openhideshowlistofrecord() {
      this.hideshowlistofrecord = !this.hideshowlistofrecord;
    },
    async openeditItemAppointment(appointment_id) {
      // this.$router.push(`/appointmenttm-edit/${appointment_id}`);
      window.open(`/appointmenttm-edit/${appointment_id}`);
    },

    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${year}/${month}/${day}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("/");
    //   return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    // },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
