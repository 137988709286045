//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import {
  server,
  imagemasterPTUrl,
  filemasterPTUrl,
} from "@/services/constants";
import api from "@/services/api";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import pdfvuer from "pdfvuer";

export default {
  name: "MasterPatentAdd",
  components: { SuccessDialog, SuccessDialogPush, pdf: pdfvuer },
  data() {
    return {
      preview_list2: [],
      mDataArrayappointment: [],
      hideshowlistofrecord: false,
      disablebtnappointmentallrecord: false,
      getpreview_list: [],
      image: null,
      previewimage: null,
      previewimageedit: null,
      image_list: [],
      preview_list: [],
      dialogpreviewimage: false,
      checkfileapreview: false,
      previewpopupfile: [],
      previewpopupfile2: [],
      dialogpreviewfile: false,
      dialogDelete: false,
      dialogDeleteimage: false,
      filename: [],
      imagename: "",
      load_bg: false,
      checkfileadd: false,
      checkimageadd: false,
      filename: [],
      filenameedit: [],
      imagenameedit: "",
      getcurrentimage: "",
      getoldimage: "",
      getcurrentfilename: [],
      getoldfilename: [],
      oldpdffiles: [],
      fileindex: -1,
      checkfileedit: false,
      checkimageedit: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      dialogEditmastertm: false,
      image_listedit: [],
      getpreview_listedit: [],
      preview_listedit: [],
      preview_listedit2: [],

      country_list: [],
      isShowPassword: false,
      fullPage: true,
      objecttype_list: [],
      contact_owner_list: [],
      contact_agent_list: [],
      contact_associate_list: [],
      menuadd: false,
      menuaddAppD: false,
      menuaddptd: false,
      menuaddpubd: false,
      menuadderdd: false,
      menuaddeaxmd: false,
      menuadderd: false,
      menuaddlapd: false,
      menuaddnad: false,
      menuaddnrd: false,
      menuaddlra: false,
      menuaddndd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogAdd: false,
      main_id: 0,
      mode_operation: "Create",
      date_of_application: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_patent: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_publishing: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      exam_request_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      exam_request_deadline: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_examination: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      next_deadline: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      last_annuity_paid: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      next_annuity_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      next_renewal: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      last_renewal_att: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      type_list: [
        { type_name: "Design", type_number: "1" },
        { type_name: "Invention", type_number: "2" },
        { type_name: "Petty", type_number: "3" },
        { type_name: "Invention / PCT", type_number: "4" },
      ],
      // eslint-disable-next-line prettier/prettier
      latest_list: [
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      patent_status_list: [
        { patent_status_name: "Nil", patent_status_id: 1 },
        { patent_status_name: "Pending", patent_status_id: 2 },
        { patent_status_name: "Registered", patent_status_id: 3 },
        { patent_status_name: "Registration", patent_status_id: 4 },
        { patent_status_name: "Renewal Pending", patent_status_id: 5 },
        { patent_status_name: "Closed", patent_status_id: 6 },
      ],
      itemdata: {
        tm_type: 0,
        file_no: "",
        title: "",
        name_of_client: "",
        app_no: "",
        patent_no: "",
        city: "",
        owner_contact: 0,
        agent_contact: 0,
        associate_contact: 0,
        val_no: "",
        page_no: "",
        priority_number: "",
        status: 0,
        type: "",
        remark: "",
        dead_last_annuity_paid: "",
        client_ref: "",
        good_service: "",
        registration_condition: "",
        owner_address: "",
        agent_address: "",
        associate_address: "",
        date_of_patent: null,
        date_of_application: null,
        date_of_publishing: null,
        date_of_examination: null,
        next_renewal: null,
        last_renewal_att: null,
        next_deadline: null,
        exam_request_deadline: null,
        exam_request_date: null,
        next_annuity_date: null,
        last_annuity_paid: null,
      },
      headers: [
        {
          text: "Work Type",
          align: "left",
          // sortable: false,
          value: "documenttype_code",
          width: "10%",
          divider: true,
        },
        {
          text: "File No.",
          align: "left",
          // sortable: false,
          value: "refno",
          divider: true,
        },
        { text: "Application No.", value: "app_no", divider: true },
        { text: "Client Name", value: "trademark_name", divider: true },
        { text: "Receive Date", value: "receive_date", divider: true },
        { text: "Deadline Date", value: "enddate", divider: true },
        { text: "Lawyer", value: "lower", divider: true },
        {
          text: "",
          value: "actions",
          width: "15%",
          align: "center",
          divider: true,
        },
      ],
    };
  },
  async mounted() {
    await this.loaddataobjecttypeBygroupId();
    await this.loaddatacontactByContact_type_owner();
    await this.loaddatacontactByContact_type_agent();
    await this.loaddatacontactByContact_type_associate();
    await this.loaddata();
    await this.loaddatacountry();
    this.$hideLoader();
  },
  methods: {
    async popupdeletefile(index) {
      this.fileindex = index;
      this.dialogDelete = true;
    },
    async closeDelete() {
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      console.log(this.image_list);
      // this.preview_listedit = [];
      this.filename.splice(this.fileindex, 1);
      this.preview_listedit2 = [];
      let index = this.fileindex - this.oldpdffiles.length;
      if (this.fileindex >= this.oldpdffiles.length) {
        this.getpreview_listedit.splice(index, 1);
        this.image_listedit.splice(index, 1);
        this.filenameedit.splice(index, 1);
      } else {
        this.getoldfilename.push(this.oldpdffiles[this.fileindex]);
        this.oldpdffiles.splice(this.fileindex, 1);
        this.getcurrentfilename = this.oldpdffiles;

        // await this.getPdf(this.oldpdffiles[fileindex], "oldfile");
      }
      console.log("delete file");
      console.log(this.getoldfilename);
      await this.getPdf(this.getpreview_listedit, "newfile");

      this.preview_listedit2 = this.preview_listedit;
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async popupdeleteimage() {
      this.dialogDeleteimage = true;
    },
    async closeDeleteimage() {
      this.dialogDeleteimage = false;
    },
    async deleteItemConfirmimage() {
      this.checkimageedit = false;

      this.getoldimage = this.getcurrentimage;
      // this.getcurrentimage = "";
      this.previewimageedit = "";
      this.imageedit = null;
      this.imagenameedit = "";
      this.dialogDeleteimage = false;
    },
    async popuppreviewfile(fileindex) {
      this.previewpopupfile2 = [];
      console.log(fileindex);
      let index = fileindex - this.oldpdffiles.length;
      if (fileindex >= this.oldpdffiles.length) {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_listedit[index] +
            "'></iframe></body></html>"
        );

        return;
        await this.getPdfforpreview(this.getpreview_listedit[index], "newfile");
      } else {
        let pdf_newTab = window.open(
          `${filemasterPTUrl}/${this.$route.params.masterpt_id}/${this.oldpdffiles[fileindex]}`
        );

        return;
        await this.getPdfforpreview(this.oldpdffiles[fileindex], "oldfile");
      }

      this.previewpopupfile2 = this.previewpopupfile;
      console.log(this.previewpopupfile2);
      this.checkfileapreview = true;
      this.dialogpreviewfile = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfile = false;
    },
    async popuppreviewimage() {
      this.dialogpreviewimage = true;
    },
    async closepreviewimage() {
      this.dialogpreviewimage = false;
    },
    previewImageEdit: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewimageedit = e.target.result;
          this.checkimageedit = true;
        };
        this.imageedit = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      this.getoldimage = this.getcurrentimage;
      this.imagenameedit = this.imageedit.name;
    },
    async previewMultiImageEdit(event) {
      // this.preview_listedit = [];
      // this.image_listedit = [];
      this.filenameedit = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listedit.push(e.target.result);
            this.preview_listedit.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_listedit.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listedit);
      await this.getPdf(this.getpreview_listedit, "newfile");
      this.preview_listedit2 = this.preview_listedit;
      this.image_listedit.forEach((x) => {
        this.filename.push({ name: x.name, checktypefile: "newfile" });
        this.filenameedit.push(x.name);
      });
      this.checkfileedit = true;
      console.log(this.image_listedit);
    },
    async getPdfforpreview(datafile, method) {
      this.previewpopupfile = [];
      let getfile = [];
      getfile = datafile;
      if (method == "oldfile") {
        this.previewpopupfile.push(
          pdfvuer.createLoadingTask(
            `${filemasterPTUrl}/${this.$route.params.masterpt_id}/${getfile}`
          )
        );
      } else {
        this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      }

      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    async getPdf(item, method) {
      this.preview_listedit = [];
      let getfile = [];

      if (method == "newfile") {
        getfile = item;

        this.oldpdffiles.forEach((file, index) => {
          this.preview_listedit.push(
            pdfvuer.createLoadingTask(
              `${filemasterPTUrl}/${this.$route.params.masterpt_id}/${file}`
            )
          );
        });

        getfile.forEach((file, index) => {
          this.preview_listedit.push(pdfvuer.createLoadingTask(`${file}`));
        });
        this.preview_listedit.forEach((item, index) => {
          item.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        });
        console.log("ertretdhbfghjf");
        console.log(this.preview_listedit);
      } else {
        if (item == "" || item == null) {
          this.checkfileedit = false;
        } else {
          getfile = item;
          getfile.forEach((file, index) => {
            this.preview_listedit.push(
              pdfvuer.createLoadingTask(
                `${filemasterPTUrl}/${this.$route.params.masterpt_id}/${file}`
              )
            );
          });

          this.preview_listedit.forEach((item, index) => {
            item.then((pdf) => {
              this.numPages = pdf.numPages;
              // window.onscroll = function() {
              //   changePage();
              //   stickyNav();
              // };

              // Get the offset position of the navbar
              // var sticky = $("#buttons")[0].offsetTop;

              // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
              // function stickyNav() {
              //   if (window.pageYOffset >= sticky) {
              //     $("#buttons")[0].classList.remove("hidden");
              //   } else {
              //     $("#buttons")[0].classList.add("hidden");
              //   }
              // }

              function changePage() {
                var i = 1,
                  count = Number(pdf.numPages);
                do {
                  if (
                    window.pageYOffset >=
                      this.findPos(document.getElementById(i)) &&
                    window.pageYOffset <=
                      this.findPos(document.getElementById(i + 1))
                  ) {
                    this.page = i;
                  }
                  i++;
                } while (i < count);
                if (
                  window.pageYOffset >= this.findPos(document.getElementById(i))
                ) {
                  this.page = i;
                }
              }
            });
          });
        }
        this.checkfileedit = true;
      }
    },
    findPos(obj) {
      return obj.offsetTop;
    },

    async loaddata() {
      if (this.$route.params.masterpt_id) {
        this.mode_operation = "Update";
        this.main_id = this.$route.params.masterpt_id;

        const result = await api.getByIdMasterPT(this.main_id);
        const editItem = result.data;
        this.itemdata = editItem[0];
        const item = result.data[0];
        // console.log("loaddata:", JSON.stringify(item));
        // console.log("loaddata item.master_files :", item.master_files);
        // console.log("loaddata item.master_images :", item.master_images);
        // console.log("loaddata item.masterpt_id :", item.masterpt_id);

        // load image //
        try {
          this.imagenameedit = "";
          this.filename = [];
          this.filenameedit = [];
          if (item.master_files == "" || item.master_files == null) {
            this.checkfileedit = false;
          } else {
            let getdata = item.master_files.split(",");
            getdata.forEach((x) => {
              this.filename.push({ name: x, checktypefile: "oldfile" });
            });
            this.oldpdffiles = item.master_files.split(",");
            this.getcurrentfilename = this.oldpdffiles;
            console.log(this.oldpdffiles);
            await this.getPdf(this.oldpdffiles, "oldfile");
            this.preview_listedit2 = this.preview_listedit;
            this.checkfileedit = true;
          }
          this.getcurrentimage = item.master_images;
          this.previewimageedit = `${imagemasterPTUrl}/${item.masterpt_id}/${item.master_images}`;
          if (item.master_images == "" || item.master_images == null) {
            this.checkimageedit = false;
          } else {
            this.checkimageedit = true;
          }
        } catch (error) {
          console.log("loaddata error:", error);
        }

        // load image //

        this.itemdata.date_of_application = this.formatDate(
          this.itemdata.date_of_application
        );
        this.itemdata.date_of_patent = this.formatDate(
          this.itemdata.date_of_patent
        );
        this.itemdata.date_of_publishing = this.formatDate(
          this.itemdata.date_of_publishing
        );
        this.itemdata.exam_request_date = this.formatDate(
          this.itemdata.exam_request_date
        );
        this.itemdata.exam_request_deadline = this.formatDate(
          this.itemdata.exam_request_deadline
        );
        this.itemdata.date_of_examination = this.formatDate(
          this.itemdata.date_of_examination
        );
        this.itemdata.next_deadline = this.formatDate(
          this.itemdata.next_deadline
        );
        this.itemdata.last_annuity_paid = this.formatDate(
          this.itemdata.last_annuity_paid
        );
        this.itemdata.next_annuity_date = this.formatDate(
          this.itemdata.next_annuity_date
        );
        this.itemdata.next_renewal = this.formatDate(
          this.itemdata.next_renewal
        );
        this.itemdata.last_renewal_att = this.formatDate(
          this.itemdata.last_renewal_att
        );
        //console.log("testdata:")
        //console.log(editItem[0]);
        this.onSelectOwnerChange(editItem[0].owner_contact);
        this.onSelectAgentChange(editItem[0].agent_contact);
        this.onSelectAssociateChange(editItem[0].associate_contact);
        this.loadAppointmentByfile_no(this.itemdata.file_no);
      }
    },
    async loadAppointmentByfile_no(file_no) {
      this.mDataArrayappointment = [];
      const result = await api.searchdataAppointmentPT({
        params: {
          searchfile_no: file_no,
        },
      });
      if (result.data && result.data.length > 0) {
        this.mDataArrayappointment = result.data;
      } else {
        this.disablebtnappointmentallrecord = true;
      }
    },
    async loaddataobjecttypeBygroupId() {
      const result = await api.getObjectTypeByGroupId(2);
      //console.log(result.data);
      this.objecttype_list = result.data;
    },
    async loaddatacontactByContact_type_owner() {
      const result = await api.getContactByContactType(1);
      this.contact_owner_list = result.data;
      // console.log("contact_owner_list:");
      // console.log(this.contact_owner_list);
    },
    async loaddatacontactByContact_type_agent() {
      const result = await api.getContactByContactType(2);
      this.contact_agent_list = result.data;
    },
    async loaddatacontactByContact_type_associate() {
      const result = await api.getContactByContactType(3);
      this.contact_associate_list = result.data;
    },
    async loaddatacountry() {
      const result = await api.getAllCountry();
      this.country_list = result.data;
    },
    async saveItem() {
      //console.log(this.itemdata)
      if (this.itemdata.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Select Object Type",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.itemdata.file_no) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Enter File No.",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.itemdata.name_of_client) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Enter Client Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      //this.$showLoader();
      if (this.mode_operation == "Create") {
        console.log("saveItem:", "Create");
        this.itemdata.date_of_application = this.changeformatDatetoinsert(
          this.itemdata.date_of_application
        );
        this.itemdata.date_of_patent = this.changeformatDatetoinsert(
          this.itemdata.date_of_patent
        );
        this.itemdata.date_of_publishing = this.changeformatDatetoinsert(
          this.itemdata.date_of_publishing
        );
        this.itemdata.exam_request_date = this.changeformatDatetoinsert(
          this.itemdata.exam_request_date
        );
        this.itemdata.exam_request_deadline = this.changeformatDatetoinsert(
          this.itemdata.exam_request_deadline
        );
        this.itemdata.date_of_examination = this.changeformatDatetoinsert(
          this.itemdata.date_of_examination
        );
        this.itemdata.next_deadline = this.changeformatDatetoinsert(
          this.itemdata.next_deadline
        );
        this.itemdata.last_annuity_paid = this.changeformatDatetoinsert(
          this.itemdata.last_annuity_paid
        );
        this.itemdata.next_annuity_date = this.changeformatDatetoinsert(
          this.itemdata.next_annuity_date
        );
        this.itemdata.next_renewal = this.changeformatDatetoinsert(
          this.itemdata.next_renewal
        );
        this.itemdata.last_renewal_att = this.changeformatDatetoinsert(
          this.itemdata.last_renewal_att
        );
        //alert("testcreate");
        this.$showLoader();
        // console.log("saveItem:", JSON.stringify(this.itemdata));
        let formData = new FormData();
        formData.append(`file`, this.image);
        this.image_list.forEach((x, index) => {
          formData.append(`file`, x);
        });

        formData.append("tm_type", this.itemdata.tm_type);
        formData.append("file_no", this.itemdata.file_no);
        formData.append("title", this.itemdata.title);
        formData.append("name_of_client", this.itemdata.name_of_client);
        formData.append("app_no", this.itemdata.app_no);
        formData.append("patent_no", this.itemdata.patent_no);
        formData.append("city", this.itemdata.city);

        if (this.itemdata.owner_contact) {
          formData.append("owner_contact", this.itemdata.owner_contact);
        }

        if (this.itemdata.agent_contact) {
          formData.append("agent_contact", this.itemdata.agent_contact);
        }

        if (this.itemdata.associate_contact) {
          formData.append("associate_contact", this.itemdata.associate_contact);
        }

        formData.append("val_no", this.itemdata.val_no);
        formData.append("page_no", this.itemdata.page_no);
        formData.append("priority_number", this.itemdata.priority_number);
        formData.append("status", this.itemdata.status);
        formData.append("type", this.itemdata.type);
        formData.append("remark", this.itemdata.remark);
        formData.append(
          "dead_last_annuity_paid",
          this.itemdata.dead_last_annuity_paid
        );
        formData.append("client_ref", this.itemdata.client_ref);
        formData.append("good_service", this.itemdata.good_service);
        formData.append(
          "registration_condition",
          this.itemdata.registration_condition
        );
        formData.append("owner_address", this.itemdata.owner_address);
        formData.append("agent_address", this.itemdata.agent_address);
        formData.append("associate_address", this.itemdata.associate_address);
        if (this.itemdata.date_of_patent) {
          formData.append("date_of_patent", this.itemdata.date_of_patent);
        }
        if (this.itemdata.date_of_application) {
          formData.append(
            "date_of_application",
            this.itemdata.date_of_application
          );
        }

        if (this.itemdata.date_of_publishing) {
          formData.append(
            "date_of_publishing",
            this.itemdata.date_of_publishing
          );
        }

        if (this.itemdata.date_of_examination) {
          formData.append(
            "date_of_examination",
            this.itemdata.date_of_examination
          );
        }

        if (this.itemdata.next_renewal) {
          formData.append("next_renewal", this.itemdata.next_renewal);
        }

        if (this.itemdata.last_renewal_att) {
          formData.append("last_renewal_att", this.itemdata.last_renewal_att);
        }

        if (this.itemdata.next_deadline) {
          formData.append("next_deadline", this.itemdata.next_deadline);
        }

        if (this.itemdata.exam_request_deadline) {
          formData.append(
            "exam_request_deadline",
            this.itemdata.exam_request_deadline
          );
        }

        if (this.itemdata.exam_request_date) {
          formData.append("exam_request_date", this.itemdata.exam_request_date);
        }

        if (this.itemdata.next_annuity_date) {
          formData.append("next_annuity_date", this.itemdata.next_annuity_date);
        }

        if (this.itemdata.last_annuity_paid) {
          formData.append("last_annuity_paid", this.itemdata.last_annuity_paid);
        }

        // const create_masterpt = await api.createMasterPT(this.itemdata);
        const create_masterpt = await api.createMasterPT_V2(formData);
        this.$hideLoader();
        //console.log(create_masterpt.data);
        //alert(create_masterpt.data.file_no);
        if (create_masterpt.status == 203) {
          if (create_masterpt.data.file_no) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "data duplicate !!!",
              "please check File No.",
              "text-h5 red--text text-center"
            );
            return;
          }
          if (create_masterpt.data.app_no) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "data duplicate !!!",
              "please check Application No.",
              "text-h5 red--text text-center"
            );
            return;
          }
        }
        if (create_masterpt.status == 200 || create_masterpt.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "save data!!!",
            "save data success!!!",
            "text-h5 green--text text-center",
            "/master-patent-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data!!!",
            "can't save data!!!",
            "text-h5 red--text text-center"
          );
        }
      }
      if (this.mode_operation == "Update") {
        this.itemdata.date_of_application = this.changeformatDatetoinsert(
          this.itemdata.date_of_application
        );
        this.itemdata.date_of_patent = this.changeformatDatetoinsert(
          this.itemdata.date_of_patent
        );
        this.itemdata.date_of_publishing = this.changeformatDatetoinsert(
          this.itemdata.date_of_publishing
        );
        this.itemdata.exam_request_date = this.changeformatDatetoinsert(
          this.itemdata.exam_request_date
        );
        this.itemdata.exam_request_deadline = this.changeformatDatetoinsert(
          this.itemdata.exam_request_deadline
        );
        this.itemdata.date_of_examination = this.changeformatDatetoinsert(
          this.itemdata.date_of_examination
        );
        this.itemdata.next_deadline = this.changeformatDatetoinsert(
          this.itemdata.next_deadline
        );
        this.itemdata.last_annuity_paid = this.changeformatDatetoinsert(
          this.itemdata.last_annuity_paid
        );
        this.itemdata.next_annuity_date = this.changeformatDatetoinsert(
          this.itemdata.next_annuity_date
        );
        this.itemdata.next_renewal = this.changeformatDatetoinsert(
          this.itemdata.next_renewal
        );
        this.itemdata.last_renewal_att = this.changeformatDatetoinsert(
          this.itemdata.last_renewal_att
        );
        // date_of_application: new Date(
        // date_of_patent: new Date(
        // date_of_publishing: new Date(
        // exam_request_date: new Date(
        // exam_request_deadline: new Date(
        // date_of_examination: new Date(
        // next_deadline: new Date(
        // last_annuity_paid: new Date(
        // next_annuity_date: new Date(
        // next_renewal: new Date(
        // last_renewal_att: new Date(
        this.$showLoader();

        // console.log("this.itemdata", JSON.stringify(this.itemdata));

        let formData = new FormData();

        if (this.getoldimage) {
          formData.append(`getoldimage`, this.getoldimage);
        } else {
          formData.append(`getcurrentimage`, this.getcurrentimage);
        }
        if (this.imageedit) {
          formData.append(`file`, this.imageedit);
        }

        this.image_listedit.forEach((x, index) => {
          formData.append(`file`, x);
        });

        if (
          this.getoldfilename &&
          this.getoldfilename != null &&
          this.getoldfilename != ""
        ) {
          formData.append("getoldfilename", this.getoldfilename);
        }

        if (
          this.getcurrentfilename &&
          this.getcurrentfilename != null &&
          this.getcurrentfilename != ""
        ) {
          formData.append("getcurrentfilename", this.getcurrentfilename);
        }

        formData.append("tm_type", this.itemdata.tm_type);
        formData.append("file_no", this.itemdata.file_no);
        formData.append("title", this.itemdata.title);
        formData.append("name_of_client", this.itemdata.name_of_client);
        formData.append("app_no", this.itemdata.app_no);
        formData.append("patent_no", this.itemdata.patent_no);
        formData.append("city", this.itemdata.city);

        if (this.itemdata.owner_contact) {
          formData.append("owner_contact", this.itemdata.owner_contact);
        }

        if (this.itemdata.agent_contact) {
          formData.append("agent_contact", this.itemdata.agent_contact);
        }

        if (this.itemdata.associate_contact) {
          formData.append("associate_contact", this.itemdata.associate_contact);
        }

        formData.append("val_no", this.itemdata.val_no);
        formData.append("page_no", this.itemdata.page_no);
        formData.append("priority_number", this.itemdata.priority_number);
        formData.append("status", this.itemdata.status);
        formData.append("type", this.itemdata.type);
        formData.append("remark", this.itemdata.remark);
        formData.append(
          "dead_last_annuity_paid",
          this.itemdata.dead_last_annuity_paid
        );
        formData.append("client_ref", this.itemdata.client_ref);
        formData.append("good_service", this.itemdata.good_service);
        formData.append(
          "registration_condition",
          this.itemdata.registration_condition
        );
        formData.append("owner_address", this.itemdata.owner_address);
        formData.append("agent_address", this.itemdata.agent_address);
        formData.append("associate_address", this.itemdata.associate_address);
        if (this.itemdata.date_of_patent) {
          formData.append("date_of_patent", this.itemdata.date_of_patent);
        }
        if (this.itemdata.date_of_application) {
          formData.append(
            "date_of_application",
            this.itemdata.date_of_application
          );
        }

        if (this.itemdata.date_of_publishing) {
          formData.append(
            "date_of_publishing",
            this.itemdata.date_of_publishing
          );
        }

        if (this.itemdata.date_of_examination) {
          formData.append(
            "date_of_examination",
            this.itemdata.date_of_examination
          );
        }

        if (this.itemdata.next_renewal) {
          formData.append("next_renewal", this.itemdata.next_renewal);
        }

        if (this.itemdata.last_renewal_att) {
          formData.append("last_renewal_att", this.itemdata.last_renewal_att);
        }

        if (this.itemdata.next_deadline) {
          formData.append("next_deadline", this.itemdata.next_deadline);
        }

        if (this.itemdata.exam_request_deadline) {
          formData.append(
            "exam_request_deadline",
            this.itemdata.exam_request_deadline
          );
        }

        if (this.itemdata.exam_request_date) {
          formData.append("exam_request_date", this.itemdata.exam_request_date);
        }

        if (this.itemdata.next_annuity_date) {
          formData.append("next_annuity_date", this.itemdata.next_annuity_date);
        }

        if (this.itemdata.last_annuity_paid) {
          formData.append("last_annuity_paid", this.itemdata.last_annuity_paid);
        }

        const update_masterpt = await api.updateMasterPt_V2(
          this.main_id,
          formData
        );

        // const update_masterpt = await api.updateMasterPt(
        //   this.main_id,
        //   this.itemdata
        // );
        await api.AppointmentUpdateclientname(this.itemdata.file_no, {
          trademark_name: this.itemdata.name_of_client,
        });
        this.$hideLoader();
        //console.log(update_masterpt);
        if (update_masterpt.status == 203) {
          if (update_masterpt.data.file_no) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "data duplicate !!!",
              "please check File No.",
              "text-h5 red--text text-center"
            );
            return;
          }
          if (update_masterpt.data.app_no) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "data duplicate !!!",
              "please check Application No.",
              "text-h5 red--text text-center"
            );
            return;
          }
        }
        if (update_masterpt.status == 200 || update_masterpt.status == 201) {

          this.$showLoader();
          let dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Create",
              table_name: "Update",
              table_id: this.main_id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
              last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
          
          this.$hideLoader();


          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "save data!!!",
            "save data success!!!",
            "text-h5 green--text text-center",
            "/master-patent-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data!!!",
            "can't save data!!!",
            "text-h5 red--text text-center"
          );
        }
      }
      //this.$hideLoader();
    },
    async onSelectOwnerChange(id) {
      //alert(id);
      this.contact_owner_list.filter((item) => {
        if (item.contact_id == id) {
          this.owner_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectAgentChange(id) {
      this.contact_agent_list.filter((item) => {
        if (item.contact_id == id) {
          this.agent_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectAssociateChange(id) {
      this.contact_associate_list.filter((item) => {
        if (item.contact_id == id) {
          this.associate_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async openhideshowlistofrecord() {
      this.hideshowlistofrecord = !this.hideshowlistofrecord;
    },
    async openeditItemAppointment(appointment_id) {
      window.open(`/appointment-patent-edit/${appointment_id}`);
    },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },

  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  watch: {
    date_of_application(val) {
      this.itemdata.date_of_application = this.formatDate(
        this.date_of_application
      );
    },
    date_of_patent(val) {
      this.itemdata.date_of_patent = this.formatDate(this.date_of_patent);
    },
    date_of_publishing(val) {
      this.itemdata.date_of_publishing = this.formatDate(
        this.date_of_publishing
      );
    },
    exam_request_date(val) {
      this.itemdata.exam_request_date = this.formatDate(this.exam_request_date);
    },
    exam_request_deadline(val) {
      this.itemdata.exam_request_deadline = this.formatDate(
        this.exam_request_deadline
      );
    },
    date_of_examination(val) {
      this.itemdata.date_of_examination = this.formatDate(
        this.date_of_examination
      );
    },
    next_deadline(val) {
      this.itemdata.next_deadline = this.formatDate(this.next_deadline);
    },
    last_annuity_paid(val) {
      this.itemdata.last_annuity_paid = this.formatDate(this.last_annuity_paid);
    },
    next_annuity_date(val) {
      this.itemdata.next_annuity_date = this.formatDate(this.next_annuity_date);
    },
    next_renewal(val) {
      this.itemdata.next_renewal = this.formatDate(this.next_renewal);
    },
    last_renewal_att(val) {
      this.itemdata.last_renewal_att = this.formatDate(this.last_renewal_att);
    },
  },
};
