//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "../services/constants";

import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
export default {
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      lockcollection: false,
      filenameimport: "",
      oldfilenameimport: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      countsuccess: 0,
      countfail: 0,
      datafailleavequota: 0,
      datafailleavetype: 0,
      sheetsdataallfail: [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ],
      sheetsempfail: [
        {
          name: "sheetsempfail",
          data: [],
        },
      ],
      sheetsleave_typefail: [
        {
          name: "sheetsleave_typefail",
          data: [],
        },
      ],

      btnconfirmimportclose: false,
      exportleavequotaempfail: "leavequota_duplicate_data.xlsx",
      exportleavequotaleavetypefail: "leavequota_leavetype_fail.xlsx",
      exportleavequotadataallfail: "leavequota_dataall_fail.xlsx",
      employeenamefail: false,
      leavetypefail: false,
      btnconfirmimport: false,
      showcheckdatafile: false,
      datafile: null,
      mDataempfail: [],
      mDataleavetypefail: [],
      DataAll: [],
      mDataArray: [],
      mDatafailall: [],
      search: "",
      headers: [
        { text: "employee_id", value: "employee_id" },
        { text: "employee_name", value: "employee_name" },
        { text: "leave_period_from", value: "leave_period_from" },
        { text: "leave_period_to", value: "leave_period_to" },
        { text: "leave_type", value: "leave_type" },
        { text: "quota", value: "quota" },
        { text: "use_leave", value: "use_leave" },
        // { text: "status", value: "status" },
      ],
      isShowPassword: false,
      fullPage: true,
    };
  },
  methods: {
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async confirmimport() {
      this.btnconfirmimportclose = true;
      this.$showLoader();
      const result = await api.importxlsxLeave_quota(this.mDataArray);
      let dataresult = result.data;
      this.$hideLoader();
      if (
        dataresult.countsuccess === this.mDataArray.length &&
        dataresult.countfail === 0
      ) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/leave-quota"
        );
        return;
      }
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Information Saved",
        "มีข้อมูลผิดพลาด!!!",
        "text-h5 red--text text-center"
      );

      this.countsuccess = dataresult.countsuccess;
      this.countfail = dataresult.countfail;
      this.datafailleavequota = dataresult.datafailleavequota.length;
      if (dataresult.datafailleavequota.length > 0) {
        let setdataexportarray = [];
        this.mDataempfail = dataresult.datafailleavequota;
        this.mDataempfail.forEach((x) => {
          let setdataexport = {
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = new Date(x.leave_period_from);
          setdataexport.leave_period_to = new Date(x.leave_period_to);
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });

        this.sheetsempfail[0].data = setdataexportarray;
        this.employeenamefail = true;
      }

      this.datafailleavetype = dataresult.datafailleavetype.length;
      if (dataresult.datafailleavetype.length > 0) {
        let setdataexportarray = [];
        this.mDataleavetypefail = dataresult.datafailleavetype;
        this.mDataleavetypefail.forEach((x) => {
          let setdataexport = {
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = new Date(x.leave_period_from);
          setdataexport.leave_period_to = new Date(x.leave_period_to);
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });

        this.sheetsleave_typefail[0].data = setdataexportarray;
        this.leavetypefail = true;
      }
      if (dataresult.datafaileall.length > 0) {
        let setdataexportarray = [];
        this.mDatafailall = dataresult.datafaileall;
        this.mDatafailall.forEach((x) => {
          let setdataexport = {
            employee_id: "",
            employee_name: "",
            leave_period_from: "",
            leave_period_to: "",
            leave_type: "",
            quota: "",
            use_leave: "",
          };
          setdataexport.employee_id = x.employee_id;
          setdataexport.employee_name = x.employee_name;
          setdataexport.leave_period_from = new Date(x.leave_period_from);
          setdataexport.leave_period_to = new Date(x.leave_period_to);
          setdataexport.leave_type = x.leave_type;
          setdataexport.quota = x.quota;
          setdataexport.use_leave = x.use_leave;
          setdataexportarray.push(setdataexport);
        });
        this.sheetsdataallfail[0].data = setdataexportarray;
      }
    },
    async getInfo(data) {},
    async Readleavequota(event) {
      this.mDataArray = [];
      this.datafile = null;
      this.datafile = event.target.files ? event.target.files[0] : null;

      this.filenameimport = this.datafile.name;

      this.lockcollection = true;
    },
    importconversedate(datedate) {
      let date = new Date(datedate);
      let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    onParsed(data) {
      const excelEpoc = new Date(1900, 0, -1).getTime();
      const msDay = 86400000;
      // alert(new Date(excelEpoc + 44926.291712962964 * msDay));
      this.datafailleavequota = 0;
      this.datafailleavetype = 0;
      this.countsuccess = 0;
      this.countfail = 0;
      this.employeenamefail = false;
      this.leavetypefail = false;
      this.mDataempfail = [];
      this.mDataleavetypefail = [];
      (this.sheetsdataallfail = [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ]),
        (this.sheetsempfail = [
          {
            name: "sheetsempfail",
            data: [],
          },
        ]),
        (this.sheetsleave_typefail = [
          {
            name: "sheetsleave_typefail",
            data: [],
          },
        ]),
        (this.mDataArray = data);
      this.mDataArray.forEach((item, index) => {
        let setleave_period_from = "";
        let setleave_period_to = "";
        item.company_id = localStorage.getItem(server.COMPANYID)?localStorage.getItem(server.COMPANYID):0;
        setleave_period_from = new Date(
          excelEpoc + parseFloat(item.leave_period_from) * msDay
        );
        setleave_period_to = new Date(
          excelEpoc + parseFloat(item.leave_period_to) * msDay
        );

        item.leave_period_from = this.importconversedate(setleave_period_from);
        item.leave_period_to = this.importconversedate(setleave_period_to);
        item.firstname = item.employee_name
          .replace(/^[ ]+/g, "")
          .replace(/ +/g, " ")
          .split(" ")[0];
        item.lastname = item.employee_name
          .replace(/^[ ]+/g, "")
          .replace(/ +/g, " ")
          .split(" ")[1];
      });

      this.btnconfirmimportclose = false;
      this.btnconfirmimport = true;
      this.lockcollection = false;
    },
  },

  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
