var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',[_c('v-container',{staticClass:"pa-12"},[_c('v-card',{staticClass:"pl-16 pr-16 pt-8 pb-16"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","justify":"center"}},[_c('h3',[_vm._v("Edit Document")])])],1)],1),_c('v-row',{staticClass:"row1 mt-3  pt-3 "},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Document Type","background-color":"#F5F5F5","disabled":""},model:{value:(_vm.doctype),callback:function ($$v) {_vm.doctype=$$v},expression:"doctype"}})],1)],1),_c('br'),[[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-6",attrs:{"dense":"","outlined":""},on:{"blur":function($event){return _vm.editItemTitle(item)}},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'Dropdown')?_c('div',[_c('v-expansion-panels',{staticClass:"mb-2 mt-2",attrs:{"accordion":""},model:{value:(item.ShowDrop),callback:function ($$v) {_vm.$set(item, "ShowDrop", $$v)},expression:"item.ShowDrop"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(item.type))]),_c('v-expansion-panel-content',{staticClass:"mt-3"},[_vm._l((item.datadropdown),function(data,index){return (item.datadropdown.length > 0)?_c('v-row',{key:index,staticClass:"ml-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Drowdown item"},on:{"blur":function($event){return _vm.updateDocdrop(data)}},model:{value:(item.datadropdown[index].text),callback:function ($$v) {_vm.$set(item.datadropdown[index], "text", $$v)},expression:"item.datadropdown[index].text"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{style:({
                                                                backgroundColor: '#9E3B1B',
                                                                color: 'white',
                                                            }),attrs:{"disabled":_vm.disabledDelete},on:{"click":function($event){return _vm.deleteDocdrop(data)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1):_vm._e()}),_c('v-row',{staticClass:"ml-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Drowdown item"},model:{value:(item.textdrop),callback:function ($$v) {_vm.$set(item, "textdrop", $$v)},expression:"item.textdrop"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{style:({
                                                                backgroundColor: '#254E58',
                                                                color: 'white',
                                                            }),on:{"click":function($event){return _vm.saveDocdrop(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" save ")])],1)],1)],1)],1)],2)],1)],1)],1):_c('div',[_c('v-row',{staticClass:"mt-2 mb-2 ml-3"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_vm._v(" "+_vm._s(item.type)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
                                                            var item = ref.item;
return [_c('v-btn',{style:({
                                    backgroundColor: '#9E3B1B',
                                    color: 'white',
                                }),attrs:{"disabled":_vm.disabledDelete},on:{"click":function($event){return _vm.openItemTable(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],_c('div',{staticClass:"pl-2 pr-2 pt-6 pb-1 elevation-1"},[[_c('v-row',[_c('v-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{ref:"myDocTitle",attrs:{"dense":"","outlined":"","label":"Title"},model:{value:(_vm.doc_title),callback:function ($$v) {_vm.doc_title=$$v},expression:"doc_title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{ref:"myType",attrs:{"items":_vm.type_list,"label":"Type","dense":"","outlined":""},on:{"change":function($event){return _vm.showTypeDropdown()}},model:{value:(_vm.documnet_type),callback:function ($$v) {_vm.documnet_type=$$v},expression:"documnet_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"ml-2",style:({
                                        backgroundColor: '#254E58',
                                        color: 'white',
                                    }),on:{"click":function($event){return _vm.saveItemAdd()}}},[_c('span',[_vm._v("Save")])])],1)],1),_vm._l((_vm.dropdownlist),function(item,index){return (_vm.showdropdown)?_c('v-row',{key:index,staticClass:"ml-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","offset":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Drowdown item"},model:{value:(_vm.dropdownlist[index].text),callback:function ($$v) {_vm.$set(_vm.dropdownlist[index], "text", $$v)},expression:"dropdownlist[index].text"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(index == 0)?_c('v-btn',{style:({
                                                backgroundColor: '#a7c4ae',
                                                color: 'green',
                                            }),attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.inputapprover}},[_vm._v("mdi-plus")])],1):_c('v-btn',{style:({
                                                backgroundColor: '#FFEBEE',
                                                color: '#F44336',
                                            }),attrs:{"icon":""},on:{"click":function($event){return _vm.deletedropdown(index)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)],1):_vm._e()})]],2)],_c('br'),_c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-span',[_c('h5',[_vm._v("Setup Department Authorization")])])],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{ref:"myDep",attrs:{"outlined":"","dense":"","items":_vm.depart_list,"item-text":"department_name","item-value":"id","multiple":""},model:{value:(_vm.authori_depart),callback:function ($$v) {_vm.authori_depart=$$v},expression:"authori_depart"}})],1)],1)],1),_c('br'),_c('br'),_c('div',[_c('v-row',[_c('v-col',{attrs:{"align":"center","cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mr-5",style:({
                                backgroundColor: '#254E58',
                                color: 'white',
                            }),attrs:{"depressed":"","width":"100"},on:{"click":function($event){return _vm.saveDepartAu()}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mr-5 buttonred",attrs:{"depressed":"","width":"100","disabled":_vm.disabledDelete},on:{"click":function($event){return _vm.openDeleteAll()}}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"depressed":"","outlined":"","width":"100","to":"/document-setup"}},[_vm._v(" Cance ")])],1)],1)],1)],2),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemTable}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDeleteAll),callback:function ($$v) {_vm.dialogDeleteAll=$$v},expression:"dialogDeleteAll"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeleteAll}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemTableAll}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }