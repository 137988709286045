//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
import {
    tolocalestringnumber,
    stringcommatonumber,
  } from "../computing/tolocalestringnumber";
export default {
  async mounted() {
    // this.$showLoader();
    await api.checkVersion();
    this.mycompany_id = localStorage.getItem(server.COMPANYID);
    await this.loadDepartmentByCompanyId();
    await this.loadCustomer();
    await this.loadEmp();
    await this.loadTableName();
    this.$hideLoader();
  },
  components: {
    SuccessDialog,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.datasearch.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.datasearch.dateto);
    },
  },
  data() {
    return {
      showsearchsum: false,
      employeeisdisable:false,
      exportleavereport: "leave_report.xlsx",
      action_list: [
          'Create',
          'Update',
          'Delete',
          'Import',
          'Export',
      ],
      sheets: [
        {
          name: "InvioceReport",
          data: [],
        },
      ],
      search:"",
      headers:[
      {
        text: "Treansaction ID",
        value: "id",
        class: "bg-colorth tabletextwhite",
        align: "end",
        width: "140px"
      },
        {
          text: "User Name",
          value: "username",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Name",
          value: "name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Action",
          value: "action",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Table Name",
          value: "table_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Table ID",
          value: "table_id",
          class: "bg-colorth tabletextwhite",
          width: "200px"
        },
        {
          text: "Date",
          value: "date",
          class: "bg-colorth tabletextwhite",
          align: "center"
        },
        {
          text: "Login ID",
          value: "log_in_out_id",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
      ],
      mDataArray:[],
      dataAll:[],
      sum_show_table: [], 
      showchargetype: "",
      checkdepartmentname: "",
      lengthAlldatasearch: 0,
      menudateto: false,
      menudatefrom: false,
      mycompany_id: 0,
      datasearch: {    
        user_id: 0,
        table_name: "",
        action: "",
        datefrom: null,
        dateto: null,
      },
      dateCurrent: this.formatDateday(new Date().toISOString().substr(0, 10)),
      getnamesignature: [],
      prpoheader: [],
      memoref_list: [],
      no_list: [],
      mysignature: "",
      approvesignature: "",
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      getheightcontentpr: 0,
      pageAll: 1,
      previewattachimage: "",
      
      table_list: {},
      department_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      isShowPassword: false,
      fullPage: true,
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      closeDelete: false,
      deleteItemConfirm: false,
      emp_list: [],
      jobtitle_list: [],
      customer_list: [],
      dataformatpr: {
        title: "",
        subtitle: "Invoice Report",
        dateto: "",
        datefrom: ""
      },
    };
  },
  methods: {
      async loadTableName(){
          const res_tb_name = await api.getTransectionLogByTable();
          this.table_list = res_tb_name.data;

      },
      async loadEmp() {
          const result = await api.getAccountslist_by_ComID(
              localStorage.getItem(server.COMPANYID)
          );

          this.emp_list = result.data;
      },
    onCreated(wb) {
      // console.log(wb);
    },
    onChange(wb) {
      // console.log(wb);
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
      wb.Sheets.leavereport.A1 = {
        t: "s",
        v: `Export Leave Report as of   ${this.dateCurrent}`,
      };
      wb.Sheets.leavereport.B1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.C1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.D1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.E1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.F1 = { t: "s", v: "" };
      wb.Sheets.leavereport.G1 = { t: "s", v: "" };
      wb.Sheets.leavereport.H1 = { t: "s", v: "" };
      wb.Sheets.leavereport.I1 = { t: "s", v: "" };
      wb.Sheets.leavereport.J1 = { t: "s", v: "" };
      wb.Sheets.leavereport.K1 = { t: "s", v: "" };
      // wb.Sheets.leavereport.L1 = { t: "s", v: "" };
      // wb.Sheets.leavereport.M1 = { t: "s", v: "" };
      // wb.Sheets.leavereport.N1 = { t: "s", v: "" };
      // wb.Sheets.leavereport.O1 = { t: "s", v: "" };
      // wb.Sheets.leavereport.P1 = { t: "s", v: "" };

      wb.Sheets.leavereport.A3 = { t: "s", v: "Employee" };
      wb.Sheets.leavereport.B3 = { t: "s", v: "Leave Type" };
      wb.Sheets.leavereport.C3 = { t: "s", v: "date time from" };
      wb.Sheets.leavereport.D3 = { t: "s", v: "date time to" };
      wb.Sheets.leavereport.E3 = { t: "s", v: "" };
      wb.Sheets.leavereport.F3 = { t: "s", v: "Description" };
      // wb.Sheets.leavereport.I3 = { t: "s", v: "report" };
      // wb.Sheets.leavereport.J3 = { t: "s", v: "payment Request" };
      // wb.Sheets.leavereport.K3 = { t: "s", v: "Cert" };
      // wb.Sheets.leavereport.L3 = { t: "s", v: "Done" };
      // wb.Sheets.leavereport.M3 = { t: "s", v: "Next90" };
      // wb.Sheets.leavereport.N3 = { t: "s", v: "Next60" };

      wb.Sheets.leavereport["!merges"] = merge;
    },
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(this.mycompany_id);
      this.department_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany(
        {
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      this.customer_list = result.data;
      console.log(this.customer_list)
    },

    findChaege(chargetype) {
      this.charge_list.filter((item, index) => {
        if (item.value == chargetype) {
          this.showchargetype = item.name;
          return;
        }
      });
    },

    async SearchAlldata() {
      this.datashowAll = [];
      this.lengthAlldatasearch = 0;
      this.dataAll = [];
      this.mDataArray = [];

      console.log("this.datasearch")
      console.log(this.datasearch)
      
      if (
          !this.datasearch.datefrom && 
          !this.datasearch.dateto && 
          this.datasearch.user_id == 0 &&
          this.datasearch.table_name == "" &&
          this.datasearch.action == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "กรุณาเลือกข้อมูล เงือนไขการค้นหา!!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.datasearch.datefrom && !this.datasearch.dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "กรุณาเลือก DateTo!!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.datasearch.dateto && !this.datasearch.datefrom) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "กรุณาเลือก DateFrom!!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      if(this.datasearch.dateto && this.datasearch.datefrom){
          if (this.datasearch.dateto < this.datasearch.datefrom) {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "ค้นหาข้อมูล!!!",
                  "กรุณาเลือก DateTo >= DateFrom !!!",
                  "text-h5 red--text text-center"
              );
              return;
          }
      }
      this.$showLoader();
      var res_search = await api.getTransectionLogBySearch(localStorage.getItem(server.COMPANYID),{params: this.datasearch});
      let copany_datat = await api.getCompanyByID(localStorage.getItem(server.COMPANYID))

      console.log("res_search.data")
      console.log(res_search.data)
      if (res_search.data.length > 0) {
        this.dataAll = res_search.data
        this.mDataArray = this.dataAll

        await this.setexporttoxlsx();

        this.dataformatpr.title = copany_datat.data[0].name;
        this.dataformatpr.datefrom = unity.formatDate(this.datasearch.datefrom);
        this.dataformatpr.dateto = unity.formatDate(this.datasearch.dateto) ;

        this.dataforshowprpo = res_search.data;
        await this.checkcontent();

        this.$hideLoader();
      }else{
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล!!!",
          "ไม่มีข้อมูล!!!",
          "text-h5 red--text text-center"
        );
        return;
      }

     
    },
  async setexporttoxlsx() {
    let setexportxlsx = [{}, {}];
    this.mDataArray.forEach((x, index) => {
      setexportxlsx.splice(index + 2, 0, {
        id: x.id,
        username: x.username,
        name: x.name,
        action: x.action,
        table_name: x.table_name,
        table_id: x.table_id,
        date: x.date,
        log_in_out_id: x.log_in_out_id,
      });
    });
    this.sheets[0].data = setexportxlsx;
  },
    formatDatetomonthdayyear(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    },
    formatDateday(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async checkcontent() {
      
      let totalclient = 0;
      this.setdatatoshow = [];
      let linedetailprpo = 40;
      let datainlineprpo = 27;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;

      console.log("this.dataforshowprpo")
      console.log(this.dataforshowprpo)
      for (let i = 0; i < this.dataforshowprpo.length; i++) {



        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก

        newhllinedetails = 0;
        getnewdata = [];

        this.dataforshowprpo[i].no = i + 1;

        let stringchecklength = "";

        if(this.dataforshowprpo[i].name != null){
          let lengthofdataforshowprpo = this.dataforshowprpo[i].name.length;
          stringchecklength = this.dataforshowprpo[i].name;

          for (let j = 0; j < this.dataforshowprpo[i].name.length; j++) {
            if (
              stringchecklength[j] == maiek ||
              stringchecklength[j] == maito ||
              stringchecklength[j] == maitree ||
              stringchecklength[j] == maijattawa ||
              stringchecklength[j] == vowelei ||
              stringchecklength[j] == vowelee ||
              stringchecklength[j] == voweleue ||
              stringchecklength[j] == voweleu ||
              stringchecklength[j] == vowelu ||
              stringchecklength[j] == voweluu ||
              stringchecklength[j] == maihun ||
              stringchecklength[j] == maiyamok
            ) {
              lengthofdataforshowprpo--;
            }
          }

          newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
          console.log(newhllinedetails)
          getnewdata.push(this.dataforshowprpo[i]);

          if (hllinedetails + newhllinedetails > linedetailprpo || this.dataforshowprpo[i].changedepartment == true) {
            console.log(i + " ============== " + this.dataforshowprpo.length);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            this.pageAll++;

            hllinedetails = 0;
            getdata = [];
            getdata.push(this.dataforshowprpo[i]);
            hllinedetails += newhllinedetails;
            if (i == this.dataforshowprpo.length - 1) {
              this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          } else {
            hllinedetails += newhllinedetails;

            getdata.push(this.dataforshowprpo[i]);

            if (i == this.dataforshowprpo.length - 1) {
              this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          }
        }else{
          if(this.dataforshowprpo[i].table_name == null){
            this.dataforshowprpo[i].table_name = "-";
          }
          let lengthofdataforshowprpo = this.dataforshowprpo[i].table_name.length;
          stringchecklength = this.dataforshowprpo[i].table_name;

          for (let j = 0; j < this.dataforshowprpo[i].table_name.length; j++) {
            if (
              stringchecklength[j] == maiek ||
              stringchecklength[j] == maito ||
              stringchecklength[j] == maitree ||
              stringchecklength[j] == maijattawa ||
              stringchecklength[j] == vowelei ||
              stringchecklength[j] == vowelee ||
              stringchecklength[j] == voweleue ||
              stringchecklength[j] == voweleu ||
              stringchecklength[j] == vowelu ||
              stringchecklength[j] == voweluu ||
              stringchecklength[j] == maihun ||
              stringchecklength[j] == maiyamok
            ) {
              lengthofdataforshowprpo--;
            }
          }

          newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

          getnewdata.push(this.dataforshowprpo[i]);

          if (hllinedetails + newhllinedetails > linedetailprpo || this.dataforshowprpo[i].changedepartment == true) {
            console.log(i + " ============== " + this.dataforshowprpo.length);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            this.pageAll++;

            hllinedetails = 0;
            getdata = [];
            getdata.push(this.dataforshowprpo[i]);
            hllinedetails += newhllinedetails;
            if (i == this.dataforshowprpo.length - 1) {
              this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          } else {
            hllinedetails += newhllinedetails;

            getdata.push(this.dataforshowprpo[i]);

            if (i == this.dataforshowprpo.length - 1) {
              this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          }
        }
      }

      this.pageAll += this.lengthAlldatasearch - 1;
      this.opensummarize = true;

      this.setdatatoshow.forEach((item, iitem) => {

        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      console.log("this.datashowAll")
      console.log(this.datashowAll)

    },

    async exportToPDF(advance_id) {
      this.$showLoader();
      // this.$refs.html2Pdf.generatePdf();
      let element = document.getElementById("mydivhtmltobase");

      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          // var filtered = pdf.internal.pages.filter(function(el) {
          //   return el != null;
          // });

          // pdf.internal.pages = filtered;
          // for (let i = 0; i < totalPages; i++) {
          //   if (i == totalPages - 1) {
          //     pdf.internal.pages.splice(i, 1);
          //   }
          // }
          // pdf.addPage();

          // console.log(pdf);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      // console.log(blob);
      // let formData = new FormData();
      // formData.append(`file`, blob, "test.pdf");
      // const resultadd = api.creatememoMaster(formData);
      // console.log(resultadd);

      // };
      this.$hideLoader();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
