//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";

export default {
  name: "stock-create",
  data: () => ({
    product: {
      name: "",
      price: "",
      stock: "",
      image: null,
    },
    imageFile: null,
  }),
  async mounted() {
    // this.$showLoader();
    this.$hideLoader();

    let result = await api.getProductById(this.$route.params.id);
    this.product = result.data;
  },

  methods: {
    cancel() {
      this.$router.back();
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // for preview
        this.product.image = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      // for upload
      this.imageFile = event.target.files[0];
    },
    async submit() {
      let formData = new FormData();
      const { name, price, stock, id } = this.product;
      formData.append("id", id);
      formData.append("name", name);
      formData.append("stock", stock);
      formData.append("price", price);
      if (this.imageFile != null) {
        formData.append("image", this.imageFile);
      }

      await api.updateProduct(formData);
      this.$router.back();
    },
    getProductImage() {
      if (this.product.image.length > 100) {
        return this.product.image;
      } else {
        return this.$options.filters.imageUrl(this.product.image);
        // return `${imageUrl}/${this.product.image}`;
      }
    },
  },
};
