export const formatDate = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
};

export const parseDate = (date) => {
  if (!date) return null;
  const [day,month, year] = date.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};
