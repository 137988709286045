import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDoctypeTemplateByID = (doctype_id) => {
    return httpClient.get(server.DOCTYPE_TEMPLATE + `/bydoctype_id/${doctype_id}`);
};

export const CreateDoctypeTemplate = (data) => {
    return httpClient.post(server.DOCTYPE_TEMPLATE, data);
};

export const UpdateDoctypeTemplate = (id, data) => {
    return httpClient.put(server.DOCTYPE_TEMPLATE +`/${id}`,data);
};

export const DeleteDoctypeTemplate = (doctemplate_id) => {
    return httpClient.delete(server.DOCTYPE_TEMPLATE +`/${doctemplate_id}`);
};
