import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getTRANNING_D = () => {
  return httpClient.get(server.TRANNING_D);
};

export const getTRANNING_DById = th_id => {
    return httpClient.get(server.TRANNING_D + `/${th_id}`);
};

export const getTRANNING_DByIdType = (th_id,type) => {
    return httpClient.get(server.TRANNING_D + `/type/${th_id}/${type}`);
};
  
export const addTRANNING_D = data => {
    return httpClient.post(server.TRANNING_D, data);
};
  
export const updateTRANNING_D = (id,data) => {
    return httpClient.put(server.TRANNING_D + `/${id}`, data);
};

export const deleteTRANNING_D = id => {
    return httpClient.delete(server.TRANNING_D + `/${id}`);
};

