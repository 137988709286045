//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SuccessDialog",
  props: ["status", "title", "message", "text_color"],
  beforeDestroy() {
    console.log("SuccessDialog beforeDestroy");
    this.$store.commit("set_global_dialog", false);
    this.$store.state.global_dialog_invoice_mis = false;
  },
};
