import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getdetailSalesOrder = () => {
    return httpClient.get(server.SALES_ORDER_D + `/get`);
};

export const getAlldetailSOById = (id) => {
    return httpClient.get(server.SALES_ORDER_D + `/getAllById/${id}`);
};

export const addDetailSO = (data) => {
    return httpClient.post(server.SALES_ORDER_D, data);
};

export const deletedetailSOById = (id) => {
    return httpClient.delete(server.SALES_ORDER_D + `/${id}`);
};