//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SuccessDialog",
  props: ["status", "title", "message", "text_color", "link"],
  beforeDestroy() {
    // console.log("SuccessDialog beforeDestroy");
    // this.$store.commit("set_global_dialog", false);
    // this.$store.state.global_dialog = false;
  },
  methods: {
    closeDialogSendmail(tlink) {
      this.$store.commit("set_global_dialog", false);
      this.$store.state.global_push_dialog = false;
      if (tlink === "/reloadpage") {
        this.$router.go();
      } else {
        this.$router.push(`${tlink}`);
      }
      // this.$router.push(`${tlink}`);
      //"/advance-list"
    },
  },
};
