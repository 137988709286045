import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDataPCDByPchID = (pch_id) => {
  return httpClient.get(server.API_PETTYCASH_D + `/getby-pch_id/${pch_id}`);
};
export const createPCD = (data) => {
    return httpClient.post(server.API_PETTYCASH_D + `/create`, data);
};
export const updatePCD = (id,data) => {
    return httpClient.put(server.API_PETTYCASH_D + `/update/${id}`, data);
};

export const deletePCD = (id) => {
    return httpClient.delete(server.API_PETTYCASH_D + `/delete/${id}`);
};