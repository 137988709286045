import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getOCRKW = () => {
    return httpClient.get(server.OCRKW + `/`);
};
export const getOCRKWList = () => {
    return httpClient.get(server.OCRKW + `/gatorckwlist`);
};
export const getOCRKWListCon = (doctype_id,notice_id) => {
    return httpClient.get(server.OCRKW + `/gatorckwlistcon/${doctype_id}/${notice_id}`);
};
export const createOCRKW = (data) => {
    return httpClient.post(server.OCRKW + `/create`, data);
};

export const updateOCRKW = (id, data) => {
    return httpClient.put(server.OCRKW + `/${id}`, data);
};
export const deleteOCRKW = (id) => {
    return httpClient.delete(server.OCRKW + `/delete/${id}`);
};

