import httpClient,{apiSMEMATE} from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDepartmentByCompanyID = (id) => {
  return httpClient.get(server.MASTER_DEPARTMENT + `/${id}`);
};

export const getDepartmentByID = (id) => {
  return httpClient.get(server.MASTER_DEPARTMENT + `/getAllByID/${id}`);
};

export const getAllDepartment = (id) => {
  return httpClient.get(server.MASTER_DEPARTMENT + `/get/All`);
};

export const getAllDepartment_By_CompanyID = (id) => {
  return httpClient.get(
    server.MASTER_DEPARTMENT + `/get/All_By_CompanyID/${id}`
  );
};

export const addDepartment = (data) => {
  return httpClient.post(server.MASTER_DEPARTMENT + `/`, data);
};

export const updateDepartment = (id, data) => {
  return httpClient.put(server.MASTER_DEPARTMENT + `/${id}`, data);
};

export const addDepartmentSME = (data) => {
  return apiSMEMATE.post(server.MASTER_DEPARTMENT + `/add`, data);
};

export const updateDepartmentSME = (id, data) => {
  return apiSMEMATE.put(server.MASTER_DEPARTMENT + `/${id}`, data);
};
