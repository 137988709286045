import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDocTransHList = () => {
    return httpClient.get(server.DOCTRANS_H);
};
export const getDocTransHListByID = (id) => {
    return httpClient.get(server.DOCTRANS_H + `/${id}`);
};
export const UpdateDoctranH = (id, data) => {
    return httpClient.put(server.DOCTRANS_H +`/${id}`,data);
};
export const UpdateDoctranHData = (id, data) => {
    return httpClient.put(server.DOCTRANS_H +`/data/${id}`,data);
};
export const createDocTransHList = (data) => {
    return httpClient.post(server.DOCTRANS_H,data);
}