import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getLeaveTypeAll = (company_id) => {
    return httpClient.get(server.LEAVE_TYPE + `/bycompany/${company_id}`);
  };
  export const createLeaveType = (data) => {
      return httpClient.post(server.LEAVE_TYPE + `/create`, data);
  };
  export const updateLeaveType = (id,data) => {
      return httpClient.put(server.LEAVE_TYPE + `/update/${id}`, data);
  };
  
  export const deleteLeaveType = (id) => {
      return httpClient.delete(server.LEAVE_TYPE + `/delete/${id}`);
  };