var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Immigration Master Work Place of issue List")])],1),_c('v-col',{attrs:{"align":"right","color":"primary","cols":"12","md":"6"}},[(_vm.authorize_add)?_c('v-btn',{staticClass:"mb-2",attrs:{"to":"/im-master-workplaceofissue-list-add/Add/0","color":"#3F7652","rounded":"","dark":""}},[_c('span',[_vm._v("Add")]),_c('v-icon',{attrs:{"small":"","center":""}},[_vm._v("add")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"widthsizesearch",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"6"}})],1),_c('br'),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":"","tile":""}},[_c('v-data-table',{staticClass:"elevation-1   595959",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.mDataArray},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{style:({
                            backgroundColor: '#E1F5FE',
                            color: '#254E58',
                        }),on:{"click":function($event){return _vm.openeditItem(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.authorize_del)?_c('v-icon',{staticClass:"ml-5",style:({
                            backgroundColor: '#FFEBEE',
                            color: '#9E3B1B',
                        }),on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }