import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getTRANNING_H = () => {
  return httpClient.get(server.TRANNING_H);
};
export const getTRANNING_HSQL = () => {
  return httpClient.get(server.TRANNING_H + `/sql`);
};


export const getTRANNING_HById = id => {
    return httpClient.get(server.TRANNING_H + `/${id}`);
};

export const getTRANNING_HByTranningName = tranning_name => {
    return httpClient.get(server.TRANNING_H + `/check/${tranning_name}`);
};
  
export const addTRANNING_H = data => {
    return httpClient.post(server.TRANNING_H, data);
};
  
export const updateTRANNING_H = (id,data) => {
    return httpClient.put(server.TRANNING_H + `/${id}`, data);
};

export const deleteTRANNING_H = id => {
    return httpClient.delete(server.TRANNING_H + `/${id}`);
};

