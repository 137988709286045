var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[[_c('v-row',[_c('h3',[_vm._v("Additional Top")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"width":"100%","align":"center","justify":"center","id":"doceditor"}},[_c('editor',{attrs:{"disabled":_vm.datainv_disable,"id":"uuid","api-key":_vm.apiKeyTiny,"output-format":"html","plugins":"lists code","model-events":"change keydown blur focus paste","init":{
                auto_focus: true,
                automatic_uploads: false,
                images_upload_url: '/api/upload-image',
                width: '250mm',
                height: 700,
                selector: 'textarea#exporteditor',
                auto_focus: 'elm1',
                plugins:
                  'pagebreak code lists link image table code help wordcount',
                toolbar:
                  'undo redo | image | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help |   ',
                content_style: 'body { width:238mm;height:297mm;};',
                contextmenu: false,
                // setup: (editor) => {
                //   editor.on('init', function(e) {
                //     editor.selection.select(editor.getBody(), true);
                //     editor.selection.collapse(false);
                //     editor.focus();
                //   });
                //   editor.on('GetContent', function(e) {
                //     editor.execCommand('mceFocus');
                //     tohtml();
                //   });

                //   editor.on('change', function(e) {
                //     editor.execCommand('mceFocus');
                //     tohtml();
                //   });
                //   editor.on('keyup', function(e) {
                //     editor.execCommand('mceFocus');
                //     tohtml();
                //   });
                //   editor.on('keydown', function(e) {
                //     editor.execCommand('mceFocus');
                //     tohtml();
                //   });
                // },
              }},on:{"getContent":_vm.tohtml},model:{value:(_vm.additional_top),callback:function ($$v) {_vm.additional_top=$$v},expression:"additional_top"}})],1)],1)],1),_c('v-row',[_c('h3',[_vm._v("Additional Bottom")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"width":"100%","align":"center","justify":"center","id":"doceditorbottom"}},[_c('editor',{attrs:{"disabled":_vm.datainv_disable,"id":"uuidbottom","api-key":_vm.apiKeyTiny,"output-format":"html","plugins":"lists code","model-events":"change keydown blur focus paste","init":{
                automatic_uploads: false,
                images_upload_url: '/api/upload-image',
                width: '250mm',
                height: 700,
                selector: 'textarea#exporteditor',
                plugins:
                  'pagebreak code lists link image table code help wordcount',
                toolbar:
                  'undo redo | image | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help |   ',
                content_style: 'body { width:238mm;height:297mm;};',
                contextmenu: false,
              }},on:{"selectionChange":_vm.tohtml},model:{value:(_vm.additional_bottom),callback:function ($$v) {_vm.additional_bottom=$$v},expression:"additional_bottom"}})],1)],1)],1),_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#3F7652","disabled":_vm.datainv_disable,"rounded":""},on:{"click":function($event){return _vm.tohtml()}}},[_vm._v(" Save Data ")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }