//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "./language/Advancelist.js";
import { tolocalestringnumber } from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "advance-list",
  data(vm) {
    return {
      search_status: "",
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: [
        "Draft",
        "Request",
        "Approve",
        "Reject",
        "Cancel",
        "Paid",
        "Receive",
        "Clearing",
        "Close",
      ],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      // mDataArray: [
      //   {
      //     no: 1,
      //     date: "1/11/2022",
      //     name: "testname",
      //     customer: "ABS co Ltd",
      //     amount: "10,000",
      //     status: "Unapproved",
      //   },
      //   {
      //     no: 2,
      //     date: "2/11/2022",
      //     name: "testname2",
      //     customer: "ABS co Ltd",
      //     amount: "3,000",
      //     status: "Approved",
      //   },
      // ],
      headers: [],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);
    // await this.loadCompanyMaster(comp_id);
    // await this.loadDepartmentMaster(comp_id);
    // await this.loadPositionMaster(comp_id);

    await this.loadAuthorize();
    await this.loaddataAdvance();

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },
  methods: {
    editItem() {},
    changelange: function(newVal, oldVal) {
      if (this.defaultlanguage === "en") {
        console.log(language.en.headertable);
        this.headers = language.en.headertable;

        // this.headers[0].text = language.en.headertable.no;

        this.setlanguage = Object.assign({}, language.en);
      } else {
        this.headers = language.th.headertable;
        this.setlanguage = Object.assign({}, language.th);
      }
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.search_status == "") {
          if (this.datefrom != null && this.dateto != null) {
            if (item.date >= this.datefrom && item.date <= this.dateto) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            return item;
          }
        } else {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.date >= this.datefrom &&
              item.date <= this.dateto &&
              item.status == this.search_status
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (item.status == this.search_status) {
              return item;
            }
          }
        }
      });
    },
    findChaege() {
      // alert(this.search_status)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom == null || this.dateto == null) {
          if (this.search_status != null) {
            if (item.status == this.search_status) {
              return item;
            }
          } else {
            return item;
          }
        }
        if (this.datefrom != null && this.dateto != null) {
          if (this.search_status != null) {
            if (
              item.status == this.search_status &&
              item.date >= this.datefrom &&
              item.date <= this.dateto
            ) {
              return item;
            }
          } else {
            if (item.date >= this.datefrom && item.date <= this.dateto) {
              return item;
            }
          }
        }
      });
    },
    onClicktoaddadvance(id) {
      this.$router.push(`/edit-advance/${id}/edit`);
    },

    async loadCompanyMaster(id) {
      const result = await api.getCompanyMaster(id);
      this.company = result.data;
    },

    async loadDepartmentMaster(id) {
      const result = await api.getDepartmentMaster(id);
      // console.log("result.data : ", JSON.stringify(result.data));
      this.department = result.data;
      // console.log('test dapart')
      // console.log(this.department)
      result.data.forEach((item) => {
        this.department_list.push(item.name);
      });
    },
    async loadAuthorize() {
      const res_get = await api.getSettingGroupMenu();
      // console.log("res loadAuthorize : ", JSON.stringify(res_get));
      this.authorize = res_get.data;
    },

    async loadPositionMaster(id) {
      const result = await api.getPositionMaster(id);
      // console.log("result.data : ", JSON.stringify(result.data));
      this.position = result.data;

      result.data.forEach((item) => {
        this.position_list.push(item.name);
      });
    },

    async loadDetailAccount(id) {
      // console.log("loadDetailAccount:", id + "//");
      const result = await api.getAccountDetail(id);
      // console.log("loadDetailAccount result: ", JSON.stringify(result));
      this.editedItem = Object.assign({}, result.data[0]);
    },
    async loaddataAdvance() {
      const result = await api.getRequestHBycompany(
        localStorage.getItem(server.USER_ID),
        {
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      this.dataAll = result.data;
      this.dataAll.forEach((x, index) => {
        x.amount = tolocalestringnumber(x.amount);

        x.status_id = x.status;
        if (x.status == 1) {
          x.status = "Draft";
        } else if (x.status == 2) {
          x.status = "Request";
        } else if (x.status == 3) {
          x.status = "Approve";
        } else if (x.status == 4) {
          x.status = "Reject";
        } else if (x.status == 5) {
          x.status = "Cancel";
        } else if (x.status == 6) {
          x.status = "Paid";
        } else if (x.status == 7) {
          x.status = "Receive";
        } else if (x.status == 8) {
          x.status = "Clearing";
        } else if (x.status == 9) {
          x.status = "Close";
        } else if (x.status == 10) {
          x.status = "Invioce";
        } else {
          x.status = "-";
        }
      });
      this.mDataArray = this.dataAll;

      // //console.log(this.approver)
      // result.data.forEach((item) => {
      //   this.approver.push(item.firstname);
      // });

      // this.$showLoader();
      this.$hideLoader();
    },
  },
};
