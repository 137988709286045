import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDataReceiveD = () => {
    return httpClient.get(server.TRC_D + `/get`);
};
export const getDataReceiveDByID = (id) => {
    return httpClient.get(server.TRC_D + `/getbyid/${id}`);
};
export const getDataReceiveDByIDOne = (id) => {
    return httpClient.get(server.TRC_D + `/getbyidone/${id}`);
};
export const getDataPrint = (id) => {
    return httpClient.get(server.TRC_D + `/getdataprint/${id}`);
};
export const CreateDataReceiveD = (data) => {
    return httpClient.post(server.TRC_D + `/`, data);
};

export const deleteReceiveD = (id) => {
    return httpClient.delete(server.TRC_D + `/${id}`);
  };