var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',[_c('v-container',{staticClass:"pa-12"},[_c('v-card',{staticClass:"pl-16 pr-16 pt-8 pb-16"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","justify":"center"}},[_c('h3',[_vm._v("New Document")])])],1)],1),_c('v-row',{staticClass:"row1 mt-3  pt-3 "},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Document Type","background-color":"#F5F5F5","disabled":""}})],1)],1),_c('br'),[[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'Dropdown')?_c('div',[_c('v-expansion-panels',{staticClass:"mb-2 mt-2",attrs:{"accordion":""},model:{value:(item.ShowDrop),callback:function ($$v) {_vm.$set(item, "ShowDrop", $$v)},expression:"item.ShowDrop"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(item.type))]),_c('v-expansion-panel-content',{staticClass:"mt-1"},_vm._l((item.datadropdown),function(data,index){return (item.datadropdown.length > 0)?_c('v-row',{key:index,staticClass:"ml-2"},[_c('v-row',[_c('v-col',{staticClass:"mt-2 mb-2",attrs:{"cols":"12","sm":"10"}},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(item.datadropdown[index].text)+" ")])],1)],1):_vm._e()}),1)],1)],1)],1):_c('div',[_c('v-row',{staticClass:"mt-2 mb-2 ml-3"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_vm._v(" "+_vm._s(item.type)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",style:({
                                    backgroundColor: '#254E58',
                                    color: 'white',
                                }),on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" save ")])],1),_c('v-btn',{style:({
                                    backgroundColor: '#9E3B1B',
                                    color: 'white',
                                }),on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],(_vm.showAdd)?_c('div',{staticClass:"pl-2 pr-2 pt-6 pb-1 elevation-1"},[[_c('v-row',[_c('v-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.type_list,"label":"Type","dense":"","outlined":""},on:{"change":function($event){return _vm.showTypeDropdown()}},model:{value:(_vm.documnet_type),callback:function ($$v) {_vm.documnet_type=$$v},expression:"documnet_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"ml-2",style:({
                                        backgroundColor: '#254E58',
                                        color: 'white',
                                    })},[_c('span',[_vm._v("Save")])])],1)],1),_vm._l((_vm.dropdownlist),function(item,index){return (_vm.showdropdown)?_c('v-row',{key:index,staticClass:"ml-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","offset":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Drowdown item"},model:{value:(_vm.dropdownlist[index].text),callback:function ($$v) {_vm.$set(_vm.dropdownlist[index], "text", $$v)},expression:"dropdownlist[index].text"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(index == 0)?_c('v-btn',{style:({
                                                backgroundColor: '#a7c4ae',
                                                color: 'green',
                                            }),attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.inputapprover}},[_vm._v("mdi-plus")])],1):_c('v-btn',{style:({
                                                backgroundColor: '#FFEBEE',
                                                color: '#F44336',
                                            }),attrs:{"icon":""},on:{"click":function($event){return _vm.deletedropdown(index)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)],1):_vm._e()})]],2):_vm._e()],_c('br'),_c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-span',[_c('h5',[_vm._v("Setup Department Authorization")])])],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{ref:"myDep",attrs:{"outlined":"","dense":"","items":_vm.depart_list,"item-text":"department_name","item-value":"id","multiple":"","disabled":""},model:{value:(_vm.authori_depart),callback:function ($$v) {_vm.authori_depart=$$v},expression:"authori_depart"}})],1)],1)],1),_c('br'),_c('br'),_c('div',[_c('v-row',[_c('v-col',{attrs:{"align":"center","cols":"12","md":"12"}},[_c('v-btn',{attrs:{"depressed":"","outlined":"","width":"100","to":"/document-setup"}},[_vm._v(" Cance ")])],1)],1)],1)],2),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }