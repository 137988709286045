import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdetailstimesheetD = (id) => {
  return httpClient.get(server.TIMESHEET_D_URL + `/get/${id}`);
};

export const printInvTS = (id) => {
  return httpClient.get(server.TIMESHEET_D_URL + `/print_inv_ts/${id}`);
};

export const getaddtimesheetD = (data) => {
  return httpClient.post(server.TIMESHEET_D_URL + `/`, data);
};

export const getAllBytimesheet_hid = (data) => {
  return httpClient.post(
    server.TIMESHEET_D_URL + `/detail_timesheet_hid`,
    data
  );
};
export const getAllBytimesheetCreeper_hid = (data) => {
  return httpClient.post(
    server.TIMESHEET_D_URL + `/detail_timesheetCreeper_hid`,
    data
  );
};

export const getupdatetimesheetD = (id, data) => {
  return httpClient.put(server.TIMESHEET_D_URL + `/${id}`, data);
};

export const getdeletetimesheetD = (id) => {
  return httpClient.delete(server.TIMESHEET_D_URL + `/${id}`);
};

export const getdeletebyTSIdtimesheetD = (id) => {
  return httpClient.delete(server.TIMESHEET_D_URL + `/timesheet_hid/${id}`);
};
