import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";


export const getDataEmailByEmailSQL = (email) => {
    return httpClient.get(server.TRANNING_EMAIL + `/email-sql/${email}`);
};
export const confirmemailRegister = (data) => {
    return httpClient.post(server.TRANNING_EMAIL + `/confirmemail`, data);
};
export const verifyemailRegister = (data) => {
    return httpClient.post(server.TRANNING_EMAIL + `/verifyemail`, data);
};
export const sendUserRegister = (data) => {
    return httpClient.post(server.TRANNING_EMAIL + `/send-email`, data);
};

export const UpdateTranningEmail = (id,data) => {
    return httpClient.put(server.TRANNING_EMAIL + `/${id}`,data);
};