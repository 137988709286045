import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdataByIdInv_Mis = (id) => {
  return httpClient.get(server.INVOICE_MISCELLANEOUS + `/getbyid/${id}`);
};

export const printInvMIS = (id) => {
  return httpClient.get(server.INVOICE_MISCELLANEOUS + `/print_inv_mis/${id}`);
};

export const addInv_Mis = (data) => {
  return httpClient.post(server.INVOICE_MISCELLANEOUS + `/`, data);
};

export const deleteInv_Mis = (id) => {
  return httpClient.delete(server.INVOICE_MISCELLANEOUS + `/${id}`);
};
