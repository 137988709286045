import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const addInv_Docref = (data) => {
  return httpClient.post(server.INVOICE_DOCREF + `/`, data);
};

export const getTs_hListId = (inv_hid) => {
  return httpClient.get(server.INVOICE_DOCREF + `/getTsId/${inv_hid}`);
};

export const printInvDocRef = (inv_hid) => {
  return httpClient.get(server.INVOICE_DOCREF + `/print_inv_docref/${inv_hid}`);
};

export const getRef_TypeSOById = (inv_hid) => {
    return httpClient.get(server.INVOICE_DOCREF + `/getSOId/${inv_hid}`);
};

export const getRef_TypeCNById = (inv_hid) => {
  return httpClient.get(server.INVOICE_DOCREF + `/getCNId/${inv_hid}`);
};

export const getRef_TypeADById = (inv_hid) => {
  return httpClient.get(server.INVOICE_DOCREF + `/getAdId/${inv_hid}`);
};

export const delete_docrefTS = (id) => {
    return httpClient.delete(server.INVOICE_DOCREF + `/docref_ts/${id}`);
};

export const delete_docrefAD = (id) => {
    return httpClient.delete(server.INVOICE_DOCREF + `/docref_ad/${id}`);
};

export const delete_docrefSO = (id) => {
    return httpClient.delete(server.INVOICE_DOCREF + `/docref_so/${id}`);
};

export const delete_docrefCN = (id) => {
  return httpClient.delete(server.INVOICE_DOCREF + `/docref_cn/${id}`);
};

