import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const createMasterPT = (data) => {
  return httpClient.post(server.MASTERPT, data);
};

export const createMasterPT_V2 = (data) => {
  return httpClient.post(server.MASTERPT + `/create_v2`, data);
};

export const getAllMasterPT = () => {
  return httpClient.get(server.MASTERPT + `/getAllSQL`);
};
export const printPT = (masterpt_id) => {
  return httpClient.get(server.MASTERPT + `/printpt/${masterpt_id}`);
};
export const getAllMasterPTLimit200 = () => {
  return httpClient.get(server.MASTERPT + `/getAllLimitSQL200`);
};

export const getByIdMasterPT = (id) => {
  return httpClient.get(server.MASTERPT + `/getById/${id}`);
};

export const getMasterPTFileNoSearch = (searchapplication_no) => {
  return httpClient.get(
    server.MASTERPT + `/getapplication_no/${searchapplication_no}`
  );
};

export const updateMasterPt = (masterpt_id, data) => {
  return httpClient.put(server.MASTERPT + `/${masterpt_id}`, data);
};

export const updateMasterPt_V2 = (masterpt_id, data) => {
  return httpClient.put(server.MASTERPT + `/update_v2/${masterpt_id}`, data);
};

export const getMasterPTSearch = (query) => {
  return httpClient.get(server.MASTERPT + `/searchdata`, query);
};

export const getfile_noPT = (searchfile_no) => {
  return httpClient.get(server.MASTERPT + `/getfile_no/${searchfile_no}`);
};
