import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getWorkPlaceofissue = () => {
    return httpClient.get(server.IM_MASTER_WORK_PLACE_OF_ISSUE);
};

export const getImMasterWorkPlaceofissueByCompany = (query) => {
    return httpClient.get(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/getbycompany`,query);
};

export const getImMasterWorkPlaceofissueByDep = (dep_id) => {
    return httpClient.get(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/get-by-dep_id/${dep_id}`);
};

export const getImMasterWorkPlaceofissueByID = (id) => {
    return httpClient.get(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/get-by-id/${id}`);
};

export const addImMasterWorkPlaceofissue = (data) => {
    return httpClient.post(server.IM_MASTER_WORK_PLACE_OF_ISSUE, data);
};

export const UpdateImMasterWorkPlaceofissue = (id, data) => {
    return httpClient.put(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/${id}`,data);
};

export const DeleteImMasterWorkPlaceofissue = (id) => {
    return httpClient.delete(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/${id}`);
};

export const getImMasterWorkPlaceofissueByCompany_Dep = (query) => {
    return httpClient.get(server.IM_MASTER_WORK_PLACE_OF_ISSUE +`/getbycompanyanddepartment`,query);
};