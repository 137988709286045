//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialogInvoice_Mis from "@/components/cards/SuccessDialogInvoice_Mis";
import SuccessDialog from "@/components/cards/SuccessDialog";
import invoiceTimeSheetTap_Selet from "./invoiceTimeSheetTap_Selet";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  props: [
    "disableinstatus",
    "ts_status_oper",
    "ts_idTab",
    "getdataByCusStatus",
    "selected_showE",
    "oper_sel",
    "cusid_freetext_ts",
    "cus_invts"
  ],
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
    invoiceTimeSheetTap_Selet,
    SuccessDialogInvoice_Mis,
  },
  data(vm) {
    return {
      dialogAddMis: false,
      text_colorMis: "text-h5 green--text text-center",
      titleMis: "green",
      messageMis: "green",
      isShowPassword: false,
      fullPage: true,
      toggleEnable: false,
      mDataArray: [],
      selected: [],

      search: "",
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      menu: false,
      menu2: false,
      datefrom: null,
      dateto: null,
      dialogConfirm: false,
      headers: [
        {
          text: "TimeSheet No.",

          class: "bg-colorth texttablehcenter ",
          value: "timesheet_id",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Requested Date",
          value: "date",
          class: "bg-colorth texttablehcenter",

          align: "start",
          divider: true,
          sortable: false,
          width: "105px",
        },

        {
          text: "TimeSheet Name",
          class: "bg-colorth texttablehcenter",
          value: "timesheet_name",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Customer",
          class: "bg-colorth texttablehcenter",
          value: "cus_name",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Employee",
          class: "bg-colorth texttablehcenter",
          value: "emp_name",
          divider: true,
          align: "start",
          sortable: false,
        },
        {
          text: "Total(Hours)",

          class: "bg-colorth texttablehcenter",
          value: "total_show",
          divider: true,
          align: "center",
          sortable: false,
        },

        {
          text: "Status",
          class: "bg-colorth texttablehcenter",
          align: "center",
          value: "status",
          divider: true,
          sortable: false,
        },
      ],
      mDataArrayC: [],
      dataAllC: [],
      timesheet_hid: [],
      hid_array: {},
      headersC: [
        {
          text: "Date",

          class: "bg-colorth texttablehcenter ",
          value: "date",
          align: "start",
          divider: true,
          sortable: false,
          width: "110px",
        },
        {
          text: "Job Title",
          value: "job_name",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          align: "start",
          divider: true,
          sortable: false,
        },

        {
          text: "Employee",
          class: "bg-colorth texttablehcenter",
          value: "emp_name",
          divider: true,
          align: "start",
          sortable: false,
          width: "20%",
        },
        {
          text: "Work Hour",
          value: "show_hours",
          class: "bg-colorth texttablehcenter",

          divider: true,
          align: "center",
          sortable: false,
          width: "140px",
        },
        {
          text: "Charge Hour",
          value: "show_chargehour",
          class: "bg-colorth texttablehcenter",

          divider: true,
          align: "center ",
          sortable: false,
          width: "140px",
        },

        {
          text: "Details",
          class: "bg-colorth texttablehcenter",
          align: "start",
          value: "detail",
          divider: true,
          sortable: false,
        },
      ],
      mDataArraytc: [],
      dataAlltc: [],
      headerstc: [
        {
          text: "Timekeepers",

          class: "bg-colorth texttablehcenter ",
          value: "tc_name",
          align: "start",
          divider: true,
          sortable: false,
          width: "23%",
        },
        {
          text: "Rate(Baht)",
          value: "tc_rate_show",
          class: "bg-colorth texttablehcenter",
          width: "16%",
          align: "end",
          divider: true,
          sortable: false,
        },

        {
          text: "Charge Hours",
          class: "bg-colorth texttablehcenter",
          value: "tc_actualchargehour_show",
          divider: true,
          align: "end",
          sortable: false,
          width: "15%",
        },
        {
          text: "Charge Value(Baht)",
          value: "tc_rateChargeHour_bath_show",
          class: "bg-colorth texttablehcenter",

          divider: true,
          align: "end",
          sortable: false,
          width: "16%",
        },
        {
          text: "No Charge Hours",
          value: "tc_nocharge_show",
          class: "bg-colorth texttablehcenter",

          divider: true,
          align: "end ",
          sortable: false,
          width: "15%",
        },

        {
          text: "No Charge Value (Baht)",
          class: "bg-colorth texttablehcenter",
          align: "center",
          value: "tc_nochargeCal_show",
          divider: true,
          sortable: false,
          width: "15%",
        },
      ],
      sum_chargehour: 0,
      sum_rateChargehour: 0,
      sum_noCharge_hour: 0,
      sum_noCharge_bath: 0,
      sum_grandTotal: 0,
      sum_chargehour_show: 0,
      sum_rateChargehour_show: 0,
      sum_noCharge_hour_show: 0,
      sum_noCharge_bath_show: 0,
      sum_grandTotal_show: 0,
      getlist_tsid: {},
      cusid_ts: 0,
      inv_ts_list: []
    };
  },
  async mounted() {
    await this.loaddataAdvance();
  },
  methods: {
    async Calculate(data){
      let rateCH = 0;
      rateCH = Number(data.tc_rate_show) * stringcommatonumber(data.tc_actualchargehour_show)
      data.tc_rate = data.tc_rate_show;
      data.tc_rateChargeHour_bath_show = tolocalestringnumber(rateCH);
      data.tc_rateChargeHour_bath = String(rateCH);

      let editedIndex = -1;
      editedIndex = this.mDataArraytc.indexOf(data);
      let data_set = [];
      let inx_dataset = 0;
      this.mDataArraytc.forEach((item,index)=>{
        if(editedIndex == index){
          data_set[inx_dataset] = data
        }else{
          data_set[inx_dataset] = item;
        }
        inx_dataset += 1;
      })
      this.mDataArraytc = data_set;
      let sumCH = 0 ;
      this.mDataArraytc.forEach((item,index)=>{
        sumCH += Number(item.tc_rateChargeHour_bath);
      })
      this.sum_rateChargehour_show = tolocalestringnumber(sumCH);
      this.sum_grandTotal_show = tolocalestringnumber(sumCH);
      this.sum_grandTotal = sumCH;
      this.inv_ts_list = this.mDataArraytc;
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    async loaddataAdvance() {
      this.$showLoader();
      const result = await api.getByemptimesheetHV2(this.cusid_freetext_ts);
      this.dataAll = result.data;
      this.dataAll.forEach((item, index, object) => {
          let hours = Math.floor(Number(item.total) / 60);
          let mins = Number(item.total) - hours * 60;
          item.total_show = hours + " ชั่วโมง " + mins + " นาที";
      });
      if (this.oper_sel == 1) {
          this.mDataArray = this.dataAll;
          this.oper_sel = 0;
      }else {
          for (let i = 0; i < this.selected_showE.length; i++) {
              this.dataAll.forEach((item, index) => {
              if (this.selected_showE[i].id == item.id) {
                  this.dataAll.splice(index, 1);
              }
              });
          }
          this.mDataArray = this.selected_showE.concat(this.dataAll);
          this.selected = this.selected_showE;
      }
      this.$hideLoader();
    },
    enterSelect(values) {
      // console.log(this.selected)
      // this.disableinstatus = false;
      //   // if (values.length > 0) {
      //   //   this.disableinstatus = false;
      //   // } else {
      //   //   this.disableinstatus = true;
      //   // }
    },
    async closedialogConfirm() {
      this.$emit("getdatafromTStotal", this.sum_grandTotal);
      this.$emit("getTsIdTab", this.hid_array.hid);
      this.$emit("getSelected_showETab", this.selected_showE);
      this.$emit("getOper_selTab", this.oper_sel);
      this.$emit("getInvTsListTab", this.inv_ts_list);
      this.sum_chargehour = 0;
      this.sum_rateChargehour = 0;
      this.sum_noCharge_hour = 0;
      this.sum_noCharge_bath = 0;
      this.sum_grandTotal = 0;
      this.sum_chargehour_show = 0;
      this.sum_rateChargehour_show = 0;
      this.sum_noCharge_hour_show = 0;
      this.sum_grandsum_noCharge_bath_showTotal = 0;
      this.sum_grandTotal_show = 0;
      this.disableinstatus = true;
      this.dialogConfirm = false;
    },
    async opendialogConfirm() {
      if (this.selected.length > 0) {
        this.selected_showE = this.selected;
        console.log("this.selected_showE")
        console.log(this.selected_showE)
        this.timesheet_hid = [];
        for (let i = 0; i < this.selected.length; i++) {
          this.timesheet_hid[i] = this.selected[i].id;
        }
        this.hid_array.hid = this.timesheet_hid;

        this.$showLoader();
        //thimesheet detail
        console.log(this.hid_array)
        const resultc = await api.getAllBytimesheet_hid(this.hid_array);
        this.dataAllC = resultc.data;
        this.dataAllC.forEach((item) => {
          let dayH = Math.floor(item.hours / 60);
          let dayM = item.hours % 60;

          item.show_hours = dayH + ` ชั่วโมง ` + dayM + ` นาที`;
          let daycH = Math.floor(item.chargehour / 60);
          let daycM = item.chargehour % 60;

          item.show_chargehour = daycH + ` ชั่วโมง ` + daycM + ` นาที`;
        });
        
        this.mDataArrayC = this.dataAllC;
        console.log("this.mDataArrayC")
        console.log(this.mDataArrayC)
        //timesheetCreeper
        const resultcp = await api.getAllBytimesheetCreeper_hid(this.hid_array);
        this.dataAlltc = resultcp.data;
        this.dataAlltc.forEach((item) => {
          item.tc_rateChargeHour_bath = (
            (Number(item.tc_actualchargehour) / 60) *
            Number(item.tc_rate)
          ).toFixed(2);
          item.tc_nocharge = Math.abs(
            (
              (Number(item.tc_actualchargehour) - Number(item.tc_hours)) /
              60
            ).toFixed(2)
          );
          item.tc_nochargeCal = (
            Math.abs(
              (Number(item.tc_actualchargehour) - Number(item.tc_hours)) / 60
            ) * Number(item.tc_rate)
          ).toFixed(2);

          //sum all
          this.sum_chargehour =
            this.sum_chargehour + Number(item.tc_actualchargehour / 60);
          this.sum_rateChargehour =
            this.sum_rateChargehour + Number(item.tc_rateChargeHour_bath);
          this.sum_noCharge_hour =
            this.sum_noCharge_hour + Number(item.tc_nocharge);
          this.sum_noCharge_bath =
            this.sum_noCharge_bath + Number(item.tc_nochargeCal);
          this.sum_grandTotal = this.sum_rateChargehour;

          // //show data in table
          item.tc_rate_show = item.tc_rate;
          item.tc_actualchargehour_show = tolocalestringnumber(
            Number(item.tc_actualchargehour / 60).toFixed(2)
          );
          item.tc_rateChargeHour_bath_show = tolocalestringnumber(
            item.tc_rateChargeHour_bath
          );
          item.tc_nocharge_show = tolocalestringnumber(item.tc_nocharge);
          item.tc_nochargeCal_show = tolocalestringnumber(item.tc_nochargeCal);
        });

        if(this.cus_invts.length> 0){
          this.sum_rateChargehour = 0;
          this.dataAlltc.forEach((item)=>{
            for(let i=0;i<this.cus_invts.length;i++){
              if(item.emp_id == this.cus_invts[i].emp_id){
                item.tc_rate = this.cus_invts[i].tc_rate;
                item.tc_rate_show = this.cus_invts[i].tc_rate_show;
                item.tc_rateChargeHour_bath = item.tc_actualchargehour_show * item.tc_rate;
                item.tc_rateChargeHour_bath_show = tolocalestringnumber(item.tc_rateChargeHour_bath)
              }
            }
          })
          this.dataAlltc.forEach((item)=>{
            
            this.sum_rateChargehour += Number(item.tc_rateChargeHour_bath)
            
          })
          this.sum_grandTotal = this.sum_rateChargehour
          this.inv_ts_list = this.dataAlltc;
        }
        this.sum_chargehour_show = tolocalestringnumber(this.sum_chargehour);
        this.sum_rateChargehour_show = tolocalestringnumber(
          this.sum_rateChargehour
        );
        this.sum_noCharge_hour_show = tolocalestringnumber(
          this.sum_noCharge_hour
        );
        this.sum_noCharge_bath_show = tolocalestringnumber(
          this.sum_noCharge_bath
        );
        this.sum_grandTotal_show = tolocalestringnumber(this.sum_grandTotal);
        this.mDataArraytc = this.dataAlltc;
        if(this.cus_invts.length == 0){
          this.inv_ts_list = this.dataAlltc;
        }
        this.$hideLoader();
        this.dialogConfirm = true;
      } else {
        this.sum_chargehour = 0;
        this.sum_rateChargehour = 0;
        this.sum_noCharge_hour = 0;
        this.sum_noCharge_bath = 0;
        this.sum_grandTotal = 0;
        this.sum_chargehour_show = 0;
        this.sum_rateChargehour_show = 0;
        this.sum_noCharge_hour_show = 0;
        this.sum_grandsum_noCharge_bath_showTotal = 0;
        this.sum_grandTotal_show = 0;
        
        this.hid_array.hid = [];
        this.selected_showE = [];
        this.inv_ts_list = [];
        
        this.$emit("getdatafromTStotal", this.sum_grandTotal);
        this.$emit("getTsIdTab", this.hid_array.hid);
        this.$emit("getSelected_showETab", this.selected_showE);
        this.$emit("getOper_selTab", this.oper_sel);
        this.$emit("getInvTsListTab", this.inv_ts_list);
        this.disableinstatus = true;
        this.$store.state.global_dialog_invoice_mis = true;
        this.setupAlertDialog(
          true,
          "Information Clear",
          "Information Clear Sucess!!!",
          "text-h5 green--text text-center"
        );

        return;
      }
    },
    finddatafromdate() {
      //alert(this.datefrom)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom != null && this.dateto != null) {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        }
        if (this.datefrom == null && this.dateto == null) {
          return item;
        }
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    // setupAlertDialog(status, title, message, text_color) {
    // this.title = title;
    // this.message = message;
    // this.dialogAdd = status;
    // this.text_color = text_color;
    // },
    // setupAlertDialogPush(status, title, message, text_color, link) {
    //   this.title = title;
    //   this.message = message;
    //   this.dialogAdd = status;
    //   this.text_color = text_color;
    //   this.link = link;
    // },
    setupAlertDialog(status, titleMis, messageMis, text_colorMis) {
      this.titleMis = titleMis;
      this.messageMis = messageMis;
      this.dialogAddMis = status;
      this.text_colorMis = text_colorMis;
    },
  },
  beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
