import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue2Filters from "vue2-filters";
import { imageUrl } from "@/services/constants";
import CountryFlag from "vue-country-flag";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.min.css";

import Notifications from "vue-notification";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSignature from "vue-signature-pad";
import VueHtml2pdf from "vue-html2pdf";
import Tabs from "vue-tabs-component";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import { Settings } from "luxon";

Settings.defaultLocale = navigator.language;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.component("vue-ctk-date-time-picker", VueCtkDateTimePicker);
Vue.component("country-flag", CountryFlag);

Vue.use(Datetime);
Vue.component("datetime", Datetime);

Vue.use(Vue2Filters);
Vue.use(VueLoading);
Vue.use(Notifications);
Vue.use(VueSignature);
Vue.use(VueHtml2pdf);
Vue.use(Tabs);
Vue.filter("imageUrl", function(image) {
  return `${imageUrl}/${image}?timestamp=${Date.now()}`;
});
