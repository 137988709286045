import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getAllCompany = () => {
    return httpClient.get(server.MASTER_COMPANY + `/getAll`);
};

export const getCompanyByID = (id) => {
  return httpClient.get(server.MASTER_COMPANY + `/${id}`);
};

export const getCompanyByComCode = (comcode) => {
  return httpClient.get(server.MASTER_COMPANY + `/comcode/${comcode}`);
};
export const getCompanyByTaxId = (tax_id) => {
  return httpClient.get(server.MASTER_COMPANY + `/tax-id/${tax_id}`);
};
export const getCompanyCol = (com_value,mode) => {
  return httpClient.get(server.MASTER_COMPANY + `/company-col/${com_value}/${mode}`);
};

export const addCompany = (data) => {
    return httpClient.post(server.MASTER_COMPANY + `/`, data);
};

export const updateCompany = (id, data) => {
    return httpClient.put(server.MASTER_COMPANY + `/${id}`, data);
};