export const tolocalestringnumber = (number) => {
    return parseFloat(number).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const tolocalestringnumbernodecimal = (number) => {
    return parseFloat(number).toLocaleString("en-US");
};

export const stringcommatonumber = (text) => {
    let decimal = text.split(".")[1];
    let cutcomma = text.split(".")[0].split(",");
    let groupcal = "";

    cutcomma.forEach((x) => {
        groupcal += x;
    });

    groupcal = `${groupcal}.${decimal}`;
    //   alert(parseFloat(groupcal).toFixed(2));
    return parseFloat(groupcal).toFixed(2)


};