//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    position: [
      { text: "ตำแหน่ง", value: "position_i_d" },
      { text: "แผนก", value: "department_i_d" },
      { text: "บริษัท", value: "company_i_d" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    department: [
      { text: "แผนก", value: "department_i_d" },
      { text: "บริษัท", value: "company_i_d" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    company: [
      { text: "บริษัท", value: "company_i_d" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    joptitle: [
      { text: "joptitle", value: "joptitle_i_d" },
      { text: "แผนก", value: "department_i_d" },
      { text: "บริษัท", value: "company_i_d" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    position_config: [],
    department_config: [],
    company_config: [],
    joptitle_config: [],
    editedIndex: -1,
    editedItem: {
      position_i_d: "",
      department_i_d: "",
      company: "",
      joptitle: "",
    },
    defaultItem: {
      position_i_d: "",
      department_i_d: "",
      company: "",
      joptitle: "",
    },
  }),
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  computed: {
    formTitle_position() {
      return this.editedIndex === -1 ? "เพิ่มตำแหน่ง" : "แก้ไขตำแหน่ง";
    },
    formTitle_department() {
      return this.editedIndex === -1 ? "เพิ่มแผนก" : "แก้ไขแผนก";
    },
    formTitle_company() {
      return this.editedIndex === -1 ? "เพิ่มบริษัท" : "แก้ไขบริษัท";
    },
    formTitle_joptitle() {
      return this.editedIndex === -1 ? "เพิ่มjoptitle" : "แก้ไขjoptitle";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.below get from database
      this.position_config = [
        {
          position_i_d: "Accouting",
          department_i_d: "IT",
          company_i_d: "Dejudom ltd.",
        },
        {
          position_i_d: "Progammer",
          department_i_d: "IT",
          company_i_d: "Digital comperate ltd.",
        },
      ];
      this.department_config = [
        {
          department_i_d: "IT",
          company_i_d: "Dejudom ltd.",
        },
        {
          department_i_d: "IT",
          company_i_d: "Digital comperate ltd.",
        },
      ];
      this.company_config = [
        {
          company_i_d: "Dejudom ltd.",
        },
        {
          company_i_d: "Digital comperate ltd.",
        },
      ];
      this.joptitle_config = [
        {
          joptitle_i_d: "joptitle",
          department_i_d: "IT",
          company_i_d: "Dejudom ltd.",
        },
        {
          joptitle_i_d: "joptitle",
          department_i_d: "IT",
          company_i_d: "Digital comperate ltd.",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.position_config.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.position_config.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.position_config.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.position_config[this.editedIndex], this.editedItem);
      } else {
        this.position_config.push(this.editedItem);
      }
      this.close();
    },
  },
};
