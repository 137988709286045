import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDataPC = (user_id) => {
  return httpClient.get(server.API_PETTYCASH_H + `/getsql/${user_id}`);
};
export const getDataPCApporver = (user_id) => {
  return httpClient.get(server.API_PETTYCASH_H + `/getsql-approver/${user_id}`);
};
export const getDataPCAccount = (user_id) => {
  return httpClient.get(server.API_PETTYCASH_H + `/getsql-account/${user_id}`);
};
export const getDataPCByID = (id) => {
  return httpClient.get(server.API_PETTYCASH_H + `/getbyid/${id}`);
};
export const getDataPCByIDSql = (id) => {
  return httpClient.get(server.API_PETTYCASH_H + `/getbyid-sql/${id}`);
};

export const createPC = (data) => {
    return httpClient.post(server.API_PETTYCASH_H + `/create`, data);
};

export const updatePCH = (id,data) => {
  return httpClient.put(server.API_PETTYCASH_H + `/update/${id}`, data);
};