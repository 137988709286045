import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const createActivity = (data) => {
  return httpClient.post(server.ACTIVITY +`/createFWeb`,data);
};
export const getActivityAll = () => {
  return httpClient.get(server.ACTIVITY + `/get/list`);
};
export const getActivityAllByCompany = (company_id) => {
  return httpClient.get(server.ACTIVITY + `/getByConpany/list/${company_id}`);
};

export const getActivityfortimesheetreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getfortimesheetreport`, query);
};

export const getActivityforemployeesummaryreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getforemployeesummaryreport`, query);
};

export const getActivityforclientsummaryreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getforclientsummaryreport`, query);
};

export const getActivityattendantreport = (query) => {
  return httpClient.get(server.ACTIVITY + `/getattendantreport`, query);
};

export const getcheckattendantuserid = (query) => {
  return httpClient.get(server.ACTIVITY + `/checkattendantuserid`, query);
};

export const getdataReportSumAct = (query) => {
  return httpClient.get(server.ACTIVITY + `/summary-timesheets-activity-report`, query);
};