import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDataReceiveH = () => {
    return httpClient.get(server.TRC_H + `/get`);
};
export const getDataReceiveByID = (id) => {
    return httpClient.get(server.TRC_H + `/getbyid/${id}`);
};
export const UpdataStatusInv = (inv_id) => {
    return httpClient.get(server.TRC_H + `/updateinvstatus/${inv_id}`);
};
export const clearStatusInv = (inv_id,inv_type) => {
    return httpClient.get(server.TRC_H + `/clearinvstatus/${inv_id}/${inv_type}`);
};
export const getDataReceiveHQ = (com_id) => {
    return httpClient.get(server.TRC_H + `/getquery/${com_id}`);
};
export const getDataReceiveADHQ = (com_id) => {
    return httpClient.get(server.TRC_H + `/getquery-adjust/${com_id}`);
};

export const CreateDataReceiveH = (oper,data) => {
    return httpClient.post(server.TRC_H + `/${oper}`, data);
};

export const updateReceiveH = (id,oper, data) => {
    return httpClient.put(server.TRC_H + `/${id}/${oper}`, data);
  };

export const updateAdjustReceiveH = (id,data) => {
    return httpClient.put(server.TRC_H + `/update-adjsut-receicpt/${id}`, data);
  };

export const deleteReceiveH = (id) => {
    return httpClient.delete(server.TRC_H + `/${id}`);
  };