import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getPOAById = id => {
    return httpClient.get(server.POA + `/by-id/${id}`);
};

export const createPOA = (id, data) => {
    return httpClient.post(server.POA +`/${id}`, data);
};

export const deletePOA = (id, contract_id, data) => {
    return httpClient.put(server.POA +`/delete/${id}/${contract_id}`, data);
};