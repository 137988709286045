var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("D-Documnet")])],1),_c('v-col',{attrs:{"align":"right","color":"primary","cols":"12","md":"6"}},[(_vm.authorize_add)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#3F7652","rounded":"","dark":"","disabled":_vm.disabledAdd},on:{"click":function($event){return _vm.openAdd()}}},[_c('span',[_vm._v("Add")]),_c('v-icon',{attrs:{"small":"","center":""}},[_vm._v("add")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.doctyype_drop,"item-text":"doctype","item-value":"id","label":"Document Type","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                            _vm.doctype_id_show = 0;
                            _vm.disabledAdd = true;
                        })},"input":function($event){return _vm.loaddoctypebydep()}},model:{value:(_vm.doctype_id_show),callback:function ($$v) {_vm.doctype_id_show=$$v},expression:"doctype_id_show"}})],1)],1),(_vm.headers.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"widthsizesearch",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"6"}})],1):_vm._e(),_c('v-card',{attrs:{"outlined":"","tile":""}},[(_vm.headers.length > 0)?_c('v-data-table',{staticClass:"elevation-1   595959",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.mDataArray},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.authorize_view)?_c('v-icon',{staticClass:"mr-5",style:({
                            backgroundColor: '#E1F5FE',
                            color: '#254E58',
                        }),on:{"click":function($event){return _vm.openshowItem(item.doctrans_hid)}}},[_vm._v(" mdi-calendar-check ")]):_vm._e(),(_vm.authorize_edit)?_c('v-icon',{style:({
                            backgroundColor: '#E1F5FE',
                            color: '#254E58',
                        }),on:{"click":function($event){return _vm.openeditItem(item.doctrans_hid)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }