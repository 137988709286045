import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getDataFileA = (module_name,module_id,company_id) => {
      return httpClient.get(server.FILE_ATTACH + `/get-by-module/${module_name}/${module_id}/${company_id}`);
};
export const createFA = (data) => {
      return httpClient.post(server.FILE_ATTACH + `/create`, data);
};
export const updateFA = (id,data) => {
      return httpClient.put(server.FILE_ATTACH + `/update/${id}`, data);
};
export const deleteFA = (id) => {
      return httpClient.delete(server.FILE_ATTACH + `/delete/${id}`);
};
  