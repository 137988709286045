export const language = {
    th: {
      title: "รายการเบิกเงิน",
      adddata: "เพิ่ม",
      name: "เบิกเงินล่วงหน้า",
      labelsearch: "ค้นหาหมายเลขเบิกเงิน",
      headertable: [{
          text: "เลขที่",
          value: "no",
        },
        {
          text: "วันที่เปิด",
          value: "date",
        },
        {
          text: "ชื่อ",
          value: "name",
        },
        {
          text: "ลูกค้า",
          value: "customer",
        },
        {
          text: "จำนวนเงิน",
          value: "amount",
        },
        {
          text: "สถานะ",
          value: "status",
        },
        {
          text: "",
          value: "actions",
        },
        // {
        //     text: "Actions",
        //     value: "actions",
        //     sortable: false
        // }
      ],
    },
    en: {
      title: "My Activities",
      adddata: "Filter",
      name: "my activities",
      labelsearch: "Search Customer Name",
      headertable: [{
          text: "Document No.",
          value: "saleorder_id",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Date",
          value: "po_date",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Customer",
          value: "name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Document Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
        },
      
        {
          text: "Editor",
          value: "user_create",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
        // {
        //     text: "Actions",
        //     value: "actions",
        //     sortable: false
        // },
      ],
    },
  };