//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
    tolocalestringnumber,
    stringcommatonumber,
} from "./computing/tolocalestringnumber";

export default {
    name: "NewAdvance",
    data() {
        return {
            authori_depart: [],
            depart_list: [],
            type_list: ["Dropdown","Text","Date"],
            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            documnet_type: "",
            showdropdown: false,
            showdropdownDataTable: false,
            showAdd: false,
            seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
            desserts:[],
            dropdownlist:[
                {
                    doctemplate_id :0,
                    text: ""
                }
            ],
            dropdownlistTable:[ ],
            headers: [
                {
                    text: "Title",
                    value: "title",
                    class: "bg-colorth",
                    align: "start",
                    divider: true,
                    sortable: false,
                    width: "25%",
                },
                {
                    text: "Type",
                    align: "start",
                    value: "type",
                    class: "bg-colorth",
                    align: "start",
                    divider: true,
                    sortable: false,
                    width: "50%",
                },
            ]
        };
    },
    components: {
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        const userId = localStorage.getItem(server.USER_ID);
        if (userId && api.isLoggedIn()) {
            // this.$router.push("/");
        } else {
            this.$store.state.isLogged = false;
            this.$router.push("/login");
        }
        if(this.$route.params.mode == "Add"){
            this.showAdd = true;
        }
        await this.loadDepartment();
        await this.loadDoctype();
        await this.loadDoctypeTemplate();
        this.$hideLoader();
    },
    methods: {  
        async loadDepartment(){
            const res_depart = await api.getAllDepartment_By_CompanyID( localStorage.getItem(server.COMPANYID));
            console.log(res_depart.data)
            this.depart_list = res_depart.data;
        },
        async loadDoctype(){
            const res_doctype = await api.getDoctypeByID(this.$route.params.doctype_id)
            this.doctype = res_doctype.data[0].doctype;
            if(res_doctype.data[0].department_authorization != ""){
                const dep_au =  res_doctype.data[0].department_authorization
                const temp_dep = dep_au.split(',');
                for(let i=0;i < temp_dep.length;i++){
                   temp_dep[i] = Number(temp_dep[i])
                }
                // console.log(temp_dep)
                this.authori_depart = temp_dep;

            }
        },
        async loadDoctypeTemplate(){
            const res_doctypetemplate = await api.getDoctypeTemplateByID(this.$route.params.doctype_id)
            this.desserts = res_doctypetemplate.data;
            this.desserts.forEach(async(item)=>{
                if(item.type == "Dropdown"){
                    const res_docdrop = await api.getDocDropByDoctempID(item.id)  
                    item.datadropdown = res_docdrop.data;
                }else{
                    item.datadropdown = [];
                }      
                item.textdrop = "";
            })
            console.log(this.desserts)
            
        },     
        saveItem(){
            
        },
        showTypeDropdown(){
            // alert(this.documnet_type)
            if(this.documnet_type == "Dropdown"){
                this.showdropdown = true;
            }else{
                this.showdropdown = false;
                this.dropdownlist = [{
                                        doctemplate_id :0,
                                        text: ""
                                    }]
            }
        },
        async inputapprover() {
            this.dropdownlist.push({
                doctemplate_id : 0,
                text: ""
            });
        },
        async deletedropdown(index) {
            this.dropdownlist.splice(index, 1);
        },
        async inputapproverTable() {
            this.dropdownlistTable.push({
                doctemplate_id : 0,
                text: ""
            });

        },
        async deletedropdownTable(index) {
            this.dropdownlistTable.splice(index, 1);
        },
        showsearchmore() {
            this.toggleEnable = !this.toggleEnable;
            this.seticonsearchmore.action = !this.seticonsearchmore.action;
            if (this.seticonsearchmore.action === true) {
                this.seticonsearchmore.icon = "mdi mdi-chevron-up";
                this.showdropdownDataTable = true;
            } else {
                this.seticonsearchmore.icon = "mdi mdi-chevron-down";
                this.showdropdownDataTable = false;
            }
},
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
};
