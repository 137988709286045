//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import api from "@/services/api";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
} from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import pdfvuer from "pdfvuer";
export default {
  name: "About",
  data(vm) {
    return {
      disableCalculateNewRenewalDate: false,
      country_list: [],
      date_of_public: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_opposition: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_filling_appeal: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_filling_lawsuit: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_renewal: null,
      date_of_renewal_filed: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      next_deadline: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateof_filling_affidavits: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_regis: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_application: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_closed: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_issuing_certificate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menudate_of_issuing_certificate: false,
      menudate_of_closed: false,
      dialogpreviewimage: false,
      checkfileapreview: false,
      previewpopupfile: [],
      previewpopupfile2: [],
      dialogpreviewfile: false,
      dialogDelete: false,
      dialogDeleteimage: false,
      fileindex: -1,
      preview_list2: [],
      getpreview_list: [],
      image: null,
      previewimage: null,
      previewimageedit: null,
      image_list: [],
      preview_list: [],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      tmstatus_list: [],
      contact_owner_list: [],
      contact_agent_list: [],
      contact_associate_list: [],
      objecttype_list: [],
      filename: [],
      imagename: "",
      load_bg: false,
      checkfileadd: false,
      checkimageadd: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      showaddItem: {
        tm_type: 0,
        class_no: "",
        country: "",
        cliant_ref: "",
        name_of_client: "",
        file_no: "",
        regis_no: "",
        trademark: "",
        owner_contact: 0,
        owner_address: "",
        agent_contact: 0,
        agent_address: "",
        associate_contact: 0,
        associate_address: "",
        app_no: "",
        good_service: "",
        condition_of_regis: "",
        val_no: "",
        page_no: "",
        tmstatusasoff: 0,
        dip_link: "",
        remark: "",
        date_of_public: "",
        date_of_opposition: "",
        date_of_filling_appeal: "",
        date_of_filling_lawsuit: "",
        date_of_regis: "",
        date_of_application: "",
        date_of_renewal: "",
        date_of_renewal_filed: "",
        date_of_filling: "",
        next_deadline: "",
        dateof_filling_affidavits: "",
        closed: false,
        disclaim: "",
        date_of_closed: "",
        date_of_issuing_certificate: "",
        user_create: localStorage.getItem(server.USER_ID),
      },
      menuaddappdate: false,
      menupublication: false,
      menuopposition: false,
      menufilling_appeal: false,
      menufilling_lawsuit: false,
      menurenewal: false,
      menurenewal_filed: false,
      menuaffidavits: false,
      menufiling_affidavits: false,
      menuadd: false,
      fullPage: true,
      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      mDataArray: [],
      sostatus:false,
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    pdf: pdfvuer,
  },
  async mounted() {
    await this.loaddataobjecttypeBygroupId();
    await this.loaddatacontactByContact_type_owner();
    await this.loaddatacontactByContact_type_agent();
    await this.loaddatacontactByContact_type_associate();
    await this.loaddatacountry();
    await this.loaddatatmupdatestatus();
    this.$hideLoader();
  },
  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  watch: {
    date_of_regis(val) {
      this.showaddItem.date_of_regis = this.formatDate(this.date_of_regis);
    },
    date_of_application(val) {
      this.showaddItem.date_of_application = this.formatDate(
        this.date_of_application
      );
    },
    date_of_public(val) {
      this.showaddItem.date_of_public = this.formatDate(this.date_of_public);
    },
    date_of_opposition(val) {
      this.showaddItem.date_of_opposition = this.formatDate(
        this.date_of_opposition
      );
    },
    date_of_filling_appeal(val) {
      this.showaddItem.date_of_filling_appeal = this.formatDate(
        this.date_of_filling_appeal
      );
    },
    date_of_filling_lawsuit(val) {
      this.showaddItem.date_of_filling_lawsuit = this.formatDate(
        this.date_of_filling_lawsuit
      );
    },
    date_of_renewal(val) {
      this.showaddItem.date_of_renewal = this.formatDate(this.date_of_renewal);
    },
    date_of_renewal_filed(val) {
      this.showaddItem.date_of_renewal_filed = this.formatDate(
        this.date_of_renewal_filed
      );
    },

    next_deadline(val) {
      this.showaddItem.next_deadline = this.formatDate(this.next_deadline);
    },
    dateof_filling_affidavits(val) {
      this.showaddItem.dateof_filling_affidavits = this.formatDate(
        this.dateof_filling_affidavits
      );
    },
    date_of_closed(val) {
      this.showaddItem.date_of_closed = this.formatDate(this.date_of_closed);
    },
    date_of_issuing_certificate(val) {
      this.showaddItem.date_of_issuing_certificate = this.formatDate(
        this.date_of_issuing_certificate
      );
    },
  },
  methods: {
    async saveaddItem() {
      console.log(this.showaddItem);
      if (this.showaddItem.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Object Type Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.file_no == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No. Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.trademark == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Trademark Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        !this.showaddItem.date_of_application ||
        this.showaddItem.date_of_application == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Application Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showaddItem.regis_no == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Registration No. Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      if (
        this.showaddItem.closed == true &&
        this.showaddItem.date_of_closed == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Closed File Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showaddItem.dip_link == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter DIP Link(URL) Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      this.$showLoader();
      let formData = new FormData();
      formData.append(`file`, this.image);
      this.image_list.forEach((x, index) => {
        formData.append(`file`, x);
      });
      formData.append("file_no", this.showaddItem.file_no.trim());
      formData.append("regis_no", this.showaddItem.regis_no.trim());
      formData.append("tm_type", this.showaddItem.tm_type);
      formData.append("class_no", this.showaddItem.class_no.trim());
      formData.append("country", this.showaddItem.country.trim());
      // formData.append("date_of_filling", this.showaddItem.date_of_filling);
      formData.append("name_of_client", this.showaddItem.name_of_client.trim());
      if (
        this.showaddItem.date_of_regis !== "" &&
        this.showaddItem.date_of_regis !== null
      ) {
        formData.append(
          "date_of_regis",
          this.changeformatDatetoinsert(this.showaddItem.date_of_regis)
        );
      }
      formData.append("cliant_ref", this.showaddItem.cliant_ref.trim());
      formData.append("app_no", this.showaddItem.app_no.trim());
      if (
        this.showaddItem.date_of_application !== "" &&
        this.showaddItem.date_of_application !== null
      ) {
        formData.append(
          "date_of_application",
          this.changeformatDatetoinsert(this.showaddItem.date_of_application)
        );
      }
      formData.append("trademark", this.showaddItem.trademark.trim());
      formData.append("owner_contact", this.showaddItem.owner_contact);
      formData.append("agent_contact", this.showaddItem.agent_contact);
      formData.append("associate_contact", this.showaddItem.associate_contact);
      if (
        this.showaddItem.date_of_public !== "" &&
        this.showaddItem.date_of_public !== null
      ) {
        formData.append(
          "date_of_public",
          this.changeformatDatetoinsert(this.showaddItem.date_of_public)
        );
      }
      if (
        this.showaddItem.date_of_filling_appeal !== "" &&
        this.showaddItem.date_of_filling_appeal !== null
      ) {
        formData.append(
          "date_of_filling_appeal",
          this.changeformatDatetoinsert(this.showaddItem.date_of_filling_appeal)
        );
      }
      formData.append("good_service", this.showaddItem.good_service.trim());
      formData.append("val_no", this.showaddItem.val_no.trim());
      if (
        this.showaddItem.date_of_filling_lawsuit !== "" &&
        this.showaddItem.date_of_filling_lawsuit !== null
      ) {
        formData.append(
          "date_of_filling_lawsuit",
          this.changeformatDatetoinsert(
            this.showaddItem.date_of_filling_lawsuit
          )
        );
      }
      formData.append("remark", this.showaddItem.remark.trim());
      formData.append("page_no", this.showaddItem.page_no.trim());
      if (
        this.showaddItem.date_of_renewal !== "" &&
        this.showaddItem.date_of_renewal !== null
      ) {
        formData.append(
          "date_of_renewal",
          this.changeformatDatetoinsert(this.showaddItem.date_of_renewal)
        );
      }

      formData.append(
        "condition_of_regis",
        this.showaddItem.condition_of_regis.trim()
      );
      if (
        this.showaddItem.date_of_opposition !== "" &&
        this.showaddItem.date_of_opposition !== null
      ) {
        formData.append(
          "date_of_opposition",
          this.changeformatDatetoinsert(this.showaddItem.date_of_opposition)
        );
      }
      if (
        this.showaddItem.date_of_renewal_filed !== "" &&
        this.showaddItem.date_of_renewal_filed !== null
      ) {
        formData.append(
          "date_of_renewal_filed",
          this.changeformatDatetoinsert(this.showaddItem.date_of_renewal_filed)
        );
      }
      if (
        this.showaddItem.next_deadline !== "" &&
        this.showaddItem.next_deadline !== null
      ) {
        formData.append(
          "next_deadline",
          this.changeformatDatetoinsert(this.showaddItem.next_deadline)
        );
      }
      if (
        this.showaddItem.dateof_filling_affidavits !== "" &&
        this.showaddItem.dateof_filling_affidavits !== null
      ) {
        formData.append(
          "dateof_filling_affidavits",
          this.changeformatDatetoinsert(
            this.showaddItem.dateof_filling_affidavits
          )
        );
      }
      formData.append("tmstatusasoff", this.showaddItem.tmstatusasoff);
      formData.append("dip_link", this.showaddItem.dip_link.trim());
      formData.append("user_create", this.showaddItem.user_create);
      formData.append("closed", this.showaddItem.closed);
      if (
        this.showaddItem.date_of_closed !== "" &&
        this.showaddItem.date_of_closed !== null
      ) {
        formData.append(
          "date_of_closed",
          this.changeformatDatetoinsert(this.showaddItem.date_of_closed)
        );
      }
      if (
        this.showaddItem.date_of_issuing_certificate !== "" &&
        this.showaddItem.date_of_issuing_certificate !== null
      ) {
        formData.append(
          "date_of_issuing_certificate",
          this.changeformatDatetoinsert(
            this.showaddItem.date_of_issuing_certificate
          )
        );
      }
      formData.append("disclaim", this.showaddItem.disclaim);

      console.log(formData);
      const resultMasterTM = await api.createMasterTM(formData);

      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {
        // console.log("resultMasterTM.data")
        // console.log(resultMasterTM.data)
        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Create",
            table_name: "mastertm",
            table_id: resultMasterTM.data.mastertm_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        this.$hideLoader(); 
        this.dialogAddmastertm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "save data!!!",
          "save data success!!!",
          "text-h5 green--text text-center",
          "/master-trademark-list"
        );
        return;
      } else if (resultMasterTM.status == 204) {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "data duplicate !!!",
          "please check File No. and Application No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "save data!!!",
          "can't save data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async saveandcreateappointmentaddItem() {
      console.log(this.showaddItem);
      if (this.showaddItem.tm_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Object Type Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.file_no == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No. Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.trademark == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Trademark Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        !this.showaddItem.date_of_application ||
        this.showaddItem.date_of_application == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Application Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showaddItem.regis_no == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Registration No. Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }

      if (
        this.showaddItem.closed == true &&
        this.showaddItem.date_of_closed == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Closed File Date Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.showaddItem.dip_link == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter DIP Link(URL) Data",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      this.$showLoader();
      let formData = new FormData();
      formData.append(`file`, this.image);
      this.image_list.forEach((x, index) => {
        formData.append(`file`, x);
      });
      formData.append("file_no", this.showaddItem.file_no.trim());
      formData.append("regis_no", this.showaddItem.regis_no.trim());
      formData.append("tm_type", this.showaddItem.tm_type);
      formData.append("class_no", this.showaddItem.class_no.trim());
      formData.append("country", this.showaddItem.country.trim());
      // formData.append("date_of_filling", this.showaddItem.date_of_filling);
      formData.append("name_of_client", this.showaddItem.name_of_client.trim());
      if (
        this.showaddItem.date_of_regis !== "" &&
        this.showaddItem.date_of_regis !== null
      ) {
        formData.append(
          "date_of_regis",
          this.changeformatDatetoinsert(this.showaddItem.date_of_regis)
        );
      }
      formData.append("cliant_ref", this.showaddItem.cliant_ref.trim());
      formData.append("app_no", this.showaddItem.app_no.trim());
      if (
        this.showaddItem.date_of_application !== "" &&
        this.showaddItem.date_of_application !== null
      ) {
        formData.append(
          "date_of_application",
          this.changeformatDatetoinsert(this.showaddItem.date_of_application)
        );
      }
      formData.append("trademark", this.showaddItem.trademark.trim());
      formData.append("owner_contact", this.showaddItem.owner_contact);
      formData.append("agent_contact", this.showaddItem.agent_contact);
      formData.append("associate_contact", this.showaddItem.associate_contact);
      if (
        this.showaddItem.date_of_public !== "" &&
        this.showaddItem.date_of_public !== null
      ) {
        formData.append(
          "date_of_public",
          this.changeformatDatetoinsert(this.showaddItem.date_of_public)
        );
      }
      if (
        this.showaddItem.date_of_filling_appeal !== "" &&
        this.showaddItem.date_of_filling_appeal !== null
      ) {
        formData.append(
          "date_of_filling_appeal",
          this.changeformatDatetoinsert(this.showaddItem.date_of_filling_appeal)
        );
      }
      formData.append("good_service", this.showaddItem.good_service.trim());
      formData.append("val_no", this.showaddItem.val_no.trim());
      if (
        this.showaddItem.date_of_filling_lawsuit !== "" &&
        this.showaddItem.date_of_filling_lawsuit !== null
      ) {
        formData.append(
          "date_of_filling_lawsuit",
          this.changeformatDatetoinsert(
            this.showaddItem.date_of_filling_lawsuit
          )
        );
      }
      formData.append("remark", this.showaddItem.remark.trim());
      formData.append("page_no", this.showaddItem.page_no.trim());
      if (
        this.showaddItem.date_of_renewal !== "" &&
        this.showaddItem.date_of_renewal !== null
      ) {
        formData.append(
          "date_of_renewal",
          this.changeformatDatetoinsert(this.showaddItem.date_of_renewal)
        );
      }

      formData.append(
        "condition_of_regis",
        this.showaddItem.condition_of_regis.trim()
      );
      if (
        this.showaddItem.date_of_opposition !== "" &&
        this.showaddItem.date_of_opposition !== null
      ) {
        formData.append(
          "date_of_opposition",
          this.changeformatDatetoinsert(this.showaddItem.date_of_opposition)
        );
      }
      if (
        this.showaddItem.date_of_renewal_filed !== "" &&
        this.showaddItem.date_of_renewal_filed !== null
      ) {
        formData.append(
          "date_of_renewal_filed",
          this.changeformatDatetoinsert(this.showaddItem.date_of_renewal_filed)
        );
      }
      if (
        this.showaddItem.next_deadline !== "" &&
        this.showaddItem.next_deadline !== null
      ) {
        formData.append(
          "next_deadline",
          this.changeformatDatetoinsert(this.showaddItem.next_deadline)
        );
      }
      if (
        this.showaddItem.dateof_filling_affidavits !== "" &&
        this.showaddItem.dateof_filling_affidavits !== null
      ) {
        formData.append(
          "dateof_filling_affidavits",
          this.changeformatDatetoinsert(
            this.showaddItem.dateof_filling_affidavits
          )
        );
      }
      formData.append("tmstatusasoff", this.showaddItem.tmstatusasoff);
      formData.append("dip_link", this.showaddItem.dip_link.trim());
      formData.append("user_create", this.showaddItem.user_create);
      formData.append("closed", this.showaddItem.closed);
      if (
        this.showaddItem.date_of_closed !== "" &&
        this.showaddItem.date_of_closed !== null
      ) {
        formData.append(
          "date_of_closed",
          this.changeformatDatetoinsert(this.showaddItem.date_of_closed)
        );
      }
      if (
        this.showaddItem.date_of_issuing_certificate !== "" &&
        this.showaddItem.date_of_issuing_certificate !== null
      ) {
        formData.append(
          "date_of_issuing_certificate",
          this.changeformatDatetoinsert(
            this.showaddItem.date_of_issuing_certificate
          )
        );
      }
      formData.append("disclaim", this.showaddItem.disclaim);

      console.log(formData);
      const resultMasterTM = await api.createMasterTM(formData);
      console.log(resultMasterTM);
     
      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {
        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Create",
            table_name: "mastertm",
            table_id: resultMasterTM.data.mastertm_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        this.$hideLoader();
        this.dialogAddmastertm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "save data!!!",
          "save data success!!!",
          "text-h5 green--text text-center",
          `/appointmenttm-add/${resultMasterTM.data.file_no}/${this.date_of_renewal}`
        );
        return;
      } else if (resultMasterTM.status == 204) {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "data duplicate !!!",
          "please check File No. and Application No.!!!",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "save data!!!",
          "can't save data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async loaddataobjecttypeBygroupId() {
      const result = await api.getObjectTypeByGroupId(1);
      console.log(result.data);
      this.objecttype_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_owner() {
      const result = await api.getContactByContactType(1);
      this.contact_owner_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_agent() {
      const result = await api.getContactByContactType(2);
      this.contact_agent_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacontactByContact_type_associate() {
      const result = await api.getContactByContactType(3);
      this.contact_associate_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async loaddatacountry() {
      const result = await api.getAllCountry();
      this.country_list = result.data;
    },
    async loaddatatmupdatestatus() {
      const result = await api.getAlltmupdatestatus();
      this.tmstatus_list = result.data;
    },
    async onSelectOwnerChange(id) {
      let setdata;
      setdata = this.contact_owner_list.filter((item) => {
        if (item.contact_id == id) {
          console.log(item);
          this.showaddItem.owner_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectCountryChange(name) {},
    async onSelectAgentChange(id) {
      let setdata;
      setdata = this.contact_agent_list.filter((item) => {
        if (item.contact_id == id) {
          this.showaddItem.agent_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectAssociateChange(id) {
      let setdata;
      setdata = this.contact_associate_list.filter((item) => {
        if (item.contact_id == id) {
          this.showaddItem.associate_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectCustomerChange() {},

    async popupdeletefile(index) {
      this.fileindex = index;
      this.dialogDelete = true;
    },
    async closeDelete() {
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      console.log(this.image_list);
      this.preview_list2 = [];
      this.getpreview_list.splice(this.fileindex, 1);
      this.image_list.splice(this.fileindex, 1);
      await this.getPdfforAdd(this.getpreview_list);

      this.filename.splice(this.fileindex, 1);
      this.preview_list2 = this.preview_list;
      this.fileindex = -1;
      this.dialogDelete = false;
    },
    async popupdeleteimage() {
      this.dialogDeleteimage = true;
    },
    async closeDeleteimage() {
      this.dialogDeleteimage = false;
    },
    async deleteItemConfirmimage() {
      this.checkimageadd = false;
      this.image = null;
      this.previewimage = "";
      this.imagename = "";
      this.dialogDeleteimage = false;
    },
    async popuppreviewfile(fileindex) {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_list[fileindex] +
          "'></iframe></body></html>"
      );

      return;
      this.previewpopupfile2 = [];
      await this.getPdfforpreview(this.getpreview_list[fileindex]);
      console.log(this.previewpopupfile);
      this.previewpopupfile2 = this.previewpopupfile;
      this.checkfileapreview = true;
      this.dialogpreviewfile = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfile = false;
    },
    async popuppreviewimage() {
      this.dialogpreviewimage = true;
    },
    async closepreviewimage() {
      this.dialogpreviewimage = false;
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewimage = e.target.result;
          this.checkimageadd = true;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }

      this.imagename = this.image.name;
    },
    async previewMultiImage(event) {
      this.preview_list = [];
      // this.image_list = [];
      this.filename = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_list.push(e.target.result);
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      await this.getPdfforAdd(this.getpreview_list);
      this.preview_list2 = this.preview_list;
      this.image_list.forEach((x) => {
        this.filename.push(x.name);
      });
      this.checkfileeadd = true;
    },
    reset: function() {
      this.image = null;
      this.previewimage = null;
      this.image_list = [];
      this.preview_list = [];
    },
    async getPdfforpreview(datafile) {
      this.previewpopupfile = [];
      let getfile = [];
      getfile = datafile;
      this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    async getPdfforAdd(item) {
      this.preview_list = [];
      let getfile = [];
      getfile = item;
      getfile.forEach((file, index) => {
        this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
      });
      this.preview_list.forEach((item, index) => {
        item.then((pdf) => {
          this.numPages = pdf.numPages;
          // window.onscroll = function() {
          //   changePage();
          //   stickyNav();
          // };
          // Get the offset position of the navbar
          // var sticky = $("#buttons")[0].offsetTop;
          // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
          // function stickyNav() {
          //   if (window.pageYOffset >= sticky) {
          //     $("#buttons")[0].classList.remove("hidden");
          //   } else {
          //     $("#buttons")[0].classList.add("hidden");
          //   }
          // }
          function changePage() {
            var i = 1,
              count = Number(pdf.numPages);
            do {
              if (
                window.pageYOffset >=
                  this.findPos(document.getElementById(i)) &&
                window.pageYOffset <=
                  this.findPos(document.getElementById(i + 1))
              ) {
                this.page = i;
              }
              i++;
            } while (i < count);
            if (
              window.pageYOffset >= this.findPos(document.getElementById(i))
            ) {
              this.page = i;
            }
          }
        });
      });
      this.checkfileadd = true;
    },
    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    async CalculateNewRenewalDate() {
      let addyear = 10;
      let addday = 1;
      console.log(this.showaddItem.date_of_renewal);
      console.log(this.date_of_renewal);
      if (
        this.showaddItem.date_of_renewal &&
        this.showaddItem.date_of_renewal != ""
      ) {
        addday = 0;
        this.date_of_renewal = new Date(
          new Date(this.date_of_renewal).setFullYear(
            new Date(this.date_of_renewal).getFullYear() + addyear,
            new Date(this.date_of_renewal).getMonth(),
            new Date(this.date_of_renewal).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showaddItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      } else {
        if (
          !this.showaddItem.date_of_application ||
          this.showaddItem.date_of_application == ""
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "Please enter Application Date Data",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.date_of_renewal = new Date(
          new Date(this.date_of_application).setFullYear(
            new Date(this.date_of_application).getFullYear() + addyear,
            new Date(this.date_of_application).getMonth(),
            new Date(this.date_of_application).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showaddItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      }
    },
    async Selectchangedate_of_application() {
      // this.disableCalculateNewRenewalDate = false;
    },

    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${year}/${month}/${day}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("/");
    //   return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    // },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
