import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getdataInvoice_H = () => {
    return httpClient.get(server.INVOICE_H + `/get`);
};

export const getdataInvoice_HBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getbycompany`, query);
};

export const getdataAdjustInvoice_HBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getAdjustbycompany`, query);
};

export const getdataByIdInvoice_H = (id) => {
    return httpClient.get(server.INVOICE_H + `/getbyid/${id}`);
};
export const getdataByIdInvoice_HRC = (id) => {
    return httpClient.get(server.INVOICE_H + `/getbyidrc/${id}`);
};

export const addInvoice_H = (data) => {
    return httpClient.post(server.INVOICE_H + `/`, data);
};

export const addInvoice_HFile = (id,data) => {
    return httpClient.post(server.INVOICE_H + `/create_file/${id}`, data);
};

export const addInvoice_H_Geninv_id = (data) => {
    return httpClient.post(server.INVOICE_H + `/addgeninv_id`, data);
};

export const addInvoice_HCN = (data) => {
    return httpClient.post(server.INVOICE_H + `/cn`, data);
};

export const addInvoice_H_Geninv_idCN = (data) => {
    return httpClient.post(server.INVOICE_H + `/addgeninv_idCN`, data);
};

export const updateInvoice_H = (id, data) => {
    return httpClient.put(server.INVOICE_H + `/${id}`, data);
};

export const updateInvoice_H_Geninv_id = (id, data) => {
    return httpClient.put(server.INVOICE_H + `/updategeninv_id/${id}`, data);
};

export const deleteInvoice_H = (id) => {
    return httpClient.delete(server.INVOICE_H + `/${id}`);
};

export const getcreditnote_ListBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getcreditnotebycompany_list`, query);
};

export const getReceive_ListBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getreceivebycompany_list`, query);
};
export const getReceiveSelect_ListBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getreceiveselectlistbycompany_list`, query);
};

export const getcreditnoteBycompany = (query) => {
    return httpClient.get(server.INVOICE_H + `/getcreditnotebycompany`, query);
};

export const getReceiveDetail = (type,data) => {
    return httpClient.post(server.INVOICE_H + `/getsoreceive/${type}`, data);
};

export const getdataReportInv = (query) => {
    return httpClient.get(server.INVOICE_H + `/searchinv_report`, query);
};
export const getdataReportRc = (query) => {
    return httpClient.get(server.INVOICE_H + `/searchreceive_report`, query);
};

export const getdataReceiveReportInv = (query) => {
    return httpClient.get(server.INVOICE_H + `/searchreceive_report`, query);
};