//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server,filesDdocfile } from "../../services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";
export default {
    name: "NewAdvance",
    data() {
        return {
            user_created: "",
            username_created: "",
            username_updated: "",
            date_n: null,
            date_u: null,
            date_t: null,
            doctype_id_show: "",
            doctitle_list: [],
            droplist_data: [],
            dialogAdd: false,
            dialogDeleteDocTran: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            //file
            preview_list2: [],
            getpreview_list: [],
            image: null,
            previewimage: null,
            previewimageedit: null,
            image_list: [],
            preview_list: [],
            dialogpreviewimage: false,
            checkfileapreview: false,
            previewpopupfile: [],
            previewpopupfile2: [],
            dialogpreviewfile: false,
            dialogDelete: false,
            dialogDeleteimage: false,
            filename: [],
            imagename: "",
            load_bg: false,
            checkfileadd: false,
            checkimageadd: false,
            dialogDeletefile: false,
            dialogpreviewimage: false,
            fileindex: -1,
            indexdelete: -1,
            editedIndex: -1,
            //--
            disabled_oper: false,
            check_upload: 0,
            fcheck_upload: "", 
        };
    },
    components: {
        SuccessDialog,
        SuccessDialogPush,
        pdf: pdfvuer,
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
    
        if(this.$route.params.mode == "Edit"){
            this.disabled_oper = true;
        }else{
            this.disabled_oper = false;
        }
        await this.loadDocTranH()
        await this.loadDocTitle()
        this.$hideLoader();
    },
    methods: {  
        async loadDocTranH(){
            const res_doctrnsh = await api.getDocTransHListByID(this.$route.params.doctrans_hid);
            console.log(res_doctrnsh.data)
            this.username_created = res_doctrnsh.data[0].full_name;
            this.date_n = res_doctrnsh.data[0].created_at;
            if(res_doctrnsh.data[0].user_update == null){
                this.username_updated = localStorage.getItem(server.FULLNAME);
            }else{
                this.username_updated = res_doctrnsh.data[0].full_name_u;
            }
            if(res_doctrnsh.data[0].updated_at == null){
                const dayU = unity.formatDate(
                        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
                    );
                const timeU = Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(11, 8);
                this.date_u =`${dayU} ${timeU}`
            }else{
                this.date_u = res_doctrnsh.data[0].updated_at;
            }
            
            if (res_doctrnsh.data[0].file_name) {
                this.filename = [];
                this.fcheck_upload = res_doctrnsh.data[0].file_name;
                this.filename.push(res_doctrnsh.data[0].file_name);
                this.filename.forEach((item, index) => {
                    this.image_list.push(
                        `${filesDdocfile}/${item}`
                    );
                    this.getpreview_list.push(
                        `${filesDdocfile}/${item}`
                    );
                });
            }
        },     
        async loadDocTitle(){
            const res_doctpye = await api.getDoctypeByID(this.$route.params.doctype_id)
            this.doctype_id_show = res_doctpye.data[0].doctype;
            const res_doctitle = await api.getDoctypeTemplateByID(this.$route.params.doctype_id)
            const res_doctrans = await api.getDocTransByDocTitle_H(this.$route.params.doctrans_hid)
            for(let i=0;i<res_doctitle.data.length;i++){
                for(let j=0;j<res_doctrans.data.length;j++){
                    if(res_doctitle.data[i].id == res_doctrans.data[j].doctemplate_id){
                        res_doctitle.data[i].doctrans_id = res_doctrans.data[j].id;
                        if(res_doctitle.data[i].type == "Text"){
                            if(res_doctrans.data[j].data_value != ""){
                                res_doctitle.data[i].dataV = res_doctrans.data[j].data_value;
                            }else{
                                res_doctitle.data[i].dataV = "";
                            }
                                
                        }else if(res_doctitle.data[i].type == "Date"){
                            if(res_doctrans.data[j].data_value != ""){
                                res_doctitle.data[i].dataV = res_doctrans.data[j].data_value;
                                res_doctitle.data[i].dataf = unity.formatDate(res_doctrans.data[j].data_value);
                            }else{
                                res_doctitle.data[i].dataV = null;
                                res_doctitle.data[i].dataf = null;
                            }   
                            res_doctitle.data[i].menu = false;
                        }else if(res_doctitle.data[i].type == "Number"){
                            if(res_doctrans.data[j].data_value != ""){
                                res_doctitle.data[i].dataV = Number(res_doctrans.data[j].data_value);
                            }else{
                                res_doctitle.data[i].dataV = "";
                            }
                        }else{
                            if(res_doctrans.data[j].data_value != ""){
                                res_doctitle.data[i].dataV = Number(res_doctrans.data[j].data_value);
                            }else{
                                res_doctitle.data[i].dataV = 0;
                            }
                            const res_drop = await api.getDocDropByDoctempID( res_doctitle.data[i].id)
                            res_doctitle.data[i].droplist = res_drop.data;
                        }
                    }
                }
            }
            this.doctitle_list = res_doctitle.data;
            console.log(this.doctitle_list)
            console.log(res_doctrans.data)
        },
        computedDatePOFormatted(data) {
            let dateindex = -1;
            dateindex = this.doctitle_list.indexOf(data)
            // console.log(data)
            this.doctitle_list[dateindex].dataf = unity.formatDate(data.dataV);
            this.doctitle_list[dateindex].menu = false;
            
        },
        async saveData(mode){
            for(let i=0;i<this.doctitle_list.length;i++){
                if (this.doctitle_list[i].dataV == 0 || this.doctitle_list[i].dataV == "" || this.doctitle_list[i].dataV == null) {
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Check Data!!!",
                        `Please ${this.doctitle_list[i].title} Data`,
                        "text-h5 red--text text-center"
                    );
                    return;
                }
            }
            if(this.image_list.length == 0){
                this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Check Data!!!",
                        `Please Enter Files Data`,
                        "text-h5 red--text text-center"
                    );
                return;
            }
            try{
                this.$showLoader();
                let formData = new FormData();
                this.image_list.forEach((x, index) => {
                    formData.append(`file`, x);
                });
                if (this.image_list.length > 0) {
                    let getnamefilename = "";
                    this.filename.forEach((x, index) => {
                    if (index > 0) {
                        getnamefilename += `,${x}`;
                    } else {
                        getnamefilename += `${x}`;
                    }
                    });
                    formData.append(`file_name`, getnamefilename.replace(/\s/g, "_"));
                } else {
                    formData.append(`file_name`, "");
                }
                let res_update_doctransH = [];
                if(this.check_upload == 1){
                    formData.append(`user_update`, localStorage.getItem(server.USER_ID));
                    res_update_doctransH = await api.UpdateDoctranH(this.$route.params.doctrans_hid,formData)
                }else{
                    res_update_doctransH = await api.UpdateDoctranHData(
                        this.$route.params.doctrans_hid,
                        { user_update: localStorage.getItem(server.USER_ID)}
                    )
                }
                if(res_update_doctransH.status == 200){
                    for(let i=0;i<this.doctitle_list.length;i++){
                        let updateitem = {}
                        let create_up_data = {}
                        create_up_data = {
                            doctrans_hid: this.$route.params.doctrans_hid,
                            doctype_id: this.doctitle_list[i].doctype_id ,
                            doctemplate_id: this.doctitle_list[i].id,
                        }
                        if(this.doctitle_list[i].type == "Text"){
                            updateitem.txt = this.doctitle_list[i].dataV;
                            create_up_data.txt = this.doctitle_list[i].dataV;
                        }else if(this.doctitle_list[i].type == "Date"){
                            updateitem.date = this.doctitle_list[i].dataV;
                            create_up_data.date = this.doctitle_list[i].dataV;
                        }else if(this.doctitle_list[i].type == "Number"){
                            updateitem.number = Number(this.doctitle_list[i].dataV);
                            create_up_data.number = Number(this.doctitle_list[i].dataV);
                        }else{
                            updateitem.ddvalue = this.doctitle_list[i].dataV;
                            create_up_data.ddvalue = this.doctitle_list[i].dataV;
                        }
                        if(this.doctitle_list[i].doctrans_id){
                            await api.UpdateDoctrans(this.doctitle_list[i].doctrans_id,updateitem)
                        }else{
                            await api.createDocTransList(create_up_data)
                        }
                        
                    }
                }

            }catch(err) {
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Information Saved",
                    "ไม่สามารถบันทึกได้!!!",
                    "text-h5 red--text text-center"
                );
            }finally{
                this.$hideLoader();
                if(mode == "Add"){
                    this.$store.state.global_push_dialog = true;
                    this.setupAlertDialogPush(
                        true,
                        "Information Saved",
                        "Information successfully saved",
                        "text-h5 green--text text-center",
                        "/d-document"
                    );
                }else{
                    this.$store.state.global_dialog = true;
                    await this.setupAlertDialog(
                        true,
                        "Information Saved",
                        "Information successfully saved",
                        "text-h5 green--text text-center"
                    );
                    this.$router.go();
                    
                }
            }
        },
        async deleteData(){
            this.dialogDeleteDocTran = true;
        },
        async deleteItemDocTran(){
            this.$showLoader();
            this.dialogDeleteDocTran = false;
            const res_update_doctransH = await api.UpdateDoctranHData(this.$route.params.doctrans_hid,{ status: "I" })
            if(res_update_doctransH.status == 200){
                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                    true,
                    "Information Saved",
                    "Information successfully saved",
                    "text-h5 green--text text-center",
                    "/d-document"
                );
            }
        },
        closedeleteData(){
            this.dialogDeleteDocTran = false;
        },
        async popupdeletefile(index) {
            this.indexdelete = index;
            this.dialogDeletefile = true;
        },
        async closeDeletefile() {
            this.indexdelete = -1;
            this.dialogDeletefile = false;
        },
        deleteItemConfirmfile() {
            this.filename.splice(this.indexdelete, 1);
            this.preview_list.splice(this.indexdelete, 1);
            this.image_list.splice(this.indexdelete, 1);
            this.getpreview_list.splice(this.indexdelete, 1);
            this.closeDeletefile();
        },
        async closeDelete() {
            this.editedIndex = -1;
            this.dialogDelete = false;
        },
        async deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        async popupdeleteimage() {
            this.dialogDeleteimage = true;
        },
        async closeDeleteimage() {
            this.dialogDeleteimage = false;
        },
        async deleteItemConfirmimage() {
            this.checkimageadd = false;
            this.image = null;
            this.previewimage = "";
            this.imagename = "";
            this.dialogDeleteimage = false;
        },
        async popuppreviewfile(fileindex) {
            let pdf_newTab = window.open("");
            pdf_newTab.document.write(
                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                this.getpreview_list[fileindex] +
                "'></iframe></body></html>"
            );

            return;
            this.previewpopupfile2 = [];
            await this.getPdfforpreview(this.getpreview_list[fileindex]);
            console.log(this.previewpopupfile);
            this.previewpopupfile2 = this.previewpopupfile;
            this.checkfileapreview = true;
            this.dialogpreviewfile = true;
        },
        async closepreviewfile() {
            this.dialogpreviewfile = false;
        },
        async popuppreviewimage() {
            this.dialogpreviewimage = true;
        },
        async closepreviewimage() {
            this.dialogpreviewimage = false;
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.previewimage = e.target.result;
                this.checkimageadd = true;
                };
                this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);
            }

            this.imagename = this.image.name;
        },
        async previewMultiImage(event) {
            this.check_upload = 1;
            this.filename = [];
            this.preview_list = [];
            this.getpreview_list = [];
            this.image_list = [];
            var input = event.target;
            var count = input.files.length;
            var index = 0;
            if (input.files) {
                while (count--) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.getpreview_list.push(e.target.result);
                    this.preview_list.push(e.target.result);
                };
                if (input.files[index].type === "application/pdf") {
                    this.image_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                }
                index++;
                }
            }
            await this.getPdfforAdd(this.getpreview_list);
            this.preview_list2 = this.preview_list;
            this.image_list.forEach((x) => {
                this.filename.push(x.name);
            });
            this.checkfileeadd = true;

            //check upload
            if(this.filename[0] == this.fcheck_upload){
                this.check_upload = 0;
            }
            console.log(this.check_upload)
        },
        reset: function() {
            this.image = null;
            this.previewimage = null;
            this.image_list = [];
            this.preview_list = [];
        },
        async getPdfforpreview(datafile) {
            this.previewpopupfile = [];
            let getfile = [];
            getfile = datafile;
            this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
            this.previewpopupfile.forEach((item, index) => {
                item.then((pdf) => {
                this.numPagespreview = pdf.numPages;
                });
            });
            console.log(this.previewpopupfile);
        },
        async getPdfforAdd(item) {
            this.preview_list = [];
            let getfile = [];
            getfile = item;
            getfile.forEach((file, index) => {
                this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
            });
        this.preview_list.forEach((item, index) => {
            item.then((pdf) => {
            this.numPages = pdf.numPages;
            // window.onscroll = function() {
            //   changePage();
            //   stickyNav();
            // };
            // Get the offset position of the navbar
            // var sticky = $("#buttons")[0].offsetTop;
            // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
            // function stickyNav() {
            //   if (window.pageYOffset >= sticky) {
            //     $("#buttons")[0].classList.remove("hidden");
            //   } else {
            //     $("#buttons")[0].classList.add("hidden");
            //   }
            // }
            function changePage() {
                var i = 1,
                count = Number(pdf.numPages);
                do {
                if (
                    window.pageYOffset >=
                    this.findPos(document.getElementById(i)) &&
                    window.pageYOffset <=
                    this.findPos(document.getElementById(i + 1))
                ) {
                    this.page = i;
                }
                i++;
                } while (i < count);
                if (
                window.pageYOffset >= this.findPos(document.getElementById(i))
                ) {
                this.page = i;
                }
            }
            });
        });
        this.checkfileadd = true;
        },
        handle_pdf_link: function(params) {
        // Scroll to the appropriate place on our page - the Y component of
        // params.destArray * (div height / ???), from the bottom of the page div
        var page = document.getElementById(String(params.pageNumber));
        page.scrollIntoView();
        },
        findPos(obj) {
        return obj.offsetTop;
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
};
