import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const createSalesOrderH = (data) => {
  return httpClient.post(server.SALES_ORDER_H + `/create`, data);
};

export const getSalesOrder = () => {
  return httpClient.get(server.SALES_ORDER_H + `/get`);
};

export const getSalesOrderBycompany = (query) => {
  return httpClient.get(server.SALES_ORDER_H + `/getbycompany`, query);
};

export const getSalesOrderBySubmit = (company_id) => {
  return httpClient.get(server.SALES_ORDER_H + `/getStatusSubmit/${company_id}`);
};

export const getAllSOById = (id) => {
  return httpClient.get(server.SALES_ORDER_H + `/getAllById/${id}`);
};

export const getCusId = (id) => {
  return httpClient.get(server.SALES_ORDER_H + `/getCusId/${id}`);
};

export const UpdateSO_H = (id, data) => {
  return httpClient.put(server.SALES_ORDER_H + `/${id}`, data);
};

export const deleteSOById = (id) => {
  return httpClient.delete(server.SALES_ORDER_H + `/${id}`);
};