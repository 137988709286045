import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getInvTitle = () => {
    return httpClient.get(server.INVOICE_TITLE);
};

export const getInvTitleByCompany = (query) => {
    return httpClient.get(server.INVOICE_TITLE +`/getbycompany`,query);
};

export const getInvTitleByDep = (dep_id) => {
    return httpClient.get(server.INVOICE_TITLE +`/get-by-dep_id/${dep_id}`);
};

export const getInvTitleByID = (id) => {
    return httpClient.get(server.INVOICE_TITLE +`/get-by-id/${id}`);
};

export const addInvTitle = (data) => {
    return httpClient.post(server.INVOICE_TITLE, data);
};

export const UpdateInvTitle = (id, data) => {
    return httpClient.put(server.INVOICE_TITLE +`/${id}`,data);
};

export const DeleteInvTitle = (id) => {
    return httpClient.delete(server.INVOICE_TITLE +`/${id}`);
};