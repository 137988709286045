//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import api from "@/services/api";
  import { server } from "@/services/constants";
  import { language } from "../language/Leavelist.js";
  import html2pdf from "html2pdf.js";
  import StockCard from "@/components/cards/StockCard";
  import SuccessDialog from "@/components/cards/SuccessDialog";
  import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
  import { imageUrl } from "@/services/constants";
  import {
    tolocalestringnumber,
    stringcommatonumber,
  } from "../computing/tolocalestringnumber";
  import PrintReceiptDejTab from "./PrintReceiptDejTab";
import PrintReceiptStandardTab from "./PrintReceiptStandardTab";
import PrintReceiptDetailDejTab from "./PrintReceiptDetailDejTab";
import PrintReceiptDetailStandardTab from "./PrintReceiptDetailStandardTab";
  var convert = require("decimal-number-to-words");
  import unity from "@/unity/unity";
  
  export default {
    name: "NewAdvance",
    data() {
      return {
        getcompany_id: localStorage.getItem(server.COMPANYID),
        currency_print: "",
        vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
        check_cus_name: false,
        date_rc: null,
        menu2: false,
        bank_name_show: "",
        bank_no_show: "",
        bank_name: "",
        bank_no: "",
        showinv_rid: "####",
        status_check: false,
        main_id: 0,
        main_customer_id: 0,
        dataAddRc_H: {},
        dataAddRc_D: {},
        payment_by: "",
        payment_comment: "",
        company_trc: "",
        customer_data: [],
        main_inv_id: [],
        res_amout: [],
        res_docRef: [],
        res_so_detail: [],
        res_ts_detail: [],
        res_ad_detail: [],
        res_ms_detail: [],
        dataAll: [],
        desserts: [],
        mDataArray: [],
        customer_code: "",
        customer_name: "",
        doc_ref: "",
        inv_id_by: 0,
        invoice_address: "",
        currency: "",
        tax_id: "",
        branch: "",
        exchange_rate: 0,
        vat_type:"",
        wth_rate: 0,
        wth_ratetotal: 0,
        tax_ratetotal: 0,
        amount_total: 0,
        inv_subtotal: 0,
        payment_term: 0,
        amoumt_t: 0,
        attention: "",
        vat: "",
        po_no: "",
        headers: [
          {
            text: "No.",
            value: "inv_seq",
            class: "bg-colorth texttablehcenter ",
            width: "8%",
            align: "start",
            divider: true,
            sortable: false,
          },
          {
            text: "Service Details",
            align: "center",
            value: "inv_detail",
            class: "bg-colorth texttablehcenter",
            width: "35%",
            align: "start",
            divider: true,
            sortable: false,
          },
          {
            text: "Qty",
            value: "inv_qty",
            class: "bg-colorth texttablehcenter ",
            width: "15%",
            divider: true,
            align: "end",
            sortable: false,
          },
  
          {
            text: "Price Per Unit",
            value: "inv_price",
            class: "bg-colorth texttablehcenter",
            width: "15%",
            divider: true,
            align: "end",
            sortable: false,
          },
          {
            text: "Total Price  ",
            value: "inv_lineamount",
            class: "bg-colorth texttablehcenter",
            width: "15%",
            divider: true,
            align: "end",
            sortable: false,
          },
          {
            value: "actions",
            class: "bg-colorth texttablehcenter",
            align: "center",
            width: "60px",
            divider: true,
            sortable: false,
          },
        ],
        dialogAdd: false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        dialogEdit: false,
        dataedit_service: {
          inv_lineamount: 0,
        },
        editedIndex: -1,
        dialogDeleteServic: false,
        dialogDeleteRecevie: false,
        //print va start
        setlanguage: language.en,
        dialogSelectPrint: false,
        dialogSelect: "1",
  
        currency_show: "",
        payment_by_print: "",
        main_id_print: 0,
        inv_id: 0,
        inv_detail_id: 0,
        tax_detail_rate: 0,
        showprint: "",
        showprintDetail: "",
        textamount_total_en: "",
        print_header_list: [
        {
          id: "1",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
        },
      ],
    
      print_header_list_detail: [
        {
          id: "1",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
        },
      ],
        print_header: null,
        print_detail_header: null,
        print_table_detail: [],
        print_table_Rcdetail: [],
        pageAll: 1,
        pageAllDetail: 1,
        opensummarize: false,
        opensummarizeDetail: false,
        datashowAll: [],
        datashowAllDetail: [],
        dataforshowprpo: [],
        setdatatoshow: [],
        setdatatoshowDetail: [],
        branch_cus:"",
        checklawoffice:false,
        heighttabledetail: "0px",
        //print va end 
      };
    },
    components: {
      StockCard,
      SuccessDialog,
      SuccessDialogPush,
      PrintReceiptDejTab,
    PrintReceiptStandardTab,
    PrintReceiptDetailDejTab,
    PrintReceiptDetailStandardTab,
    },
    computed: {
      version() {
        return this.initial_data;
      },
      computedDateFormatted() {
        return unity.formatDate(this.date_rc);
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
  
    async mounted() {
      this.$forceUpdate();
      const userId = localStorage.getItem(server.USER_ID);
      if (userId && api.isLoggedIn()) {
        // this.$router.push("/");
      } else {
        this.$store.state.isLogged = false;
        this.$router.push("/login");
      }
      await this.loaddataRc();
      await this.loadCustomer();
      await this.loaddataRc_D();
      await this.loadInvByID();
      this.$hideLoader();
    },
    methods: {
      // print start 
  
      async onClicktoPrint() {
        this.dialogSelectPrint = true;
        this.main_id_print = this.$route.params.id;
      },
  
      //print sumary start
      async loadRef_TypeRcById() {
      this.print_header= {};
      const res_inv_id = await api.getDataReceiveDByIDOne(this.main_id_print);
     
      this.inv_id = res_inv_id.data[0].inv_hid;
      this.tax_rate = res_inv_id.data[0].tax_rate;
      this.payment_by_print = res_inv_id.data[0].payment_by;
      this.bank_name_show = res_inv_id.data[0].bank_name;
      this.bank_no_show = res_inv_id.data[0].bank_no;
      const res_hresult = await api.getdataByIdInvoice_HRC(
        res_inv_id.data[0].inv_hid
      );

      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[0];
        this.checklawoffice = false;
        this.heighttabledetail = "270px";
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[1];
        this.checklawoffice = true;
        this.heighttabledetail = "300px";
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.heighttabledetail = "365px";
        this.print_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_header.com_name = res_hresult.data[0].com_name;
        this.print_header.inv_company_address = res_hresult.data[0].inv_company_address;
        this.print_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
        this.print_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
        this.print_header.inv_company_email = res_hresult.data[0].inv_company_email;
        this.print_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
        this.print_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;
      }
  
      if (res_inv_id.data[0].payment_by == "cash") {
        this.print_header.payment_by = "Cash";
      } else if (res_inv_id.data[0].payment_by == "Bank") {
        this.print_header.payment_by = "Transfer";
      } else if (res_inv_id.data[0].payment_by == "cheque") {
        this.print_header.payment_by = "Cheque";
      } else {
        this.print_header.payment_by = "Other";
      }
      this.print_header.payment_detail =
        res_inv_id.data[0].payment_comment;
      this.print_header.inv_id = res_inv_id.data[0].trc_id;

      this.print_header.invoice_date = res_hresult.data[0].date_show;

      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = this.tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );

    },
      async loadMainInvSoData() {
      // var tmp_inv_data;
      // this.print_header = {};
      const res_inv_so = await api.getdataByIdInvoice_HRC(this.inv_id);

      if (res_inv_so.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[1];
      }else{
        this.getcompany_id = res_inv_so.data[0].company_inv;
        this.print_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_header.com_name = res_inv_so.data[0].com_name;
        this.print_header.inv_company_address = res_inv_so.data[0].inv_company_address;
        this.print_header.inv_company_branch = res_inv_so.data[0].inv_company_branch;
        this.print_header.inv_company_tel = res_inv_so.data[0].inv_company_tel;
        this.print_header.inv_company_email = res_inv_so.data[0].inv_company_email;
        this.print_header.inv_tax_id = res_inv_so.data[0].inv_tax_id;
        this.print_header.inv_company_name_th = res_inv_so.data[0].inv_company_name_th;
      }
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_header.addressall = res_inv_so.data[0].addressall;
      this.print_header.customer_address = res_inv_so.data[0].customer_address;

      this.print_header.province_invoice = res_inv_so.data[0].province_invoice;
      this.print_header.country_invoice = res_inv_so.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_inv_so.data[0].zipcode_invoice;

      this.print_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_inv_so.data[0].customer_taxid;
      this.branch_cus = res_inv_so.data[0].customer_branch;
      this.print_header.tax_rate = this.tax_rate;
      // alert(this.branch_cus)
    },
    async loadInvSoData() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
     
      const res_inv_rc = await api.getDataPrint(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;

      tmp_inv_data.forEach((item, index) => {
        var data = {
          no: 0,
          inv_id: "",
          date: null,
          credit_term: 0,
          currency: "",
          amount: 0,
        };

        data.no = index + 1;
        data.inv_id = item.inv_id;
        data.date = item.date_show;
        data.credit_term = item.credit_term;
        data.currency = item.currency;
      
        data.amount = Number(item.Amount);
        if(this.vat_type == "E"){
          data.amount_s = Number(item.Amount);
        }else if(this.vat_type == "I"){
          data.amount_s = Number(item.Amount);
        }else{
          return;
        }
        data.amount_s = parseFloat(data.amount_s);
        this.print_table_detail.push(data);
        sub_total = Number(sub_total) + Number(data.amount);
      });
      sub_total = sub_total.toFixed(2);

      if(this.vat_type == "E"){
        this.print_header.sub_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_header.tax_rate_amount = parseFloat(
          Number((this.print_header.sub_total * this.vat) / 100).toFixed(2)
        );
        this.print_header.amount_total = parseFloat(
          Number(this.print_header.sub_total) + Number(this.print_header.tax_rate_amount)
        );
        console.log("this.print_header.sub_total: " + this.print_header.sub_total)
        console.log("this.print_header.tax_rate_amount: " + this.print_header.tax_rate_amount)
      }else if(this.vat_type == "I"){
        this.print_header.amount_total = parseFloat(sub_total);
        this.print_header.sub_total = parseFloat(
          (sub_total * 100 ) / (100 + Number(this.vat))
        )
        this.print_header.tax_rate_amount = parseFloat(
          this.print_header.amount_total - this.print_header.sub_total
        );
      }else{
        return;
      }

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }


      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total.toFixed(2));
        let setcheckscratch = false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });

      }else{
       
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          let thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          let thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          let [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let  de_check = 0;

          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            let numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              let units = thaiUnits[numArrays.length - index - 1];
              let digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          let numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            let unit = thaiUnits[numArray.length - index - 1];
            let digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";



          let result = "";
          result = integerText + decimalText;
          this.textamount_total_en = result;
      }

    },
    async loadInvSoDataOth() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
      var gran_total = 0;
      var sub_total_oth = 0;
      var gran_total_oth = 0;
      const res_inv_rc = await api.getDataPrint(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;
      console.log("res_inv_rc.data:")
      console.log("")
      tmp_inv_data.forEach((item, index) => {
        var data = {
          no: 0,
          inv_id: "",
          date: null,
          credit_term: 0,
          currency: "",
          amount: 0,
          amount_s: 0,
          amount_s_ex: 0,
          amount_sub: 0
        };
        data.no = index + 1;
        data.inv_id = item.inv_id;
        data.date = item.date_show;
        data.credit_term = item.credit_term;
        data.currency = item.currency;
        data.amount = Number(item.inv_amount_total);
        if(this.vat_type == "E"){
          data.amount_s = Number(item.inv_amount_total).toFixed(2);
        }else if(this.vat_type == "I"){
          // data.amount_s = (data.amount  inv_sub_total(data.amount * (this.vat / 100))).toFixed(2);
          data.amount_s = Number(item.inv_amount_total).toFixed(2);
        }else{
          return;
        }
        data.amount_sub = Number(item.inv_sub_total).toFixed(2);

        data.amount_s_ex = Number(data.amount_s) * this.exchange_rate ;
        data.amount_s_ex =  Number(data.amount_s_ex.toFixed(2));
        data.amount_s = parseFloat(data.amount_s);
        data.amount_s_ex = parseFloat(data.amount_s_ex);
        this.print_table_detail.push(data);

 
        this.curency_show_tb = "";
        this.curency_show_tb = item.currency;

        sub_total_oth = Number(sub_total_oth) + Number(data.amount_sub);
        gran_total_oth = Number(gran_total_oth) + Number(data.amount_s);
        gran_total = Number(gran_total) + Number(data.amount_s_ex);

      });
      sub_total_oth = sub_total_oth.toFixed(2)
      gran_total_oth = gran_total_oth.toFixed(2)
      gran_total = gran_total.toFixed(2)
      console.log("gran_total_oth") 
      console.log(gran_total_oth) 
      // if(this.vat_type == "E"){
      //   this.print_header.sub_total_oth = parseFloat(Number(gran_total_oth).toFixed(2));
      //   this.print_header.tax_rate_amount_oth = parseFloat(Number(this.print_header.sub_total_oth * (this.vat/100)).toFixed(2));
      //   this.print_header.amount_total_oth = parseFloat(Number(this.print_header.sub_total_oth + this.print_header.tax_rate_amount_oth).toFixed(2));

      //   this.print_header.amount_total = parseFloat(Number(this.print_header.amount_total_oth * this.exchange_rate).toFixed(2));
      //   this.print_header.sub_total = parseFloat(Number(this.print_header.sub_total_oth * this.exchange_rate).toFixed(2));
      //   this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - this.print_header.sub_total);
      //   //
      // }else  if(this.vat_type == "I"){
        this.print_header.amount_total_oth = parseFloat(Number(gran_total_oth).toFixed(2));
        this.print_header.sub_total_oth = parseFloat(Number(sub_total_oth).toFixed(2));
        this.print_header.tax_rate_amount_oth = parseFloat(this.print_header.amount_total_oth -  this.print_header.sub_total_oth);

        this.print_header.amount_total = parseFloat(Number(this.print_header.amount_total_oth * this.exchange_rate).toFixed(2));
        this.print_header.sub_total = parseFloat(Number(this.print_header.amount_total  / (1 + this.vat/100)).toFixed(2));
        this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - this.print_header.sub_total);
      // }else{
        
      // }
      console.log("this.print_header") 
      console.log(this.print_header)
      // this.print_header.amount_total_oth = parseFloat(gran_total_oth);
      // this.print_header.amount_total = parseFloat(gran_total);

      // sub_total_oth = (this.print_header.amount_total_oth / (1 + (this.vat / 100))).toFixed(2) 
      // sub_total = (this.print_header.amount_total / (1 + (this.vat / 100))).toFixed(2) 
      // this.print_header.sub_total_oth = parseFloat(sub_total_oth);
      // this.print_header.sub_total = parseFloat(sub_total);

      
      // this.print_header.tax_rate_amount_oth = parseFloat(this.print_header.amount_total_oth - sub_total_oth)
      // this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - sub_total)

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }


      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total.toFixed(2));
        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });
      }else{
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let  de_check = 0;
          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            var numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              var units = thaiUnits[numArrays.length - index - 1];
              var digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          var numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            var unit = thaiUnits[numArray.length - index - 1];
            var digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";



          var result = "";
          result = integerText + decimalText;
          this.textamount_total_en = result;
      }
      // this.print_header.sub_total = sub_total;

    },
      async checkcontent() {
        this.datashowAll = [];
        // alert(data.length);
        this.setdatatoshow = [];
        let linedetailprpo = 23;
        let datainlineprpo = 80;
        let addnewbutget = 1;
        let getdata = [];
        let getnewdata = [];
        let hllinedetails = 0;
        this.pageAll = 1;
        let newhllinedetails = 0;
        for (let i = 0; i < this.print_table_detail.length; i++) {
          let stringchecklength = "";
          let lengthofdataforshowprpo = this.print_table_detail[i].inv_id.length;
  
          let maiek = "่"; //ไม้เอก
          let maito = "้"; //ไม้โท
          let maitree = "๊"; //ไม้ตรี
          let maijattawa = "๋"; //ไม้จัตวา
          let vowelei = "ิ"; //สระอิ
          let vowelee = "ี"; //สระอี
          let voweleue = "ึ"; //สระอึ
          let voweleu = "ื"; //สระอือ
          let vowelu = "ุ"; //สระอุ
          let voweluu = "ู"; //สระอู
          let maihun = "ั"; //ไม้หันอากาศ
          let maiyamok = "็"; //ไม้เอก
          newhllinedetails = 0;
          getnewdata = [];
          this.print_table_detail[i].no = i + 1;
  
          for (let j = 0; j < this.print_table_detail[i].inv_id.length; j++) {
            if (
              stringchecklength[j] == maiek ||
              stringchecklength[j] == maito ||
              stringchecklength[j] == maitree ||
              stringchecklength[j] == maijattawa ||
              stringchecklength[j] == vowelei ||
              stringchecklength[j] == vowelee ||
              stringchecklength[j] == voweleue ||
              stringchecklength[j] == voweleu ||
              stringchecklength[j] == vowelu ||
              stringchecklength[j] == voweluu ||
              stringchecklength[j] == maihun ||
              stringchecklength[j] == maiyamok
            ) {
              lengthofdataforshowprpo--;
            }
          }
          newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

          // if (this.print_table_detail[i].first_item == 1) {
          //   newhllinedetails++;
          // }
  
          // newhllinedetails = Math.ceil(
          //   this.print_table_detail[i].NAME.length / datainlineprpo
          // );
  
          // newhllinedetails += addnewbutget;
          getnewdata.push(this.print_table_detail[i]);
        
          if (hllinedetails + newhllinedetails > linedetailprpo) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            this.pageAll++;
  
            hllinedetails = 0;
            getdata = [];
            getdata.push(this.print_table_detail[i]);
            hllinedetails += newhllinedetails;
            if (i == this.print_table_detail.length - 1) {
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          } else {
            hllinedetails += newhllinedetails;
  
            getdata.push(this.print_table_detail[i]);
  
            if (i == this.print_table_detail.length - 1) {
              this.setdatatoshow[this.pageAll - 1] = getdata;
              getdata = [];
            }
          }
        }
        this.opensummarize = true;
      
  
        this.setdatatoshow.forEach((item, iitem) => {
        
          if (iitem == this.setdatatoshow.length - 1) {
            item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
          }
  
          this.datashowAll.push(item);
        });
   
      },
      async exportToPDF() {
        // this.$refs.html2Pdf.generatePdf();
  
        this.showprint = "";
        let element = document.getElementById("mydivhtmltobase");
        const opt = {
          margin: [0, 0, 0, 0],
          image: {
            type: "jpeg",
            quality: 0.98,
          },
          // enableLinks: false,
          html2canvas: {
            scale: 2,
            dpi: 192,
            letterRendering: true,
            useCORS: true,
            // ignoreElements: (e) => {
            //   return e.classList.contains("cke_pagebreak") ||
            //     e.classList.contains("html2pdf__page-break")
            //     ? true
            //     : false;
            // },
          },
          jsPDF: {
            unit: "cm",
            format: "A4",
            orientation: "portrait",
            putOnlyUsedFonts: true,
            pagesplit: true,
          },
          // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
        };
  
        let abcd = await html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .get("pdf")
          // .output("bloburl")
          .then(function(pdf) {
           
            const totalPages = pdf.internal.getNumberOfPages();
            pdf.deletePage(totalPages);
            return pdf.output("bloburl");
          });
        // console.log(abcd);
        window.open(abcd);
        let blob = await fetch(abcd).then((r) => r.blob());
        this.showprint = "display:none;";
  
        return;
      },
      //print summary end
      //print detail start
      async loadRef_TypeRcDetailById() {
      this.print_detail_header= {};
      const res_inv_id = await api.getDataReceiveDByIDOne(this.main_id_print);
     
      this.inv_detail_id = res_inv_id.data[0].inv_hid;
      this.tax_detail_rate = res_inv_id.data[0].tax_rate;
      this.payment_by_print = res_inv_id.data[0].payment_by;
      this.bank_name_show = res_inv_id.data[0].bank_name;
      this.bank_no_show = res_inv_id.data[0].bank_no;
      let inv_no = "";
      res_inv_id.data.forEach((item, index) => {
        if (index < res_inv_id.data.length - 1) {
          inv_no = inv_no + item.inv_id + ", ";
        } else {
          inv_no = inv_no + item.inv_id;
        }
      });

      const res_hresult = await api.getdataByIdInvoice_HRC(
        res_inv_id.data[0].inv_hid
      );

      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[0];
        this.checklawoffice = false;
        this.heighttabledetail = "270px";
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[1];
        this.checklawoffice = true;
        this.heighttabledetail = "300px";
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.heighttabledetail = "365px";
        this.print_detail_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_detail_header.com_name = res_hresult.data[0].com_name;
        this.print_detail_header.inv_company_address = res_hresult.data[0].inv_company_address;
        this.print_detail_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
        this.print_detail_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
        this.print_detail_header.inv_company_email = res_hresult.data[0].inv_company_email;
        this.print_detail_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
        this.print_detail_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;
      }
     
      this.print_detail_header.invoice_no = inv_no;
      if (res_inv_id.data[0].payment_by == "cash") {
        this.print_detail_header.payment_by = "Cash";
      } else if (res_inv_id.data[0].payment_by == "Bank") {
        this.print_detail_header.payment_by = "Bank Transfer";
      } else if (res_inv_id.data[0].payment_by == "cheque") {
        this.print_detail_header.payment_by = "Bank Cheque";
      } 
      this.print_detail_header.payment_detail =
        res_inv_id.data[0].payment_comment;
      this.print_detail_header.inv_id = res_inv_id.data[0].trc_id;

      this.print_detail_header.invoice_date = res_hresult.data[0].date_show;

      this.print_detail_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_detail_header.tax_rate = this.tax_detail_rate;
      this.print_detail_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
     
    },
      async loadMainRcData() {
      // this.print_detail_header = {};
      // var tmp_inv_data;
      const res_inv_so = await api.getdataByIdInvoice_HRC(this.inv_detail_id);
      if (res_inv_so.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[1];
      }else{
        this.getcompany_id = res_inv_so.data[0].company_inv;
        this.print_detail_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_detail_header.com_name = res_inv_so.data[0].com_name;
        this.print_detail_header.inv_company_address = res_inv_so.data[0].inv_company_address;
        this.print_detail_header.inv_company_branch = res_inv_so.data[0].inv_company_branch;
        this.print_detail_header.inv_company_tel = res_inv_so.data[0].inv_company_tel;
        this.print_detail_header.inv_company_email = res_inv_so.data[0].inv_company_email;
        this.print_detail_header.inv_tax_id = res_inv_so.data[0].inv_tax_id;
        this.print_detail_header.inv_company_name_th = res_inv_so.data[0].inv_company_name_th;

      }
      
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_detail_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_detail_header.addressall = res_inv_so.data[0].addressall;
      this.print_detail_header.customer_address =
        res_inv_so.data[0].customer_address;

      this.print_detail_header.province_invoice =
        res_inv_so.data[0].province_invoice;
      this.print_detail_header.country_invoice =
        res_inv_so.data[0].country_invoice;
      this.print_detail_header.zipcode_invoice =
        res_inv_so.data[0].zipcode_invoice;

      this.print_detail_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_detail_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_detail_header.customer_taxid =
        res_inv_so.data[0].customer_taxid;
      this.branch_cus = res_inv_so.data[0].customer_branch;
      this.print_detail_header.tax_rate = this.vat;
    },
    async loadRcData() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
      const res_inv_rc = await api.getDataReceiveDByID(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;

      tmp_inv_data.forEach((item, index) => {
        var data = {
          description: "",
          quantity: 0,
          amount: 0,
        };

        data.description = item.inv_detail;
        data.quantity = parseFloat(item.inv_qty);
        data.amount = parseFloat(item.inv_lineamount);
        this.print_table_Rcdetail.push(data);
        sub_total = sub_total + parseFloat(item.inv_lineamount);
      });
      if(this.vat_type == "E"){
        this.print_detail_header.sub_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_detail_header.tax_rate_amount = parseFloat(
          Number((this.print_detail_header.sub_total * this.tax_detail_rate) / 100).toFixed(2)
        );
        this.print_detail_header.amount_total = parseFloat(
          this.print_detail_header.sub_total +
            this.print_detail_header.tax_rate_amount
        );
      }else if(this.vat_type == "I"){
        this.print_detail_header.amount_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_detail_header.sub_total = parseFloat(
          Number(sub_total / (1 + this.tax_detail_rate/100)).toFixed(2)
        );
        this.print_detail_header.tax_rate_amount = parseFloat(
          this.print_detail_header.amount_total - this.print_detail_header.sub_total
        );
      }else{

      }

      // this.textamount_total_en = convert.toWords(
      //   this.print_detail_header.amount_total.toFixed(2)
      // );

      //check lag
      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_detail_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }

      if(this.check_cus_name){
        
        this.textamount_total_en = convert.toWords(
          this.print_detail_header.amount_total.toFixed(2)
        );

        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });
      }else{
        var result = "";

          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_detail_header.amount_total = Math.round(this.print_detail_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_detail_header.amount_total.toString().split('.');
          
          let  de_check = 0;
            if(decimalPart){
              if(decimalPart.split('').length == 1){
                if(Number(decimalPart) >= 10){
                  decimalPart = decimalPart;
                }else if(Number(decimalPart) >0){
                  decimalPart = Number(decimalPart) * 10;
                }else{
                  de_check = 1;
                }
              }
            }else{
              de_check = 1;
            }
            
            let decimalText = '';
            if(de_check == 0){
              decimalPart = String(decimalPart);

              
              let numArrays = decimalPart.split('').map(Number);
              numArrays.forEach((num, index) => {
                let units = thaiUnits[numArrays.length - index - 1];
                let digits = thaiNumbers[num];
                if (num !== 0) {
                  if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                    decimalText += 'เอ็ด' + units;
                  } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += 'ยี่' + units;
                  } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += units;
                  } else {
                    decimalText += digits + units;
                  }
                }
              });

              decimalText = decimalText + "สตางค์";
            }

            // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
            let integerText = '';

            let numArray = integerPart.split('').map(Number);
            numArray.forEach((num, index) => {
              let unit = thaiUnits[numArray.length - index - 1];
              let digit = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
              }
            });

            integerText = integerText + "บาท";

        

            result = integerText + decimalText;
        this.textamount_total_en = result;
        console.log("this.textamount_total_en: " + this.textamount_total_en)
      }
    
    },
      async checkcontentdetail() {
       
        this.datashowAllDetail = [];
        // alert(data.length);
        this.setdatatoshowDetail = [];
        let linedetailprpo = 17;
        let datainlineprpo = 50;
        let addnewbutget = 1;
        let getdata = [];
        let getnewdata = [];
        let hllinedetails = 0;
        this.pageAllDetail = 1;
        let newhllinedetails = 0;
        for (let i = 0; i < this.print_table_Rcdetail.length; i++) {
          let stringchecklength = "";
          let lengthofdataforshowprpo = this.print_table_Rcdetail[i].description
            .length;
  
          let maiek = "่"; //ไม้เอก
          let maito = "้"; //ไม้โท
          let maitree = "๊"; //ไม้ตรี
          let maijattawa = "๋"; //ไม้จัตวา
          let vowelei = "ิ"; //สระอิ
          let vowelee = "ี"; //สระอี
          let voweleue = "ึ"; //สระอึ
          let voweleu = "ื"; //สระอือ
          let vowelu = "ุ"; //สระอุ
          let voweluu = "ู"; //สระอู
          let maihun = "ั"; //ไม้หันอากาศ
          let maiyamok = "็"; //ไม้เอก
          newhllinedetails = 0;
          getnewdata = [];
          this.print_table_Rcdetail[i].no = i + 1;
  
          for (
            let j = 0;
            j < this.print_table_Rcdetail[i].description.length;
            j++
          ) {
            if (
              stringchecklength[j] == maiek ||
              stringchecklength[j] == maito ||
              stringchecklength[j] == maitree ||
              stringchecklength[j] == maijattawa ||
              stringchecklength[j] == vowelei ||
              stringchecklength[j] == vowelee ||
              stringchecklength[j] == voweleue ||
              stringchecklength[j] == voweleu ||
              stringchecklength[j] == vowelu ||
              stringchecklength[j] == voweluu ||
              stringchecklength[j] == maihun ||
              stringchecklength[j] == maiyamok
            ) {
              lengthofdataforshowprpo--;
            }
          }
          newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
       
  
          getnewdata.push(this.print_table_Rcdetail[i]);
       
          if (hllinedetails + newhllinedetails > linedetailprpo) {
          
            this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
            this.pageAllDetail++;
  
            hllinedetails = 0;
            getdata = [];
            getdata.push(this.print_table_Rcdetail[i]);
            hllinedetails += newhllinedetails;
            if (i == this.print_table_Rcdetail.length - 1) {
              this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
              getdata = [];
            }
          } else {
            hllinedetails += newhllinedetails;
  
            getdata.push(this.print_table_Rcdetail[i]);
  
            if (i == this.print_table_Rcdetail.length - 1) {
              this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
              getdata = [];
            }
          }
        }
        this.opensummarizeDetail = true;
     
  
        this.setdatatoshowDetail.forEach((item, iitem) => {
         
          if (iitem == this.setdatatoshowDetail.length - 1) {
            item[this.setdatatoshowDetail[iitem].length - 1].lastdata =
              "lastindex";
          }
  
          this.datashowAllDetail.push(item);
        });
      
      },
      async exportToPDFDetail() {
        // this.$refs.html2Pdf.generatePdf();
  
        this.showprintDetail = "";
        let element = document.getElementById("mydivhtmltobaseDetail");
        const opt = {
          margin: [0, 0, 0, 0],
          image: {
            type: "jpeg",
            quality: 0.98,
          },
          // enableLinks: false,
          html2canvas: {
            scale: 2,
            dpi: 192,
            letterRendering: true,
            useCORS: true,
            // ignoreElements: (e) => {
            //   return e.classList.contains("cke_pagebreak") ||
            //     e.classList.contains("html2pdf__page-break")
            //     ? true
            //     : false;
            // },
          },
          jsPDF: {
            unit: "cm",
            format: "A4",
            orientation: "portrait",
            putOnlyUsedFonts: true,
            pagesplit: true,
          },
          // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
        };
  
        let abcd = await html2pdf()
          .from(element)
          .set(opt)
          .toPdf()
          .get("pdf")
          // .output("bloburl")
          .then(function(pdf) {
           
            const totalPages = pdf.internal.getNumberOfPages();
            pdf.deletePage(totalPages);
            return pdf.output("bloburl");
          });
        // console.log(abcd);
        window.open(abcd);
        let blob = await fetch(abcd).then((r) => r.blob());
        this.showprintDetail = "display:none;";
  
        return;
      },
      //print detail end 
      async printReceive() {
      if (this.dialogSelect == "1") {
        // alert(this.currency_print)
        this.datashowAll = [];
        this.print_table_detail = [];
        this.dialogSelectPrint = false;
        this.$showLoader();
        await this.loadRef_TypeRcById();
        await this.loadMainInvSoData();
        if(this.currency == "THB"){
          await this.loadInvSoData();    
        }else{
          await this.loadInvSoDataOth(); 
        }
        await this.checkcontent();
        await this.exportToPDF();
        this.$hideLoader();
      } else {
        this.datashowAllDetail = [];
        this.print_table_Rcdetail = [];
        this.dialogSelectPrint = false;
        this.$showLoader();
        await this.loadRef_TypeRcDetailById();
        await this.loadMainRcData();
        await this.loadRcData();
        await this.checkcontentdetail();
        await this.exportToPDFDetail();
        this.$hideLoader();
      }
    },
  
      //print end 
      setupAlertDialogPush(status, title, message, text_color, link) {
        this.title = title;
        this.message = message;
        this.dialogAdd = status;
        this.text_color = text_color;
        this.link = link;
      },
      setupAlertDialog(status, title, message, text_color) {
        this.title = title;
        this.message = message;
        this.dialogAdd = status;
        this.text_color = text_color;
      },
      async SumDataT(){
      
      let sum_data = 0;
      this.mDataArray.forEach((item)=>{
        item.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
        sum_data = sum_data + item.inv_lineamount;
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);
      })
      if(this.vat_type == "E"){
      this.inv_subtotal = tolocalestringnumber(sum_data);
      this.wth_ratetotal = 0;      
      // this.wth_ratetotal = tolocalestringnumber(Number(this.wth_rate)/100 * sum_data);
      this.tax_ratetotal = tolocalestringnumber(Number(this.vat/100 * sum_data));
      this.amount_total = tolocalestringnumber(sum_data  +(Number(this.vat/100 * sum_data)));
      // this.amount_total = tolocalestringnumber(sum_data - (Number(this.wth_rate)/100 * sum_data) +(Number(this.vat/100 * sum_data)));
    }else if(this.vat_type == "I"){
        this.amount_total = tolocalestringnumber(sum_data);
        // this.amount_total = tolocalestringnumber(sum_data /(1+Number(this.wth_rate)/100 ));
        this.wth_ratetotal = 0;
        // this.wth_ratetotal = tolocalestringnumber(Number(sum_data - stringcommatonumber(this.amount_total)).toFixed(2));
        this.inv_subtotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) / (1 + this.vat / 100))).toFixed(2));
        this.tax_ratetotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) - stringcommatonumber(this.inv_subtotal))).toFixed(2));
      
    }else{

    }
      
    },
    async loadInvByID() {
      const res_inv = await api.getdataByIdInvoice_HRC(this.inv_id_by)
      this.branch = res_inv.data[0].customer_branch
      this.payment_term = res_inv.data[0].customer_paymentterm;
      this.attention = res_inv.data[0].attention;
      this.po_no = res_inv.data[0].po_no;
      this.wth_rate = res_inv.data[0].wth_rate;
      // this.vat_type = res_inv.data[0].vat_type;
      await this.SumDataT();
    },
  
      async loaddataRc() {
      this.main_id = this.$route.params.id;
      const res_rc = await api.getDataReceiveByID(this.main_id);

      this.main_customer_id = res_rc.data[0].customer_id;
      this.payment_by = res_rc.data[0].payment_by;
      this.bank_name  = res_rc.data[0].bank_name;
      this.bank_no = res_rc.data[0].bank_no;
      this.payment_comment = res_rc.data[0].payment_comment;
      this.company_trc = res_rc.data[0].company_trc;
      this.vat = res_rc.data[0].tax_rate;
      this.amoumt_t = res_rc.data[0].trc_amoumt;
      if(res_rc.data[0].trc_id != null){
        this.showinv_rid = res_rc.data[0].trc_id;
      }
      this.exchange_rate = res_rc.data[0].exchange_rate;
      this.date_rc = new Date(res_rc.data[0].trc_date_show).toISOString().substr(0, 10);
    },
      async loadCustomer() {
      if (this.main_customer_id != 0) {
        const res_customer = await api.getCustomersById(this.main_customer_id);
        this.customer_data = res_customer.data;
        console.log(this.customer_data[0])

        //set data
        this.customer_code = this.customer_data[0].cust_account;
        this.customer_name = this.customer_data[0].name;
        this.invoice_address = this.customer_data[0].address_invoice;
        this.currency = this.customer_data[0].currency;
        this.currency_print = this.customer_data[0].currency;
        this.payment_term = this.customer_data[0].payment_term;
        this.tax_id = this.customer_data[0].taxid;
        this.vat_type = this.customer_data[0].vat_type;
      }
    },
      async loaddataRc_D() {
      let inv_docref = "";
      const res_rc_detail = await api.getDataReceiveDByID(this.main_id);
      if (res_rc_detail.data[0].status == 2) {
        this.status_check = true;
      }
      this.desserts = res_rc_detail.data;
      this.desserts.forEach((item, index) => {
        item.inv_qty = tolocalestringnumber(item.inv_qty);
        item.inv_price = tolocalestringnumber(item.inv_lineamount / item.inv_qty);
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);

        if (index < this.desserts.length - 1) {
          inv_docref = inv_docref + item.inv_hid + ",";
        } else {
          inv_docref = inv_docref + item.inv_hid;
        }
      });
      this.mDataArray = this.desserts;
      const res_docRef = await api.getReceiveDetail("INV_REF", {
        id: inv_docref,
      });
      this.res_docRef = res_docRef.data;
      let inv_id_s = "";
      this.res_docRef.forEach((item, index) => {
        if (index < this.res_docRef.length - 1) {
          inv_id_s = inv_id_s + item.inv_id + ",";
        } else {
          inv_id_s = inv_id_s + item.inv_id;
        }
      });
      this.doc_ref = inv_id_s;
      this.inv_id_by = res_docRef.data[0].id;
   
    },
      async openeditItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.dataedit_service.inv_seq = item.inv_seq;
        this.dataedit_service.inv_detail = item.inv_detail;
        this.dataedit_service.inv_qty = stringcommatonumber(item.inv_qty);
        this.dataedit_service.inv_price = stringcommatonumber(item.inv_price);
        this.dataedit_service.inv_lineamount = item.inv_lineamount;
        this.dialogEdit = true;
      },
      async closeeditItem() {
        this.dialogEdit = false;
      },
      async calserviceamount() {
        this.dataedit_service.inv_lineamount = parseFloat(
          this.dataedit_service.inv_qty * this.dataedit_service.inv_price
        );
        this.dataedit_service.inv_lineamount = tolocalestringnumber(this.dataedit_service.inv_lineamount);
      },
      async saveeditItem() {
        this.desserts[this.editedIndex].inv_detail = this.dataedit_service.inv_detail;
        this.desserts[this.editedIndex].inv_qty =  tolocalestringnumber(this.dataedit_service.inv_qty);
        this.desserts[this.editedIndex].inv_price = tolocalestringnumber(this.dataedit_service.inv_price);
        this.desserts[this.editedIndex].inv_lineamount = this.dataedit_service.inv_lineamount;
        this.dialogEdit = false;
        await this.SumDataT();
      },
      deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.dialogDeleteServic = true;
      },
      closeDeleteService() {
        this.dialogDeleteServic = false;
      },
      async deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.desserts.forEach((item, index) => {
          if (index >= this.editedIndex) {
            item.inv_seq = item.inv_seq - 1;
          }
        });
        this.dialogDeleteServic = false;
        await this.SumDataT();
      },
      async saveinvDraft() {
        // alert(this.exchange_rate)
        if (this.exchange_rate == 0 || this.exchange_rate == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check information!!!",
            "Please Enter Exchange Rate Data!!!",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.dataAddRc_H.trc_amoumt = Number(stringcommatonumber(this.amount_total));
        this.dataAddRc_H.trc_sub_amoumt = Number(stringcommatonumber(this.inv_subtotal));
        this.dataAddRc_H.exchange_rate = this.exchange_rate;
        this.dataAddRc_H.payment_by = this.payment_by;
  
        this.dataAddRc_H.bank_name = this.bank_name;
        this.dataAddRc_H.bank_no = this.bank_no;
  
        this.dataAddRc_H.payment_comment = this.payment_comment;
        this.dataAddRc_H.user_update = localStorage.getItem(server.USER_ID);
        this.dataAddRc_H.company_trc = this.company_trc;
        this.dataAddRc_H.trc_tax = Number(stringcommatonumber(this.tax_ratetotal));
        this.dataAddRc_H.trc_date = this.date_rc;
        this.$showLoader();
        const res_updateData = await api.updateReceiveH(
          this.main_id,
          "Draft",
          this.dataAddRc_H
        );
        if (res_updateData.status == 200 || res_updateData.status == 201) {
          await api.deleteReceiveD(this.main_id);
          this.desserts.forEach(async (item) => {
            this.dataAddRc_D.trc_hid = this.main_id;
            this.dataAddRc_D.inv_seq = item.inv_seq;
            this.dataAddRc_D.inv_hid = item.inv_hid;
            this.dataAddRc_D.inv_detail = item.inv_detail;
            this.dataAddRc_D.inv_qty = Number(stringcommatonumber(item.inv_qty));
            this.dataAddRc_D.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
            this.dataAddRc_D.user_create = Number(
              localStorage.getItem(server.USER_ID)
            );
            this.dataAddRc_D.user_update = Number(
              localStorage.getItem(server.USER_ID)
            );
            await api.CreateDataReceiveD(this.dataAddRc_D);
          });
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            `/receive-adjsut-edit/${this.$route.params.id}`
          );
          await this.loaddataRc();
          await this.loadCustomer();
          await this.loaddataRc_D();
          await this.loadInvByID();
  
        } else {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "ผิดพลาด!!!",
            "บันทึกข้อมูลไม่สำเร็จ!!!",
            "text-h5 red--text text-center",
            "/receive"
          );
          this.$hideLoader();
        }
      },
      async saveCancel(){
        this.dialogDeleteRecevie = true;
      },
      async saveinvSubmit() {
        if (this.exchange_rate == 0 || this.exchange_rate == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check information!!!",
            "Please Enter Exchange Rate Data!!!",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.dataAddRc_H.trc_amoumt = Number(stringcommatonumber(this.amount_total));
        this.dataAddRc_H.trc_sub_amoumt = Number(stringcommatonumber(this.inv_subtotal));

        this.dataAddRc_H.payment_by = this.payment_by;
        this.dataAddRc_H.exchange_rate = this.exchange_rate;
        this.dataAddRc_H.payment_comment = this.payment_comment;
  
        this.dataAddRc_H.bank_name = this.bank_name;
        this.dataAddRc_H.bank_no = this.bank_no;
  
        // this.dataAddRc_H.company_trc = this.company_trc;
        // this.dataAddRc_H.trc_tax = Number(stringcommatonumber(this.tax_ratetotal));
        // this.dataAddRc_H.status = 2;
        this.dataAddRc_H.user_update = localStorage.getItem(server.USER_ID);
  
        // this.dataAddRc_H.trc_date = this.date_rc;
  
        this.$showLoader();
        const res_updateData = await api.updateReceiveH(
          this.main_id,
          "Summit_Adjust",
          this.dataAddRc_H
        );
        if (res_updateData.status == 200 || res_updateData.status == 201) {
          await api.deleteReceiveD(this.main_id);
          this.desserts.forEach(async (item) => {
            this.dataAddRc_D.trc_hid = this.main_id;
            this.dataAddRc_D.inv_seq = item.inv_seq;
            this.dataAddRc_D.inv_hid = item.inv_hid;
            this.dataAddRc_D.inv_detail = item.inv_detail;
            this.dataAddRc_D.inv_qty = Number(stringcommatonumber(item.inv_qty));
            this.dataAddRc_D.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
            this.dataAddRc_D.user_create = Number(
              localStorage.getItem(server.USER_ID)
            );
            this.dataAddRc_D.user_update = Number(
              localStorage.getItem(server.USER_ID)
            );
            await api.CreateDataReceiveD(this.dataAddRc_D);
          });
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            `/receive-adjsut-edit/${this.$route.params.id}`
          );
          await this.loaddataRc();
          await this.loadCustomer();
          await this.loaddataRc_D();
          await this.loadInvByID();
        } else {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "ผิดพลาด!!!",
            "บันทึกข้อมูลไม่สำเร็จ!!!",
            "text-h5 red--text text-center",
            "/receive"
          );
          this.$hideLoader();
        }
      },
      async deleteReceive() {
        this.dialogDeleteRecevie = true;
      },
      closeDeleteRecive() {
        this.dialogDeleteRecevie = false;
      },
      async deleteReceiveConfirm() {
        this.dialogDeleteRecevie = false;
        
        let dataEdit = { status: 0};
        dataEdit.status = 3;

        this.$showLoader();
        const res_update = await api.updateAdjustReceiveH(Number(this.main_id),dataEdit);
        if(res_update.status == 200 || res_update.status == 201){
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            `/adjust-receipt`
          );
        }else{
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "ผิดพลาด!!!",
            "บันทึกข้อมูลไม่สำเร็จ!!!",
            "text-h5 red--text text-center",
            "/receive"
          );
          
        }
        // console.log("res_update")
        // console.log(res_update)
      },
      async onSelectvat_type() {
   
  },
    },
    async beforeCreate() {
      //   console.log("beforeCreate");
      this.$store.state.navMenu = true;
  
      // this.showdefaultsignature();
    },
    async created() {
      // this.showdefaultsignature();
    },
  };
  