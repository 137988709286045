import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getIPPriceList = () => {
    return httpClient.get(server.IP_PRICE_LIST + `/get`);
};
export const getIPPriceListForInvSo = () => {
    return httpClient.get(server.IP_PRICE_LIST + `/get-detail-inv`);
};
export const getIPPriceListByID = (id) => {
    return httpClient.get(server.IP_PRICE_LIST + `/by-id/${id}`);
};
export const getIPPriceListS = (mode,data) => {
    return httpClient.post(server.IP_PRICE_LIST + `/by-shearch/${mode}`,data);
};

export const createIPPriceList = (data) => {
    return httpClient.post(server.IP_PRICE_LIST + `/create`, data);
};

export const checkIPPriceList = (data) => {
    return httpClient.post(server.IP_PRICE_LIST + `/check`, data);
};

export const updateIPPriceList = (id, data) => {
    return httpClient.put(server.IP_PRICE_LIST + `/update/${id}`, data);
};


export const deleteIPPriceList = (id) => {
    return httpClient.delete(server.IP_PRICE_LIST + `/delete/${id}`);
};