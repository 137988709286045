//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import api from "@/services/api";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
} from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialogPushpaymentIM from "@/components/cards/SuccessDialogPushpaymentIM";
import pdfvuer from "pdfvuer";
export default {
  name: "About",
  data(vm) {
    return {
      customer_type_list: [
        { customer_type_id: "Company", customer_type_name: "Company" },
        { customer_type_id: "Agency", customer_type_name: "Agency" },
      ],
      disableCalculateNewRenewalDate: false,
      country_list: [],

      visa_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      work_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_regis: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_application: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_closed: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_of_issuing_certificate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menudate_of_issuing_certificate: false,
      menudate_of_closed: false,
      dialogpreviewimage: false,
      checkfileapreview: false,
      previewpopupfile: [],
      previewpopupfile2: [],
      dialogpreviewfile: false,
      dialogDelete: false,
      dialogDeletefile: false,
      dialogDeleteimage: false,
      fileindex: -1,
      indexdelete: -1,
      preview_list2: [],
      getpreview_list: [],
      image: null,
      previewimage: null,
      previewimageedit: null,
      image_list: [],
      preview_list: [],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      querydata:{},
      tmstatus_list: [],
      contact_owner_list: [],
      contact_agent_list: [],
      contact_associate_list: [],
      worktype_list: [
        { id: "Visa", name: "Visa" },
        { id: "Work Permit", name: "Work Permit" },
        { id: "Work / Visa", name: "Work / Visa" },
        { id: "VISA Cancellation", name: "VISA Cancellation" },
        { id: "Work Cancellation", name: "Work Cancellation" },
        { id: "Urgent Work Permit", name: "Urgent Work Permit" },
        { id: "Visa / Work Cancellation", name: "Visa / Work Cancellation" },
        { id: "Police Clearance Certification (PCC)", name: "Police Clearance Certification (PCC)" },
        { id: "Legalization", name: "Legalization" },
        { id: "Notarial Services", name: "Notarial Services" },
        { id: "LTR", name: "LTR" },
        { id: "Etc.", name: "Etc." },
        { id: "90 days", name: "90 days" },
      ],
      status_list: [
        { id: "Open", name: "Open" },
        { id: "Onprocess", name: "Onprocess" },
        { id: "Complete", name: "Complete" },
        { id: "Close", name: "Close" },
      ],
      participants_type_list: [
        { id: "Principle", name: "Principle" },
        { id: "Spouse", name: "Spouse" },
        { id: "Children", name: "Children" },
        { id: "Parent", name: "Parent" },
      ],
      principle_type_list: [
        { id: "Principle", name: "Principle" },
      ],
      filename: [],
      imagename: "",
      load_bg: false,
      checkfileadd: false,
      checkimageadd: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      showaddItem: {
        work_type: "",
        file_no: 0,
        client_name: "",
        customer_name: "",
        company_name:"",
        customer_id: 0,
        work_permit_data: "",
        visa_expire_date: "",
        work_expire_date: "",
        due_date: "",
        im_file: "",
        status: "",
        remark: "",
        company_id: localStorage.getItem(server.COMPANYID),
        user_create: localStorage.getItem(server.USER_ID),
      },
      menuaddappdate: false,
      menupublication: false,
      menuopposition: false,
      menufilling_appeal: false,
      menufilling_lawsuit: false,
      menurenewal: false,
      menurenewal_filed: false,
      menuaffidavits: false,
      menufiling_affidavits: false,
      menuvisaissuedate: false,
      menuworkissuedate: false,
      menuvisaexpiredate: false,
      menuworkexpiredate: false,
      due_date: false,
      participantsmenuvisaexpiredate: false,
      participantsmenuworkexpiredate: false,
      dependentmenuvisaexpiredate: false,
      dependentmenuworkexpiredate: false,
      dependentmenunextvisaexpiredate: false,
      dependentmenunextworkexpiredate: false,
      menuadd: false,
      fullPage: true,
      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      mDataArray: [],
      headers: [
      {
          text: "Type",
          value: "type",
          class: "bg-colorth texttablehcenter ",
          align: "start",
          width:"10%",
          divider: true,
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          class: "bg-colorth texttablehcenter",
          align: "start",
          width:"10%",
          divider: true,
          sortable: false,
        },
        {
          text: "Work Type",
          value: "work_type",
          class: "bg-colorth texttablehcenter",
          align: "start",
          width:"10%",
          divider: true,
          sortable: false,
        },
        {
          text: "Visa Expire",
          value: "visa_expire",
          class: "bg-colorth texttablehcenter",
          divider: true,
          width: "14%",
          align: "end",
          sortable: false,
        },
        {
          text: "Next Visa Expire",
          value: "next_visa_expire",
          class: "bg-colorth texttablehcenter",
          divider: true,
          width: "14%",
          align: "end",
          sortable: false,
        },
        {
          text: "Work Expire",
          value: "work_expire",
          class: "bg-colorth texttablehcenter",
          divider: true,
          width: "14%",
          align: "end",
          sortable: false,
        },
        {
          text: "Next Work Expire",
          value: "next_work_expire",
          class: "bg-colorth texttablehcenter",
          divider: true,
          width: "14%",
          align: "end",
          sortable: false,
        },
        {
          text: "Due Date 90 Days",
          value: "due_date",
          class: "bg-colorth texttablehcenter",
          divider: true,
          width: "14%",
          align: "end",
          sortable: false,
        },
        // {
        //   value: "actions",
        //   class: "bg-colorth texttablehcenter",
        //   width: "5%",
        //   divider: true,
        //   sortable: false,
        // },
      ],
      defaultdependent: {
        type: "",
        name: "",
        visa_expire: "",
        next_visa_expire: "",
        work_expire: "",
        next_work_expire: "",
      },
      dependent: {
        type: "",
        name: "",
        visa_expire: "",
        next_visa_expire: "",
        work_expire: "",
        next_work_expire: "",
      },
      fileno_list: [],
      transaction: [],

      visa_expire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      work_expire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      work_expire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dependent_visa_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dependent_next_visa_issue_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dependent_workexpire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dependent_next_workexpire_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      workpermitdata_list: [
        { id: "Yes", name: "Yes" },
        { id: "No", name: "No" },
      ],
      disableallcasedata: false,
      disableworkpermitdata: false,
      disableninetydaysdata: false,
      disablefileno: false,
      editedIndex: -1,
      sostatus: false,
      disableintable: false,
      dataimmaster:{},
      getidofmaster:0,
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    SuccessDialogPushpaymentIM,
    pdf: pdfvuer,
  },
  async mounted() {
    await this.loadfileno_list();
    await this.loaddatafile_no();
    this.$hideLoader();
  },
  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  watch: {
    visa_expire_date(val) {
      this.showaddItem.visa_expire_date = this.formatDate(
        this.visa_expire_date
      );
    },
    work_expire_date(val) {
      this.showaddItem.work_expire_date = this.formatDate(
        this.work_expire_date
      );
    },
    due_date(val) {
      this.showaddItem.due_date = this.formatDate(this.due_date);
    },
    dependent_visa_issue_date(val) {
      this.dependent.visa_expire = this.formatDate(
        this.dependent_visa_issue_date
      );
    },
    dependent_workexpire_date(val) {
      this.dependent.work_expire = this.formatDate(
        this.dependent_workexpire_date
      );
    },
    dependent_next_visa_issue_date(val) {
      this.dependent.next_visa_expire = this.formatDate(
        this.dependent_next_visa_issue_date
      );
    },
    dependent_next_workexpire_date(val) {
      this.dependent.next_work_expire = this.formatDate(
        this.dependent_next_workexpire_date
      );
    },
  },
  methods: {
    async WorktypeintableChange(work_type,index){
      if(work_type == "90 days"){
        // this.mDataArray[index].visa_expire = "";
        // this.mDataArray[index].show_visa_expire = "";
        // this.mDataArray[index].next_visa_expire = "";
        // this.mDataArray[index].show_next_visa_expire = "";
        // this.mDataArray[index].work_expire = "";
        // this.mDataArray[index].show_work_expire = "";
        // this.mDataArray[index].next_work_expire = "";
        // this.mDataArray[index].show_next_work_expire = "";
        this.mDataArray[index].disabledate = true;
      }else{
        // this.mDataArray[index].due_date = "";
        // this.mDataArray[index].show_due_date = "";
        this.mDataArray[index].disabledate = false;
      }

    },
    async loaddatafile_no() {
      console.log(this.$route.params.file_no);
      this.fileno_list.forEach((x) => {
        if (x.file_number == this.$route.params.file_no) {
          this.showaddItem.work_type = "Work / Visa";
          this.onSelectWorktypeChange(this.showaddItem.work_type);
          this.showaddItem.file_no = x.id;
          this.onSelectFilenoChange(this.showaddItem.file_no);
        }
      });
    },
    save() {
      console.log(this.dependent);
      if (this.dependent.type == 0 || this.dependent.type == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Type",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dependent.name == 0 || this.dependent.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      // if (this.dependent.visa_expire == 0 || this.dependent.visa_expire == "") {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Visa Expire",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (
      //   this.dependent.next_visa_expire == 0 ||
      //   this.dependent.next_visa_expire == ""
      // ) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Failed!!!",
      //     "Please enter Next Visa Expire",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      // if (this.showaddItem.work_permit_data == "Yes") {
      //   if (
      //     this.dependent.work_expire == 0 ||
      //     this.dependent.work_expire == ""
      //   ) {
      //     this.$store.state.global_dialog = true;
      //     this.setupAlertDialog(
      //       true,
      //       "Failed!!!",
      //       "Please enter Work Expire",
      //       "text-h5 red--text text-center"
      //     );
      //     return;
      //   }
      // }

      // this.dependent.visa_expire = this.parseDate(this.dependent.visa_expire);
      // this.dependent.work_expire = this.parseDate(this.dependent.work_expire);
      // this.dependent.next_visa_expire = this.parseDate(
      //   this.dependent.next_visa_expire
      // );
      // this.dependent.next_work_expire = this.parseDate(
      //   this.dependent.next_work_expire
      // );

       
      //  this.dependent.id =  this.mDataArray.length;
        this.dependent.index =  this.mDataArray.length;
         this.dependent.visa_expire = this.parseDate(this.dependent.visa_expire);
         this.dependent.work_expire = this.parseDate(this.dependent.work_expire);
         this.dependent.show_visa_expire = this.formatDate(this.dependent.visa_expire);
         this.dependent.show_work_expire = this.formatDate(this.dependent.work_expire);
         this.dependent.next_visa_expire =  this.parseDate(this.dependent.next_visa_expire);
         this.dependent.next_work_expire = this.parseDate(this.dependent.next_work_expire);
         this.dependent.show_next_visa_expire =  this.formatDate(this.dependent.next_visa_expire);
         this.dependent.show_next_work_expire = this.formatDate(this.dependent.next_work_expire);
         this.dependent.openmenuvisa_expire = false;
         this.dependent.openmenunext_visa_expire = false;
         this.dependent.openmenuwork_expire = false;
         this.dependent.openmenunext_work_expire = false;
         this.dependent.openmenudue_date = false;
         this.dependent.work_type = this.showaddItem.work_type;
         this.dependent.disabledate=false;
        // this.mDataArray.push(this.dependent);

      // if (this.editedIndex > -1) {
      //   Object.assign(this.mDataArray[this.editedIndex], this.dependent);
      // } else {
      this.mDataArray.push(this.dependent);
      // }

      console.log(this.mDataArray);

      this.close();
    },
    close() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.dependent = Object.assign({}, this.defaultdependent);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.mDataArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    enterSelect(values) {
      console.log(values);
      this.transaction = values;
      if (values.length > 0) {
        this.disableinstatus = false;
      } else {
        this.disableinstatus = true;
      }
    },
    async loadfileno_list() {
      const result = await api.getfile_numberim_principal_Company({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      console.log(result);
      this.fileno_list = result.data;
    },
    async saveaddItem(mode) {
      console.log(this.showaddItem);
      this.transaction.sort(function(a, b){
          return a.index - b.index;
      });
      console.log(this.transaction);

      if (this.showaddItem.work_type == "" || this.showaddItem.work_type == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Work Type",
          "text-h5 red--text text-center"
        );
        return;
      }
      
      if (this.showaddItem.file_no == "" || this.showaddItem.file_no == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter File No.",
          "text-h5 red--text text-center"
        );
        return;
      }

    
      // if (
      //   this.showaddItem.work_type == "Visa" ||
      //   this.showaddItem.work_type == "Work Permit" ||
      //   this.showaddItem.work_type == "Work / Visa" ||
      //   this.showaddItem.work_type == "LTR"
      // ) {
      //   if (
      //     this.showaddItem.visa_expire_date == "" ||
      //     this.showaddItem.visa_expire_date == 0 ||
      //     this.showaddItem.visa_expire_date == null
      //   ) {
      //     this.$store.state.global_dialog = true;
      //     this.setupAlertDialog(
      //       true,
      //       "Failed!!!",
      //       "Please enter Visa Expire Date",
      //       "text-h5 red--text text-center"
      //     );
      //     return;
      //   }
      // }

      // if (this.showaddItem.status == "Complete") {
      //   if (
      //     this.showaddItem.work_expire_date == "" ||
      //     this.showaddItem.work_expire_date == 0 ||
      //     this.showaddItem.work_expire_date == null
      //   ) {
      //     this.$store.state.global_dialog = true;
      //     this.setupAlertDialog(
      //       true,
      //       "Failed!!!",
      //       "Please enter Work Expire Date",
      //       "text-h5 red--text text-center"
      //     );
      //     return;
      //   }
      // }

    
      for (let i = 0; i < this.transaction.length; i++) {
        if (this.transaction[i].type == 0 || this.transaction[i].type == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Type(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
        if (this.transaction[i].name == 0 || this.transaction[i].name == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Name(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
        if (this.transaction[i].work_type == 0 || this.transaction[i].work_type == "") {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Work Type(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
        if(this.showaddItem.status == "Complete" && (this.transaction[i].work_type == "Visa" || this.transaction[i].work_type == "Work / Visa" || this.transaction[i].work_type == "LTR")){
        if (
          this.transaction[i].visa_expire == 0 ||
          this.transaction[i].visa_expire == "" ||
          this.transaction[i].visa_expire == null
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Visa Expire(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
        if (
          this.transaction[i].next_visa_expire == 0 ||
          this.transaction[i].next_visa_expire == "" ||
          this.transaction[i].next_visa_expire == null
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Next Visa Expire(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
      }
      if(this.showaddItem.status == "Complete" && (this.transaction[i].work_type == "Work Permit" || this.transaction[i].work_type == "Work / Visa" || (this.transaction[i].work_type == "LTR" && this.showaddItem.work_permit_data == "Yes") )){
        if (
          this.transaction[i].work_expire == 0 ||
          this.transaction[i].work_expire == "" ||
          this.transaction[i].work_expire == null
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Work Expire(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
      }

      if(this.showaddItem.status == "Complete" && (this.transaction[i].work_type == "Work Permit" || this.transaction[i].work_type == "Work / Visa")){
        if (
          this.transaction[i].next_work_expire == 0 ||
          this.transaction[i].next_work_expire == "" ||
          this.transaction[i].next_work_expire == null
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Next Work Expire(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
      }
      if(this.showaddItem.status == "Complete" && (this.transaction[i].work_type == "90 days")){
        if (
          this.transaction[i].due_date == 0 ||
          this.transaction[i].due_date == "" ||
          this.transaction[i].due_date == null
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            `Please enter Due Date 90 Days(${i+1})`,
            "text-h5 red--text text-center"
          );
          return;
        }
      }
        
     
      }
      // }

      if (this.showaddItem.status == "" || this.showaddItem.status == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Status",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.showaddItem.work_type == "Etc." || this.showaddItem.work_type == "90 days") {
        this.mDataArray = [];
        this.transaction = [];
      }

      this.$showLoader();
      let formData = new FormData();
      formData.append(`file`, this.image);
      this.image_list.forEach((x, index) => {
        formData.append(`file`, x);
      });
      formData.append("work_type", this.showaddItem.work_type);
      formData.append("file_no", this.showaddItem.file_no);
      formData.append("client_name", this.showaddItem.client_name);
      formData.append("customer_name", this.showaddItem.customer_name);
      formData.append("company_name", this.showaddItem.company_name);
      formData.append("customer_id", this.showaddItem.customer_id);
      formData.append("work_permit_data", this.showaddItem.work_permit_data);
      formData.append("status", this.showaddItem.status);
      formData.append("remark", this.showaddItem.remark);
      formData.append("user_create", this.showaddItem.user_create);
      formData.append("company_id", this.showaddItem.company_id);

      if (
        this.showaddItem.visa_expire_date !== "" &&
        this.showaddItem.visa_expire_date !== null
      ) {
        formData.append(
          "visa_expire_date",
          this.changeformatDatetoinsert(this.showaddItem.visa_expire_date)
        );
      }
      if (
        this.showaddItem.work_expire_date !== "" &&
        this.showaddItem.work_expire_date !== null
      ) {
        formData.append(
          "work_expire_date",
          this.changeformatDatetoinsert(this.showaddItem.work_expire_date)
        );
      }
      if (
        this.showaddItem.due_date !== "" &&
        this.showaddItem.due_date !== null
      ) {
        formData.append(
          "due_date",
          this.changeformatDatetoinsert(this.showaddItem.due_date)
        );
      }

      if (mode === "paymentrequest") {
        formData.append("flag", 1);
      }
      
      const resultMasterTM = await api.addim_transaction(formData);
      console.log(resultMasterTM);

      if (resultMasterTM.status == 200 || resultMasterTM.status == 201) {

        var dataTransaction = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Create",
              table_name: "IM_Transaction",
              table_id: resultMasterTM.data.id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.addTransectionLog(dataTransaction)

        var dataEditLog = {
          last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
        
        if (this.transaction.length > 0) {
          this.transaction.forEach(async(item, index) => {
            let adddependent = {};
            adddependent.im_transaction_id = resultMasterTM.data.id;
            adddependent.type = item.type;
            adddependent.name = item.name;
            adddependent.work_type = item.work_type;
            // item.im_transaction_id = resultMasterTM.data.id;
            if (item.visa_expire && item.visa_expire != null) {
              adddependent.visa_expire = item.visa_expire.split("T")[0];
            }
            if (item.next_visa_expire && item.next_visa_expire != null) {
              adddependent.next_visa_expire = item.next_visa_expire.split(
                "T"
              )[0];
            }
            if (item.work_expire && item.work_expire != null) {
              adddependent.work_expire = item.work_expire.split("T")[0];
            }
            if (item.next_work_expire && item.next_work_expire != null) {
              adddependent.next_work_expire = item.next_work_expire.split(
                "T"
              )[0];
            }
            if (item.duedate && item.duedate != null) {
              adddependent.duedate = item.duedate.split(
                "T"
              )[0];
            }

            if(item.type == "Principle" && (item.work_type == "Visa" || item.work_type == "Work Permit" 
            || item.work_type == "Work / Visa" || item.work_type == "VISA Cancellation" || item.work_type =="Urgent Work Permit" || item.work_type == "Work Cancellation"
             || item.work_type == "Visa / Work Cancellation") && 
            ((item.next_visa_expire && item.next_visa_expire != null) || 
            (item.next_work_expire && item.next_work_expire != null))){
              if(item.next_visa_expire && item.next_work_expire && (item.work_type == "Work / Visa" || item.work_type == "Visa / Work Cancellation")){
                api.updateim_principal(this.getidofmaster, {visa_expire_date:item.next_visa_expire.split(
                "T"
              )[0],work_expire_date:item.next_work_expire.split(
                "T"
              )[0]});
              }else if(item.next_visa_expire && (item.work_type == "Visa" || item.work_type == "VISA Cancellation")){
                api.updateim_principal(this.getidofmaster, {visa_expire_date:item.next_visa_expire.split(
                "T"
              )[0]});
              }else if(item.next_work_expire && (item.work_type == "Work Permit" || item.work_type == "Work Cancellation" || item.work_type == "Urgent Work Permit")){
                api.updateim_principal(this.getidofmaster, {work_expire_date:item.next_work_expire.split(
                "T"
              )[0]});
              }else{
              } 
              }

            var res_add_transaction_dependent = await api.addim_transaction_dependent(adddependent);

            var dataAddTransactionDependent = {
                  user_id: localStorage.getItem(server.USER_ID),
                  log_in_out_id: localStorage.getItem(server.LOGIN_ID),
                  action: "Create",
                  table_name: "IM_Transaction_Dependent",
                  table_id: res_add_transaction_dependent.data.id,
                  date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.addTransectionLog(dataAddTransactionDependent)

            var dataEditLog = {
              last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
            }
            await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

            if (index === this.transaction.length - 1) {
              this.$hideLoader();
              if (mode === "paymentrequest") {
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPushpaymentIM(
                  true,
                  "save data!!!",
                  "save data success!!!",
                  "text-h5 green--text text-center",
                  `/new-free-invoice/0/invoice/D/${this.showaddItem.customer_id}/IM`,
                  this.dataimmaster
                );
                return;
              } else {
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialogPush(
                  true,
                  "save data!!!",
                  "save data success!!!",
                  "text-h5 green--text text-center",
                  "/immigration-transaction"
                );
                return;
              }
            }
          });
        } else {
          this.$hideLoader();
          if (mode === "paymentrequest") {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPushpaymentIM(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              `/new-free-invoice/0/invoice/D/${this.showaddItem.customer_id}/IM`,
              this.dataimmaster
            );
            return;
          } else {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "save data!!!",
              "save data success!!!",
              "text-h5 green--text text-center",
              "/immigration-transaction"
            );
            return;
          }
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "save data!!!",
          "can't save data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },

    async onSelectCountryChange(name) {},
    async onSelectAgentChange(id) {
      let setdata;
      setdata = this.contact_agent_list.filter((item) => {
        if (item.contact_id == id) {
          this.showaddItem.agent_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectAssociateChange(id) {
      let setdata;
      setdata = this.contact_associate_list.filter((item) => {
        if (item.contact_id == id) {
          this.showaddItem.associate_address =
            item.address + " " + item.city + " " + item.country;
          return item;
        }
      });
    },
    async onSelectFilenoChange(file_no) {
      console.log(this.fileno_list);
      let principle_visa_expire_date = "";
      let principle_work_expire_date = "";
      this.dataimmaster = {};
      this.fileno_list.filter((x) => {
        if (x.id == file_no) {
          this.getidofmaster = x.id;
          this.showaddItem.client_name = x.client_name;
          this.showaddItem.customer_name = x.customer_name;
          this.showaddItem.company_name = x.company_name;
          this.showaddItem.customer_id = x.customer_id;
          principle_visa_expire_date = x.visa_expire_date;
          principle_work_expire_date = x.work_expire_date;
          this.dataimmaster = {client_name_master:x.client_name?x.client_name:"",company_name_master:x.company_name?x.company_name:"",customer_name_master:x.customer_name?x.customer_name:"",contact_master:x.contact?x.contact:""};
        }
      });
      this.mDataArray = [];
      const result = await api.getdataByIdim_participant(file_no);
        this.mDataArray.push({
          index:0,
          type:"Principle",
          principletype:"Yes",
        visa_expire : principle_visa_expire_date,
        work_expire : principle_work_expire_date,
        show_visa_expire : this.formatDate(principle_visa_expire_date),
        show_work_expire : this.formatDate(principle_work_expire_date),
        name:this.showaddItem.client_name,
        openmenuvisa_expire : false,
        openmenunext_visa_expire : false,
        openmenuwork_expire : false,
        openmenunext_work_expire : false,
        openmenudue_date : false,
        work_type : this.showaddItem.work_type,
        disabledate:false
      })
      result.data.forEach((x,index) => {
        x.index = index + 1;
        x.visa_expire = this.parseDate(x.visa_expire);
        x.work_expire = this.parseDate(x.work_expire);
        x.show_visa_expire = this.formatDate(x.visa_expire);
        x.show_work_expire = this.formatDate(x.work_expire);
        x.openmenuvisa_expire = false;
        x.openmenunext_visa_expire = false;
        x.openmenuwork_expire = false;
        x.openmenunext_work_expire = false;
        x.openmenudue_date = false;
        x.work_type = this.showaddItem.work_type;
        x.disabledate=false;
        this.mDataArray.push(x);
      });
    },
    async onSelectWorktypeChange(work_type) {
      // this.showaddItem.file_no = 0;
      // this.showaddItem.client_name = "";
      //   this.showaddItem.customer_name = "",
      // this.showaddItem.work_permit_data = "";
      this.disablefileno = true;
      if (work_type == "Visa" || work_type == "Work Permit" || work_type == "Work / Visa" 
      || work_type == "VISA Cancellation" || work_type == "Work Cancellation" || work_type == "Urgent Work Permit" || work_type == "Visa / Work Cancellation" 
      || work_type == "Police Clearance Certification (PCC)" || work_type == "Legalization" || work_type =="Notarial Services") {
        this.disableallcasedata = true;
        this.disableworkpermitdata = false;
        this.disableninetydaysdata = false;
        // this.mDataArray = [];
        this.disableintable = true;
      } else if (work_type == "LTR") {
        this.disableallcasedata = true;
        this.disableworkpermitdata = true;
        if(this.showaddItem.work_permit_data){
          this.disableintable = true;
        }else{
          this.disableintable = false;
        }
        // this.mDataArray = [];
        this.disableninetydaysdata = false;
      } else if (work_type == "Etc.") {
        this.disableallcasedata = false;
        this.disableworkpermitdata = false;
        this.disableninetydaysdata = false;
        // this.mDataArray = [];
      } else if (work_type == "90 days") {
        // this.mDataArray = [];
        this.disableallcasedata = false;
        this.disableninetydaysdata = true;
      } else {
      }
    },
    async onSelectWorkpermitdataChange() {
      this.disableintable = true;
    },
    async popupdeletefile(index) {
      this.indexdelete = index;
      this.dialogDeletefile = true;
    },
    async closeDelete() {
      this.editedIndex = -1;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.mDataArray.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    async popupdeleteimage() {
      this.dialogDeleteimage = true;
    },
    async closeDeletefile() {
      this.indexdelete = -1;
      this.dialogDeletefile = false;
    },
    async deleteItemConfirmfile() {
      this.filename.splice(this.indexdelete, 1);
      this.preview_list.splice(this.indexdelete, 1);
      this.image_list.splice(this.indexdelete, 1);
      this.getpreview_list.splice(this.indexdelete, 1);
      this.closeDeletefile();
    },
    async deleteItemConfirmimage() {
      this.checkimageadd = false;
      this.image = null;
      this.previewimage = "";
      this.imagename = "";
      this.dialogDeleteimage = false;
    },
    async popuppreviewfile(fileindex) {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_list[fileindex] +
          "'></iframe></body></html>"
      );

      return;
      this.previewpopupfile2 = [];
      await this.getPdfforpreview(this.getpreview_list[fileindex]);
      console.log(this.previewpopupfile);
      this.previewpopupfile2 = this.previewpopupfile;
      this.checkfileapreview = true;
      this.dialogpreviewfile = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfile = false;
    },
    async popuppreviewimage() {
      this.dialogpreviewimage = true;
    },
    async closepreviewimage() {
      this.dialogpreviewimage = false;
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewimage = e.target.result;
          this.checkimageadd = true;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }

      this.imagename = this.image.name;
    },
    async previewMultiImage(event) {
      this.preview_list = [];
      // this.image_list = [];
      this.filename = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_list.push(e.target.result);
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      await this.getPdfforAdd(this.getpreview_list);
      this.preview_list2 = this.preview_list;
      this.image_list.forEach((x) => {
        this.filename.push(x.name);
      });
      this.checkfileeadd = true;
    },
    reset: function() {
      this.image = null;
      this.previewimage = null;
      this.image_list = [];
      this.preview_list = [];
    },
    async getPdfforpreview(datafile) {
      this.previewpopupfile = [];
      let getfile = [];
      getfile = datafile;
      this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    async getPdfforAdd(item) {
      this.preview_list = [];
      let getfile = [];
      getfile = item;
      getfile.forEach((file, index) => {
        this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
      });
      this.preview_list.forEach((item, index) => {
        item.then((pdf) => {
          this.numPages = pdf.numPages;
          // window.onscroll = function() {
          //   changePage();
          //   stickyNav();
          // };
          // Get the offset position of the navbar
          // var sticky = $("#buttons")[0].offsetTop;
          // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
          // function stickyNav() {
          //   if (window.pageYOffset >= sticky) {
          //     $("#buttons")[0].classList.remove("hidden");
          //   } else {
          //     $("#buttons")[0].classList.add("hidden");
          //   }
          // }
          function changePage() {
            var i = 1,
              count = Number(pdf.numPages);
            do {
              if (
                window.pageYOffset >=
                  this.findPos(document.getElementById(i)) &&
                window.pageYOffset <=
                  this.findPos(document.getElementById(i + 1))
              ) {
                this.page = i;
              }
              i++;
            } while (i < count);
            if (
              window.pageYOffset >= this.findPos(document.getElementById(i))
            ) {
              this.page = i;
            }
          }
        });
      });
      this.checkfileadd = true;
    },
    handle_pdf_link: function(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    async CalculateNewRenewalDate() {
      let addyear = 10;
      let addday = 1;
      console.log(this.showaddItem.date_of_renewal);
      console.log(this.date_of_renewal);
      if (
        this.showaddItem.date_of_renewal &&
        this.showaddItem.date_of_renewal != ""
      ) {
        this.date_of_renewal = new Date(
          new Date(this.date_of_renewal).setFullYear(
            new Date(this.date_of_renewal).getFullYear() + addyear,
            new Date(this.date_of_renewal).getMonth(),
            new Date(this.date_of_renewal).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showaddItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      } else {
        if (
          !this.showaddItem.date_of_application ||
          this.showaddItem.date_of_application == ""
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "Please enter Application Date Data",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.date_of_renewal = new Date(
          new Date(this.date_of_application).setFullYear(
            new Date(this.date_of_application).getFullYear() + addyear,
            new Date(this.date_of_application).getMonth(),
            new Date(this.date_of_application).getDate() - addday
          ) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.showaddItem.date_of_renewal = this.formatDate(
          this.date_of_renewal
        );
        this.disableCalculateNewRenewalDate = true;
      }
    },
    async Selectchangedate_of_application() {
      // this.disableCalculateNewRenewalDate = false;
    },

    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${year}/${month}/${day}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("/");
    //   return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    // },
    async setupAlertDialogPushpaymentIM(status, title, message, text_color, link,querydata) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
      this.querydata = querydata;
    },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
