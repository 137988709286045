import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getAllObjectType = () => {
    return httpClient.get(server.OBJECTTYPE + `/getAll`);
};

export const getObjectTypeByGroupId = (id) => {
    return httpClient.get(server.OBJECTTYPE + `/getByObjectgroup_id/${id}`);
};

export const createObjectType = (data) => {
    return httpClient.post(server.OBJECTTYPE, data);
};

export const updateObjectType = (id, data) => {
    return httpClient.put(server.OBJECTTYPE + `/${id}`, data);
};


export const deleteObjectType = (id) => {
    return httpClient.delete(server.OBJECTTYPE + `/${id}`);
};