//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";

export default {
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
  },
  data() {
    return {
      isShowPassword: false,
      fullPage: true,
    };
  },
  methods: {},
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
