//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "NewAdvance",
  data() {
    return {
      menu: false,
      menu2: false,
      add_leaveQuota: {},
      update_per: {},
      remarklist: {},
      dialogDeleteimage: false,
      desserts: [],
      headers: [
        {
          text: "ID",
          value: "leave_id",
          class: "bg-colorth",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Leave Type",
          align: "center",
          value: "leave_type",
          class: "bg-colorth texttablehcenter",
          width: "20%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Quota(Days)",
          value: "actionsD",
          class: "bg-colorth texttablehcenter ",
          width: "20%",
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "Quota(Hour)",
          value: "actionsH",
          class: "bg-colorth texttablehcenter ",
          width: "20%",
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "Used(Days)",
          align: "center",
          value: "use_leave_c",
          class: "bg-colorth texttablehcenter",
          width: "34%",
          align: "start",
          divider: true,
          sortable: false,
        },
      ],
      remark: "",
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      datefrom: "",
      dateto: "",
      checklistNull: 0,
      main_id: 0,
      main_periodfrom: "",
      main_periodto: "",
      periovdFrom: "",
      periovdTO: "",
      chechlog: 0,
      period_datefrom: "",
      period_dateto: "",
      statusAdd: "",
      status_update_dateperiod: 0, // 0 อัพเดตวัน 1 ไม่อัพเดตวัน
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    computeddatefromFormatted() {
      return unity.formatDate(this.period_datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.period_dateto);
    },
  },

  async mounted() {
    this.loadData();
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    this.$hideLoader();
  },
  methods: {
    async loadData() {
      this.main_id = this.$route.params.id;
      this.main_periodfrom = this.$route.params.period_datefrom;
      this.main_periodto = this.$route.params.period_dateto;
      const resultC = await api.getAccountByid(this.main_id);
      //console.log(resultC.data)
      this.company_id = resultC.data[0].company_id;
      this.company_name = resultC.data[0].company_name;
      this.employee_id = resultC.data[0].id;
      this.employee_name = resultC.data[0].name;
      this.department_name = resultC.data[0].department_name;
      this.position_name = resultC.data[0].position_name;
      this.period_datefrom = this.main_periodfrom;
      this.period_dateto = this.main_periodto;

      const res_dataedit = await api.getDataEditLeaveQuota(
        this.main_id,
        this.period_datefrom,
        this.period_dateto,
        Number(localStorage.getItem(server.COMPANYID))
      );
      this.desserts = res_dataedit.data;
      //console.log(res_dataedit.data);
      this.desserts.forEach((item) => {
        item.quotaD = Math.floor(item.quota);
        item.quotaH = Math.floor((item.quota % 1).toFixed(4) * 8);
        item.quotaDC = item.quotaD;
        item.quotaHC = item.quotaH;
        let useCD = 0;
        let useCH = 0;
        let useCM = 0;
        useCD = Math.floor(item.use_leave);
        useCH = Math.floor((item.use_leave - useCD).toFixed(4) * 8);
        useCM =
          ((item.use_leave - useCD).toFixed(4) * 8 - useCH).toFixed(1) * 60;
        item.use_leave_c =
          useCD + " Days " + useCH + " Hour " + useCM + " Minute";
        item.disabled = true;
        if (item.quota_id) {
          this.remark = item.remark;
        }
        //item.actionsH
      });
      console.log(this.desserts);
    },

    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async ckeckperiod() {
      if (this.period_datefrom > this.period_dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Period From > Period To",
          "text-h5 red--text text-center"
        );
        return;
      }
      //check period
      if (
        this.period_datefrom == this.main_periodfrom &&
        this.period_dateto == this.main_periodto
      ) {
        this.status_update_dateperiod = 0;
      } else {
        const checkperiod = await api.getcheckeditperiodquota(
          this.main_id,
          this.period_datefrom,
          this.period_dateto,
          this.main_periodfrom,
          this.main_periodto
        );
        //console.log(checkperiod.data)
        this.status_update_dateperiod = 1;
        if (checkperiod.data[0].status > 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "ช่วงเวลาซ้ำ กรุณาตรวจสอบข้อมูล",
            "text-h5 red--text text-center"
          );
          this.period_datefrom = this.main_periodfrom;
          this.period_dateto = this.main_periodto;
          return;
        }
      }
    },
    async saveQuota() {
      if (this.checklistNull == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Select Leave Quota ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.period_datefrom == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Enter Period From",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.period_dateto == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Enter Period To",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.period_datefrom > this.period_dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Period From > Period To",
          "text-h5 red--text text-center"
        );
        return;
      }
      //check period
      if (
        this.period_datefrom == this.main_periodfrom &&
        this.period_dateto == this.main_periodto
      ) {
        this.status_update_dateperiod = 0;
      } else {
        const checkperiod = await api.getcheckeditperiodquota(
          this.main_id,
          this.period_datefrom,
          this.period_dateto,
          this.main_periodfrom,
          this.main_periodto
        );
        //console.log(checkperiod.data)
        this.status_update_dateperiod = 1;
        if (checkperiod.data[0].status > 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed!!!",
            "ช่วงเวลาซ้ำ กรุณาตรวจสอบข้อมูล",
            "text-h5 red--text text-center"
          );
          return;
        }
      }

      for (let i = 0; i < this.desserts.length; i++) {
        if (this.desserts[i].disabled) {
        } else {
          if (this.desserts[i].quotaD == "") {
            this.desserts[i].quotaD = 0;
          }
          if (this.desserts[i].quotaH == "") {
            this.desserts[i].quotaH = 0;
          }
          if (this.desserts[i].quotaH < 0 || this.desserts[i].quotaH >= 8) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Failed!!!",
              "Please Check Data Quota(Hour) " +
                this.desserts[i].leave_type +
                " in ( 0 - 7 ) Hour",
              "text-h5 red--text text-center"
            );
            return;
          }
          if (this.desserts[i].quotaD < 0 || this.desserts[i].quotaH < 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Failed!!!",
              "Please Check Type Data Quota " + this.desserts[i].leave_type,
              "text-h5 red--text text-center"
            );
            return;
          }
          if (this.desserts[i].quotaD == 0 && this.desserts[i].quotaH == 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Failed!!!",
              "Please Enter Data Quota " + this.desserts[i].leave_type,
              "text-h5 red--text text-center"
            );
            return;
          }
          let quotaCheck = 0;
          quotaCheck =
            Number(this.desserts[i].quotaD) +
            Number(this.desserts[i].quotaH) / 8;
          if (quotaCheck < this.desserts[i].use_leave) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Failed!!!",
              "(Quota < Used ) Please Select New Leave Quota  ",
              "text-h5 red--text text-center"
            );
            return;
          }
        }
      }

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      // เพิ่มข้อมูล
      this.desserts.forEach(async (item, index) => {
        if (item.quota_id) {
          this.statusAdd = item.status;
        }
        if (this.status_update_dateperiod == 1) {
          if (item.quota_id) {
            this.update_per.leave_period_from = this.period_datefrom;
            this.update_per.leave_period_to = this.period_dateto;
            api.updateQuota(item.quota_id, this.update_per);
          }
        } else {
          if (item.quota_id) {
            this.remarklist.remark = this.remark;
            // console.log(this.remark)
            api.updateQuota(item.quota_id, this.remarklist);
          }
        }
        if (item.disabled) {
        } else {
          let quotacre = 0;
          quotacre = Number(item.quotaD) + Number(item.quotaH) / 8;
          if (item.quota_id) {
            this.add_leaveQuota.quota = quotacre;
            this.add_leaveQuota.remark = this.remark;
            api.updateQuota(item.quota_id, this.add_leaveQuota);
          } else {
            this.add_leaveQuota.employee_id = this.main_id;
            this.add_leaveQuota.leave_period_from = this.period_datefrom;
            this.add_leaveQuota.leave_period_to = this.period_dateto;
            this.add_leaveQuota.leave_type_id = item.id;
            this.add_leaveQuota.quota = quotacre;
            this.add_leaveQuota.remark = this.remark;
            this.add_leaveQuota.status = this.statusAdd;
            this.add_leaveQuota.company_id = this.company_id;
            // console.log(this.add_leaveQuota)
            api.createLeaveQuota(this.add_leaveQuota);
          }
        }
      });
      loader.hide();
      this.$store.state.global_push_dialog = true;
      this.setupAlertDialogPush(
        true,
        "Information Saved",
        "Information successfully saved",
        "text-h5 green--text text-center",
        "/leave-quota"
      );
    },
    async changeRoute(item) {
      console.log(item.item.quotaH);
      if (item.item.quotaH < 0 || item.item.quotaH >= 8) {
        item.item.quotaH = item.item.quotaHC;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Check Data Quota(Hour) " +
            item.item.leave_type +
            " in ( 0 - 7 ) Hour",
          "text-h5 red--text text-center"
        );
        return;
      }
      //console.log(item.item)
      if (item.item.quotaD < 0 || item.item.quotaH < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please Check Data ",
          "text-h5 red--text text-center"
        );
        return;
      }
      let quota = 0;
      quota = Number(item.item.quotaD) + Number(item.item.quotaH) / 8;
      // console.log(quota)
      if (quota < item.item.use_leave) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "(Quota < Used ) Please Select New Leave Quota  ",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    enterSelect(values) {
      if (values.length > 0) {
        this.checklistNull = 1;
      } else {
        this.checklistNull = 0;
      }
      this.desserts.forEach((item) => {
        item.disabled = true;
      });
      values.forEach((item, index) => {
        item.disabled = false;
      });
    },
    closeDelete() {
      this.sumtotal = 0;
      this.sumGrandTotal = 0;

      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        this.sumtotal += parseInt(item.amount, 10);
      });
      this.sumGrandTotal = (this.sumtotal * (1 + this.vatCal / 100)).toFixed(2);
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // deleteItem(item) {
    //   console.log(item.id)
    // },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      //console.log(this.image_list);
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
  },
  async beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
