//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  name: "AddIPPriceList",
  async mounted() {
    this.$showLoader();

    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");
    await this.loadObjecttype()
    await this.loadDoctype()
    await this.loadNotice()
    // alert(this.$route.params.id)
    if(this.$route.params.id){
       await this.loaddataedit()
    }
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      userId: 0,
      authorize: [],
      doctype_list: [],
      doctype_list_fiter: [],
      objecttype: [],
      notice_list: [],
      notice: 0,
      gropType: 0,
      des_pirices: "",
      thb_b: 0,
      us_b: 0,
      disableNotice: false,
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      load_bg: false,
      dialogAddKeyword: false,
      dialogAdd: false,
      dialogAddData: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      objecttype_id: 0,
      objecttype_id_old: 0,
      objectgroup_id: 0,
      objectgroup_id_old: 0,
      doctype: 0,
      doctype_old: 0,
      mDataArray: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_ct: "",
    };
  },
  methods: {
    async loadObjecttype() {
        const res_objecttype = await api.getAllObjectType()
        this.objecttype =  res_objecttype.data;
        // console.log( this.objecttype)
    },
    async loadDoctype() {
        const res_doctype = await api.getdocumenttypeAll()
        this.doctype_list_fiter =  res_doctype.data;
        this.doctype_list = this.doctype_list_fiter
        // console.log( this.doctype_list)
    },
    async loadNotice() {
        const res_notice = await api.getnotice_code()
        this.notice_list =  res_notice.data;
    },
    async loaddataedit(){
        const res_data_edit = await api.getIPPriceListByID(this.$route.params.id)
        // console.log(res_data_edit.data)
        this.objecttype_id = res_data_edit.data[0].objecttype_id;
        this.objecttype_id_old = res_data_edit.data[0].objecttype_id;

        await this.onSelectObjecttype();

        this.doctype = res_data_edit.data[0].work_type;
        this.doctype_old = res_data_edit.data[0].work_type;

        this.notice = res_data_edit.data[0].notice_id;
        this.notice_old = res_data_edit.data[0].notice_id;

        this.des_pirices = res_data_edit.data[0].price_desc;
        this.thb_b = res_data_edit.data[0].thb;
        this.us_b = res_data_edit.data[0].us;
    },
    async onSelectObjecttype() {
        this.doctype = 0;
        this.objecttype.forEach((item)=>{
            if(item.objecttype_id == this.objecttype_id){
                this.objectgroup_id  = item.objectgroup_id;
            }
        })
        this.doctype_list = this.doctype_list_fiter.filter((item) => {
            if(this.objectgroup_id == item.objectgroup_id){
                return item;
            }
        })
        if(this.objectgroup_id == 1){
            this.disableNotice = true;
        }else{
            this.disableNotice = false;
            this.notice = 0;
        }
        
    },
    async onSelectDoctype() {
        this.doctype_list.forEach((item)=>{
            if(item.documenttype_id == this.doctype){
                this.gropType = item.objectgroup_id;
            }
        })
        if(this.gropType == 1 || this.gropType == "1"){
            this.disableNotice = true;
        }else{
            this.disableNotice = false;
            this.notice = 0;
        }
    },
    async saveaddItem(){
        if (this.objecttype_id == 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter Object Type Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        if (this.doctype == 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter Work Type Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        if(this.disableNotice == true){
            
            if (this.notice == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please enter Notice Type Data",
                    "text-h5 red--text text-center"
                );
                return;
            }
        }
        if (this.des_pirices == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter Description Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        if (this.thb_b <= 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter THB  Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        if (this.us_b <= 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter US  Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        this.$showLoader()
        //check data
        let check_data = {
                objecttype_id: this.objecttype_id,
                work_type: this.doctype,
                notice_id: this.notice,
                objectgroup_id: this.objectgroup_id
            }
        let res_check = []
        // res_check = await api.checkIPPriceList(check_data)
        if(this.objecttype_id == this.objecttype_id_old){
            if(this.doctype == this.doctype_old){
                if(this.notice != this.notice_old){
                    res_check = await api.checkIPPriceList(check_data);
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Failed!!!",
                        "Please enter Check Data Duplicate",
                        "text-h5 red--text text-center"
                    );
                    return;
                }
            }else{
                res_check = await api.checkIPPriceList(check_data);
            }
        }else{
            res_check = await api.checkIPPriceList(check_data);
        }
        if (res_check.data[0].status == 1) {
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please enter Check Data Duplicate",
                    "text-h5 red--text text-center"
                );
                return;
        }
        //end check data

        //set data
        let additem = {
            objecttype_id: this.objecttype_id,
            work_type: this.doctype,
            notice_id: this.notice,
            price_desc: this.des_pirices,
            thb: Number(this.thb_b),
            us: Number(this.us_b),
        }
        let res_oper = [];
        if(this.$route.params.id){
            additem.update_by = localStorage.getItem(server.USER_ID);
            // console.log(additem)
            res_oper = await api.updateIPPriceList(this.$route.params.id,additem)
        }else{
            additem.create_by = localStorage.getItem(server.USER_ID);
            res_oper  =  await api.createIPPriceList(additem)
        }
        
        if (res_oper.status == 200 || res_oper.status == 201) {
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
                true,
                "save data!!!",
                "save data success!!!",
                "text-h5 green--text text-center",
                "/ip-price-list"
                );
            return;
        }
        
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
