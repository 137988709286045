import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const addRegsiterCompany = (data) => {
    return httpClient.post(server.REGISTER_COMPANY + `/`, data);
};
export const checkVerifyEmail = (data) => {
    return httpClient.post(server.REGISTER_COMPANY + `/email-verify`, data);
};