//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  name: "MappingList",
  async mounted() {
    this.$showLoader();

    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");
    await this.loadDoctype()
    await this.loadNotice()
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      userId: 0,
      authorize: [],
      doctype_list: [],
      notice_list: [],
      notice: 0,
      gropType: 0,
      keyword: "",
      disableNotice: false,
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      load_bg: false,
      dialogAddKeyword: false,
      dialogAdd: false,
      dialogAddData: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      doctype: 0,
      mDataArray: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_ct: "",
      itemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      },
      edititemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
      },
      contactid_del: 0,
      oparation: "",
      headers: [
        {
          text: "Work Type",
          value: "",
          class: "bg-colorth",
          divider: true,
          // width: "8%",
        },
        {
          text: "Notice Type",
          value: "contact_name",
          class: "bg-colorth",
          divider: true,
          // width: "10%",
        },
        
        {
          text: "",
          value: "actions",
          class: "bg-colorth",
          divider: true,
          width: "140px",
        },
      ],
    };
  },
  methods: {
    async loadDoctype() {
        const res_doctype = await api.getdocumenttypeAll()
        this.doctype_list =  res_doctype.data;
    },
    async loadNotice() {
        const res_notice = await api.getnotice_code()
        this.notice_list =  res_notice.data;
    },
    async onSelectDoctype() {
        this.doctype_list.forEach((item)=>{
            if(item.documenttype_id == this.doctype){
                this.gropType = item.objectgroup_id;
            }
        })
        if(this.gropType == 1 || this.gropType == "1"){
            this.disableNotice = true;
        }else{
            this.disableNotice = false;
            this.notice = 0;
        }
    },
    async saveaddItem(){
        if (this.doctype == 0) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter Work Type Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        if(this.disableNotice == true){
            
            if (this.notice == 0) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please enter Notice Type Data",
                    "text-h5 red--text text-center"
                );
                return;
            }
        }
        if (this.keyword == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed!!!",
                "Please enter Keyword Data",
                "text-h5 red--text text-center"
            );
            return;
        }
        const dataitem = {
            doctype_id: this.doctype,
            notice_type: this.notice,
            objectgroup_id: this.gropType,
            kw: this.keyword,
        }
        this.$showLoader()
        const res_add =  await api.createOCRKW(dataitem)
        // alert(res_add.status)
        
        if (res_add.status == 200 || res_add.status == 201) {
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
                true,
                "save data!!!",
                "save data success!!!",
                "text-h5 green--text text-center",
                "/mapping-keyword"
                );
            return;
        }
        
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
