import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllPosition = () => {
  return httpClient.get(server.POSITION + `/get/list`);
};
export const getAllPositionTranning = (company_id,department_id) => {
  return httpClient.get(server.POSITION + `/position-tranning/${company_id}/${department_id}`);
};

export const getAllPosition_By_CompanyID = (id) => {
  return httpClient.get(server.POSITION + `/get/list_By_CompanyID/${id}`);
};

export const addPosition = (data) => {
  return httpClient.post(server.POSITION + `/add`, data);
};

export const updatePosition = (id, data) => {
  return httpClient.put(server.POSITION + `/update/${id}`, data);
};
