import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";

export const getAlltmupdatestatus = () => {
    return httpClient.get(server.TMUPDATESTATUS + `/getAll`);
};

export const gettmupdatestatusById = (id) => {
    return httpClient.get(server.TMUPDATESTATUS + `/getBytmupdatestatus_id/${id}`);
};

export const createtmupdatestatus = (data) => {
    return httpClient.post(server.TMUPDATESTATUS, data);
};

export const updatetmupdatestatus = (id, data) => {
    return httpClient.put(server.TMUPDATESTATUS + `/${id}`, data);
};


export const deletetmupdatestatus = (id) => {
    return httpClient.delete(server.TMUPDATESTATUS + `/${id}`);
};