//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";

export default {
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);
    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
    if (!this.authorize_view) {
      this.$router.back();
    }

    await this.loaddataappointmentLimit200();
    await this.loaddatadocumenttype();
    this.$hideLoader();
    // await this.loaddataappointment();
    // this.$showLoader();
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data(vm) {
    return {
      table_id_export_list: "",
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      exportleavequota: "appointmenttrademark.xlsx",
      sheetName: "appointmenttrademark",
      sheets: [
        {
          name: "appointmenttrademark",
          data: [],
        },
      ],
      itemsPerPageArray: [10, 15, 20],
      page: 1,
      pageSize: 3,
      pageSizes: [3, 6, 9],
      pageCount: 0,
      itemsPerPage: 100,
      deletedItem: 0,
      dialogDelete: false,
      searchdatefrom: "",
      searchdateto: "",
      searchdatefromF: "",
      searchdatetoF: "",
      searchdocumenttype: "",
      searchtrademark_name: "",
      searchfile_no: "",
      searchapp_no: "",
      menuadddatefrom: false,
      menuadddateto: false,
      documenttype_list: [],
      isShowPassword: false,
      fullPage: true,
      search: "",
      dateCurrent: vm.formatDateday(new Date().toISOString().substr(0, 10)),
      headers: [
        {
          text: "File No.",
          align: "left",
          // sortable: false,
          value: "refno",
          divider: true,
        },
        { text: "Application No.", value: "app_no", divider: true },
        { text: "Trademark Name", value: "trademark_name", divider: true },
        { text: "Agent", value: "agent_contact_name", divider: true },

        {
          text: "Work Type",
          align: "left",
          // sortable: true,
          value: "documenttype_code",
          width: "10%",
          divider: true,
        },
        { text: "Notice Type", value: "notice_name", divider: true },
        { text: "Final Deadline", value: "enddate", divider: true },
        { text: "Filed Date", value: "filed_date", divider: true },
        { text: "Status", value: "statusnow", divider: true },
        {
          text: "",
          value: "actions",
          width: "15%",
          align: "center",
          divider: true,
        },
      ],
      mDataArray: [],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      load_bg: false,
    };
  },
  methods: {
    async export_log(){
      if(this.table_id_export_list !=""){
          var dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Export",
              table_name: "appointment",
              table_id: this.table_id_export_list,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)
      }
    },
    onCreated(wb) {

    },
    onChange(wb) {
      console.log(`onChange`)
      // console.log(wb);
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
      wb.Sheets.appointmenttrademark.A1 = {
        t: "s",
        v: `Export Appointment Trademark as of   ${this.dateCurrent}`,
      };
      wb.Sheets.appointmenttrademark.B1 = { t: "s", c: 0, v: "" };
      wb.Sheets.appointmenttrademark.C1 = { t: "s", c: 0, v: "" };
      wb.Sheets.appointmenttrademark.D1 = { t: "s", c: 0, v: "" };
      wb.Sheets.appointmenttrademark.E1 = { t: "s", c: 0, v: "" };
      wb.Sheets.appointmenttrademark.F1 = { t: "s", v: "" };
      wb.Sheets.appointmenttrademark.G1 = { t: "s", v: "" };
      wb.Sheets.appointmenttrademark.H1 = { t: "s", v: "" };
      wb.Sheets.appointmenttrademark.I1 = { t: "s", v: "" };
      wb.Sheets.appointmenttrademark.J1 = { t: "s", v: "" };
      wb.Sheets.appointmenttrademark.K1 = { t: "s", v: "" };
      // wb.Sheets.appointmenttrademark.L1 = { t: "s", v: "" };
      // wb.Sheets.appointmenttrademark.M1 = { t: "s", v: "" };
      // wb.Sheets.appointmenttrademark.N1 = { t: "s", v: "" };
      // wb.Sheets.appointmenttrademark.O1 = { t: "s", v: "" };
      // wb.Sheets.appointmenttrademark.P1 = { t: "s", v: "" };

      wb.Sheets.appointmenttrademark.A3 = { t: "s", v: "Work Type" };
      wb.Sheets.appointmenttrademark.B3 = { t: "s", v: "File No." };
      wb.Sheets.appointmenttrademark.C3 = { t: "s", v: "Application No." };
      wb.Sheets.appointmenttrademark.D3 = { t: "s", v: "Trademark Name" };
      wb.Sheets.appointmenttrademark.E3 = { t: "s", v: "Receive Date" };
      wb.Sheets.appointmenttrademark.F3 = { t: "s", v: "Final Deadline" };
      wb.Sheets.appointmenttrademark.G3 = { t: "s", v: "Internal Date" };
      wb.Sheets.appointmenttrademark.H3 = { t: "s", v: "Source Type" };
      wb.Sheets.appointmenttrademark.I3 = { t: "s", v: "Status" };
      wb.Sheets.appointmenttrademark.J3 = { t: "s", v: "Description" };
      wb.Sheets.appointmenttrademark.K3 = { t: "s", v: "Remark" };
      // wb.Sheets.appointmenttrademark.I3 = { t: "s", v: "report" };
      // wb.Sheets.appointmenttrademark.J3 = { t: "s", v: "payment Request" };
      // wb.Sheets.appointmenttrademark.K3 = { t: "s", v: "Cert" };
      // wb.Sheets.appointmenttrademark.L3 = { t: "s", v: "Done" };
      // wb.Sheets.appointmenttrademark.M3 = { t: "s", v: "Next90" };
      // wb.Sheets.appointmenttrademark.N3 = { t: "s", v: "Next60" };

      wb.Sheets.appointmenttrademark["!merges"] = merge;

    },
    async loaddataappointmentLimit200() {
      // this.$showLoader();

      const result = await api.getAllAppointment200();
      console.log(result.data);
      // const result = await api.getAllMasterTM();
      this.mDataArray = result.data;
      await this.setexporttoxlsx();

      // this.$hideLoader();
    },
    async loaddataappointment() {
      const result = await api.getAllAppointment();
      console.log(result.data);
      this.mDataArray = result.data;
      let setexportxlsx = [];
      await this.setexporttoxlsx();
    },
    async onSearchAll() {
      if (this.searchdocumenttype) {
        this.onSelectdocumenttype();
      } else if (this.searchtrademark_name) {
        this.onSearchtrademark_name();
      } else if (this.searchfile_no) {
        this.onSearchfile_no();
      } else if (this.searchapp_no) {
        this.onSearchapp_no();
      }
    },
    async onSelectdocumenttype(data) {
      this.$showLoader();
      this.searchtrademark_name = "";
      this.searchfile_no = "";
      this.searchapp_no = "";
      // this.searchdatefrom = "";
      // this.searchdateto = "";
      console.log(this.searchdocumenttype);
      const result = await api.searchdataAppointment({
        params: {
          searchdocumenttype: this.searchdocumenttype,
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async onSearchtrademark_name() {
      this.$showLoader();
      this.searchfile_no = "";
      this.searchapp_no = "";
      this.searchdocumenttype = "";
      // this.searchdatefrom = "";
      // this.searchdateto = "";
      const result = await api.searchdataAppointment({
        params: {
          searchtrademark_name: this.searchtrademark_name.trim(),
          searchdatefrom: this.searchdatefrom,
          searchdateto: this.searchdateto,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async onSearchfile_no() {
      this.$showLoader();
      this.searchapp_no = "";
      this.searchtrademark_name = "";
      this.searchdocumenttype = "";
      // this.searchdatefrom = "";
      // this.searchdateto = "";
      const result = await api.searchdataAppointment({
        params: {
          searchfile_no: this.searchfile_no.trim(),
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async onSearchapp_no() {
      this.$showLoader();
      this.searchfile_no = "";
      this.searchtrademark_name = "";
      this.searchdocumenttype = "";
      // this.searchdatefrom = "";
      // this.searchdateto = "";
      const result = await api.searchdataAppointment({
        params: {
          searchapp_no: this.searchapp_no.trim(),
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async onSearchdatefrom() {
      this.$showLoader();
      // this.searchfile_no = "";
      // this.searchtrademark_name = "";
      // this.searchdocumenttype = "";
      // this.searchapp_no = "";
      if (!this.searchdatetoF) {
        this.$hideLoader();
        return;
      }
      const result = await api.searchdataAppointment({
        params: {
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
          searchfile_no: this.searchfile_no,
          searchtrademark_name: this.searchtrademark_name,
          searchdocumenttype: this.searchdocumenttype,
          searchapp_no: this.searchapp_no,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async onSearchdateto() {
      this.$showLoader();
      // this.searchfile_no = "";
      // this.searchtrademark_name = "";
      // this.searchdocumenttype = "";
      // this.searchapp_no = "";
      if (!this.searchdatefromF) {
        this.$hideLoader();
        return;
      }
      const result = await api.searchdataAppointment({
        params: {
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
          searchfile_no: this.searchfile_no,
          searchtrademark_name: this.searchtrademark_name,
          searchdocumenttype: this.searchdocumenttype,
          searchapp_no: this.searchapp_no,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async SearchAll() {
      this.$showLoader();
      const result = await api.searchdataAppointment({
        params: {
          searchdatefrom: this.searchdatefromF,
          searchdateto: this.searchdatetoF,
          searchfile_no: this.searchfile_no,
          searchtrademark_name: this.searchtrademark_name,
          searchdocumenttype: this.searchdocumenttype,
          searchapp_no: this.searchapp_no,
        },
      });
      if (result.status == 204) {
        await this.loaddataappointmentLimit200();
        this.$hideLoader();
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Search data !!!",
        //   "Please input Search data!!!",
        //   "text-h5 red--text text-center"
        // );
        // return;
      } else {
        this.mDataArray = result.data;
        let setexportxlsx = [];
        await this.setexporttoxlsx();
        this.$hideLoader();
      }
    },
    async loaddatadocumenttype() {
      const result = await api.getdocumenttype_code();
      console.log(result.data);
      this.documenttype_list = result.data;
      // const result = await api.getAllMasterTM();
      // this.mDataArray = result.data;
    },
    async openAppointmentadd() {
      let file_no = " ";
      let date_of_renewal = "null";
      localStorage.setItem(server.MODELMENU, 6);
      this.$router.push(`/appointmenttm-add/${file_no}/${date_of_renewal}`);
    },
    async openeditItem(appointment_id) {
      // this.$router.push(`/appointmenttm-edit/${appointment_id}`);
      window.open(`/appointmenttm-edit/${appointment_id}`);
    },
    deleteItem(item) {
      this.deletedIndex = this.mDataArray.indexOf(item);
      console.log(item.appointment_id);
      this.deletedItem = item.appointment_id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.deletedItem = 0;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      this.mDataArray.splice(this.deletedIndex, 1);
      this.dialogDelete = false;
      // let setflag = 0;
      // // 0 = delete
      // const resdel = await api.AppointmentUpdate(this.deletedItem, {
      //   ocr_flag: setflag,
      // });
      const resdel = await api.AppointmentDelete(this.deletedItem);
      console.log(resdel);
      if (resdel.status == 200) {

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Delete",
            table_name: "appointment",
            table_id: this.deletedItem,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
          last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        // this.loaddatadetails();
        this.closeDelete();
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialog(
          true,
          "delete data!!!",
          "delete data success!!!",
          "text-h5 green--text text-center"
        );
        return;
      } else {
        // this.loaddatadetails();
        this.closeDelete();
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "delete data!!!",
          "can't delete data!!!",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async setexporttoxlsx() {
      var table_id_export = "";
      let setexportxlsx = [{}, {}];
      this.mDataArray.forEach((x, index) => {
        
        if (!x.done) {
          x.done = 0;
        }
        if(index == 0){
          table_id_export = `${table_id_export}${x.appointment_id}`;
        }else{
          table_id_export = `${table_id_export} , ${x.appointment_id}`;
        }
        
        setexportxlsx.splice(index + 2, 0, {
          WorkType: x.documenttype_code,
          FileNo: x.refno,
          ApplicationNo: x.app_no,
          TrademarkName: x.trademark_name,
          ReceiveDate: x.receive_date
            ? new Date(this.changeformatDatetoinsert(x.receive_date))
            : "",
          FinalDate: x.enddate
            ? new Date(this.changeformatDatetoinsert(x.enddate))
            : "",
          InternalDate: x.internaldate ? new Date(x.internaldate) : "",
          SourceType: x.sourcetype,
          Status: x.statusnow,
          Description: x.description,
          Remark: x.remark,
        });
      });

      this.sheets[0].data = setexportxlsx;

      this.table_id_export_list = table_id_export;
    },
    formatDateday(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  watch: {
    searchdatefromF(val) {
      this.searchdatefrom = this.formatDate(this.searchdatefromF);
    },
    searchdatetoF(val) {
      this.searchdateto = this.formatDate(this.searchdatetoF);
    },
  },
};
