import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getInvTSByID = (inv_id) => {
    return httpClient.get(server.INVOICE_TS + `/by-inv-id/${inv_id}`);
};
export const addInvTS = (data) => {
    return httpClient.post(server.INVOICE_TS + `/create`, data);
};
export const deleteInvTS = (id) => {
    return httpClient.delete(server.INVOICE_TS + `/delete/${id}`);
};

export const getAllInvTSBytimesheetCreeper_hid = (data) => {
    return httpClient.post(
      server.INVOICE_TS + `/detail_timesheetCreeper_hid`,
      data
    );
  };