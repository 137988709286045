import httpClient from "@/services/httpClient";
import {
    server
} from "@/services/constants";


export const getdocumenttypeAll = () => {
    return httpClient.get(server.DOCUMENTTYPE + `/getdocumenttypeAll`);
};

export const getdocumenttype_code = () => {
    return httpClient.get(server.DOCUMENTTYPE + `/getdocumenttype_code`);
};

export const getdocumenttype_ptcode = () => {
    return httpClient.get(server.DOCUMENTTYPE + `/getdocumenttype_ptcode`);
};

export const getdocumenttype_codeByobjecttype_id = (id) => {
    return httpClient.get(server.DOCUMENTTYPE + `/getdocumenttype_code_byobjecttype_id/${id}`);
};

export const getdocumenttypeByID = (id) => {
    return httpClient.get(server.DOCUMENTTYPE + `/getdocumenttypebyid/${id}`);
};