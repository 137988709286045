//
//
//
//
//
//
//
//
//
//

import Header from "@/components/core/Header";
import Menu from "@/components/core/Menu";
import Content from "@/components/core/Content";
import AustinHairlineRoman from "./assets/FONT-DEJUDOM/AustinHairline-Roman.otf";

export default {
  name: "App",
  components: {
    Header,
    Menu,
    Content,
  },
  mounted() {
    this.$store.dispatch({ type: "restoreLogin" });
  },
};
