//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Solist.js";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "SalseOrder For Invoice",
  data(vm) {
    return {
      customer_id: 0,
      dialogAdd: false,
      idInvCN: [],
      dialogConfirm: false,
      disableinstatus: true,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      dataAll: [],
      status: ["Draft", "Submit"],
      datefrom: null,
      dateto: null,
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      mDataArray: [],
      mDataArrayRD: [],
      authorize: [],
      selected: [],
      company_id: 0,
      headers: [
        {
          text: "Ivoice/CN",
          value: "receive_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Doc Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
          align: "start",
        },
        {
          text: "Customer Name",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "sub_total",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
        {
          text: "Currency",
          value: "currency",
          class: "bg-colorth tabletextwhite",
          align: "center",
        },
      ],
      headersRD: [
        {
          text: "Doc No.",
          value: "receive_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Doc Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Credit Term",
          value: "credit_term_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "sub_total",
          class: "bg-colorth tabletextwhite",
          align: "end"
        },
        {
          text: "Currency",
          value: "currency",
          class: "bg-colorth tabletextwhite",
        },
      ],
    };
  },
  components: {
    SuccessDialog,
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {},
  beforeUpdate() {},
  computed: {
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    this.userId = localStorage.getItem(server.USER_ID);
    await this.loaddataInvCN();
  },
  methods: {
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom != null && this.dateto != null) {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        }
        if (this.datefrom == null && this.dateto == null) {
          return item;
        }
      });
    },
    async loaddataInvCN() {
      this.company_id = this.$route.params.company_inv;
      const result = await api.getReceiveSelect_ListBycompany({
        params: { company_id: this.company_id },
      });
      this.dataAll = result.data;
      this.dataAll.forEach((item) => {
        item.sub_total = tolocalestringnumber(Number(item.sub_total));
      });
      this.mDataArray = this.dataAll;
      console.log(this.mDataArray);
      this.$hideLoader();
    },
    enterSelect(values) {
      console.log(values);
      if (values.length > 0) {
        this.disableinstatus = false;
      } else {
        this.disableinstatus = true;
      }
      //senddata to create
      if (values.length == 1) {
        if (values[0].customer_id != null) {
          this.customer_id = values[0].customer_id;
          this.idInvCN[0] = values[0].id;
          console.log(this.idInvCN);
        } else {
          this.customer_id = 0;
        }
      } else if (values.length > 1) {
        if (values[0].customer_id != null) {
          this.customer_id = values[0].customer_id;
        } else {
          this.customer_id = 0;
        }
        let customer_check = values[0].customer_name;
        for (let i = 0; i < values.length; i++) {
          this.idInvCN[i] = values[i].id;
          if (customer_check != values[i].customer_name) {
            values.splice(i, 1);
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "โปรดเลือกลูกค้าเดียวกัน!!!",
              "text-h5 red--text text-center"
            );
          }
        }
      }
    },
    async closedialogConfirm() {
      this.dialogConfirm = false;
    },
    async opendialogConfirm() {
      this.mDataArrayRD = this.selected;
      this.dialogConfirm = true;
    },
    SendDatatoAdd() {
      this.idInvCN = [];
      this.selected.forEach((item, index) => {
        this.idInvCN[index] = item.id;
      });
      // console.log(this.idInvCN)
      this.$router.push(
        `/receive-confirm/${this.company_id}/${this.customer_id}/${this.idInvCN}`
      );
    },
  },
};
