import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getPlaceofissue= () => {
    return httpClient.get(server.IM_MASTER_PLACE_OF_ISSUE);
};

export const getImMasterPlaceofissueByCompany = (query) => {
    return httpClient.get(server.IM_MASTER_PLACE_OF_ISSUE +`/getbycompany`,query);
};

export const getImMasterPlaceofissueByDep = (dep_id) => {
    return httpClient.get(server.IM_MASTER_PLACE_OF_ISSUE +`/get-by-dep_id/${dep_id}`);
};

export const getImMasterPlaceofissueByID = (id) => {
    return httpClient.get(server.IM_MASTER_PLACE_OF_ISSUE +`/get-by-id/${id}`);
};

export const addImMasterPlaceofissue = (data) => {
    return httpClient.post(server.IM_MASTER_PLACE_OF_ISSUE, data);
};

export const UpdateImMasterPlaceofissue = (id, data) => {
    return httpClient.put(server.IM_MASTER_PLACE_OF_ISSUE +`/${id}`,data);
};

export const DeleteImMasterPlaceofissue = (id) => {
    return httpClient.delete(server.IM_MASTER_PLACE_OF_ISSUE +`/${id}`);
};

export const getImMasterPlaceofissueByCompany_Dep = (query) => {
    return httpClient.get(server.IM_MASTER_PLACE_OF_ISSUE +`/getbycompanyanddepartment`,query);
};