import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdataByIdim_participant = (id) => {
  return httpClient.get(server.IM_PARTICIPANT + `/getbyid/${id}`);
};

export const getAllim_participant_Company = (query) => {
    return httpClient.get(server.IM_PARTICIPANT + `/getAllim_participant_Company`, query);
  };

export const addim_participant = (data) => {
  return httpClient.post(server.IM_PARTICIPANT + `/`, data);
};

export const updateim_participant = (id,data) => {
  return httpClient.put(server.IM_PRINCIPAL + `/${id}`, data);
};

export const deleteim_participant = (id) => {
  return httpClient.delete(server.IM_PARTICIPANT + `/${id}`);
};
