//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import {
  server,
  fileSaleorderUrl,
  imageSaleorderUrl,
} from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";

export default {
  name: "NewAdvance",
  data() {
    return {
      vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
      os_h: {
        customerid: 0,
        remark: "",
        contact_position: "",
        po_no: "",
        // po_date: "",
        // delivery_date: "",
        po_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        delivery_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        additional: "",
        status: 0,
        image: "",
      },
      os_d: {
        sales_order_h_id: null,
        item_name: "",
        seq: 0,
        item_qty: 0,
        item_price: 0,
        item_line_amt: 0,
        user_update: localStorage.getItem(server.USER_ID),
      },
      preview_list: [],
      oldpreview_list: [],
      dialogDeleteimage: false,
      sumtotal: 0,
      vatCal: 0,
      sumGrandTotal: 0,
      vat: "",
      vat_type:"E",
      cus_id: 0,
      close_status: false,
      dialogDeleteForm: false,
      image_list: [],
      datecreate_at: "",
      saleorder_h_id: 0,
      saleorder_id: "",
      branch: "",
      customer_code: "",
      tax_id: "",
      currency: "",
      payment_term: "",
      invoice_address: "",
      customer_address: "",
      customer_list: [],
      customer_name: "",
      sosubmitstatusbtn: true,
      sostatus: false,
      showbtnadd: true,
      advance_code: [],
      advance_code_list: [],
      advance_code_id: 0,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      customer_id: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      initial_data2: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: false,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",

      selectbankname: "",

      radioType: ["cash", "cheque"],
      radioGroup: "",
      setusername: localStorage.getItem(server.USERNAME),
      role: localStorage.getItem(server.ROLE),
      department: "DCM",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service/Description",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "35%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          width: "8%",
          divider: true,
          sortable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },

      advance_log: {
        module: "SO",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      customerName: {},
      sostatus: false,
      customer_code: "",
      filenameattach: [],
      previewpopupattachfile2: [],
      previewpopupattachfile: [],
      checkattachfileapreview: false,
      dialogpreviewattachfile: false,
      dialogpreviewattachimage: false,
      dialogconfirmdeleteattachfile: false,
      numPagespreviewattach: 0,
      previewattachimage: "",
      indexdeleteattachfile: -1,
      getpreview_listattach: [],
      preview_listattach: [],
      image_listattach: [],
    };
  },
  components: {
    pdf: pdfvuer,
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDatePOFormatted() {
      return unity.formatDate(this.os_h.po_date);
    },
    computedDateDeliveryFormatted() {
      return unity.formatDate(this.os_h.delivery_date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();
    await this.$forceUpdate();
    await this.loaddatadefault();
    await this.initialize();
    await this.loadCustomer();
    await this.loadAdvanceCode();

    const res_acc = await api.getAccountDetail(
      localStorage.getItem(server.USER_ID)
    );

    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));

    this.user_sign = res_acc.data[0].signature;

    this.showsig1 = true;
    this.dialogSignature = true;
    this.showdefaultsignature();
    this.savesig1();
    // this.$refs.firstsignaturePad.lockSignaturePad();

    // let yourUrlString = window.location;
    // alert(yourUrlString);

    // let parser = document.createElement("a");
    // parser.href = yourUrlString;

    // parser.protocol; // => "http:"
    // parser.hostname; // => "example.com"
    // parser.port; // => "3000"
    // parser.pathname; // => "/pathname/"
    // parser.search; // => "?search=test"
    // parser.hash; // => "#hash"
    // parser.host; // => "example.com:3000"

    // alert(parser.pathname);

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    const result = await api.getAllCustomerListactiveCompany({
      params: {
        company_id: localStorage.getItem(server.COMPANYID),
      },
    });
    // console.log("result.data : ", JSON.stringify(result.data));
    this.customer = result.data;

    result.data.forEach((item) => {
      this.customer_list.push(item.name);
    });

    // console.log("Login mounted : " + api.isLoggedIn());
    // console.log("Login mounted : " + userId);
    // await this.loadProducts();
  },
  methods: {
    async previewattachfile(index) {
      if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
        this.previewpopupattachfile2 = [];
        await this.getPforpreviewattach(this.getpreview_listattach[index]);
        this.previewpopupattachfile2 = this.previewpopupattachfile;
        console.log(this.previewpopupattachfile2);
        this.checkattachfileapreview = true;
        this.dialogpreviewattachfile = true;
      } else {
        this.previewattachimage = this.getpreview_listattach[index];
        this.dialogpreviewattachimage = true;
      }
    },
    async getPforpreviewattach(datafile) {
      this.previewpopupattachfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupattachfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupattachfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreviewattach = pdf.numPages;
        });
      });
    },
    async closepreviewattachfile() {
      this.dialogpreviewattachfile = false;
    },
    async closepreviewattachimage() {
      this.dialogpreviewattachimage = false;
    },
    async confirmdeleteattachfile(index) {
      this.indexdeleteattachfile = index;
      this.dialogconfirmdeleteattachfile = true;
    },
    async closedialogconfirmdeleteattachfile() {
      this.indexdeleteattachfile = -1;
      this.dialogconfirmdeleteattachfile = false;
    },
    async deletedialogconfirmdeleteattachfile() {
      this.filenameattach.splice(this.indexdeleteattachfile, 1);
      this.preview_listattach.splice(this.indexdeleteattachfile, 1);
      this.image_listattach.splice(this.indexdeleteattachfile, 1);
      this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
      this.dialogconfirmdeleteattachfile = false;
    },
    async loaddatadefault() {
      this.main_id = this.$route.params.id;
      const res_h = await api.getAllSOById(this.main_id);
      console.log("res_h.data")
      console.log(res_h.data)
      if (res_h.data[0]) {
        let dataso_h = res_h.data[0];
        if (dataso_h.image) {
          this.filenameattach = dataso_h.image.split(",");
          this.filenameattach.forEach((x, index) => {
            if (x.split(/[\s.]+/).pop() == "pdf") {
              this.image_listattach.push(
                `${fileSaleorderUrl}/${this.$route.params.id}/${x}`
              );
              this.getpreview_listattach.push(
                `${fileSaleorderUrl}/${this.$route.params.id}/${x}`
              );
            } else {
              this.image_listattach.push(
                `${imageSaleorderUrl}/${this.$route.params.id}/${x}`
              );
              this.getpreview_listattach.push(
                `${imageSaleorderUrl}/${this.$route.params.id}/${x}`
              );
            }
          });
          // let setiamggfile = dataso_h.image.split(",");
          // setiamggfile.forEach((x) => {
          //   this.oldpreview_list.push(`${imageUrl}/${x}`);
          // });
        }
        if (dataso_h.status === "Draft") {
          this.sosubmitstatusbtn = true;
          this.sostatus = false;
        } else {
          this.sosubmitstatusbtn = false;
          this.sostatus = true;
        }
        this.saleorder_id = dataso_h.saleorder_id;
        this.datecreate_at = dataso_h.created_at;
        this.customer_id = dataso_h.customer_id;
        this.customer_name = dataso_h.name;
        this.customer_address = dataso_h.customer_address;
        this.invoice_address = dataso_h.customer_inv_address;
        this.payment_term = dataso_h.payment_term;
        this.currency = dataso_h.currency;
        this.vat = dataso_h.vat;
        this.vat_type = dataso_h.vat_type;
        this.vatCal = dataso_h.sum_tax;
        this.customer_code = dataso_h.customer_code;
        this.tax_id = dataso_h.tax_id;
        this.branch = dataso_h.branch;
        this.os_h.remark = dataso_h.remark;
        this.os_h.contact_position = dataso_h.contact_position;
        this.os_h.po_no = dataso_h.po_no;
        this.os_h.po_date = dataso_h.po_date;
        this.os_h.delivery_date = dataso_h.delivery_date;
        this.os_h.additional = dataso_h.additional;
        this.os_h.customer_id = dataso_h.customer_id;
      }
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      const res_d = await api.getAlldetailSOById(this.main_id);
      this.desserts = res_d.data;
      this.calvatAll();
      loader.hide();
    },
   async save() {
      // alert("texttable")
      if (this.editedItem.description == "") {
        // alert("กรุณากรอก Description/Service ");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอก Description/Service!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtDescription"].focus();
        return;
      }

      if (
        this.editedItem.unit == undefined ||
        this.editedItem.unit == "0" ||
        this.editedItem.unit == 0
      ) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Qty!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (this.editedItem.unit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Qty ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (
        this.editedItem.PricePerUnit == undefined ||
        this.editedItem.PricePerUnit == "0" ||
        this.editedItem.PricePerUnit == 0
      ) {
        // alert("กรุณากรอกจำนวนเงินต่อหน่วย");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Price Per Unit!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtPricePerUnit"].focus();
        return;
      }

      if (this.editedItem.PricePerUnit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Price Per Unit ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }
      // if (this.editedItem.amount == 0 ) {
      //   alert("กรุณากรอกจำนวนเงิน");
      //   this.$refs["txtAmount"].focus();
      //   return;
      // }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
     await this.calvatAll();


      this.close();
    },
    calvatAll(vat) {
      this.sumtotal = 0;
      this.sumGrandTotal = 0;
      this.vatCal = 0;
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      
      if(this.vat_type == "E"){
        this.sumtotal = (this.sumtotal).toFixed(2);
      this.vatCal = (this.sumtotal * (this.vat / 100)).toFixed(2);
      this.sumGrandTotal = (this.sumtotal * (1 + this.vat / 100)).toFixed(2);
      }else if(this.vat_type == "I"){
        this.sumGrandTotal = this.sumtotal.toFixed(2);
        this.sumtotal = (this.sumGrandTotal / (1 + this.vat / 100)).toFixed(2);
        this.vatCal = (this.sumGrandTotal - this.sumtotal).toFixed(2);
      }else{
      }
      this.vatCal = tolocalestringnumber(this.vatCal);
      this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal);
    },

    closeDialogSendmail() {
      this.dialogSendMail = false;
      this.$router.push("/advance-list");
    },
    async loadAdvanceCode() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      result.data.forEach((item) => {
        this.advance_code_list.push(item.code + " " + item.description);
      });
    },
    onSelectAdvanceCodeChange(acvance_code) {
      // var tmp = null;
      // tmp = this.customer.filter((c) => c.name.includes(search_customer));

      // this.customer_id = tmp[0].id;
      // console.log("acvance_code : " + acvance_code);
      var tmp = acvance_code.split(" ");
      this.editedItem.code = tmp[0];
      this.editedItem.description = tmp[1];
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },

    changeFocusText(t) {
      // alert(t);
      if (t === 1) {
        this.$refs["txtCode"].focus();
      } else if (t === 2) {
        this.$refs["txtDescription"].focus();
      } else if (t === 3) {
        this.$refs["txtAmount"].focus();
        this.$refs["txtunit"].focus();
        this.$refs["txtPricePerUnit"].focus();
        // if (this.editedItem.code == "") {
        //   alert("กรุณากรอกรหัสการขออนุมัติ");
        //   this.$refs["txtCode"].focus();
        // } else {
        //   this.$refs["txtAmount"].focus();
        // }
      }
    },
    initialize() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += item.amount;
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async loadCustomer() {
      // console.log("loadCustomer");
      let result = [];
      if (this.sosubmitstatusbtn === true) {
        result = await api.getAllCustomerListactiveCompany({
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        });
      } else {
        result = await api.getAllCustomerListCompany({
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        });
      }
      
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;
      this.customer.forEach((item) => {
        item.address = `${item.address} ${item.provice} ${item.country} ${item.zipcode}`
        item.invoice_address = `${item.address_invoice} ${item.province_invoice} ${item.country_invoice} ${item.zipcode_invoice}`
      })
      // console.log("this.customer55")
      // console.log(this.customer)

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });

      //   console.log("this customer:" + JSON.stringify(this.customer));

      //   console.log("this customer:" + this.advance_h.customer_id);
      //   var tmp = null;
      //   tmp = this.customer.filter((c) =>
      //     c.id.includes(1)
      //   );

      let cs_id = parseInt(this.advance_h.customer_id, 10);

      let fill_cus = this.customer.filter(function(c) {
        return c.id == cs_id;
      });
      //   console.log("this customer:" + fill_cus);
      //   console.log("this customer:" + JSON.stringify(fill_cus));
      this.customer_name = fill_cus[0].name;
    },
    closeDelete() {
      this.calvatAll();

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDeleteForm() {
      // alert("closeDeleteForm");
      this.dialogDeleteForm = false;
      this.close_status = true;
      this.closeStatus();
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirmForm() {
      // alert("deleteItemConfirmForm");
      this.deleteForm();
      this.closeDeleteForm();
    },
    async deleteForm() {
      // alert("deleteForm");
      this.close_status = false;
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      this.dialogDeleteForm = true;

      await api.deleteSOById(this.$route.params.id);
      await api.deletedetailSOById(this.$route.params.id);
      this.$store.state.global_push_dialog = true;
      this.setupAlertDialogPush(
        true,
        "ลบข้อมูล!!!",
        "ลบข้อมูลสำเร็จ",
        "text-h5 green--text text-center",
        "/so-list"
      );

      loader.hide();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            // this.preview_list.push(e.target.result);
            this.getpreview_listattach.push(e.target.result);
            this.preview_listattach.push(e.target.result);
          };
          if (
            input.files[index].type === "application/pdf" ||
            input.files[index].type.split("/")[0] === "image"
          ) {
            this.image_listattach.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.image_list);
      this.image_listattach.forEach((x) => {
        if (x.name) {
          this.filenameattach.push(x.name);
        } else {
          this.filenameattach.push(x.split(/[\s/]+/).pop());
        }
      });
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
      this.oldpreview_list = [];
    },
    closeSaveDraft() {},

    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
    onSelectCustomerCodeChange(code) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.cust_account.includes(code));
      console.log(tmp);
      this.customer_id = tmp[0].id;
      this.customer_name = tmp[0].name;
      this.customer_address = tmp[0].address;
      this.invoice_address = tmp[0].address_invoice;
      this.payment_term = tmp[0].payment_term;
      this.currency = tmp[0].currency;
      this.customer_code = tmp[0].cust_account;
      this.branch = tmp[0].branch;
      this.tax_id = tmp[0].taxid;
      this.vat = tmp[0].vat;
      this.vat_type = tmp[0].vat_type;
      this.vatCal = tmp[0].vat;
      this.os_h.customerid = this.customer_id;
      this.os_h.remark = tmp[0].contact_name;
      this.os_h.contact_position=  tmp[0].contact_position;
      console.log(this.customer_address)
      this.calvatAll();
    },
    onSelectCustomerChange(search_customer) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.name.includes(search_customer));
      console.log(tmp);
      this.customer_id = tmp[0].id;
      this.customer_address = tmp[0].address;
      this.invoice_address = tmp[0].address_invoice;
      this.payment_term = tmp[0].payment_term;
      this.currency = tmp[0].currency;
      this.customer_code = tmp[0].cust_account;
      this.branch = tmp[0].branch;
      this.tax_id = tmp[0].taxid;
      this.vat = tmp[0].vat;
      this.vat_type = tmp[0].vat_type;
      this.vatCal = tmp[0].vat;
      this.os_h.customerid = this.customer_id;
      this.os_h.remark = tmp[0].contact_name;
      this.os_h.contact_position=  tmp[0].contact_position;
      this.calvatAll();
    },
    async saveSOdraftstatus() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 1;
      this.advance_log.approve_date = new Date();

      let formData = new FormData();
      this.image_listattach.forEach((x, index) => {
        formData.append(`file`, x);
      });

      if (this.image_listattach.length > 0) {
        let getnamefilenameattach = "";
        this.filenameattach.forEach((x, index) => {
          if (index > 0) {
            getnamefilenameattach += `,${x}`;
          } else {
            getnamefilenameattach += `${x}`;
          }
        });
        formData.append(
          `attachment_files`,
          getnamefilenameattach.replace(/\s/g, "_")
        );
      } else {
        formData.append(`attachment_files`, "");
      }
      formData.append("customerid", this.customer_id);
      formData.append("customer_inv_address", this.invoice_address);
      formData.append("company_id", localStorage.getItem(server.COMPANYID));
      formData.append("customer_address", this.customer_address);
      formData.append("payment_term", this.payment_term);
      formData.append("currency", this.currency);
      formData.append("vat", stringcommatonumber(this.vat.toString()));
      formData.append("vat_type", this.vat_type);
      formData.append(
        "sum_disc",
        stringcommatonumber(this.sumtotal.toString())
      );
      formData.append("sum_tax", stringcommatonumber(this.vatCal.toString()));
      formData.append(
        "sum_line_amt",
        stringcommatonumber(this.sumGrandTotal.toString())
      );
      formData.append("remark", this.os_h.remark);
      formData.append("contact_position", this.os_h.contact_position);
      formData.append("po_no", this.os_h.po_no);
      formData.append("po_date", this.os_h.po_date);
      formData.append("delivery_date", this.os_h.delivery_date);
      formData.append("additional", this.os_h.additional);
      formData.append("status", 1);
      const updateso_h = await api.UpdateSO_H(this.main_id, formData);
      if (updateso_h.status == 200 || updateso_h.status == 201) {
        await api.deletedetailSOById(this.$route.params.id);
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            // this.advance_d.advance_hid = res_h.data.id;
            this.os_d.sales_order_h_id = this.$route.params.id;
            this.os_d.seq = item.no;
            this.os_d.item_name = item.description;
            this.os_d.item_qty = stringcommatonumber(item.unit.toString());
            this.os_d.item_price = stringcommatonumber(
              item.PricePerUnit.toString()
            );
            this.os_d.item_line_amt = stringcommatonumber(
              item.amount.toString()
            );
            console.log(this.os_d);
            api.addDetailSO(this.os_d);
          });
        }

        this.sendMail.name = localStorage.getItem(server.FULLNAME);
        this.sendMail.email = localStorage.getItem(server.APPROVE_LEVEL1_EMAIL);
        this.sendMail.advance_id = this.$route.params.id;
        console.log("send_request1 : " + JSON.stringify(this.sendMail));
        const send_request = await api.sendMail(this.sendMail);

        console.log("send_request2 : " + JSON.stringify(send_request));
        if (send_request.status == 200) {
          // this.$store.state.global_dialog = true;

          // this.setupAlertDialogPush(
          //   true,
          //   "Information Saved",
          //   "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ!!!",
          //   "text-h5 green--text text-center",
          //   "/advance-list"
          // );

          const res_log = await api.addAdvanceLog(this.advance_log);
          // console.log("res_log : " + JSON.stringify(res_log.status));

          if (res_log.status == 201) {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              "/so-list"
            );
          } else {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้!!!",
              "text-h5 red--text text-center"
            );
          }
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้!!!",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
    },
    async saveSOsubmit() {
      if (this.desserts.length <= 0) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณากรอกรายละเอียด Advance Detail";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด SO Detail!!!",
          "text-h5 red--text text-center"
        );
        return;
      }

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 2;
      this.advance_log.approve_date = new Date();

      let formData = new FormData();
      this.image_listattach.forEach((x, index) => {
        formData.append(`file`, x);
      });

      if (this.image_listattach.length > 0) {
        let getnamefilenameattach = "";
        this.filenameattach.forEach((x, index) => {
          if (index > 0) {
            getnamefilenameattach += `,${x}`;
          } else {
            getnamefilenameattach += `${x}`;
          }
        });
        formData.append(
          `attachment_files`,
          getnamefilenameattach.replace(/\s/g, "_")
        );
      } else {
        formData.append(`attachment_files`, "");
      }

      formData.append("customerid", this.customer_id);
      formData.append("customer_inv_address", this.invoice_address);
      formData.append("company_id", localStorage.getItem(server.COMPANYID));
      formData.append("customer_address", this.customer_address);
      formData.append("payment_term", this.payment_term);
      formData.append("currency", this.currency);
      formData.append("vat", stringcommatonumber(this.vat.toString()));
      formData.append("vat_type", this.vat_type);
      formData.append(
        "sum_disc",
        stringcommatonumber(this.sumtotal.toString())
      );
      formData.append("sum_tax", stringcommatonumber(this.vatCal.toString()));
      formData.append(
        "sum_line_amt",
        stringcommatonumber(this.sumGrandTotal.toString())
      );
      formData.append("remark", this.os_h.remark);
      formData.append("contact_position", this.os_h.contact_position);
      formData.append("po_no", this.os_h.po_no);
      formData.append("po_date", this.os_h.po_date);
      formData.append("delivery_date", this.os_h.delivery_date);
      formData.append("additional", this.os_h.additional);
      formData.append("status", 2);
      const updateso_h = await api.UpdateSO_H(this.main_id, formData);
      if (updateso_h.status == 200 || updateso_h.status == 201) {
        await api.deletedetailSOById(this.$route.params.id);
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            // this.advance_d.advance_hid = res_h.data.id;
            this.os_d.sales_order_h_id = this.$route.params.id;
            this.os_d.seq = item.no;
            this.os_d.item_name = item.description;
            this.os_d.item_qty = stringcommatonumber(item.unit.toString());
            this.os_d.item_price = stringcommatonumber(
              item.PricePerUnit.toString()
            );
            this.os_d.item_line_amt = stringcommatonumber(
              item.amount.toString()
            );
            console.log(this.os_d);
            api.addDetailSO(this.os_d);
          });

          this.sendMail.name = localStorage.getItem(server.FULLNAME);
          this.sendMail.email = localStorage.getItem(
            server.APPROVE_LEVEL1_EMAIL
          );
          this.sendMail.advance_id = this.$route.params.id;
          console.log("send_request1 : " + JSON.stringify(this.sendMail));
          const send_request = await api.sendMail(this.sendMail);

          console.log("send_request2 : " + JSON.stringify(send_request));
          if (send_request.status == 200) {
            // this.$store.state.global_dialog = true;

            // this.setupAlertDialogPush(
            //   true,
            //   "Information Saved",
            //   "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ!!!",
            //   "text-h5 green--text text-center",
            //   "/advance-list"
            // );

            const res_log = await api.addAdvanceLog(this.advance_log);
            // console.log("res_log : " + JSON.stringify(res_log.status));

            if (res_log.status == 201) {
              this.$store.state.global_push_dialog = true;
              this.setupAlertDialogPush(
                true,
                "Information Saved",
                "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ!!!",
                "text-h5 green--text text-center",
                "/so-list"
              );
            } else {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Information Saved",
                "ไม่สามารถบันทึกได้!!!",
                "text-h5 red--text text-center"
              );
            }
          }
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้!!!",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
    },
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.showdefaultsignature();
  },
};
