//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
export default {
  components: {
    SuccessDialog,
  },
  data: () => ({
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    search: "",
    dialogadd: false,
    dialogedit: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Code", value: "code" },
      { text: "Name", value: "name" },
      // { text: "address", value: "address" },
      // { text: "provice", value: "provice" },
      // { text: "zipcode", value: "zipcode" },
      { text: "Status", value: "status" },
      { text: "Action", value: "actions" },
    ],
    desserts: [],
    itemadd: {
      code: "",
      name: "",
      company_address: "",
      company_branch: "",
      company_email: "",
      company_tel: "",
      inv_company_name: "",
      inv_company_name_th: "",
      inv_company_address: "",
      inv_company_branch: "",
      inv_company_email: "",
      inv_company_tel: "",
      inv_tax_id: "",
      status: "",
      image: null,
    },
    itemeditid: 0,
    itemedit: {
      code: "",
      name: "",
      company_address: "",
      company_branch: "",
      company_email: "",
      company_tel: "",
      inv_company_name: "",
      inv_company_name_th: "",
      inv_company_address: "",
      inv_company_branch: "",
      inv_company_email: "",
      inv_company_tel: "",
      inv_tax_id: "",
      status: "",
      image: null,
    },
    statusall: ["ACTIVE", "INACTIVE"],
    disabled_status: false,
    image_link: "",
    checkSameComInv: false,
    numberRules: [
                (v) =>
                    /^[0-9]+$/.test(v) || "Please Input Number Only",
                ],
                telRules: [
                (v) =>
                    /^[+]?([0-9]+)$/.test(v) || "Please Input Number Only",
                ],
                emailRules: [
                (v) =>
                    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    v
                    ) || "Please check that the Email",
                ],
                comcodeRules: [
                    (v) =>{
                        return v.length > 6 ? "Please check that the Company Code" : true;
                    }
                ],
  }),
  async mounted() {
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    if (localStorage.getItem(server.ROLE) != "ADMIN") {
      this.authorize_add = false;
      this.disabled_status = true;
    }

    if (!this.authorize_view) {
      // console.log("res_auth:" + JSON.stringify(res_auth.data));
      // console.log("authorize_view:" + this.authorize_view);
      // console.log("authorize_add:" + this.authorize_add);
      // console.log("authorize_edit:" + this.authorize_edit);
      // console.log("authorize_del:" + this.authorize_del);
      // this.$router.back();

      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let token = localStorage.getItem(server.USER_ID);

    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    await this.getcompanydata();
  },
  watch: {
            checkSameComInv(newVal) {
             this.checkSameComInvChanged(newVal);
            }
        },
  methods: {
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async getcompanydata() {
      if (localStorage.getItem(server.ROLE) == "ADMIN") {
        const result = await api.getAllCompany();
        this.desserts = result.data;
      } else {
        const result = await api.getCompanyByID(
          localStorage.getItem(server.COMPANYID)
        );
        this.desserts = result.data;
      }

      // this.$showLoader();
      this.$hideLoader();
    },

    openadd() {
      this.itemadd = {
        code: "",
        name: "",
        status: "",
      };
      this.dialogadd = true;
    },
    canceladd() {
      this.dialogadd = false;
    },
    canceledit() {
      this.dialogedit = false;
    },
    async savedata() {
      if (this.itemadd.code == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Company Code",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Company Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemadd.status == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please select Status",
          "text-h5 red--text text-center"
        );
        return;
      }

      let formData = new FormData();
      formData.append("code", this.itemadd.code);
      formData.append("name", this.itemadd.name);
      formData.append("status", this.itemadd.status);
      // formData.append("image", this.itemadd.image);

      if (this.itemadd.image != null || this.itemadd.image != "") {
        formData.append("image", this.itemadd.image);
      }

      // const res_add = await api.addCompany(this.itemadd);
      const res_add = await api.addCompany(formData);
      if (res_add.status == 200 || res_add.status == 201) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success!!!",
          "Add data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Add data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.dialogadd = false;
    },

    async openeditItem(item) {
      this.itemeditid = item.id;
      this.itemedit.code = item.code;
      this.itemedit.name = item.name;
      this.itemedit.company_address=item.company_address,
      this.itemedit.company_branch=item.company_branch,
      this.itemedit.company_email=item.company_email,
      this.itemedit.company_tel=item.company_tel,
      this.itemedit.inv_company_name=item.inv_company_name,
      this.itemedit.inv_company_name_th=item.inv_company_name_th,
      this.itemedit.inv_company_address=item.inv_company_address,
      this.itemedit.inv_company_branch=item.inv_company_branch,
      this.itemedit.inv_company_email=item.inv_company_email,
      this.itemedit.inv_company_tel=item.inv_company_tel,
      this.itemedit.inv_tax_id=item.inv_tax_id,
      this.itemedit.status = item.status;
      if (item.image != null) {
        // this.itemedit.image = item.image;
        this.image_link = imageUrl + "/" + item.image;
      } else {
        this.image_link = imageUrl + "/personal.png";
        // this.itemedit.image = null;
      }
      // alert(this.image_link);
      // this.itemedit.image = item.image;
      this.dialogedit = true;
    },
    async savedataedit() {
      if (this.$refs.form.validate()) {
       
                if(this.itemedit.name.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Name Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Name"].focus();
                    return;
                }
                if(this.itemedit.company_address.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Invoice Address Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Address"].focus();
                    return;
                }
                if(this.itemedit.company_branch.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Branch Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Branch"].focus();
                    return;
                }
                if(this.itemedit.company_email.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Email Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Email"].focus();
                    return;
                }
                if(this.itemedit.company_tel.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Telephone Number Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Telephone_Number"].focus();
                    return;
                }

                if(this.itemedit.code.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Code Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Company_Code"].focus();
                    return;
                }
                if(this.itemedit.inv_company_name.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Name Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Company_Name"].focus();
                    return;
                }
                if(this.itemedit.inv_company_name_th.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Name Thai Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Company_Name_TH"].focus();
                    return;
                }
                if(this.itemedit.inv_company_address.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Invoice Address Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["Invoice_Address"].focus();
                    return;
                }
                if(this.itemedit.inv_company_branch.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Branch Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Branch"].focus();
                    return;
                }
                if(this.itemedit.inv_company_email.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Company Email Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Email"].focus();
                    return;
                }
                if(this.itemedit.inv_company_tel.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Telephone Number Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Telephone_Number"].focus();
                    return;
                }
                if(this.itemedit.inv_tax_id.trim() == ""){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Check Tax ID Data",
                    "text-h5 red--text text-center"
                    );
                    this.$refs["INV_Tax_ID"].focus();
                    return;
                }




      let formData = new FormData();
      formData.append("name", this.itemedit.name);
      formData.append("company_address", this.itemedit.company_address);
      formData.append("company_branch", this.itemedit.company_branch);
      formData.append("company_email", this.itemedit.company_email);
      formData.append("company_tel", this.itemedit.company_tel);
      formData.append("inv_company_name", this.itemedit.inv_company_name);
      formData.append("inv_company_name_th", this.itemedit.inv_company_name_th);
      formData.append("inv_company_address", this.itemedit.inv_company_address);
      formData.append("inv_company_branch", this.itemedit.inv_company_branch);
      formData.append("inv_company_email", this.itemedit.inv_company_email);
      formData.append("inv_company_tel", this.itemedit.inv_company_tel);
      formData.append("inv_tax_id", this.itemedit.inv_tax_id);
      formData.append("status", this.itemedit.status);

      if (this.itemedit.image != null || this.itemedit.image != "") {
        formData.append("image", this.itemedit.image);
      }

      // const res_update = await api.updateCompany(
      //   this.itemeditid,
      //   this.itemedit
      // );
      const res_update = await api.updateCompany(this.itemeditid, formData);

      console.log(res_update);
      if (res_update.status == 200 || res_update.status == 201) {
        //   alert("debug1");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success!!!",
          "Update data success",
          "text-h5 green--text text-center"
        );
        this.getcompanydata();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Update data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.dialogedit = false;
    }
    },
    async checkSameComInvChanged (value){
                if (value) {
                    this.itemedit.inv_company_name = this.itemedit.name.trim();
                    this.itemedit.inv_company_address = this.itemedit.company_address.trim();
                    this.itemedit.inv_company_branch = this.itemedit.company_branch.trim();
                    this.itemedit.inv_company_email = this.itemedit.company_email.trim();
                    this.itemedit.inv_company_tel = this.itemedit.company_tel.trim();
                }else{
                    this.itemedit.inv_company_name = "";
                    this.itemedit.inv_company_address = "";
                    this.itemedit.inv_company_branch = "";
                    this.itemedit.inv_company_email = "";
                    this.itemedit.inv_company_tel = "";
                }
            },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
