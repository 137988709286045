import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllMasterTM = () => {
  return httpClient.get(server.MASTERTM + `/getAll`);
};

export const getAllMasterTMLimit200 = () => {
  return httpClient.get(server.MASTERTM + `/getAllLimit200`);
};

export const getMasterTMById = (id) => {
  return httpClient.get(server.MASTERTM + `/getById/${id}`);
};

export const getMasterTMSearch = (query) => {
  return httpClient.get(server.MASTERTM + `/searchdata`, query);
};

export const getMasterTMFileNoSearch = (searchapplication_no) => {
  return httpClient.get(
    server.MASTERTM + `/getapplication_no/${searchapplication_no}`
  );
};

export const createMasterTM = (data) => {
  return httpClient.post(server.MASTERTM, data);
};

export const updateMasterTM = (mastertm_id, data) => {
  return httpClient.put(server.MASTERTM + `/${mastertm_id}`, data);
};

export const deleteMasterTM = (id) => {
  return httpClient.delete(server.MASTERTM + `/${id}`);
};

export const getfile_no = (searchfile_no) => {
  return httpClient.get(server.MASTERTM + `/getfile_no/${searchfile_no}`);
};
