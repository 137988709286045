import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getdataByIdim_transaction = (id) => {
  return httpClient.get(server.IM_TRANSACTION + `/getbyid/${id}`);
};

export const getAllim_transaction_Company = (query) => {
    return httpClient.get(server.IM_TRANSACTION + `/getAllim_transaction_Company`, query);
  };

  export const getfileno_im_transaction_Company = (query) => {
    return httpClient.get(server.IM_TRANSACTION + `/getfileno_im_transaction_Company`, query);
  };

export const addim_transaction = (data) => {
  return httpClient.post(server.IM_TRANSACTION + `/`, data);
};

export const updateim_transaction = (id,data) => {
  return httpClient.put(server.IM_TRANSACTION + `/${id}`, data);
};

export const updateByfile_noim_transaction = (id,data) => {
  return httpClient.put(server.IM_TRANSACTION + `/updateByfile_no/${id}`, data);
};

export const deleteim_transaction = (id) => {
  return httpClient.delete(server.IM_TRANSACTION + `/${id}`);
};
