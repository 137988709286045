//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "StockCard",
  props: ["title", "subtitle", "avatar_bg", "avatar_ic"],
};
