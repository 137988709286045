import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getTRANNING_USER = () => {
  return httpClient.get(server.TRANNING_USER);
};

export const getTRANNING_USERById = id => {
    return httpClient.get(server.TRANNING_USER + `/${id}`);
};

  
export const addTRANNING_USER = data => {
    return httpClient.post(server.TRANNING_USER, data);
};
  
export const updateTRANNING_USER = (id,data) => {
    return httpClient.put(server.TRANNING_USER + `/${id}`, data);
};

export const deleteTRANNING_USER = id => {
    return httpClient.delete(server.TRANNING_USER + `/${id}`);
};

