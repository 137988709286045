import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const readTextOCR = (id, file_name) => {
  return httpClient.get(server.OCR + `/read-text/${id}/${file_name}`);
};

export const getOCR = () => {
  return httpClient.get(server.OCR);
};

export const checkAppointmentOCR = (app_no, file_no) => {
  return httpClient.get(
    server.OCR + `/check_appointment_ocr/${app_no}/${file_no}`
  );
};

export const mapKeyWordTM = (app_no, file_no) => {
  return httpClient.get(server.OCR + `/map-keyword-tm/${file_no}/${app_no}`);
};

export const mapKeyWordPT = (app_no, file_no) => {
  return httpClient.get(server.OCR + `/map-keyword-pt/${file_no}/${app_no}`);
};

export const getOCRbyID = (id) => {
  return httpClient.get(server.OCR + `/by-id/${id}`);
};

export const getOCRbyFileName = (file_name) => {
  return httpClient.get(server.OCR + `/by-name/${file_name}`);
};

export const getTrademarkOCR = () => {
  return httpClient.get(server.OCR + `/getTrademarkOCR`);
};

export const getPatentOCR = () => {
  return httpClient.get(server.OCR + `/getPatentOCR`);
};

export const findLastIDOCR = () => {
  return httpClient.get(server.OCR + `/findLastId`);
};

export const updateOCR = (id, data) => {
  return httpClient.put(server.OCR + `/${id}`, data);
};

export const addImageFileOCR = (doc_id, data) => {
  return httpClient.post(server.OCR + `/addImageFile/${doc_id}`, data);
};

export const deleteOCR = (id) => {
  return httpClient.delete(server.OCR + `/delete/${id}`);
};
