//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";

export default {
  name: "invoice",
  data(vm) {
    return {
      dataformatpr: {
        headtitle: "Receive",
        title: "Dej-Udom & Associates LTD.",
        subtitle: "ATTORNEY AT LAW",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
        titleth: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
        address1: "CHARN ISSARA TOWER 9 FLOOR",
        address2: "942/69 RAMA IV ROAD,",
        address3: "KWAENG SURIYAWONG, KHET BANGRAK,",
        address4: "BANGKOK 10500, THAILAND",
        tel: "Tel : 0-2233-0055, 0-2267-5152",
        fax: "Fax : 0.2236-6681, 0-2233-0227",
        email: "E-MAIL:account@dejudom.com",
        taxid: 'TAX ID. No. 0992000028227 / "HEAD OFFICE"',
      },
      datashowAll: [],
      setdatatoshow: [],
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "P.Invoice", "Invoice", "Cancel"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [
        {
          id: "97",
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          customer: "testcus",
          status: "Draft",
        },
      ],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        {
          text: "Invoice No.",
          value: "invoice_no",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Customer",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await this.loaddataInvoice();
  },
  methods: {
    editItem() {},

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    finddatafromdate() {
      //alert(this.datefrom)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom != null && this.dateto != null) {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        }
        if (this.datefrom == null && this.dateto == null) {
          return item;
        }
      });
    },
    onClicktonewinvoice(id) {
      let name = "inv_h";
      this.$router.push(`/add-credit-note/${id}`);
    },
    async loaddataInvoice() {
      const result = await api.getcreditnoteBycompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.dataAll = result.data;
      console.log(this.dataAll);
      //   console.log("loadLeave", JSON.stringify(this.dataAll));
      this.mDataArray = result.data;
      this.mDataArray.forEach((item) => {
        if (item.inv_id) {
          item.invoice_no = item.inv_id;
        } else if (item.pinv_id) {
          item.invoice_no = item.pinv_id;
        } else {
          item.invoice_no = "####";
        }
      });

      // this.$showLoader();
      this.$hideLoader();
    },
    async checkcontent(data) {
      let totalclient = 0;
      console.log("send data ====");
      console.log(this.dataforshowprpo[0]);
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 34;
      let datainlineprpo = 45;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.dataforshowprpo[0].length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.dataforshowprpo[0][i].detail.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.dataforshowprpo[0][i].no = i + 1;
        totalclient += parseFloat(
          this.dataforshowprpo[0][i].duration
            ? this.dataforshowprpo[0][i].duration
            : 0
        );
        if (i == 0) {
          this.checkdepartmentname = this.dataforshowprpo[0][i].department_name;
          this.dataforshowprpo[0][i].changedepartment = true;
        } else {
          if (
            this.checkdepartmentname !==
            this.dataforshowprpo[0][i].department_name
          ) {
            this.dataforshowprpo[0][i].changedepartment = true;
            this.checkdepartmentname = this.dataforshowprpo[0][
              i
            ].department_name;
            hllinedetails++;
          }
        }
        stringchecklength = this.dataforshowprpo[0][i].detail;

        // let myArrayname = this.dataforshowprpo[i].NAME.split("\n");
        // for (let a = 0; a < myArrayname.length; a++) {
        //   let lengthnewline = myArrayname[a].length;
        //   for (let b = 0; b < myArrayname[a].length; b++) {
        //     if (
        //       myArrayname[a][b] == maiek ||
        //       myArrayname[a][b] == maito ||
        //       myArrayname[a][b] == maitree ||
        //       myArrayname[a][b] == maijattawa ||
        //       myArrayname[a][b] == vowelei ||
        //       myArrayname[a][b] == vowelee ||
        //       myArrayname[a][b] == voweleue ||
        //       myArrayname[a][b] == voweleu ||
        //       myArrayname[a][b] == vowelu ||
        //       myArrayname[a][b] == voweluu ||
        //       myArrayname[a][b] == maihun ||
        //       myArrayname[a][b] == maiyamok
        //     ) {
        //       lengthnewline--;
        //     }

        //   }

        for (let j = 0; j < this.dataforshowprpo[0][i].detail.length; j++) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

        // newhllinedetails = Math.ceil(
        //   this.dataforshowprpo[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.dataforshowprpo[0][i]);
        // console.log(getdata.length);
        // console.log(hllinedetails + newhllinedetails + getdata.length);
        // console.log(hllinedetails);
        // console.log(newhllinedetails);
        // console.log(getdata.length);
        // console.log("=================================");
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(i + " ============== " + this.dataforshowprpo[0].length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.dataforshowprpo[0][i]);
          hllinedetails += newhllinedetails;
          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.dataforshowprpo[0][i]);

          if (i == this.dataforshowprpo[0].length - 1) {
            this.dataforshowprpo[0][i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.pageAll += this.lengthAlldatasearch - 1;
      this.opensummarize = true;
      console.log(this.pageAll);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      console.log(this.datashowAll);
    },
  },
};
