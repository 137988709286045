import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";


export const getAllAdvanceCodeByCompany = (company_id) => {
  return httpClient.get(
    server.ADVANCE_CODE_URL + `/getAllByCompany/${company_id}`
  );
};
export const getAllAdvanceCodeByCompanyList = (company_id) => {
  return httpClient.get(
    server.ADVANCE_CODE_URL + `/getAllByCompany-list/${company_id}`
  );
};

export const createAdvanceCode = (data) => {
  return httpClient.post(
    server.ADVANCE_CODE_URL + `/`,data
  );
};
export const updateAdvanceCode = (id,data) => {
  return httpClient.put(
    server.ADVANCE_CODE_URL + `/${id}`,data
  );
};

export const deleteAdvanceCode = (id) => {
  return httpClient.delete(
    server.ADVANCE_CODE_URL + `/${id}`
  );
};

