import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const getRequestAllH = () => {
  return httpClient.get(server.ADVANCE_H_URL + `/get`);
};

export const getRequestH = (employee_id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/get/${employee_id}`);
};

export const getRequestHBycompany = (employee_id, query) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getbycompany/${employee_id}`, query);
};

export const getAllByCus = (customer_id, company_id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getbycustomer/${customer_id}/${company_id}`);
};

export const getAllHById = (id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getAllById/${id}`);
};

export const getclearRequestH = (employee_id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getclear/${employee_id}`);
};

export const getclearRequestHByCompany = (employee_id, query) => {
  return httpClient.get(server.ADVANCE_H_URL + `/getclearbycompany/${employee_id}`, query);
};

export const addAdvanceH = (data) => {
  return httpClient.post(server.ADVANCE_H_URL, data);
};

export const gatAdvanceHByManyId = (data) => {
  return httpClient.post(server.ADVANCE_H_URL + `/getAllById/select`, data);
};

export const updateAdvanceH = (id, data) => {
  return httpClient.put(server.ADVANCE_H_URL + `/${id}`, data);
};

export const updateclearAdvanceH = (id, data) => {
  return httpClient.put(server.ADVANCE_H_URL + `/clear/${id}`, data);
};

export const sendMail = (data) => {
  return httpClient.post(server.ADVANCE_H_URL + `/sendMail`, data);
};

export const deleteAdvanceH = (id) => {
  return httpClient.delete(server.ADVANCE_H_URL + `/${id}`);
};


//account advance 
export const getAccountPending = () => {
  return httpClient.get(server.ADVANCE_H_URL + `/accountpending`);
};

export const getAccountPendingByCompany = (query) => {
  return httpClient.get(server.ADVANCE_H_URL + `/accountpendingbycompany`, query);
};

export const getAccountClear = () => {
  return httpClient.get(server.ADVANCE_H_URL + `/accountclear`);
};

export const getAccountClearByCompany = (query) => {
  return httpClient.get(server.ADVANCE_H_URL + `/accountclearbycompany`, query);
};


//All detail id advance
export const getDetailiIdAdvance = (id) => {
  return httpClient.get(server.ADVANCE_H_URL + `/detailadvanceid/${id}`);
};