import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getAllAppointment200 = () => {
  return httpClient.get(server.APPOINTMENT + `/getAllLimit200`);
};
export const getAllPTAppointment200 = () => {
  return httpClient.get(server.APPOINTMENT + `/getAllPTLimit200`);
};

export const getAllAppointment = () => {
  return httpClient.get(server.APPOINTMENT + `/getAll`);
};

export const AppointmentgetById = (id) => {
  return httpClient.get(server.APPOINTMENT + `/getById/${id}`);
};

export const searchdataAppointment = (query) => {
  return httpClient.get(server.APPOINTMENT + `/searchdata`, query);
};
export const searchdataAppointmentPT = (query) => {
  return httpClient.get(server.APPOINTMENT + `/searchdatapt`, query);
};

export const AppointmentAdd = (data) => {
  return httpClient.post(server.APPOINTMENT, data);
};

export const AppointmentUpdate = (id, data) => {
  return httpClient.put(server.APPOINTMENT + `/${id}`, data);
};

export const AppointmentDelete = (id) => {
  return httpClient.delete(server.APPOINTMENT + `/${id}`);
};

export const AppointmentUpdateclientname = (refno, data) => {
  return httpClient.put(
    server.APPOINTMENT + `/updateclientname/${refno}`,
    data
  );
};

export const AppointmentImportxlsx = (data) => {
  return httpClient.post(server.APPOINTMENT + `/importxlsx`, data);
};
export const AppointmentImportxlsxpatent = (data) => {
  return httpClient.post(server.APPOINTMENT + `/importxlsxpatent`, data);
};

export const getfile_noforappointment = (searchfile_no) => {
  return httpClient.get(server.APPOINTMENT + `/getfile_no/${searchfile_no}`);
};
